<template>
  <div class="h-section_1">
    <!-- mob -->
    <div v-if="$store.state.isMobile" class="group_6 flex-col">
    <div class="box_1 flex-col justify-end">
      <span class="text_1">{{ $t("INDEX.INTRODUCTION.CONTENT") }}</span>
      <span class="text_2">{{ $t("INDEX.INTRODUCTION.TITLE") }}</span>
      <span class="text_3" style="margin-top: 10px;">{{ $t("INDEX.INTRODUCTION.TEXT1") }}</span>
      <span class="text_3" style="margin-top: 10px;">{{ $t("INDEX.INTRODUCTION.TEXT2") }}</span>
      <div class="list_1 flex-row">
        <div
          class="image-text_1 flex-col justify-between"
          v-for="(item, index) in loopData0"
          :key="index"
          @click="() => {curIndex = index}"
        >
          <img
            class="image_1"
            
            :src="item.lanhuimage0"
          />
          <span
            class="text-group_1"
            :style="{ color: curIndex == index ? 'rgba(19,20,21,1)' : 'rgba(147,153,166,1)' }"
            v-html="item.lanhutext0"
          ></span>
          <div v-if="curIndex == index" class="section_1 flex-col"></div>
        </div>
      </div>
      <div class="section_1 flex-col"></div>
    </div>
    <div class="box_2 flex-col" :style="{backgroundImage: 'url(' + require(`@/assets/details/home_section_${curIndex + 1}.png`) + ')'}"></div>
    <div class="box_3 flex-col">
      <span class="text_4">{{$t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TITLE`)}}</span>
      <span class="paragraph_1">
        {{$t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TEXT1`)}}<br />
        {{$t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TEXT2`)}}<br />
        {{$t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TEXT3`) == `INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TEXT3` ? '' : $t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TEXT3`)}}
        </span>
      <div class="group_1 flex-row justify-between" @click="$router.push(loopData0[curIndex].lanhutext0url)">
        <span class="text_5">{{ $t("INDEX.INTRODUCTION.BTN") }}</span>
        <img
          class="thumbnail_1"         
          src="@/assets/lxl/jinru_h.png"
        />
      </div>
      <div class="group_2 flex-row" 
        @click="goRouter($t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TITLE`) == '音声SIM' ? $CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.SIM_CARD_ONLY : $t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TITLE`) == 'モバイルWi-Fi' ? $CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.WIFI_ONLY : $t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TITLE`) == '光回線' ? $CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.LIGHT_ONLY : $CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.GENERAL_MERCHANDISE)">
        <span class="text_6">
          {{ $t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.BTN2`)  }}</span>

        <img
          class="thumbnail_2"
          
          src="@/assets/lxl/jinru_b.png"
        />
      </div>
    </div>
  </div>
    <!-- PC -->
    <div v-else class="section_6 flex-col justify-between">
    <div class="block_1 flex-col justify-end">
      <span class="text_1">{{ $t("INDEX.INTRODUCTION.CONTENT") }}</span>
      <span class="text_2">{{ $t("INDEX.INTRODUCTION.TITLE") }}</span>
      <span class="text_3" style="margin-top: 10px;">{{ $t("INDEX.INTRODUCTION.TEXT1") }}</span>
      <span class="text_3" style="margin-top: 10px;">{{ $t("INDEX.INTRODUCTION.TEXT2") }}</span>
      <div class="list_1 flex-row">
        <div
          class="image-text_1 flex-col justify-between"
          v-for="(item, index) in loopData0"
          :key="index"
          @click="() => {curIndex = index}"
        >
          <img
            class="image_1"
            
            :src="item.lanhuimage0"
          />
          <span
            class="text-group_1"
            :style="{ color: curIndex == index ? 'rgba(19,20,21,1)' : 'rgba(147,153,166,1)' }"
            v-html="item.lanhutext0"
          ></span>
          <div v-if="curIndex == index" class="group_1 flex-col"></div>
        </div>
      </div>
      <div class="group_1 flex-col"></div>
    </div>
    <div class="block_2 flex-col">
      <div class="group_2 flex-row justify-between">
        <div class="section_1 flex-col" :style="{backgroundImage: 'url(' + require(`@/assets/details/home_section_${curIndex + 1}.png`) + ')'}"></div>
        <div class="section_2 flex-col">
          <div class="text-group_2 flex-col justify-between">
            <span class="text_4">{{$t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TITLE`)}}</span>
            <span class="paragraph_1"
              >
              {{$t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TEXT1`)}}<br />
              {{$t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TEXT2`)}}<br />
              {{$t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TEXT3`) == `INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TEXT3` ? '' : $t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TEXT3`)}}</span
            >
          </div>
          <div class="group_3 flex-row justify-between">
            <div class="section_3 flex-row justify-between" @click="$router.push(loopData0[curIndex].lanhutext0url)">
              <span class="text_5">{{ $t("INDEX.INTRODUCTION.BTN") }}</span>
              <img
                class="image_2"
                
                src="@/assets/lxl/jinru_h.png"
              />
            </div>
            <div class="section_4 flex-row justify-between" @click="goRouter($t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TITLE`) == '音声SIM' ? $CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.SIM_CARD_ONLY : $t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TITLE`) == 'モバイルWi-Fi' ? $CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.WIFI_ONLY : $t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.TITLE`) == '光回線' ? $CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.LIGHT_ONLY : $CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.GENERAL_MERCHANDISE)">
              <span class="text_6">{{ $t(`INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD${curIndex + 1}.BTN2`) }}</span>
              <img
                class="image_3"
                
                src="@/assets/lxl/jinru_b.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      curIndex: 0,
      ifmCommoditySet: [
        this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.SIM_CARD_ONLY,
        this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.WIFI_ONLY,
        this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.LIGHT_ONLY,
        this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.GENERAL_MERCHANDISE
      ],
      loopData0: [
        {
          lanhuimage0:
            require('@/assets/lxl/SketchPng21090b6c26e7631714add27c71b526c659ad2531dfd6fe2f28e51cba058b8333.png'),
          lanhutext0: '音声SIM',
          lanhufontColor0: 'rgba(147,153,166,1)',
          lanhutext0url: '/details/sim/section1'
        },
        {
          lanhuimage0:
            require('@/assets/lxl/SketchPngb2791dcc9b2d515652ac0f09c5f95fb964310286fd8df33f44b65f5f8cec13f0.png'),
          lanhutext0: 'モバイルWi-Fi',
          lanhufontColor0: 'rgba(19,20,21,1)',
          lanhutext0url: '/details/wifi/section1'
        },
        {
          lanhuimage0:
            require('@/assets/lxl/SketchPngd27f1c72b2880552b7eafd4e1122462a1051ecd35e18f2d7aaac5bfc1281bf75.png'),
          lanhutext0: '光回線',
          lanhufontColor0: 'rgba(147,153,166,1)',
          lanhutext0url: '/details/light/section1'
        },
        {
          lanhuimage0:
            require('@/assets/lxl/SketchPng589bf21c67ac7634788826b4617fbb1c9561f07ada47709308ca1a63eb758998.png'),
          lanhutext0: '端末',
          lanhufontColor0: 'rgba(147,153,166,1)',
          lanhutext0url: '/details/pro/section1'
        },
      ],
      constants: {},
    };
  },
  computed: {
    curVal () {
      return this.curIndex + 1
    }
  },
  methods: {
    goRouter (value) {
      this.toTop();
      this.$localStorage.set("menuItemHeader", value);
      this.$router.push("/order");
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>