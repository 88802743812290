<template>
  <div>
    <section>
      <div class="p-login__container">
        <div class="p-login__container--form">
          <div>
            <section class="p-form-login">
              <div>

                <div class="text_1">
                  <h5>{{ $t("FORGET_PASSWORD.TITLE") }}</h5>
                </div>

                <div class="text-x">
                  <text-more :length="$store.state.isMobile ? 16 : 30" flag="※">
                    {{ $t("FORGET_PASSWORD.MESSAGE1") }}
                    {{ $t("FORGET_PASSWORD.MESSAGE2") }}
                  </text-more>
                </div>

                <div class="p-form-login__input-area">

                  <b-input-group size="lg" class="code">
                    <b-form-input id="input-email" type="email" :disabled="isViewInput"
                      :placeholder="$t('FORGET_PASSWORD.TITLE2')" :state="emailState" v-model="forget.email"
                      name="email" />
                    <b-input-group-prepend>
                      <b-button variant="success" type="button" v-on:click="sendMail()"
                        class="p-form-login__input-area__btn-mail" :disabled="counting">
                        <p v-if="counting" :time="60000" @end="onCountdownEnd">{{ timerCount }}</p>
                        <span v-else>{{ $t("FORGET_PASSWORD.SEND") }}</span>
                      </b-button>
                    </b-input-group-prepend>
                  </b-input-group>
                  <div class="validation-message">
                    <span class="validation-message" v-show="show">{{
                      $t("FORGET_PASSWORD.VALIDATE_MAIL") }}</span>
                  </div>

                  <div v-if="isViewInput" class="p-form-register__form--input">

                    <b-input-group size="lg" class="code">
                      <b-form-input id="input-email" type="email" :placeholder="$t('FORGET_PASSWORD.CODE')"
                        v-model="forget.smscode" name="code" :state="smscodeState" />
                    </b-input-group>
                    <div v-show="alert" class="validation-message">
                      <span class="validation-message" v-show="forget.smscode == ''">{{
                        $t("FORGET_PASSWORD.VALIDATE_CODE")
                      }}</span>
                    </div>

                    <b-input-group size="lg" class="code">
                      <b-form-input id="input-valid" type="password" :placeholder="$t('FORGET_PASSWORD.PASSWORD')"
                        v-model="forget.password" :state="passwordState">
                      </b-form-input>
                    </b-input-group>
                    <div v-show="alert" class="validation-message">
                      <span class="validation-message" v-show="forget.password == ''">{{
                        $t("COMMON.ITEM_TYPE.VALIDATE")
                      }}</span>
                    </div>
                    <div style="text-align: left; font-size: 12px;">
                      <span class="text_5">{{ $t("LOGIN.FORGET_MESSAGE") }}</span>
                    </div>
                    <b-input-group size="lg" class="code">
                      <b-form-input id="input-valid" type="password" :placeholder="$t('FORGET_PASSWORD.CONFIRM_PASSWORD')"
                        v-model="forget.confirm" :state="confirmState">
                      </b-form-input>
                    </b-input-group>
                    
                    <div v-show="alert" class="validation-message">
                      <span class="validation-message" v-show="forget.confirm == ''">{{
                        $t("COMMON.ITEM_TYPE.VALIDATE")
                      }}</span>
                    </div>

                  </div>

                  <div class="message">
                    {{ mesErorr }}
                  </div>
                </div>
              </div>

              <div class="p-form-login__loginbutton-area">
                <b-button variant="success" class="p-form-register__form--input__btn text-wrapper_6 flex-col"
                  v-on:click="btnSubmit()">
                  <span class="text_9">{{ $t("FORGET_PASSWORD.BUTTON.SUBMIT") }}</span></b-button>
                <b-button variant="success" class="p-form-register__form--input__btn btn1 text-wrapper_7 flex-col"
                  @click="goToLogin">
                  <span class="text_10">{{ $t("FORGET_PASSWORD.BUTTON.CANCEL") }}</span>
                   
                  </b-button>
              </div>

              <div class="text-x">
                  <text-more :length="60">
                    {{ $t("FORGET_PASSWORD.MESSAGE3") }}
                  </text-more>
                </div>

            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

export default {
  setup() { },
  data() {
    return {
      show: false,
      alert: false,
      errors: "",
      errorsMail: "",
      counting: false,
      timerCount: 0,
      radio_click: 0,
      detail: [],
      isViewInput: false,
      isClicked: false,
      mesErorr: '',
      departId: '',
      forget: {
        accountBy: 0,
        email: "",
        language: "",
        password: "",
        confirm: "",
        smscode: "",
        sysUserDepartDepId: "",
        personalId: "",
      },
      validate: {
        email: null,
        password: null,
        confirm: null,
        smscode: null,
      },

      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  created() { },
  computed: {
    emailState() {
      this.validate.email = this.reg.test(this.forget.email) && this.forget.email.length > 0;
      return this.validate.email;
    },
    passwordState() {
      if (this.isClicked) {
        this.validate.password = this.forget.password.length > 0;
        return this.validate.password;
      }
      return null;
    },
    confirmState() {
      if (this.isClicked) {
        this.validate.confirm = this.forget.confirm.length > 0 && this.forget.password === this.forget.confirm;
        if (this.forget.password != this.forget.confirm) {
          this.mesErorr = this.$t("REGISTER.ERROR.RESPASSWORD");
        } else this.mesErorr = "";
        return this.validate.confirm;
      }
      return null;
    },
    smscodeState() {
      if (this.isClicked) {
        this.validate.smscode = this.forget.smscode.length > 0;
        return this.validate.smscode;
      }
      return null;
    },
  },
  methods: {
    checkInputMail() {
      if (this.reg.test(this.forget.email) == false) {
        this.counting = false;
        this.show = true;
      } else {
        this.show = false;
      }
    },
    sendMail() {
      this.checkInputMail();
      if (this.reg.test(this.forget.email) == false) {
        this.errorsMail = this.$t("API.FORMAT_MAIL");
        this.counting = false;
        setTimeout(this.timeOut, 2000);
      } else {
        this.counting = true;
        this.timerCount = 60;
        this.isViewInput = true;
        if (this.$CONST.DEV) {
          console.log("mail:", this.forget.email, " ==> call API: ", "sys/user/sms");
        } else {
          this.$api.post("userLogin/smsForget", {
            email: this.forget.email,
            languageType: this.$localStorage.get("languageCode"),
          })
            .then((response) => { })
        }
      }
    },
    checkInputText: function () {
      if (this.forget.confirm == "" || this.forget.password == "" || this.forget.smscode == "") {
        this.alert = true;
        return false;
      } else {
        this.alert = false;
        return true;
      }
    },

    async btnSubmit() {
      this.isClicked = true;
      var payLoad = {
        captcha: this.forget.smscode,
        confirmpassword: this.forget.confirm,
        email: this.forget.email,
        languageType: this.$localStorage.get("languageCode"),
        password: this.forget.password
      };
      if (this.checkInputText()) {
        if (this.forget.confirm == this.forget.password) {
          this.$api.put("userLogin/resetPassword", payLoad)
            .then((response) => {
              if (response.success) {
                this.$api.post("userLogin/login", {
                  accountId: this.forget.email,
                  password: this.forget.password,
                }).then((response) => {
                  if (response.success == true) {
                    this.setLocalStorage(response);
                  } else {
                    this.showToast(response.message)
                  }
                })
              } else {
                this.showToast(response.message)
              }
            })
        }
      } else {
        this.showToast(this.$t("COMMON.PAYMENT.VALIDATE.CHECK_INPUT"))
      }
    },

    showToast(mess) {
      this.$bvToast.toast(mess, {
        title: `Errors`,
        solid: true,
        variant: `warning`
      })
    },

    setLocalStorage(response) {
      if (response.success) {
        this.$localStorage.set("token", response.result.token);
        this.$localStorage.set("accountBy", response.result.accountBy);
        this.$localStorage.set("name", response.result.name);
        this.$localStorage.set("nameKana", response.result.nameKana);
        this.$localStorage.set("roleId", response.result.roleId);
        this.$localStorage.set("userId", response.result.userId);
        this.$localStorage.set("userIdCode", response.result.userIdCode);
        this.$localStorage.set("email", response.result.email);
        this.$localStorage.set("registerUrl", response.result.registerUrl);
        this.$localStorage.set("nextPayType", response.result.nextPayType);
        sessionStorage.setItem("loginComplete", 1);

        if (this.$localStorage.get("Answer")) {
          this.$api.post("userLogin/getHasUserDetail").then((response) => {
            if (response.result) {
              setTimeout(() => { this.$router.back(); }, 1000)
            } else {
              this.$localStorage.set("pageBack", '/order');
              this.$router.push({ path: '/mypage', query: { view: '0' } })
            }
          });
        } else {
          this.$router.push({ path: "/home" });
        }
      }
    },

    timeOut() {
      this.errors = "";
      this.errorsMail = "";
    },

    goToLogin: function () {
      if (this.$localStorage.get("Answer")) {
        setTimeout(() => { this.$router.back(); }, 1000)
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    onCountdownEnd: function () {
      this.counting = false;
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
        if (value == 0) {
          this.counting = false;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/common.scss';
@import './index.response.scss';

@media screen and (max-width: 768px) {
  @import './index.response.m.scss';
}
</style>
  