<template>
  <div class="sction5">
    <!-- mob -->
    <div v-if="$store.state.isMobile" class="group_16 flex-col justify-end">
      <div class="tip">
        <div class="tip-h">
          <div style="font-size: 20px; font-weight: bold">
            🔔 &nbsp;お知らせ
          </div>
          <div class="tip-h_r"></div>
        </div>
        <div>
          <a
            download :href="'https://ifmobile.jp/download/Member_Only_Page.pdf'"
            style="text-decoration: underline; cursor: pointer; color: #a0a0a0"
            >{{ texts[0] }}</a
          >
        </div>
        <div>
          <a
            download :href="'https://ifmobile.jp/download/In Reserve.pdf'"
            style="text-decoration: underline; cursor: pointer; color: #a0a0a0"
            >{{ texts[1] }}</a
          >
        </div>
        <!-- <div class="go">お知らせをもっと見る＞</div> -->
      </div>
    </div>
    <!-- pc -->
    <div v-else class="group">
      <div class="tip">
        <div class="tip-h">
          <div>🔔 &nbsp;お知らせ</div>
          <div class="tip-h_r"></div>
        </div>
        <div>
          <a
            download :href="'https://ifmobile.jp/download/In Reserve.pdf'"
            style="text-decoration: underline; cursor: pointer; color: #a0a0a0"
            >{{ texts[0] }}</a
          >
        </div>
        <div>
          <a
            download :href="'https://ifmobile.jp/download/Member_Only_Page.pdf'"
            style="text-decoration: underline; cursor: pointer; color: #a0a0a0"
            >{{ texts[1] }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      texts: [
        "2023/6/15 貯まって、現金に換金できるIFポイント！",
        "2023/7/1 会員専用ページについて",
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>
