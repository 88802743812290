<template>
  <div class="tabbar">
    <div class="bg"></div>
    <ul>
      <li class="item" v-for="(item, i) in tabs" 
        @click="showPage(i)"
        :class="[{'active': currIndex == i}, {'item_fix0': i == 0}]">
        <div class="item-x">
          <div class="icon-bg"></div>
          <img class="icon" :src="item.img1">
        </div>
        <span class="item-t">{{ item.text }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'tabbar',
  data() {
    return {
      currIndex: 4,
      t: [
      {
        name: "ポイント管理 ",
        value: 1,
        url: require("@/assets/historypoint.jpg"),
        icon: "clock-history",
      },
      {
        name: "換金管理",
        value: 2,
        url: require("@/assets/Cashmanagement.jpg"),
        icon: "cash-coin",
      },
      {
        name: "オーダー情報",
        value: 3,
        url: require("@/assets/Orderinformation.jpg"),
        icon: "box-seam",
      },
      {
        name: "データ利用状況",
        value: 4,
        url: require("@/assets/Datausage.jpg"),
        icon: "pie-chart",
      },
      {
        name: "契約情報",
        value: 5,
        url: require("@/assets/Contractinformation.jpg"),
        icon: "receipt-cutoff",
      },
      {
        name: "料金情報",
        value: 6,
        url: require("@/assets/Feeinformation.jpg"),
        icon: "list-check",
      },
      {
        name: "定期課金",
        value: 7,
        url: require("@/assets/recurringbilling.jpg"),
        icon: "calendar2-check",
      },
      {
        name: "アカウントとセキュリティ",
        value: 10,
        url: require("@/assets/AccountAndSecurity.png"),
        icon: "calendar2-check",
      },
      {
        name: "ログアウト",
        value: 99,
        url: require("@/assets/LogOut.png"),
        icon: "calendar2-check",
      },
    ],
      tabs: [
        {
          text: 'IF Mobile',
          value: 9,
          url: '',
          active: false,
          img1: require('@/assets/tabbar/if.svg')
        },
        {
          text: "チャージ",
          value: 4,
          active: false,
          url:'',
          img1: require('@/assets/tabbar/paihang.svg')
        },
        {
          text: 'お支払い',
          value: 6,
          url: '',
          active: false,
          img1: require('@/assets/tabbar/qianbao.svg')
        },
        {
          text: '契約情報',
          value: 5,
          url: '',
          active: false,
          img1: require('@/assets/tabbar/shuji.svg')
        },
        // {
        //   text: "サポート",
        //   value: 1,
        //   url: '',
        //   active: false,
        //   img1: require('@/assets/tabbar/jifen.svg')
        // },
        {
          text: "サポート",
          value: -1,
          url: '/details/com/section6',
          active: false,
          img1: require('@/assets/tabbar/jifen.svg')
        },
      ]
    }
  },
  computed: {
    viewsPage() {
      return this.$route.query?.view || "";
    },
  },
  mounted() {
    this.tabs.some((item, i) => {
      if (item.value == this.viewsPage) {
        this.currIndex = i
        return true
      }
      return false
    })
  },
  methods: {
    showPage: function (i) {
      this.currIndex = i
      if (this.tabs[i].value < 0) {
        this.$router.push(this.tabs[i].url)
        return
      }
      let menuItem = this.tabs[i].value
      console.log('menuItem', menuItem)
      this.$localStorage.set("menuItemHeader", menuItem);
      this.toTop();
      // this.$parent.viewsPage = menuItem;
      this.$router.replace(`/mypage?view=${menuItem}`)
    },
    toPage(item){
      this.$router.replace(item.toSrc);
    } ,
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  }
}
</script>

<style lang="scss" scoped>
$themeColor: #ffffff;
$activeColor: #ff7319;

.tabbar {
  position: fixed;
  bottom: 0;
  height: 80px;
  left: 0;
  right: 0;
  z-index: 99999;

  .bg {
    position: absolute;
    top: 8px;
    bottom: 0;
    left: 0;
    right: 0;
    background: $themeColor;
    z-index: -1;
    box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
  }

  ul {
    display: flex;
    justify-content: space-around;
    margin-bottom: 0;
    
    li {
      display: flex;
      flex: 1 0 20%;
      flex-direction: column;
    }
  }

  .item_fix0 {

    .item-t {
      margin-top: 8px;
    }

    .icon {
      margin-top: 4px;
      width: 35% !important;
    }
  }

  .item {
    
    &-x {
      position: relative;
    }

    &-t {
      font-size: 12px;
      color: #6b6b6b;
    }
    .icon {
      width: 50%;
      object-fit: contain;
      filter: grayscale(100%);
      transition: all .2s;
    }

    .icon-bg {
      position: absolute;
      top: 2px;
      left: 25%;
      right: 25%;
      bottom: 0;
      border-radius: 50%;
      background: linear-gradient(-180deg, $themeColor, $themeColor 40%, transparent 40%, transparent);
    }
    
  }

  .item.active  {
    .icon {
      transform: translateY(-4px) scale(1.2);
      filter: drop-shadow(16px 16px 10px rgba($activeColor, 0.3));
    }

    .item-t{
      color: $activeColor;
    }
  }
}
</style>