export default {
    BUTTON: {
        PREVIOUS: '返回',
        NEXT: '下一步',
        MYPAGE: '个人主页',
        ORDER: '购买',
        LOGIN: '会员登录',
        LOGOUT: '退出',
        CANCEL: '取消',
        REGISTER: '注册会员',
        CHOOSE: '申请',
        CONFIRM: ' 申请这个套餐',
        PAYMENT_NEXT: " 支付",
    },

    VALIDATION: {
        REQUIRED: '未使用',
    },

    MENU: {
        SIM_SERVICE: "手机卡",
        SIM_ORDER: "手机卡",
        WIFI_SERVICE: "Wi-Fi",
        OTP_SERVICE: "光纤宽带",
        SELL_SERVICE: '服务 • 终端',
        TERMINAL: "服务 • 终端",
        LOG_OUT: "退出",
        INVESTIGATION: "联系我们",
        LOGIN: '登录',
        MYPAGE: '个人主页',
        MYPAGE_MENU: [
            
            {
                name: "介绍专用URL",
                value: 8,
                url: "",
            },
            {
                name: "使用状况",
                value: 4,
                url: "",
            },
            {
                name: "支付・使用费用 ",
                value: 6,
                url: "",
            },
            {
                name: "契约套餐",
                value: 5,
                url: "",
            },
            {
                name: "申请状况",
                value: 3,
                url: "",
            },
            {
                name: "会员情报",
                value: 0,
                url: "",
            },
            {
                name: "我的积分管理",
                value: 1,
                url: "",
            },
            {
                name: "换金管理",
                value: 2,
                url: "",
            },
            {
                name: "自动缴费",
                value: 7,
                url: "",
            },
            {
                name: "帐户和安全",
                value: 10,
                url: "",
            },
        ],
    },

    COMMON: {
        STRING: {
            IS_BLANK: "",
            IS_NULL: null,
            IS_INVALID: undefined,
            STRIKE_THROUGH: '—'
        },
        ITEM_TYPE: {
            NECESSARY: "必须",
            PRICE_MONTH: "(月%{price}日元)",
            PRICE_MONTH_STEP1: "月%{price}日元",
            PRICE_MONTH_STEP1_TAX: "(%{price}日元 含税)",
            ONLY_PRICE: "%{price} 日元",
            VALIDATE: "必填信息"
        },
        NAVBAR: {
            STEP1: "选择手机卡套餐",
            STEP2: "选择服务・终端",
            STEP3: "选择移动 Wi-Fi ",
            STEP35: "租借Wi-Fi",
            STEP4: "领取方法",
            STEP5: "申请内容确认",
            STEP6: "本人确认",
            STEP7: "规约",
            STEP8: "支付",
        },
        SUC: {
            CONTENT1: "处理已完成。请确认以下内容：",
            CONTENT2: "□订单处理：在商品送达之前，请通过会员专用页面和电子邮件进行确认审查结果通知。",
            CONTENT3: "□服务预约：在会员专用页面的订单状态显示为完成或接收到预约完成的电子邮件后，请按指定时间前往店铺。",
            CONTENT4: "□月费支付：如发生支付无效或退款处理，请联系客户服务中心。",
            CONTENT5: "□流量充值：购买完成后会立即充流量，并转为高速模式。请在使用情况页面上确认剩余流量。",
            CONTENT6: "□套餐变更：更改完成后，新的流量套餐和非通话套餐将在下个月生效，通话包套餐划将在次日生效。每月仅可更改一次。",
            CONTENT7: "□会员信息更新：在更改后的文件被审核后，本人将无法在3个月内进行再更改或删除。",
            CONTENT8: "□积分充值：充值完成后，请在今后每月话费的付款方式中选择IF积分方式。",
            CONTENT9: "□兑现申请：如果要使用微信支付宝兑现，请联系客户服务中心。",
            CONTENT10: "□MNP、解约、暂停/解除：申请完成后，请等待客户服务中心的联络。",
            CONTENT11: "□咨询：提交咨询后，请等待客户服务中心的联络。",
            ORDER_PAY_BUTTON: "回到主页",
            CHANGE_PAY_BUTTON: "在商品到达之前，请确保查看会员专用页面和邮件中的通知。",
        },
        ERR: {
            CONTENT: [
                "画面转换中发生了错误",
                "请您退出登录后，关闭浏览器，稍后再试。",
                "如果错误仍然没有解开，请联系 IF Mobile 客服中心。",
            ],
            REVER_ERR: "返回主页",
            CODE_INPUT: '认证码不一致！！！',
        },
        PAYMENT: {
            MESSAGE:"请选择初期费用的支付方式。",
            CHOOSE_PAY_WAY: "请选择初期费用的支付方式。",
            MESSAGE3:"我们提供线上支付（信用卡支付、二维码支付）和线下支付（网路银行、现金、ATM转账）的两种方式。",
            MESSAGE4:"□如果选择信用卡支付，请输入信用卡信息后，点击【确定】键，完成支付。",
            MESSAGE5:"□如果选择各种扫码支付，请打开相应支付二维码，扫码支付后，点击【确定】键，完成支付。",
            MESSAGE6:"□如果选择网上银行，请在选择后网上银行后，直接点击【确定】键，完成处理。付款账户将发到您的登录电子邮箱。请查看邮箱，在您的网络银行APP里完成支付。",
            MESSAGE7:"□如果选择现金或ATM转账，请在选择后，拍照或截图保存金额和转账账户，点击【确定】键。然后请用ATM转账后将小票发给客服，或者持日元现金在您设置的时间内到店铺缴费办理。",
            MESSAGE8:"※今后的电话费如用自动扣款方式（信用卡扣款，银行卡自动扣款，IF积分自动扣款），请在下方选择后，再点击【确定】键，完成处理。",
            ONLINE_PAY: "在线支付",
            DEFERRED_PAY: "延期付款",
            PAYMENT_AMOUNT: "支付费用:",
            CARD_TITLE: "CreditCard",
            CARD_NO: "下4位数",
            TAX: "日元（含税）",
            BANKING: "网上银行",
            BANKING_MESSAGE: "获取付款所需的信息",
            MESSAGE1:"选择后网上银行后，直接点击【确定】键，完成处理。付款账户将发到您的登录电子邮箱。请查看邮箱，在您的网络银行APP里完成支付。",
            MESSAGE2:"请参考以下使用步骤。",    

            CREDIT: {
                TITLE: "信用卡",
                RECOMMENT: "推荐",
                TEXT: "每月的费用将从您的信用卡中自动扣除。需要登录 可以使用借记卡。可以使用在日本以外发行的信用卡。但需要开通国际支付服务（人民币/美元）。",
                PLA1: '卡号',
                PLA2: '有効期限（年)',
                PLA3: '有効期限（月)',
                PLA4: '卡的名字（姓名）',
                PLA5: '卡的名字（姓名）',
                PLA6: '安全号码',
            },
            CODE: {
                TITLE: "各种二维码支付（微信支付、支付宝，Paypay）",
                ALIPAY: '支付宝 国际支付',
                WECHAT: '微信 国际支付',
                MESSAGE: "您可以扫描条形码进行付款。",
            },
            STORE: {
                TITLE: "便利店支付(无法使用)",
                TEXT: '便利店现金支付。请在订购开始7日内进行支付',
                PLA1: '契约者名',
                PLA2: '电话号码',
                STORE_TYPE: '便利店类型',
            },
            CASH: {
                TITLE: "现金或ATM银行转账",
                TITLE1: "现金",
                TITLE2: "ATM银行转账",
                TEXT1: '在客服中心和代理店进行面对面的现金支付。',
                TEXT2: '只接受日元现金。',
                TEXT3: "汇款地址信息：三井住友银行世田谷支店普通5954038。",
                TEXT4: "汇款后，请务必将汇款明细发送至客户支持中心（如LINE/WECHAT/邮件）。",
                TEXT5: "付款方式在这里。",
            },
            CHOOSE_METHOD: {
                TITLE: "请选择今后每月费用的付款方法。",
                METHOD1: "信用卡自动付款（推荐）",
                METHOD2: "日本银行自动付款（推荐）",
                METHOD3: "各种二维码支付 WeChat、Alipay、Paypay",
                METHOD4: "便利店",
                METHOD5: "来店现金 网上银行 ATM银行转账",
                METHOD6: "IF点",
            },
            WITHDRAW: {
                TITLE: "关于自动扣款",
                CONTENT1: "不能支付初期费用。",
                CONTENT2: "每月付款将自动从您的银行帐户中扣除。我们会随产品寄给您一张自动扣款登记表和一个回邮信封，请您填写盖章后，寄回给我们。",
                PDF: "「XXXXXX.PDF」"
            },
            BUTTON: {
                CONFIRM: "確認",
                PAY: "下一步",
                RETURN: "返回"
            },
            VALIDATE:{
                INPUT:"选择支付方法",
                MESSAGE: '请先完成支付',
                FAIL: '处理失败',
                CHECK_INPUT: '您没有充分的填写信息',
            }
        },
    },

    SIM_SERVICE: {
        STEP1_PLAN_SELECT: {
            TITLE: "手机卡选择",
            QUESTIONS_PURCHASE_METHOD: {
                TITLE: '申请前需要准备的材料',
                CARD1: {
                    TITLE: '支付方法',
                    TEXT1: '各种信用卡',
                    TEXT2: '银行转账',
                    TEXT3: '各种二维码付款 微信，支付宝',
                    TEXT4: '便利店',
                    LINK: '支付方法的详情',
                },
                CARD2: {
                    TITLE1: '本人确认材料',
                    TITLE2: '（驾驶证，保险证等）',
                    TEXT1: '根据《手机不当使用预防法》进行身份验证、需要用户本人的身份证明文件。',
                    TEXT2: '※登录照片',
                    LINK: '本人确认材料',
                },
                CARD3: {
                    TITLE1: 'MNP预约号码',
                    TITLE2: '（仅限携号转入的用户）',
                    TEXT1: '如果您想保留当前的电话号码，需要事先取得  MNP预约号码。',
                    TEXT2: '希望从当前的通信公司MNP转出,',
                    TEXT3: '请与我们联系办理.',
                    LINK: '详情点击此处',
                },
                CARD4: {
                    TITLE: '电子邮件地址',
                    TEXT1: '用于会员注册和接收信息',
                    TEXT2: '请准备好您的电子邮件地址。',
                    TEXT3: "请与我们联系办理",
                },
            },
            QUESTIONS_SELECT_TEL_NUMBER: {
                NEW_AGREEMENT: "新号办理",
                ANOTHER_COMPANY: {
                    TITLE: "MNP",
                    TEL_PLACE_HOLDER: "请输入您想携号转入的手机号码。",
                    MNP_PLACE_HOLDER: "MNP预约号码",
                    DATE_PLACE_HOLDER: "MNP预约号码的有效期限 ",
                    SIM_PLACE_HOLDER: "如果申请前已经知道手机号码 请填写",
                    TITLE_BANK: "请选择您当前使用的手机通讯品牌。",
                    BANKS: [
                        { value: 0, text: 'DOCOMO' },
                        { value: 1, text: 'SOFTBANK' },
                        { value: 2, text: 'AU' },
                        { value: 3, text: 'UQ' },
                        { value: 4, text: 'Y!M' },
                        { value: 9, text: '其他' }
                    ],
                }
            },
            QUESTIONS_SET: {
                TITLE: " 手机卡 or  手机卡和Wi-Fi网络组合？",
                SIM: "仅SIM卡",
                OTP: "光纤宽带和手机卡组合",
                WIFI: "移动Wi-Fi和手机卡组合",
            },
            QUESTIONS_RATE_PLAN: {
                TITLE: "高速流量套餐　契约后可以变更",
            },
            QUESTIONS_FLAT_RATE_CALL: {
                TITLE: "定额通话套餐",
            },
            QUESTIONS_OPTION: {
                TITLE: "其他服务套餐",
                OPTION1: "来电语音留言服务",
                OPTION2: "优先插入电话服务",
                OPTION3: "不需要",
            },
            QUESTIONS_DESIRED_DATE: {
                TITLE: "希望开通日期",
            },
            SIM_CALL_QUOTA: [
                { value: '000011', text: '5分钟内无限次日本国内通话' },
                { value: '000012', text: '10分钟内无限次日本国内通话' },
                { value: '000013', text: '日本国内无限制通话' },
                { value: '000014', text: '不需要' },
            ],
            NONE: '不需要',
            OPTIONCHOSSE: [
              { value: '000016', text: '来电语音留言服务' },
              { value: '000017', text: '优先插入电话服务' },
            ],
        },
        STEP2_CHOOSE_CABLE: {
            NAVBAR: "Select Fiber-optic internet",
            TITLE: "Select Fiber-optic internet",
            QUESTIONS_CHOOSE_PACKAGE: {
                TITLE: "宽带类型 ",
            },
            QUESTIONS_START_DAY: {
                TITLE: " 希望开通日期 ",
            },
            RENT: {
                TITLE1: " 新办理 ",
                TITLE2: " 变更运营商 ",
                USAGE_STATUS: "使用状态",
                QES: '无线路由器出租',
                NEED: '希望',
                NO_NEED: '不希望 '
            },
            PHONE: {
                TITLE: '白天可以联络的电话号码',
            },
            TIME: {
                TITLE: '白天可以联系的时间段',
            },
            IFM_COMMODITY: [
                { value: '000025', text: '公寓型', type: '(面向集体住房)' },
                { value: '000026', text: '家庭型', type: '(面向独立住房)' },
            ],
        },
        STEP3_WIFI_SELECTION: {
            REQUIRED1: "Wi-Fi 高速流量套餐",
            REQUIRED2: "希望开通日期",
        },
        STEP4_RECEIVE: {
            QUESTIONS_RADIO: {
                SELFIE: {
                    TITLE: '门店领取',
                    TEXT1: '总公司',
                    TEXT2: '店铺名称：IF Mobile',
                    TEXT3: '地址：東京都新宿区高田馬場1-28-3 工新大厦5樓 SEIWA',
                    TEXT4: '各代理机构',
                },
                TIME_DELI: '希望配送时间段',
                OVERSEAS: "邮寄",
                IN_JAPAN: "日本国内",
                OUTSIDE_JAPAN: "海外（除日本以外的国家和地区）",
                AM: "上午",
                PM: "下午",
            },
            QUESTIONS_TEXTINPUT: {
                SELECT:"选择",
                MAILING_NAME: "姓名",
                POSTAL_CODE: "邮编号码",
                MAILING_CONTACT_PHONE: "电话号码",
                MAILING_DETAILS_ADDRESS: "住所",
                JA: "日本国内 配送费 %{price}日元",
                FOREIGN: "海外  配送费 %{price}日元",
                DELIVERY_TIME:"希望配送时间段",
                VISIT:"门店领取时间",
                VISIT_DATE:"请选择来店日期",
                MESSAGE1: "如果要返回上一页，请点击顶部的申请流程图标。",
            },
            VISIT_TIME: [
              { value: "", text: '时间', disabled: true  },
              { value: '10:00 ～ 12:00', text: '10:00 ～ 12:00'},
              { value: '12:00 ～ 14:00', text: '12:00 ～ 14:00'},
              { value: '14:00 ～ 16:00', text: '14:00 ～ 16:00'},
              { value: '16:00 ～ 19:00', text: '16:00 ～ 19:00'},
          ]
        },
        STEP5_PURCHASE_CONFIRMATION: {
            TITLE: "套餐和费用确认",
            TEXT: "确认选择的套餐和价格之后进行下一步。",
            COMMISSION: "登录开通手续费",
            ANOTHER_COMMISSION: "事务手续费",
            PACKAGE_FEE: "公寓型",
            TAX: "消费税",
            AMOUNT: '支付金额',
            WITHOUT_TAX: "%{price} 日元(税抜)",
            WITH_TAX: "%{price} 日元(含税)",
            CONFIRM: "请确认以上内容无误。如需更改，请点击申请流程页面顶部的图标进行修改，然后返回申请内容确认页面。",
            FIXED_COSTS: "每月基本费用",
            QUANTITATIVE_FEE: "通话和短信的费用",
            TEXT1: "两个月前的通话费用",
            UNSETTLED: "未定",
            OVERSEAS_FEE: "海外使用料金",
            OPENING_COST: "开通工事费",
            INSTALLMENT_COST : "工事费分月付金额",
            DEPOSIT: "保证金",
            SIM_CARD_PURCHASE_DETAILS: {
                TITLE: "手机卡",
                CONTRACT_TYPE: " 套餐种类",
                PHONE_NUMBER: "电话号码",

                MNP_PHONE_NUMBER:"携号转入的手机号码",
                MNP_CODE:"MNP预约号码",
                MNP_DEADLINE:"MNP预约号码的有效期限",
                SIM_BRAND_TITLE:"当前使用的手机通讯品牌",
                SIM_BRAND_0:"DOCOMO",
                SIM_BRAND_1:"SOFTBANK",
                SIM_BRAND_2:"AU",
                SIM_BRAND_3:"UQ",
                SIM_BRAND_4:"Y!M",
                SIM_BRAND_9:"其他",

                OPEN_DAY: " 希望开通日期",
                PLAN: " 高速流量套餐",
                TOTAL_INITIAL_COST: "月费用合计",
                SIM_DAMAGES: "手机卡发行费",
                UNIVERSERVE: "通信基础服务费",
                RELAYSERVICE: "紧急援助服务费",
                FLAT: ' 定额通话套餐',
                MONTH_BASE: ' 上上个月%{month}通话费用',
                SMS_BASE: ' 上上个月%{month}短信费用',
                MONTH: '28日',
                FIRST_MONTH_FEE: "首月的费用",
                ONETIME_FEE:"仅限初回一次性缴纳费用",
                DETAIL: "点击此处查看明细",
                INITIAL_COST:"初期费用",
                MONTHLY_FEE: "月费",
                NEXT_MONTH_FEE: "使用开始后次月的费用",
                PAYMENT_PREVIOUS_MONTH: "当月费用在前月月底支付。",
                MONTHLY_PAYMENT:"每月的支付费用",
                MONTHLY_PAYMENT_TYPE:" ＋通话费用＋短信费用",
                SMS_FEE_LAST_MONTH: "两个月前的短信费用",
            },
            LIGHT_PURCHASE_DETAILS: {
                TITLE: "光纤宽带",
                PLAN: " 套餐",
                OPEN_DAY: " 希望开通日期",
                TOTAL_INITIAL_COST: "初期费用合计",
                USAGE_STATUS: "使用状态",
            },
            WIFI_PURCHASE_DETAILS: {
                TITLE: "移动Wi-Fi",
                RENT_TITLE: "租借Wi-Fi",
                PLAN: " 套餐",
                OPEN_DAY: " 希望开通日期",
                COD: "COD",
                DEVICE_PRICE: "Wi-Fi机器费用",
            },
            RECEIVE: {
                TITLE: "领取方法",
                PRODUCT_ACQUISITION_METHOD: "领取方法",
                AREA: " 地区",
                MAILING_NAME: " 姓名",
                MAIL_CONTACT_PHONE: " 电话号码",
                LOCAL_POSTAL_CODE: " 邮编号码",
                MAILING_DETAILS_ADDRESS: " 住所"
            },
            TOTAL: {
                POSTAGE: "邮送费",
                TOTAL_INITIAL_COST: "全部初期费用合计",
            }
        },
        STEP6_INFOMATION_CONFIRM: {
            LOGIN_ALERT: "请登录或开设我的页面。!",
            INFO_DETAIL_ALERT: "信息请填写完全!",
            LOGIN_NOTIFIC_ATION: "需要注册会员，创建我的主页才能上传本人资料。请用您的用户ID（电子邮件）和密码进行注册。",
            NOTIFICATION: "登录IF移动的“会员专用主页”后，您可以进行使用情况的确认、数据容量的充值、套餐变更以及用户信息的修改等操作。",
            NOTICE_CONTENT: "",
            QUESTIONS_MEMBER_CONFIRM: {
                TITLE2: "非会员",
                TITLE1: "会員",
                MEMBER: "登录",
                NOT_A_MENBER: "注册会员",
                SIGN_UP: '会员注册',
                NONE_SIGN_UP: '无需注册，先登录本人情报信息',
            },
            QUESTIONS_REGISTER_INFO: {
                TITLE: " 已完成登录的客人",
                REGISTER: "会员信息详细登入",
            }
        },
        STEP7_CONSENT_SIGN: {
            CONTENT: '点击条款和注意事项并在申请前检查内容。',
            TITLE: '关于契约',
            NAVBAR: "同意书签字   ",
            CHECKBOX: "理解并同意上述条款和注意事项。",
            TEMP1: "条款",
            TEMP2: "契约细则及注意事项 ",
            BTN: {
              BTN1: "SIM",
              BTN2: "Wi-Fi",
              BTN3: "光纤宽带"
            },
            PAYMENT_FORM: {
                FORM1: {
                    PLA1: '卡号',
                    PLA2: '有効期限（年)',
                    PLA3: '有効期限（月)',
                    PLA4: 'カード名（姓名）',
                    PLA5: '卡的名字（姓名）',
                    PLA6: '安全号码',
                },
                FORM2: {
                    TITLE: '支付宝 国际支付'
                },
                FORM3: {
                    TITLE: '微信 国际支付'
                },
                FORM4: {
                    TITLE: '便利店支付',
                    PLA1: '契约者名',
                    PLA2: '电话号码',
                    PLA3: '便利店类型',
                }
            },
            PAYMENT_METHOD: '* 支付方法：',
            SIGN: {
                TITLE: "签名   仅限本人禁止代签",
                ACCEPT: "确定",
                CLEAN: "重新设定"
            },
            OPTION: [
                { value: null, text: '选择支付方法' },
                { value: '0', text: ' credit：信用卡支付' },
                { value: '1', text: 'alipay：支付宝 国际支付' },
                { value: '2', text: 'wechatpay：微信 国际支付' },
                { value: '3', text: 'unionpay：银联在线支付' },
                { value: '4', text: '门店支付' },
                { value: '5', text: '便利店' }
            ],
            OPTION_STORE: [
                { text: '便利店类型', value: null },
                { text: 'Seven Eleven', value: 'seven_eleven' },
                { text: 'Family Mart', value: 'family_mart' },
                { text: 'Lawson', value: 'lawson' },
                { text: 'Mini Stop', value: 'mini_stop' },
                { text: 'Seico Mart', value: 'seico_mart' },
                { text: 'Pay Easy', value: 'pay_easy' },
                { text: 'Circle K', value: 'circle_k' },
                { text: 'Sunkus', value: 'sunkus' },
                { text: 'Daily Yamazaki', value: 'daily_yamazaki' },
                { text: 'Yamazaki Daily Store', value: 'yamazaki_daily_store' },
            ],
        },
        GENERAL_MERCHADISE: {
          TITLE: '填写申请内容',
        }
    },

    FOOTER: {
        TITLE1: {
            TITLE: '产品',
            LINK1: '手机卡',
            LINK2: 'Wi-Fi',
            LINK3: '光纤宽带',
            LINK4: '服务 • 终端',//tag
        },
        TITLE2: {
            TITLE: 'IF会员',
            LINK1: '会员服务',
            LINK2: '注册会员',
        },
        TITLE3: {
            TITLE: '服务中心',
            LINK1: 'Q&A',
            LINK2: '窗口',
            LINK3: '设定方法',
            LINK4: '支付方法',
        },
        TITLE4: {
            TITLE: '公司信息',
            LINK1: '运营公司',
            LINK2: '基于对特定商户的交易法律的标示',
            LINK3: '服务条款',
            LINK4: '隐私政策',
        },
    },

    LOGIN: {
        TITLE: "会员登录",
        USERNAME: "客户ID：电子邮件地址",
        PASSWORD: "密码",
        CODE: "认证码",
        FORGET_MESSAGE: "※至少6个半角英文字母或数字",
        FORGET_PASSWORD: "忘记密码的",
        RECOVER_PASSWORD: "请点击这里",
        NO_ACCOUNT: "第一次使用吗？新规登录",
        NEW_ACCOUNT: "请点击这里",
        CANCEL: '取消',
        LOGIN: "登录",
    },

    FORGET_PASSWORD: {
      TITLE: "忘记密码的客户",
      EMAIL_ADDRESS: "电子邮箱地址",
      SEND: "发送",
      CODE: "认证码",
      VALIDATE_CODE: "验证码输入有误",
      VALIDATE_MAIL: "这不是正确的电子邮件地址",
      PASSWORD: "新密码",
      CONFIRM_PASSWORD: "新密码（确认）",
      MESSAGE1: "重置我的个人页面密码并向您注册的电子邮件地址发送了一封邮件，其中包含密码设置用的认证码。",
      MESSAGE2: "请设置您的电子邮件，允许来自“ifmobile.jp”的电子邮件。",
      MESSAGE3: "如果您没有收到电子邮件，请与IF移动客户中心联系。",
      TITLE2:"客户ID（注册的电子邮件地址）",
      BUTTON: {
        SUBMIT: "重新发行申请",
        CANCEL: "返回",
      },
    },

    REGISTER: {
        TITLE: "注册会员 创建主页",
        INDIVIDUAL: "个人",
        CORPORATION: "法人",
        EMAIL_ADDRESS: "电子邮件地址",
        SEND_VERIFICATION_CODE: "发送 ",
        MESSAGE: "请输入您的电子邮箱地址",
        PLACE_HOLDER: {
            PLH0: '电子邮件地址',
            PLH1: '认证码',
            PLH2: '姓  名（与护照上相同的拼写）',
            PLH3: '姓　名（片假名）',
            PLH4: '密码（半角英数字，至少6个字符以上',
            PLH5: '重新输入密码',
            PLH6: "公司名称（与营业执照上相同的拼写）",
            PLH7: "公司名称 （片假名）",
            MESSAGE1:"您注册的电子邮件地址是您的会员ID。",
            MESSAGE2:"我们还将向您发送重要通知，因此请使用正确电子邮件地址注册。并允许来自“ifmobile.jp”的电子邮件。",
            MESSAGE3:"如果您没有电子邮件地址，请创建并注册一个您可以接收的电子邮件地址",
            MESSAGE4:"密码是登录时必需的，请务必保存好密码。",
        },
        BUTTON_RESGITER: "创建主页",
        BUTTON_RESGITER_CANCEL:"返回",
        ERROR: {
            RESPASSWORD: '密码和确认密码不匹配',
            EMAIL_EXIST: "该电子邮件地址已存在，请更改。",
        }
    },

    PERSONAL_MANAGER: {
        NOTE: {
            TITLE: '本人信息确认',
            TEXT1: '您提供的个人信息将仅用于本人身份验证。',
            TEXT2: '1、根据日本法律，购买手机卡时，必须提交附有地址的个人身份验证文件。此外，剩余有效期必须是1 个月以上。',
            TEXT3: '2、手机卡的邮寄地址，一定要和本人确认文件一致。如果不一致，请同时上传能证明地址的辅助文件。并且文件的有效期是6个月以内。',
            TEXT4: '3. 请上传顾客本人手持身份证件照片，不要用手或其它物品遮住脸部。 拍照的内容一定要显示清晰。',
            TEXT5: '4、请输入正确的本人信息。如果上传的信息不符合上述要求，您将无法购买手机卡。',
            TEXT6: '本人确认材料',
            TEXT7: '请在这里查看详细内容',
        },
        NOTE1: {
            TITLE: '※请上传身份证明文件正反面照片以及本人手持证件照片。',
            TEXT1: '①文件格式为 JPG/PNG，大小不超过 4MB。',
            TEXT2: '②请务必上传您自己的身份证明文件和面部照片。',
            TEXT3: '万一与照片中的人不同或确定不符合我公司规定的要求，',
            TEXT4: '我们可能无法通过您的请求。',
        },
        NOTE2: {
          TEXT: '文件类型JPG/PNG　文件大小4MB以内',
          TEXT2: '本人确认文件或者联系情报发生变更时，请填写变更信息。'
        },
        BASICINFO: {
            CONTENT: "请确保SIM卡收货地址与身份验证文件的地址一致。如与身份证明文件不符，请同时上传有地址的证明文件。※自签发日起6个月内有效证件。",
            LINE:"----------------------------------------------------------------------------------------------------------------",
            BASIC_INFORMATION: "请输入本人的情报信息",
            TITLE: '用户姓名:',
            TITLE1: '请与身份证明一致（汉字或罗马字）',
            TITLE2: '契约者姓名（全角片假名）:',
            HO: '姓',
            TEN: '名',
            HO1: '姓',
            TEN1: '名',
            PLACEHOLDER: {
                PLA1: '输入',
                PLA3: '输入号码',
                PLA4: '请输入推荐码。',
            },
            GENDER: "* 性別 ：",
            RADIO_MAN: "男",
            RADIO_WOMAN: "女",
            YEAR:"年",
            MONTH:"月",
            DAY:"日",
            DATE_OF_BIRTH: "* 出生年月日 ：",
            IDENTITY_VERIFICATION_DOCUMENTS: "* 本人确认材料 ：",
            ID: '* 本人身份确认文件号码 :',
            SELECT_IDENTITY_VERIFICATION_DOCUMENTS: {
                SELEST: "前选择一个项目",
                OPTION_RESIDENCE_CARD: {
                    name: "在留卡",
                    value: ''
                },
                OPTION_PASSPORT: {
                    name: "护照",
                    value: ''
                },
                OPTION_DRIVER_LICENSE: {
                    name: "驾驶证",
                    value: ''
                },
            },
            COUNTRY_OF_CITIZENSHIP: "* 国籍 ：",
            CONTENT1: {
                TEXT1: "请上传本人確認证件证明照片",
                TEXT2: "请上传本人確認证件反面照片",
                TEXT3: "请上传本人手持露脸证件照片",
            },
            SUPPORTING_DOCUMENTS: "补助身份确认文件 ：",
            SELECT_SUPPORTING_DOCUMENTS: {
                SELECT: "前选择一个项目",
                OPTION_HEALTH_INSURANCE_CARD: {
                    name: "健康保险证 ",
                    value: ''
                },
                OPTION_MY_NUMBER_CARD: {
                    name: "个人号码卡",
                    value: ''
                },
                OPTION_STUDENT_CARD: {
                    name: "学生证",
                    value: ''
                },
                OPTION_NOTIFICATION: {
                    name: "在留许可通知书",
                    value: ''
                },
                OPTION_LANDING_CERTIFICATE: {
                    name: "入境证明书",
                    value: ''
                },
            },
            CONTENT2: {
                TEXT1: "上传辅助材料※发行日始6个月内有效期",
                TEXT2: "上传辅助材料※发行日始6个月内有效期",
            },
        },
        CONTACTINFO: {
            CONTACT_INFORMATION: "契约者的联系信息",
            CONTACT_PHONE_NUMBER: "*联系电话 ：",
            POST_CODE: "*邮编号码 ：",
            ADDRESS: "*住所 ： ",
            TITLE_REQUIRED: "外国人的情下，紧急联系信息请输入母国的联系人的信息",
            EMERGENCY_CONTACT: "*紧急联系人 ：",
            EMERGENCY_CONTACT_INFORMATION: "*紧急联系人住所 ：",
            EMERGENCY_CONTACT_PHONE: "*紧急联系人的电话 ：",
            EMERGENCY_RELATION: '*本人关系 :',
            PREFERRED_LANGUAGE: "* 希望语言 ：",
            SELECT_PREFERRED_LANGUAGE: {
                SELEST: "前选择一个项目",
                CHECKBOX_JAPANESE: {
                    name: "日语",
                    value: ''
                },
                CHECKBOX_ENGLISH: {
                    name: "英语",
                    value: ''
                },
                CHECKBOX_CHINESE: {
                    name: "汉语",
                    value: ''
                },
                CHECKBOX_VIETNAMESE: {
                    name: "越南语",
                    value: ''
                },
                CHECKBOX_KOREAN: {
                    name: "韩语",
                    value: ''
                },
                CHECKBOX_SRILANKAN: {
                    name: "斯里兰卡语",
                    value: ''
                },
                CHECKBOX_NEVALA: {
                    name: "尼泊尔语",
                    value: ''
                },
            },
        },
        WORKSCHOOLINFO: {
            TITLE: "工作单位/学校（任意）留学生 必须",
            NAMEPHONE__NAME: "工作地址／学校信息 ：",
            NAMEPHONE__NUMBERPHONE: "工作地址／学校的电话号码：",
            ADDRESS: "工作地址／学校的住址 ：",
        },
        OTHERINFO: {
            TITLE: "其他信息",
            ADVERTISEMENT: "*您是从哪里听说 IF Mobile的？",
            CHECKBOX__ROUNDED: {
                CHECKBOX_REFER_A_FRIEND: {
                    name: "朋友介绍",
                    value: ''
                },
                CHECKBOX_SCHOOL_INTRODUCTION: {
                    name: "学校介绍",
                    value: ''
                },
                CHECKBOX_INTRODUCTION: {
                    name: "留学/留学中介介绍",
                    value: ''
                },
                CHECKBOX_INTRODUCED_FROM: {
                    name: "其他网络跳转",
                    value: ''
                },
                CHECKBOX_FACEBOOK: {
                    name: "Facebook广告",
                    value: ''
                },
                CHECKBOX_YOUTUBE: {
                    name: "Youtube广告",
                    value: ''
                },
                CHECKBOX_GOOGLE: {
                    name: "Google广告",
                    value: ''
                },
                CHECKBOX_OTHER_ADVERTISEMENTS: {
                    name: "その他广告",
                    value: ''
                },
                CHECKBOX_OTHERS: {
                    name: "其他",
                    value: ''
                },
            },
            USE: {
                TITLE: "您在使用什么样的交友软件",
                CHECKBOX_FACEBOOK: {
                    name: "Facebook",
                    value: ''
                },
                CHECKBOX_LINE: {
                    name: "LINE",
                    value: ''
                },
                CHECKBOX_WECHAT: {
                    name: "WeChat",
                    value: ''
                },
                CHECKBOX_KAKAO: {
                    name: "Kakao Talk",
                    value: ''
                },
                CHECKBOX_SNS: {
                    name: "请输入交友软件名",
                    value: ''
                },
            },
        },
        REFERRAL_CODE: "推荐码:",
        BTN: "保存",
        DELETE: '删除'
    },

    COMPANY_MANAGER: {
        NOTE: {
            TITLE: '公司确认',
            TEXT1: '您提供的个人信息将仅用于公司验证。',
            TEXT2: '1、根据日本法律，购买手机卡时，必须提交附有地址的公司验证文件。此外，剩余有效期必须是1 个月以上。',
            TEXT3: '2、请输入正确的公司信息。如果上传的信息不符合上述要求，您将无法购买手机卡。',
        },
        BASIC_INFORMATION: {
            TITLE: '请输入契约公司的情报信息',
            CEO: '* 董事长:',
            PDF: '* 营业执照:',
            TITLE_ADD_IMG: '名片 :',
            TEXT_ADD_IMG: '请上传名片的照片',
        },
        CONTACTINFO: {
            TITLE: '契约公司的联系信息',
            PHONE1: '* 公司电话号码:',
            ZIP_CODE: '* 公司的邮政号码:',
            ADDRESS: '* 公司地址：',
            NAME: '* 担当者姓名:',
            CONTACT: '* 责任人的联系电话:',
            POSITION: '* 职务:',
        },
        PLACEHOLDER: {
            PLA1: "需与公司藤本一致",
            PLA2: "请输入推荐码。",
        },
        REFERRAL_CODE: "推荐码:",
        LANGUAGE: "希望语言：",
        BTN: "保存",
        UPDATED_CONTACT: {
            TITLE: "本人确认文件或者联系情报发生变更时，请填写变更信息。",
            PLACEHOLDER: "请输入变更后的联系信息",
        }
    },

    INDEX: {
        LOGO: {
            ANIMATION1: "IF",
            ANIMATION2: "Mobile",
            ANIMATION3: "IF Mobile",
        },
        SLOGAN: "To Serve Your Needs",
        PLAN: {
            CONTENT: "The Plans",
            TITLE: "低价位的收费体系！",
            TITLE_TEXT1: "可以自由组合“手机卡”和“Wi-Fi”！根据日常所需，制订合适的通信方案。",
            TITLE_TEXT2: "推荐组合：5G手机M卡+ 100G移动Wi-Fi  最受欢迎！",
            MONTHLY_FEE:"每月费用",
            PLAN_BASIC: "基本套餐",
            PRICE: "单价",
            BENEFITS: "特典",
            SIM: {
                PERMISSION: "其他服务套餐",
            },
            SELL_PHONE: {
              PLAN_BASIC: '种类',
              CONTENTS:"内容",
            }
        },
        APPLY: {
            STEP1: {
                NAME: "STEP 1",
            },
            STEP2: {
                NAME: "STEP 2",
            },
            STEP3: {
                NAME: "STEP 3",
            },
        },
        INTRODUCTION: {
            CONTENT: "Smart and Simple",
            TITLE: " IFMobile是什么？",
            TEXT1: "●无需信用卡，审查简单，根据您所需来提供合理的日本通讯产品组合",
            TEXT2: "●提供可兑换为现金的积分(IF P)以及丰富的会员服务，提供通信以外的价值的品牌",
            BTN: "查看详情",
            INTRODUCTION_CARD: {
                CARD1: {
                    TITLE: "手机卡",
                    TEXT1: "7种容量套餐，简单易懂",
                    TEXT2: "现有的智能手机可以继续使用，并享受无限制流量",
                    TEXT3: "同时还有MNP转入特典活动",
                    BTN2: "获取手机卡",
                },
                CARD2: {
                    TITLE: "移动Wi-Fi",
                    TEXT1: "无速度限制、无契约期限",
                    TEXT2: "不需要工事，马上可以使用",
                    TEXT3: "一次性购买或租借都OK",
                    BTN2: "申请移动Wi-Fi",
                },
                CARD3: {
                    TITLE: "光纤宽带",
                    TEXT1: "NTT网络速度快",
                    TEXT2: "无限流量套餐（无速度限制、速度快）",
                    TEXT3: "工事时间端短，1周以内可以使用",
                    BTN2: "申请光纤宽带",
                },
                CARD4: {
                    TITLE: "服务・终端",
                    TEXT1: "会员可获取合作店铺或旅行商品的优惠券",
                    TEXT2: "在合作餐厅就餐可获得IF积分，积分可兑换成现金",
                    TEXT3: "购买手机卡和终端（新机/二手机）组合，可分期支付",
                    BTN2: "申请购买终端",
                },
            },
        },
        QUESTIONS: {
            CONTENT: "Let's talk with us",
            TITLE: "Contact Us",
            ADDRESS: "Our Office",
            ADDRESS_TEXT: "201 Oak Stress Building 27, Manchester, USA",
            CALL_US: "Call Us",
            PHONE: "+1 719-338-4628",
            EMAIL_US: "Email Us",
            EMAIL: "support@envato.com",
            INPUT: {
              NAME: 'Name※',
              EMAIL: 'Email※',
              PHONE: 'Phone※',
              MESS: 'Your Message※'
            }
        },
    },

    PAGINATION: {
        NUMBER_RECORD: "显示的件数:",
    },

    INQUIRY: {
        TITLE: "联系我们",
        NOTE: " 如果您有什么问题，请提交以下内容，我们会在2天以内联系您！",
        EMAIL: "电子邮件地址:",
        CONTACT: "咨询种类",
        SNS: "其他",
        SNS_NAME: "SNS名",
        SNS_ID: "SNS ID",
        RETURN: "返回",
        SEND: "发送",
    },

    ORDER_INFO: {
        NOTE: {
            TITLE: "「 未完成（需要处理）」",
            CONTENT1: "由于本人身份确认材料的不完整或不一致，该过程可能未正确完成。 或者您还有未支付的费用。",
            CONTENT2_1: "这个情况下，我们会向您申请时登录的电子邮箱地址里发送",
            CONTENT2_2: "「【重要】IF Mobile 由于本人身份确认材料资料的不完整，请重新重新上传或付款」",
            CONTENT2_3: "发送如上邮件",
            CONTENT3_1: "请在确认不完善的基础上、",
            CONTENT3_2: "【处理】",
            CONTENT3_3: "请点击按钮进行必要的流程",
            CONTENT4_1: "请在下面的页面确认详细信息",
            CONTENT4_2: "本人信息的确认不完善 ",
        },
        TITLE: "购买申请记录",
        ORDER_NUMBER: "购买申请号码　",
        ORDER_TYPE_TITLE: "申请内容",
        ORDER_PRICE: "价格（含税）日元",
        ORDER_DAY: "申请日期",
        EXPECT_DAY: "使用开始日",
        PAYMENT_METHOD: "支付方法",
        WAY_RECEIVE: "领取方法",
        MAILING_AREA: "邮寄地区",
        MAILING_NAME: "邮寄姓名",
        CONTACT_PHONE: "邮寄的联系电话",
        ADDRESS: "邮寄的详细地址",
        PROCESSING: "处理",
        OREDER_PAYMENT_OPTION: [
          { value: '0', text: '卡片' },
          { value: '1', text: 'Wechat' },
          { value: '2', text: 'Ali' },
          { value: '3', text: '便利店' },
          { value: '4', text: '门店支付' },
          { value: '5', text: '银行转账' },
        ],
        ORDER_TYPE: [
          { value: '001', text: 'SIM+Wi-Fi' },
          { value: '002', text: 'SIM+光纤宽带' },
          { value: '003', text: 'SIM' },
          { value: '004', text: 'Wi-Fi' },
          { value: '005', text: '光纤宽带' },
          { value: '006', text: '服务 • 终端' },//tag
          { value: '007', text: 'Wi-Fi租赁' },
        ],
        ORDER_STATUS: [
          { value: '0', text: '审查中（办理中)' },
          { value: '1', text: '未完成（需要处理)' },
          { value: '2', text: '配送中' },
          { value: '3', text: '开通中（完成）' },
            "审查中（办理中）",
            "未完成（需要处理） ",
            "配送中",
            "订单完成"
        ],
        ORDER_PHONE: {
            NUMBER: "服务号码",
            CONTENT: "申请种类",
            DATESTART: "使用开始日",
            NUMBER_OF_USERS: "使用人数",
            INTENDED_AMOUNT: "预定金额",
            ACTUAL_AMOUNT: "实际金额",
            UPLOAD_IMAGE: "请上传收据的照片",
        }
    },

    BAR_MENU: {
        LOG_OUT: "退出",
        POINT: "积分数量:",
        MYPAGE:"我的IF Mobile",
    },

    CONTRACT_DETAIL: {
        TITLE: '契约内容',
        NOTE: '电话线路',
        CONTRACT_PHONE: "契约电话号码",
        CONTRACT_STATUS: '使用状态',
        CONTRACT_PLAN: '高速流量套餐',
        DATESTART:'使用开始日',
        BTN_DETAIL: '内容确认/变更',
        THIS_MONTH: '这个月',
        NEXT_MONTH: '下个月',
        PLAN_DATA: '流量套餐',
        CALL_QUOTA: '定额通话套餐',
        OPTION: '其他服务套餐',
        //option amountPlan
        TUTORIAL1: '变更套餐将在下个月生效。',
        TUTORIAL2: '变更手续费是 220 日元。每月只能变更一次。',
        TUTORIAL3: '选择下月套餐',
        //option converseQuota
        TUTORIAL4: '通话套餐变更后，将于第二天开始生效。',
        TUTORIAL5: '将产生 220 日元的变更手续费和当月的通话套餐费用。',
        TUTORIAL6: '每月只能变更一次。',
        //option ortherOption
        TUTORIAL7: '服务套餐选项的任何变更将在下个月生效。',
        TUTORIAL8: '产生 220 日元的变更手续费。',
        TUTORIAL9: '每月只能变更一次。',
        //MNP TUTORIAL
        MNPTUTORIAL1: 'MNP 转出时，如果是在契约期内将产生解约费。',
        MNPTUTORIAL2: '将产生 11,000 日元的解约费和 3,300 日元的手续费。',
        MNPTUTORIAL3: '支付完以后，可以当天发行MNP转出号码',
        //Cancellation TUTORIAL
        CANCELTUTORIAL1: '在契约期限内解约将产生解约费。',
        CANCELTUTORIAL2: '将收取 11,000 日元的解约费。',
        CANCELTUTORIAL3: '付款手续完成后，当日进行解约。',
        OPEN: "开通中",
        CANCEL: "解约",
        MNP:"MNP",
        TEMPORARY_STOP: "一时停止",
        GO_AGAIN: "重新发行",
        ALL:"全部",
        PAYMENT: '变更手续费',
        CONFIRM: '确定',
        BTN_CANCEL: "解约",
        PLAN_CHANGE: '变更套餐',
        CALL_CHANGE: '变更通话套餐',
        OPTION_CHANGE: '变更服务套餐',
        NOTE2: '宽带契约',
        NOTE3: '移动Wi-Fi契约',
        CONTRACT_LINE_ID: '宽带ID',
        CONTRACT_LINE_TYPE:"宽带类型",
        CONTRACT_IMEI: 'IMEI号码',
        CANCEL_CONTRACT: '解约',
        CANCEL_CONTRACT_TITLE: '解约申请',
        CHANGE_MNP_TITLE: 'MNP转出',
        CONTRACT_TYPE: '契约种类',
        EXPLANATORY_TEXT: '说明文',
        EXPLANATORY_PLACEHOLDER: '留言',
        SELECT_OPTION1: '请选择签约电话',
        SELECT_OPTION2: '请选择IMEI',
        SELECT_OPTION3: '请选择回线ID',
        YEN: '日元',
        UPDATE_STATUS: {
            TITLE: "使用停止・再次发行・再次开通（丢失・被盗・临时回国等等）",
            OPTION: "请选择对应的电话卡",
            OPTION1: "同号电话卡的再次发行",
            OPTION2: "电话卡临时停止",
            OPTION3: "电话卡再次开通",
            NOTICE1: "使用停止的时候，每月的费用还是会继续产生。",
            NOTICE2: "电话卡再发行的情况，会产生5000日元的再发行费用。",
            BUTTON: "确定",
            UPDATE_FALSE: "操作失败",
        }
    },

    CASH: {
        GOLD_EXCHANGE_RELUES: "兑换现金规则",
        NOTE: "① 积分以 10000 为单位起才可进行兑换。 （1 积分= 1 日元）",
        NOTE1: "② 可通过日本银行账户转账（手续费由客户承担）、各种二维码支付（微信支付，支付宝Paypay，PayPal）和门店现金来进行交换。",
        NOTE2: "③ 每月25号到月底，在“个人主页”里申请换金后，将在下个月28日至月底付款。",
        NUMBER_OF_REDEMPTION_POINTS: "换金积分数",
        REDEEMABLE_POINTS: "可兑换现金积分：",
        REDEMPTION_POINTS: "兑换现金积分：",
        AMOUNTS: "金额：",
        BANK_NAME: "※银行名称 ：",
        BRANCH_NAME: "※支店名称：",
        ACCOUNT_HOLDER: "※账户持有人：",
        YEN: '日元',
        JAPAN_BANK: "日本银行账号",
        BRANCH_NUMBER: "※支店号码：",
        ACCOUNT_NUMBER: "※账户号码：",
        TELEPHONE_NUMBER: "※电话号码：",
        BTN_BANK:"日本银行账号",
        BTN_PAY: "二维码支付",
        BTN_SURE: "保存",
        NOTICE: "如果您想变更已保存的银行帐户信息，请联系我们的客户服务中心。"
    },

    REFERRAL_CODE: {
        TITLE: "介绍专用链接",
        QR: "QR Code",
        CODE: "ifmobile.com?id=2023323de34334234343434343434332324545",
        URL: "URL",
        TEXT: "签约一人后系统会自动赠与3000的积分",
        TEXT1: "只需分享介绍专用URL，就能赚钱！",
        TEXT2: "详细",
    },

    FEE_INFO: {
        PDF: '下载使用明细　PDF',
        TITLE: '使用金额',
        MONTH_TITLE: "支付费用",
        SIM: "手机卡",
        OPT: "光纤费用",
        WIFI: "Wi-Fi费用",
        PHONE: "契约电话号码",
        OTPID: "回线ID:",
        IMEI: "IMEI",
        AMOUNT: "使用金额",
        PRICE: " 日元",
        PAYMENTFLAG: "支付状态",
        UNPAID: "未支付",
        PAID: "已支付",
        PAYDEADLINE: "支付期限",
        PAY: "支付",
        DETAIL: "查看使用明细",
        CREATE_FORM: "帳票作成",
        CALL_DETAILS: "上上个月的通话详细",
        MONTH_BASE: ' 上上个月的通话费用',
        SMS_BASE: ' 上上个月的短信费用',
        DOMESTIC_CALL: "国内通话：%{number}　分",
        INTERNATIONAL_CALL: "国际通话：%{number}　分",
        SMS_DETAILS: "上上个月的短信详细",
        DOMESTIC_SMS: "国内短信：%{number}　件",
        INTERNATIONAL_SMS: "国际短信：%{number}　件",
        //hiden form info
        FIVE_MINUTE: "5分钟内无限次日本国内通话",
        TEN_MINUTE: "10分钟内无限次日本国内通话",
        COMPLETELY_MINUTE:"日本国内无限制通话",
        NO_MINUTE:"不需要",
        ABSENCEPHONE: "留言电话",
        TELEPHONECHARGE: "通话费用",
        SMSFEE: "短信费用	",
        CATCHPHONE: "优先插入电话服务",
        PLAN: "使用套餐费用",
        TEXT1:"请在截止日期之前完成付款。如果在一定期限内无法确认付款，我们将暂停您的通信服务。如果由于暂时回国等原因导致付款延迟，请务必事先与我们联系。",
        TEXT2:"如您希望每月自动从银行账户、信用卡或 IF 积分中扣款支付费用，请联系客户支持中心。您也可以查看定期付款页面进行确认。",   
        MESSAGE1:"本月账单包括本月的高速流量费和前前月的通话和短信费用。",
        MESSAGE2:"需要提前支付，本月的账单是下个月的费用。",
    },

    DATA_USAGE: {
        MESSAGE: '高速流量剩余量变成0GB后，进入低速模式。高速流量充值',
        TITLE: '高速流量使用量',
        CONTACT_PHONE_NUMBER: '契约电话号码',
        DATA_PACKAGES: ' 高速流量套餐',
        UPDATE_DATE: '更新日期和时间：',
        DATA_USAGE_THIS_MONTH: '本月使用流量合计',
        TOTAL: '这个月剩余流量',
        TO_USE: '已使用流量',
        EXTANDT: '剩余流量',
        BTN: '流量追加购买',
        ALL_DATA: "%{data}GB (+流量充值量)",
        BARCHART_TITLE:"最近 4 天的流量使用状况",
        REVENUESUMMARY: "SIM流量充值费",
        PACKET_CHARGE: {
            TITLE: '',
            GUIDE: '选择流量，然后按“确认”按钮。每 1GB 的充值费用为 550 日元。使用期限：到本月底',
            SIM_NUMBER: 'SIM 卡号 :',
            FEE_AMOUNT: "流量充值",
            AMOUNT_OF_MONEY: '金额（含税）',
            CONFIRM: '购买',
            CANCEL: '取消',
            NOTICE:'选择容量并点击“购买”按钮后，购买完成后立即充值，您将进入高速模式。',
        },
    },

    RECUR_PAYMENT: {
        BTN_SUBMIT: '确定',
        BTN1: '信用卡信息注册',
        TITLE: '定期课金',
        TITLE2: '定期支付',
        BTN2: '更改定期卡',
        MESSAGE1:"●信用卡",
        MESSAGE2:"自动缴费的信用卡。",
        MESSAGE3:"信用卡号码等信息将被注册到由Univapay提供的支付服务中，并得到安全管理。",
        MESSAGE4:"变更适用时间表",
        MESSAGE5:"・在每月1日至3日更改：适用于当月的付款",
        MESSAGE6:"・在每月4日到月末更改：适用于下月的付款",
        MESSAGE7:"●自动转账",
        MESSAGE8_1:"自动转账 ",
        MESSAGE8_2:" 登录中",
        MESSAGE9:"如果您想使用自动转账来支付每月的费用，请选择银行自动转账作为每月付款的付款方式，并下载以下请求表格，在填写、盖章后将其发送给我们。",
        MESSAGE10:"邮寄地址：169-0075 東京都新宿区高田馬場1-28-3工新ビル　503号室　IF Mobile　お客様サポートセンター",
        MESSAGE11:"※银行转账登记：最短次月生效，月末登记的话，次次月生效。",
        MESSAGE12:"※邮寄時期：如果您在每月16日之前寄送申请，将会在下个月开始生效自动扣款。",
        MESSAGE13:"如果在每月16日之后发送，则从下下个月开始，可以进行账户自动扣款。",
        MESSAGE14:"※请务必盖章。",
        MESSAGE15:"※印章上的名称必须与金融机构账户注册的名称完全相同。",
        MESSAGE16:"●IF 积分",
        MESSAGE17_1:"IF 积分（详细信息请参阅",
        MESSAGE17_2:"IF积分历史记录",
        MESSAGE17_3:"）",
        MESSAGE18:"如果您想利用IF积分自动扣除每月的费用，请在将来选择IF积分作为每月付款方式，并在我的页面的积分管理中",
        MESSAGE18_1:"IF 积分",
        MESSAGE18_2:"充值",
        MESSAGE19:"持有积分",
        MESSAGE20:"积分充值 1个积分=1日元",
        MESSAGE21:"输入积分",
        MESSAGE22:"充值",
        MESSAGE23:"请仅支持全额使用积分进行支付，不支持部分积分支付。",
        MESSAGE24:"注意，IF积分不能用于数据充值、解约金、MNP转出费用或任何手续费的支付。",
        SIM: {
            TITLE: '手机卡的定期收费',
            CONTRACT_PHONE_NUMBER: '契约电话号码',
        },
        WIFI: {
            TITLE: '口袋Wi-Fi的定期收费',
            IMEI: 'IMEI',
        },
        HIKARI: {
            TITLE: '光纤的定期收费',
            LINE_ID: '回线ID',
        },
        CARD1: '银行卡',
        CARD2: '银行汇款',
    },

    MYPAGE_HOME: {
        TITLE: "重要通知",
        NOTICE_CONTENT: "银行账户或信用卡每月费用自动扣款的办理手续",
        TEXT_CONTENT1:"通过介绍积累的积分可以兑换现金！",
        LINK1: "点击此处了解详情",
        TEXT_CONTENT2:"介绍推荐用URL/二维码：请从菜单/积分管理中复制和下载后使用。",
        LINK2: "〉我的积分管理",
        INVOICE:"日元%{month}月请求金额 ",
        LINK3: "点击此处进行支付",
        REMAIN_DATA: "剩余流量",
        LINK4: "流量充值",
        MENU_TITLE: "经常使用的",
        MENU_TITLE1:"目录",
        MENU: [
        {
            name: "积分管理 ",
            value: 1,
            url: require("@/assets/historypoint.jpg"),
            icon: "clock-history",
        },
        {
            name: "提现管理",
            value: 2,
            url: require("@/assets/Cashmanagement.jpg"),
            icon: "cash-coin",
        },
        {
            name: "订单信息",
            value: 3,
            url: require("@/assets/Orderinformation.jpg"),
            icon: "box-seam",
        },
        {
            name: "流量使用情况",
            value: 4,
            url: require("@/assets/Datausage.jpg"),
            icon: "pie-chart",
        },
        {
            name: "契约信息",
            value: 5,
            url: require("@/assets/Contractinformation.jpg"),
            icon: "receipt-cutoff",
        },
        {
            name: "费用信息",
            value: 6,
            url: require("@/assets/Feeinformation.jpg"),
            icon: "list-check",
        },
        {
            name: "定期自动收费",
            value: 7,
            url: require("@/assets/recurringbilling.jpg"),
            icon: "calendar2-check",
        },
        {
            name: "账号与安全",
            value: 10,
            url: require("@/assets/AccountAndSecurity.png"),
            icon: "calendar2-check",
        },
        {
            name: "退出登录",
            value: 99,
            url: require("@/assets/LogOut.png"),
            icon: "calendar2-check",
        }
    ],
  },

    POINT_HISTORY: {
        DATETIME: "日時",
        DIFFERENTIATION: "区分",
        OPERATOR: "操作积分数",
        STATUS: "状态",
        POINTOPERATE: "操作者",
        DEPICT: "积分详细",
        TITLE: "积分管理",
        CURRENT_POINT: "持有积分",
        CHARGE_PRICE: "充值积分  1积分=1日元",
        ENTER_YOURSELF: "输入积分",
        SUBMIT: "充值",
        TABLE_TITLE:'积分记录',
        MESSAGE:"查看积分的累积和使用方法",
        NOTICE: {
            TEXT1: "请输入正确的积分",
            TEXT2: " 1积分=1日元",
            TEXT3: "以1000为一个单位",
            TEXT4: "最少充值积分：5000、最多充值积分：10000",
            TEXT5: "充值的积分不管任何理由都不予退还。",
            IMPORTANT1:"*详细信息",
            IMPORTANT2: "请确认",
            HIGHLIGHT1: "IF IF积分的使用规则",
            HIGHLIGHT2: "支付方式请看这里",
        },
        POINT_TYPE: [
          { value: '0', text: '介绍GET' },
          { value: '1', text: '消费GET' },
          { value: '2', text: '组合优惠GET' },
          { value: '3', text: '自己充值' },
          { value: '4', text: '管理员充值' },
          { value: '5', text: '月料金使用' },
          { value: '6', text: '服务利用' },
          { value: '7', text: '換金' },
        ],
        POINT_STATUS: [
          { value: '01', text: '确认中' },
          { value: '02', text: '确认完了' },
          { value: '03', text: '确认拒绝' },
          { value: '04', text: '充值完成' },
          { value: '05', text: '換金完成' },
        ],
        POINT_DETAIL: [
          { value: '0', text: '日本银行账号' },
          { value: '1', text: '二维码支付' },
        ],
    },

    SLIDE: [
        {
            url: require("@/assets/slide1.jpg"),
        },
        {
            url: require("@/assets/slide2.jpg"),
        },
        {
            url: require("@/assets/slide3.jpg"),
        },
        ],

    RENT_WIFI : {
        TITLE: "Wi-Fi机器租借",
        NOTICE: "请选择套餐再进行下一步。",
        NECESSARY_TITLE1: "请选择希望的套餐",
        NECESSARY_TITLE2: "请进行下一步",
        NECESSARY_TITLE3: "租借开始日",
        OPTION:[
          { "name": "1晚2天套餐", "price": "1180" },
          { "name": "2晚3天套餐", "price": "1770" },
          { "name": "3晚4天套餐", "price": "2360" },
          { "name": "4晚5天套餐", "price": "2950" },
          { "name": "5晚6天套餐", "price": "3540" },
          { "name": "6晚7天套餐", "price": "4130" },
          { "name": "7晚8天套餐", "price": "4720" },
          { "name": "8晚9天套餐", "price": "5310" },
          { "name": "9晚10天套餐", "price": "5900" },
          { "name": "10晚11天套餐", "price": "6490" },
          { "name": "11晚12天套餐", "price": "7080" },
          { "name": "12晚13天套餐", "price": "7670" },
          { "name": "13晚14天套餐", "price": "8260" },
          { "name": "1个月套餐", "price": "8850", "date": "（15天～31天）" },
          { "name": "2个月套餐", "price": "15750", "date": "（60晚61天）" },
          { "name": "3个月套餐", "price": "22000", "date": "（90晚91天）" },
          { "name": "4个月套餐", "price": "27600", "date": "（120晚121天）" },
          { "name": "5个月套餐", "price": "30600", "date": "（150晚151天）" },
          { "name": "6个月套餐", "price": "33600", "date": "（180晚181天）" }
      ],
    },

    TERMS: {
        SERVICE_CHARGE: "通信基础服务费",
    },

    ACCOUNT_SECURITY:{
        TITLE: "更改密码",
        NOTICE:"* 请填写必填项，",
        CURRENT_PASSWORD:"现在的密码 *",
        NEW_PASSWORD: "新密码 *",
        RE_ENTER_PASSWORD: "再次输入新密码 * ",
        CONFIRMATION_CODE: "确认码",
        SAVE_CHANGES: "保存更改",
        MESSAGE1: "旧密码输入有误",
        MESSAGE2: "新旧密码相同",
    },

    PLAN:{
      PL1: "晚",
      PL2: "天",
      PL3: "月",
      PL45: "无SIM卡 iPhone8[64GB]",
      PL46: "【中古品美品】",
      PL47: "本体价格20,000日円",
      PL48: "（含税价格22,000日円）",
      PL49: "无SIM 卡 iPhone13[128GB]",
      PL50: "【新品】",
      PL51: "本体价格98,000日円",
      PL52: "（含税价格107,800日円）",
      PL53: "套餐",
      PL54: "音声",
      PL55: "移动",
      PL56: "餐厅、旅行商品、日常服务",
      PL57: "最多可享受50％折扣",
      PL58: "iPhone / Android（新品、二手）、IoT设备",
      PL59: "会员折扣/分期付款",
      OPTION1: [
        { "price": "20日円/30秒", "text": "通话费用" },
        { "price": "500日円/GB", "text": "追加充值" },
        { "price": "3日円/通", "text": "日本国内短信" },
        { "price": "100日円/通", "text": "国际短信" },
      ],
      OPTION2: [
        { "text": "5分钟内无限次通话", "price": "500日円" },
        { "text": "10分钟内无限次通话", "price": "850日円" },
        { "text": "留言电话", "price": "350日円" },
        { "text": "优先插入电话服务", "price": "250日円" },
      ],
  },

  HEADER:{
    HD1: "会员登录",
    HD2: "费用・套餐",
    HD3: "MNP",
    HD4: "初期设定",
    HD5: "支付方法",
    HD6: "一次性购买",
    HD7: "租借",
    HD8: "费用",
    HD9: "介绍制度",
    HD10: "benefit",
    HD11: "合作伙伴服务",
    HD12: "Q＆A",
    HD13: "SNS",
    HD14: "日本語",
    HD15: "中国",
    HD16: "English",
    HD17: "Tiếng Việt",
    MESSAGE: "是否要退出登录？",
    YES: "同意",
  },

  GENERAL:{
    TEXT1: "服务号码",
    TEXT2: "申请内容",
    TEXT3: "使用开始日",
    TEXT4: "使用人数",
    TEXT5: "预定金额",
    TEXT6: "日元",
    LINK:"服务・终端",
  },

};
