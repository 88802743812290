import Vue from 'vue';

const MEDIA_WIDTH_MAX = 768;
const HEADER_HEIGHT_PC = '4.17vw';
const HEADER_HEIGHT_MOBILE = '13.87vw';

/**---- 吸顶 ----*/
Vue.directive('sticky', {
  inserted: function (el, binding) {
    // 获取当前视口宽度
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    // 获取原始位置
    const originalPosition = el.style.position;
    const originalTop = el.style.top;
    // 获取参数
    const position = binding.arg; // 'top' 或 'bottom'
    // 获取修饰符
    const fade = binding.modifiers.fade; // true 或 false
    // 监听滚动事件
    window.addEventListener('scroll', function () {
      // 获取滚动距离
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // 获取元素距离视口顶部或底部的距离
      let offset;
      if (position === 'top') {
        offset = el.getBoundingClientRect().top;
      } else if (position === 'bottom') {
        offset = window.innerHeight - el.getBoundingClientRect().bottom;
      }
      // 如果滚动距离大于等于元素距离视口顶部或底部的距离，则将元素固定在相应位置，并设置过渡效果（如果有）
      // console.log(scrollTop, offset)
      if (scrollTop >= offset) {
        el.style.position = 'fixed';
        el.style.zIndex = 2
        if (position === 'top') {
          el.style.top = viewportWidth > MEDIA_WIDTH_MAX ? HEADER_HEIGHT_PC : HEADER_HEIGHT_MOBILE;
        } else if (position === 'bottom') {
          el.style.bottom = '0';
        }
        if (fade) {
          el.style.transition = 'opacity 0.5s';
          el.style.opacity = '0.8';
        }
      } else {
        // 否则恢复原始位置和透明度
        el.style.position = originalPosition;
        el.style.top = originalTop;
        el.style.opacity = '1';
      }
    });
  }
});