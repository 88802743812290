<template>
    <section>
        <h1 class="main_title">サービス約款</h1>
        <b-card class="content__card">
            <span style="color: red; font-weight: 400;">第1章　総則</span>

            <div>
                <h5>第1条 (約款の適用)</h5>
                <p>星和通商株式会社（以下「当社」といいます）は、本「IFモバイルサービス契約約款」（以下「本約款」といいます）を定め、これにより本サービス（第3条に定義します）を提供します。</p>
            </div>

            <div>
                <h5>第2条 (約款の変更)</h5>
                <ol style="margin-left:2%">
                    <li>当社は、この約款を変更することがあります。この場合には、料金その他の提供条件は、変更後の約款によります。</li>
                    <li>当社はこの約款を変更するときは、当社のホームページ <a href=""
                            style="color:blue">（https://user.ifmobile.jp/ja/users/bill_detail)</a>
                        その他当社が別に定める方法により通知します。
                    </li>
                </ol>
            </div>

            <div>
                <h5>第3条 (約款の公表)</h5>
                <p>当社は、当社のホームページ <a href="" style="color:blue">（https://user.ifmobile.jp/ja/users/bill_detail)</a>
                    その他当社が別に定める方法により、この約款を公表します。</p>
            </div>

            <div>
                <h5>第4条 (用語の定義)</h5>
                <p>この約款においては、次の用語はそれぞれ次の意味で使用します。</p>
                <table class="table">
                    <tr style="background: #e9ecef;">
                        <td style="width: 30%; vertical-align: middle;">用語</td>
                        <td>用語の意味</td>
                    </tr>

                    <tr>
                        <td style="background: #e9ecef; vertical-align: middle;">IFモバイルサービス</td>
                        <td>この約款に基づいて提供される当社のサービス総称（以下、「本サービス」という）</td>
                    </tr>

                    <tr>
                        <td style="background: #e9ecef; vertical-align: middle;">契約者</td>
                        <td>本サービスの契約者</td>
                    </tr>

                    <tr>
                        <td style="background: #e9ecef; vertical-align: middle;">課金開始日</td>
                        <td>本サービスの利用に係る料金（初期費用、一時費用を除く）の起算日として当社が指定する日</td>
                    </tr>

                    <tr>
                        <td style="background: #e9ecef; vertical-align: middle;">解約日</td>
                        <td>本サービス契約の解約の効力が生ずる日</td>
                    </tr>

                    <tr>
                        <td style="background: #e9ecef; vertical-align: middle;"><span
                                style="background: yellow;">IPv4アドレス</span></td>
                        <td><span style="background: yellow;">インターネットプロトコルバージョン6(IPv6）として定められている32bitのアドレス</span></td>
                    </tr>

                    <tr>
                        <td style="background: #e9ecef; vertical-align: middle;"><span
                                style="background: yellow;">IPアドレス</span></td>
                        <td><span style="background: yellow;">IPv4アドレスの総称</span></td>
                    </tr>

                    <tr>
                        <td style="background: #e9ecef; vertical-align: middle;">SIMカード</td>
                        <td>契約者識別番号その他の情報を記憶することが出来るICカードであって、当社が契約者に貸与するものをいう。</td>
                    </tr>

                    <tr>
                        <td style="background: #e9ecef; vertical-align: middle;">ユニバーサルサービス料</td>
                        <td>ユニバーサルサービス提供の確保のため必要な負担金として、その使用している電話番号の数に比例した額を、基礎的電気通信役務支援機関である一般社団法人電気通信事業者協会を通じて、東日本電信電話株式会社または西日本電信電話株式会社に支払うために、当社が本サービス館員からこの特約に定める方法および金額にて徴収する料金をいう。
                        </td>
                    </tr>

                    <tr>
                        <td style="background: #e9ecef; vertical-align: middle;">MNP</td>
                        <td>電気通信事業法第2条第5号に定める電気通信事業者（以下「電気通信事業者」という）のうち携帯音声通信サービスを提供する者（以下「携帯音声通話事業者」という）を携帯音声通信サービスの加入者が変更する際に、変更元で利用していた電話番号を変更先でも継続して利用できる仕組み（Mobile
                            Number Portability：携帯電話番号ポータビリティ）をいう。</td>
                    </tr>
                </table>
            </div>

            <div>
                <h5>第5条 (本サービスの提供区域)</h5>
                <p>日本国内、且つ株式会社NTTドコモ（以下、「NTTドコモ」という）、au（以下、「au」という）が定める提供区域のうち当社が指定する提供区域とします。</p>
            </div>

            <div>
                <h5>第6条 (ID及びパスワード)</h5>
                <ol style="margin-left: 2%">
                    <li>契約者は、当社が契約者に対し付与するID及びパスワードの管理責任を負うものとします。</li>
                    <li>契約者はIDなどを第三者に利用させないものとします。</li>
                </ol>
            </div>

            <div>
                <h5>第7条 (専属的合意管轄裁判所)</h5>
                <p>当社と契約者の間で訴訟の必要が生じた場合、訴額に応じて、東京簡易裁判所又は東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
            </div>

            <div>
                <h5>第8条 (準拠法)</h5>
                <p>本約款に関する準拠法は、日本法とします。</p>
                <p style="color: red; font-weight: 400;">第2章　申込及び承諾等</p>
            </div>

            <div>
                <h5>第9条 (申込)</h5>
                <ol style="margin-left: 2%">
                    <li>本サービス利用の申込（以下、「申込」といいます）は、本サービス内容を特定するために必要な事項を記載した当社指定の書面を提出して行うものとします。</li>
                    <li>本サービスにおいて、音声機能付SIMカード利用の申込をする者は、本人確認（携帯音声通信事業者による契約者などの本人確認等及び携帯音声通信役務の不正な利用防止に関する法律（平成17年31号）第9条の規定に基づくものであって、氏名、住所、生年月日等の契約者を特定する情報の確認を行うことをいいます。以下同じとします）のために当社が別途定める書類を提示する必要があります。
                    </li>
                </ol>
            </div>

            <div>
                <h5>第10条 (申込の承諾等)</h5>
                <ol style="margin-left: 2%">
                    <li>当社は、本サービスの利用の申込があった時は、次条(申込の拒絶)に定める申込の拒絶事由に該当する場合を除き、これを承諾するものとします。</li>
                    <li>申込に係る本サービスの提供は、申込を受け付けた順とします。ただし、当社は、必要と認めるときは、その順序を変更することがあります。</li>
                </ol>
            </div>

            <div>
                <h5>第11条 (申込の拒絶)</h5>
                <ol style="margin-left: 2%">
                    <li>当社は、本サービスの申込者が次の各号に該当する場合には、本サービスの利用の申込を承諾しないことがあります。
                        <ul style="margin-left: 2%">
                            <li>本サービス利用のために契約者が満たすべき要件が満たされていないとき</li>
                            <li>申込に係る本サービスの提供又は当該サービスに係る装置の保守が技術上著しく困難なとき</li>
                            <li>本サービスの申込者が、当該申込に係る本サービス契約上の債務の支払いを怠るおそれがあると当社が判断するとき</li>
                            <li>
                                申込者が現に締結し、又は、従前締結していた本サービス契約において、債務不履行又は不法行為を行ったことがあるとき、若しくは当社により当該契約が解除されたことがあるとき
                            </li>
                            <li>本サービスの利用の契約申込書に不備があるとき、若しくは虚偽の事実を記載したとき</li>
                            <li>
                                違法、不当、公序良俗違反、当社若しくは当社のサービスの信用を毀損する、又は、当社サービスを直接若しくは間接に利用する者に重大な支障をきたす等の態様で本サービスを利用するおそれがあるとき
                            </li>
                            <li>その他当社が不適切と認めたとき</li>
                        </ul>
                    </li>
                    <li>当社が前項の規定により、本サービスの利用の申込を拒絶したときは、当社は、申込者に対し、当社所定の方法によりその旨を通知するものとします。</li>
                </ol>
            </div>

            <div>
                <h5>第12条 (権利義務の譲渡制限)</h5>
                <p>契約者は、本サービス契約上の権利義務を譲渡、売買、質権の設定その他の担保に供する等の一切の処分をすることは出来ません。</p>
            </div>

            <div>
                <h5>第13条 (契約者)</h5>
                <p>契約者は本約款およびその他の本サービスに関する諸規定に従って本サービスを利用するものとし、個人に限るものとします。</p>
            </div>

            <div>
                <h5>第14条 (契約の単位)</h5>
                <p>当社は、電話番号一番号ごとに一の本サービス契約を締結します。この場合、本サービス契約者は、一の本サービス契約につき一人に限ります。</p>
                <p style="color: red; font-weight: 400;">第3章　契約事項の変更</p>
            </div>

            <div>
                <h5>第15条 (本サービス内容の変更)</h5>
                <ol style="margin-left: 2%">
                    <li>契約者は、本約款に定めがある場合には、本サービス契約の内容の変更を請求することができるものとします。</li>
                    <li>第11条(申込の拒絶)の規定は、前項の請求があった場合について準用します。この場合において、同条中「申込」とあるのは「変更の請求」と、「申込者」とあるのは「契約者」と読み替えるものとします。
                    </li>
                </ol>
            </div>

            <div>
                <h5>第16条 (契約者の名称の変更等)</h5>
                <p>契約者は、その氏名若しくは名称又は住所若しくは居所その他当社が指定する事項に変更があったときは、当社に対し、速やかに当該変更の事実を証する書類を添えてその旨を届け出るものとします。</p>
            </div>

            <div>
                <h5>第17条 (個人の契約上の地位の引継)</h5>
                <ol style="margin-left: 2%">
                    <li>契約者である個人(以下この項において「元契約者」といいます。)が死亡したときは、元契約者に係る本サービス契約は、終了します。ただし、相続開始の日から2週間を経過する日までに当社に申出をすることにより、相続人(相続人が複数あるときは、そのうち1人を当社に対する代表者と定めて申出ていただきます。これを変更したときも同様とします。又、その際、当社は当該代表者である旨を証明する書類の提出を求める場合があります。)は、引き続き当該契約に係る本サービスの提供を受けることができます。当該申出があったときは、当該相続人は、元契約者の当該契約上の地位(元契約者の当該契約上の債務を含みます。)を引き継ぐものとします。
                    </li>
                    <li>第11条(申込の拒絶)の規定は、前項の場合について準用します。この場合において、同条中「申込」とあるのは「申出」と、「申込者」とあるのは「相続人」と、「本サービスの利用の契約申込書」とあるのは「申出書」とそれぞれ読み替えるものとします。
                    </li>
                </ol>
                <p style="color: red; font-weight: 400;">第4章　契約者の義務</p>
            </div>

            <div>
                <h5>第18条 (契約者の義務)</h5>
                <p>契約者は、本約款に定められた契約者の義務を遵守するものとします。</p>
            </div>

            <div>
                <h5>第19条 (禁止事項)</h5>
                <ol style="margin-left: 2%">
                    <li>契約者は、次の各号のいずれかに該当する事項を行ってはならないものとします。違法、不当、公序良俗に反する態様において本サービスを利用すること</li>
                    <li>当社又は当社のサービスの信用を毀損するおそれがある態様で本サービスを利用すること</li>
                    <li>当社のサービスを直接又は間接に利用する者の当該利用に対し支障を与える態様において本サービスを利用すること</li>
                    <li>第三者に対し、販売、又は利用を許諾する目的において本サービスを利用すること</li>
                    <li>その他以下に該当する行為をすること。
                        <ul style="margin-left: 2%">
                            <li>電子メールの送受信上の支障を生じさせるおそれのある電子メールを送信する行為</li>
                            <li>
                                (a)のほか、当社若しくは他社のインターネット関連設備の利用若しくは運営、又は他の契約者の平均的な利用の範囲に支障を与える行為又は与えるおそれがある行為</li>
                            <li>
                                無断で他人に広告、宣伝若しくは勧誘する行為又は他人に嫌悪感を抱かせ、若しくは嫌悪感を抱かせるおそれがある文章等を送信、記載若しくは転載する行為
                            </li>
                            <li>他人になりすまして各種サービスを利用する行為</li>
                            <li>他人の著作権、肖像権、商標、特許権その他の権利を侵害する行為又は侵害するおそれがある行為</li>
                            <li>他人の財産、プライバシー等を侵害する行為、又は侵害するおそれがある行為</li>
                            <li>他人を差別若しくは誹膀中傷し、又はその名誉若しくは信用を毀損する行為</li>
                            <li>猥褻、虐待等、児童及び青少年に悪影響を及ぼす情報、画像、音声、文字、文書等を送信、記載又は掲載する行為</li>
                            <li>無限連鎖講（ネズミ講）若しくはマルチまがい商法を開設し、又はこれを勧誘する行為</li>
                            <li>連鎖販売取引（マルチ商法）に関して特定商取引に関する法律（昭和51年法律第57号）に違反する行為</li>
                            <li>インターネット接続機能により利用しうる情報を改ざんし、又は消去する行為</li>
                            <li>ウィルス等の有害なコンピュータープログラム等を送信し、又は掲載する行為</li>
                            <li>犯罪行為又はそれを誘発若しくは扇動する行為</li>
                            <li>(a)から(m)のほか、法令又は慣習に違反する行為</li>
                            <li>売春、暴力、残虐等、公序良俗に違反し、又は他人に不利益を与える行為</li>
                            <li>その他、当社のサービスの運営を妨げる行為</li>
                            <li>上記(p)までの禁止行為に該当するコンテンツヘのアクセスを助長する行為</li>
                        </ul>
                    </li>
                </ol>
            </div>

            <div>
                <h5>第20条 (契約者の義務違反)</h5>
                <p>契約者が、第18条(契約者の義務)又は前条(禁止事項)に違反した場合にあっては、当社は、契約者に対してこれにより当社が被った損害の賠償請求をすることができるものとします。又、契約者が本サービスの利用に関して第三者に与えた損害につき当社が当該第三者に当該損害の賠償をしたときは、当社は、契約者に対し、当該賠償について求償することができるものとします。
                </p>
                <p style="color: red; font-weight: 400;">第5章　品質保証、責任の限定等</p>
            </div>

            <div>
                <h5>第21条 (本サービスの品質保証又は保証の限定)</h5>
                <ol style="margin-left: 2%">
                    <li>本サービスは、ドコモ、AUの移動無線通信に係る通信網において通信が著しく輻輳したとき、電波状況が著しく悪化した場合又はその他ドコモ、AUの定めに基づき、通信の全部又は一部の接続ができない場合や接続中の通信が切断される場合があり、当社は、当該場合において契約者又は第三者に発生した損害について何ら責任を負うものではありません。
                    </li>
                    <li>前項に定める事項のほか、本サービスは、その通信の可用性、遅延時間その他通信の品質について保証するものではありません。</li>
                </ol>
            </div>

            <div>
                <h5>第22条 (当社の免責)</h5>
                <p>当社は、本約款において明示的に規定された場合を除き、前条(本サービスの品質保証又は保証の限定)によって定められた品質保証の違背、その他、契約者が本サービスの利用に関して被った損害(その原因の如何を問いません。)について賠償、返金、料金の減免等の責任を負わないものとします。
                </p>
                <p style="color:red; font-weight: 400;">第6章　利用の制限、中止及び停止並びに本サービスの廃止</p>
            </div>

            <div>
                <h5>第23条 (利用の制限)</h5>
                <ol style="margin-left: 2%">
                    <li>当社は、電気通信事業法第8条の規定に基づき、天災事変その他の非常事態が発生し、若しくは発生するおそれがあるときは、災害の予防若しくは救援、交通、通信若しくは電力の供給の確保又は秩序の維持に必要な通信その他の公共の利益のために緊急を要する通信を優先的に取り扱うため、本サービスの利用を制限する措置を採ることがあります。
                    </li>
                    <li>当社は、児童買春、児童ポルノに係る行為等の処罰及び児童の保護等に関する法律（平成11年法律第52号）において定める児童ポルノを閲覧又は取得するための通信を制限する場合があります。</li>
                </ol>
            </div>

            <div>
                <h5>第24条 (利用の中止)</h5>
                <ol style="margin-left: 2%">
                    <li>当社は、次に掲げる事由があるときは、本サービスの提供を中止することがあります。
                        <ul style="margin-left: 2%">
                            <li>当社の電気通信設備の保守又は工事のためやむを得ないとき</li>
                            <li>当社が設置する電気通信設備の障害等やむを得ない事由があるとき</li>
                        </ul>
                    </li>
                    <li>当社は、本サービスの提供を中止するときは、契約者に対し、前項第1号により中止する場合にあっては、その14日前までに、同項第2号により中止する場合にあっては、事前に、その旨並びに理由及び期間を通知します。ただし、緊急やむを得ないときは、この限りではありません。
                    </li>
                </ol>
            </div>

            <div>
                <h5>第25条 (利用の停止等)</h5>
                <ol style="margin-left: 2%">
                    <li>当社は、契約者が次の各号に該当するときは、本サービスの提供を停止又は利用を制限することがあります。
                        <ul style="margin-left: 2%">
                            <li>本サービス契約上の債務の支払を怠り、又は怠るおそれがあることが明らかであるとき</li>
                            <li>本サービスに係る契約の申し込みに当たって当社所定の書面に事実に反する記載を行ったことが判明したとき</li>
                            <li>第16条（契約者の名称の変更等）に違反したとき、又は第16条（契約者の名称の変更等）の規定により届け出た内容について事実に反することが判明したとき</li>
                            <li>第18条(契約者の義務)の規定に基づき定められた契約者の義務に違反したとき</li>
                            <li>第19条(禁止事項)の規定に違反したとき</li>
                            <li>その他本約款に違反したとき</li>
                            <li>本サービスの契約者への提供が適切でないと、当社が判断したとき</li>
                            <li>当社は、前項の規定による措置を講ずるときは、契約者に対し、あらかじめその理由及び期間を通知します。ただし、緊急やむを得ないときは、この限りではありません。</li>
                        </ul>
                    </li>
                </ol>
            </div>

            <div>
                <h5>第26条 (本サービスの廃止)</h5>
                <ol style="margin-left: 2%">
                    <li>当社は、当社の判断により、本サービスの全部又は一部を廃止することがあります。</li>
                    <li>当社は、前項の規定により本サービスの全部又は一部を廃止するときは、契約者に対し、廃止する日の1ヶ月前までに、その旨を通知します。</li>
                    <li>NTTドコモ、auの電気通信サービスの提供が、契約の解除その他の理由により終了した場合、本サービスは自動的に廃止となります。</li>
                </ol>
                <p style="color: red; font-weight: 400;">第7章　契約の解除</p>
            </div>

            <div>
                <h5>第27条 (当社による解除)</h5>
                <p>当社は次に掲げる事由があるときは、本サービス契約を解除することがあります。</p>
            </div>

            <div>
                <h5>第28条 (契約者による解約)</h5>
                <ol style="margin-left: 2%">
                    <li>契約者は、本サービス契約を解約しようとするときは、解約の当月末を解約日とし、本約款若しくはwebサイトに定める手順に従い、当社指定の書面を当社の指定する場所に届け出ていただきます。この場合、本条第2項に基づき当社にSIMカードを返還します。
                    </li>
                    <li>契約者が利用契約を解約する場合、貸与機器がある場合は当社に返還するものとします。</li>
                    <li>本条による解約の場合、解約月における全ての利用料その他債務を契約者が負担するものとする。</li>
                </ol>
            </div>

            <div>
                <h5>第29条 (機器の選定)</h5>
                <p>貸与機器は、契約回線数に応じて、又は契約者が指定できる種類等がある場合にはその種類等の中から、当社が選択して貸与するものとします。</p>
            </div>

            <div>
                <h5>第30条 (機器の管理)</h5>
                <ol style="margin-left: 2%">
                    <li>契約者は当社が貸与する貸与機器につき、通常の用途以外の使用をしないこと。</li>
                    <li>当社は、第三者が貸与機器を利用した場合であっても、当該貸与機器の貸与を受けている契約者が利用したものとみなして取り扱います。</li>
                    <li>本サービス契約が事由の如何を問わず終了した場合、その他貸与機器を利用しなくなった場合には、契約者は、遅滞なく貸与機器を当社に返還するものとします。</li>
                    <li>当社は、契約者との本サービス契約が理由の如何を問わず終了した場合、若しくは貸与機器を利用しなくなった場合は、当該本サービス契約にかかる貸与機器に登録された一切の情報を、当社が別に定める方法により消去するものとし、契約者は一切異議を述べないものとします。
                    </li>
                </ol>
            </div>

            <div>
                <h5>第31条 (故障が生じた場合の措置等)</h5>
                <p>契約者は、貸与機器に故障が生じたときは、当該貸与機器を当社に返還するものとします。当社は、代替貸与機器の送付を行います。貸与機器の故障が契約者の責によるものである場合には、契約者は、当社に対し、当該貸与機器の回復に要する費用として金額を支払うものとします。
                </p>
            </div>

            <div>
                <h5>第32条 (亡失品に関する措置)</h5>
                <p>契約者は、貸与機器を亡失した場合は可及的速やかに当社が定める方法により当社に通知するものとし、当社は、当該通知があったときは代替貸与機器の送付を行います。事由の如何を問わず、契約者は、当社に対し亡失負担金を支払うものとします。
                </p>
            </div>

            <div>
                <h5>第33条 (ソフトウェアの利用)</h5>
                <ol style="margin-left: 2%">
                    <li>契約者は、本サービスにおける通信を行う場合において、当社が提供するソフトウェアを利用すことができるものとします。</li>
                    <li>契約者は、前項の利用の場合において、別途当社が定めるソフトウェアに関する使用許諾条件を遵守するものとします。</li>
                </ol>
                <p style="color: red; font-weight: 400;">第8章　料金等</p>
            </div>

            <div>
                <h5>第34条 (契約者の料金支払義務)</h5>
                <ol style="margin-left: 2%">
                    <li>契約者は、当社に対し、本サービスの利用に関し、料金表に定めるところにより料金を支払うものとします。</li>
                    <li>この場合において、初期費用の支払義務は本サービスの申込を当社が承諾した時点で、月額利用料の支払義務は課金開始日に、一時費用の支払義務は当該一時費用の発生に係る契約内容変更の申込を当社が承諾した時点で、それぞれ発生するものとします。
                    </li>
                    <li>本約款の他の条項及び個別規程で定める場合を除き、本サービスの利用に伴って継続的に課金される料金について、課金開始日が暦月の初日以外の日である場合にあっては当社が定める日割計算式を適用して算定するものとします。
                    </li>
                    <li>第23条(利用の制限)、第24条(利用の中止)ならびに第25条(利用の停止等)の規定により、本サービスの利用が停止又は制限された場合の当該停止又は制限の期間における当該サービスに係る本サービスの料金の額の算出については、当該サービスの提供があったものとして取り扱うものとします。
                    </li>
                </ol>
            </div>

            <div>
                <h5>第35条 (最低利用期間)</h5>
                <p>本サービス契約について、最低利用期間内における解除、契約内容の変更その他本約款で定める事由が発生した場合には、契約者は、別添「本サービスに関する料金・サービス内容」に定めるところにより、契約解除料を支払うものとします。
                </p>
            </div>

            <div>
                <h5>第36条 (料金の支払方法)</h5>
                <p>契約者は、本サービスの料金を、当社が指定する期日までに、当社が指定する方法により支払うものとします。</p>
            </div>

            <div>
                <h5>第37条 (割増金)</h5>
                <p>本サービスの料金の支払を不法に免れた契約者は、当社に対しその免れた金額の2倍に相当する金額を支払うものとします。</p>
            </div>

            <div>
                <h5>第38条 (遅延損害金)</h5>
                <p>契約者は、本サービスの料金その他本サービス契約上の債務の支払を怠ったときは、その支払うべきこととされた日の翌日から支払いの日の前日までの間の当社が定める日数について年14．6％の割合（年当たりの割合は、閏年の日を含む期間についても、365日当たりの割合とします）で算出した額の遅延損害金を支払うものとします。
                </p>
            </div>

            <div>
                <h5>第39条 (割増金等の支払方法)</h5>
                <p>第36条(料金の支払方法)の規定は、第37条(割増金)及び前条(遅延損害金)の場合について準用します。</p>
            </div>

            <div>
                <h5>第40条 (消費税)</h5>
                <p>契約者が当社に対し本サービスに関する債務を支払う場合において、消費税法(昭和63年法律第108号)及び同法に関する法令の規定により当該支払について消費税が賦課されるものとされているときは、契約者は、当社に対し、当該債務を支払う際に、これに対する消費税相当額を併せて支払うものとします。
                </p>
                <p style="color: red; font-weight: 400;">第9章　契約者情報</p>
            </div>

            <div>
                <h5>第41条 (通信の秘密)</h5>
                <p>当社は、通信の秘密に係る契約者の情報について、電気通信事業法（昭和59年法律第86号）第4条を遵守した取り扱いを行うものとします。</p>
            </div>

            <div>
                <h5>第42条 (営業秘密等)</h5>
                <p>契約者は、本サービスの利用に関し知り得た当社の技術情報、本サービスの内容、その他当社が秘密である旨指定して契約者に開示する場合の当該情報について、当社があらかじめ承諾した場合を除き、第三者に開示してはならないものとします。
                </p>
            </div>

            <div>
                <h5>第43条 (個人情報の取り扱い)</h5>
                <ol style="margin-left: 2%">
                    <li>当社は、本サービスの提供に関して取得した個人情報を利用目的の範囲内において取り扱うものとします。</li>
                    <li>当社は、代金の決済のためクレジットカード情報を取得する場合があります。当該情報は契約者がカード決済時にご利用された各カード会社を含む各種決済代行事業者に提供し、その後当社で控えとして当社で定めた期間保管した後削除いたします。
                    </li>
                    <li>当社は、当社が指定する契約（以下、「対象契約」といいます）の契約者（申込者含む）に関する個人情報を、契約者の対象契約に関する契約及び継続可否審査または契約者の対象契約に関する代金の支払能力調査の目的で、当社が加盟する個人信用情報機関（以下、「加盟個人信用情報機関」といいます）ならびに、与信業務等に関して提携する企業（以下、「提携企業」といい、加盟個人信用情報機関と提携企業をあわせて「加盟個人信用情報機関等」といいます）に、契約者が当社に登録している情報を提供する場合があります。
                    </li>
                    <li>当社は、本サービス契約の販売代理者または取次者（以下、「販売代理者等」といいます）に、販売代理者等への手数料等の支払い、当社と販売代理者等との業務の連携及び販売代理者等から契約者への連絡の目的で、契約者の氏名及び携帯電話番号を書面により提供します。
                    </li>
                    <li>当社は、本サービスの提供に係る業務における個人情報の取扱いの全部又は一部を第三者に委託する場合にあっては、当社は、当社の監督責任下において個人情報を第三者に委託するものとします。</li>
                    <li>契約者による当社への個人情報の提出は任意ですが、本サービス契約の提供に必要な情報が提出されない場合は、本サービス契約の提供ができません。</li>
                    <li>契約者は、当社に対して契約者本人の個人情報に関して、利用目的の通知および個人情報の開示、訂正・追加・削除、利用停止・提供停止・消去の請求を当社のおこなうことができます。当該請求の方法は、「個人情報に関する公表事項」をご確認ください。
                    </li>
                    <li>当社は、契約者の個人情報を、個人情報保護管理者（財務総務部 責任者 03-5155-4673）の統括の下、適切に管理するものとします。</li>
                </ol>
                <p style="color: red; font-weight: 400;">第10章　雑則</p>
            </div>

            <div>
                <h5>第44条 (電磁的方法による意思表示)</h5>
                <p>当社及び契約者間の書面の交付、通知、提出等は、当社が定める範囲内において、電磁的方法により行うことができるものとします。</p>
            </div>

            <div>
                <h5>第45条 (業務委託)</h5>
                <p>当社は、本サービスの提供に必要な業務については、当社の指定する第三者に委託することができるものとします。</p>
            </div>

            <div>
                <h5>第46条 (本サービス利用に必要な役務等)</h5>
                <p>本サービスを利用するために必要な電気供給等の役務、装置等は、本約款において明示的に規定されている場合を除き、契約者の責任において調達するものとします。</p>
            </div>

            <div>
                <h5>第47条 (技術的事項)</h5>
                <p>本サービスにおける基本的な技術事項は、別途当社が定めるものとします。</p>
            </div>

            <div>
                <h5>第48条 (報告)</h5>
                <p>当社は、契約者に対し、必要に応じ合理的な範囲で、本サービスの利用の状況について報告を求めることができるものとします。この場合において、契約者は、速やかに当該報告を行うものとします。</p>
            </div>

            <div>
                <h5>第49条 (位置情報の送出)</h5>
                <p>携帯電話事業者または協定事業者がワイヤレスデータ通信にかかる当社との間に設置した接続点と、契約者回線との間の通信中にその当社にかかる電気通信設備から携帯事業者が別に定める方法により位置情報（その契約者回線に接続されている移動無線装置の所在にかかる情報をいう。以下、本条において同じとする）の要求があったときは、契約者があらかじめ当社への位置情報の送出にかかる設定を行った場合に限り、その接続点へ位置情報を送出することを、契約者は、あらかじめ承諾するものとします。
                </p>
            </div>

            <div>
                <h5>第50条 (情報の収集)</h5>
                <p>当社は、本サービスに関し、契約者に技術サポート等を提供するために必要な情報を収集、利用することがあります。契約者は、契約者から必要な情報が提供されないことにより、当社が十分な技術サポート等を提供できないことがあることをあらかじめ了承するものとします。
                </p>
            </div>

            <div>
                <h5>第51条 (反社会勢力に対する表明保証)</h5>
                <ol style="margin-left: 2%">
                    <li>契約者は、サービス利用契約締結時および締結後において、自らが暴力団または暴力団関連企業・団体その他反社会的勢力（以下、総称して「反社会的勢力」という）ではないこと、反社会的勢力の支配・影響を受けていないことを表明し、保証するものとします。
                    </li>
                    <li>契約者が次の各号のいずれかに該当することが合理的に認められた場合、当社はなんら催告することなくサービス利用契約を解除することができるものとします。
                        <ul style="margin-left: 2%">
                            <li>反社会的勢力に属していること</li>
                            <li>反社会的勢力が経営に実質的に関与していること</li>
                            <li>反社会的勢力を利用していること</li>
                            <li>反社会的勢力に対して資金などを提供し、または便宜を提供するなどの関与をしていること</li>
                            <li>反社会的勢力と社会的に非難されるべき関係を有していること</li>
                            <li>自らまたは第三者を利用して関係者に対し、詐術、暴力的行為、または脅迫的言辞を用いたこと</li>
                        </ul>
                    </li>
                    <li>前項各号のいずれかに該当した契約者は、当社が当該解除により被った損害を賠償する責任を負うものとし、自らに生じた損害の賠償を当社に求めることはできないものとします。</li>
                </ol>
            </div>

            <div>
                <h5>第52条 (他の電気通信事業者への情報の通知)</h5>
                <p>契約者は、料金その他の債務の支払いをしない場合、または前条に定める契約者確認に応じない場合には、当社が、当社以外の電気通信事業者からの請求に基づき、氏名、住所、契約者識別番号、生年月日および支払い状況等の情報（契約者を特定するために必要なものおよび支払い状況に関するものであって、当社が別に定めるものに限る）を当該事業者に通知することにあらかじめ同意するものとします。
                </p>
            </div>

            <div>
                <h5>第53条 (本サービスの技術仕様等の変更等)</h5>
                <p>当社は、本サービスにかかわる技術仕様その他の提供条件の変更または電気通信設備の更改などに伴い、契約者が使用する本SIMカードの改造または撤去などを要することとなった場合であっても、その改造または撤去等に要する費用について負担しないものとします。
                </p>
            </div>

            <div>
                <h5>第54条 (分離性)</h5>
                <p>本規約の一部分が無効で強制力をもたないと判明した場合でも、本規約の残りの部分の有効性はその影響を受けず引き続き有効で、その条件に従って強制力を持ち続けるものとします。</p>
            </div>

            <div>
                <h5>第55条 (協議)</h5>
                <p>当社および契約者は、本サービスまたは本規約に関して疑義が生じた場合には、両者が誠意を持って協議のうえ解決するものとします。</p>
            </div>

            <div>
                <h5>第56条 (その他)</h5>
                <p>本規約から生じる当社の権利は、当社が権利を放棄する旨を契約者に対して明示的に通知しない限り、放棄されないものとします。</p>
                <br /><br />
                <p>以上</p>
            </div>

        </b-card>
    </section>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  mounted() {

  },

  created() {
    this.toTop();
  },
  computed: {},
  methods: {
    toTop: function () {
      window.scrollTo(0,0);
    },
  },
};
</script>

<style lang="scss" scoped>
.main_title {
    border: none;
    text-decoration: underline;
    text-underline-offset: 10px;
    margin: auto;
    text-align: center;
}

ol {
    list-style: auto;
}

ul {
    list-style: lower-alpha;
}

p,
li {
    font-weight: 100;
}

h4,
h5 {
    font-weight: 500;
}

section {
    width: 80%;
    text-align: left;
    margin: auto;

    .content__card {
        min-width: 200px;
        background: #fff;
        border-radius: 5px;
        border: 2px solid var(--primary);
        width: 80%;
        margin: 50px auto 50px;
    }
}

.table {

    tr,
    td {
        border: 1px solid #000;
    }
}

@media screen and (max-width: 1000px) {

    section {
        width: 100%;
    }

    .content__card {
        width: 90% !important;
    }
}

@media screen and (max-width: 390px) {
    .content__card {
        width: 100% !important;
    }
}
</style>
