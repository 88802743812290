<template>
  <div>
    <div class="content">
        <div class="container">
          <!-- <iframe class="video"
            src="https://www.youtube.com/embed/Yj2iELI6OeI?controls=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=Yj2iELI6OeI">
          </iframe> -->
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },

}
</script>

<style lang="scss" scoped>
.content{
  width: 100%;
  margin: auto;
  margin-top: 220px;
}
.video {
  height: 100%;
  min-height: 500px;
  width: 100%;
}

@media (max-width: 600px) {
  .video {
    min-height: 290px;
  }
}
</style>
