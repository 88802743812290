import Vue from 'vue';

const ConstPlugin = {
    install(Vue, options) {
        Vue.prototype.$CONST = {
            DEV: false,
            BUTTON: {
                PREVIOUS: '戻る',
                NEXT: '次へ',
                CREATE: '登録',
                MENU: {
                    TOP: "トップ",
                    SIM_SERVICE: "SIMサービス",
                    CAMPAIGN: "キャンペーン",
                    RATE_PLAN: "料金プラン"
                }
            },
            COMMON: {
                LANGUAGE: {
                    JA: 0,
                    ZH: 1,
                    EN: 2,
                },
                API_DATA_USAGE: {
                    Response: {
                        "code": 0,
                        "message": "",
                        "result": [{
                            "availableData": 20,
                            "chargeUnitPrice": 250,
                            "fourMonthsResidualDataPOJOList": [{
                                    "exploitData": 50,
                                    "month": "7月"
                                },
                                {
                                    "exploitData": 40,
                                    "month": "8月"
                                },
                                {
                                    "exploitData": 25,
                                    "month": "9月"
                                },
                                {
                                    "exploitData": 40,
                                    "month": "10月"
                                },
                            ],
                            "phoneNo": "123456789",
                            "residualData": 30,
                            "simMakeCd": "038-3474-34748",
                            "simPlan": "23",
                            "totalPacketData": 50,
                            "updateTime": ""
                        }, ],
                        "success": true,
                        "timestamp": 0
                    }
                },
                API_RECUR_PAYMENT: {
                    Response: {
                        "code": 0,
                        "message": "",
                        "result": [{
                            "sim": [{
                                    "phone_number": "1234567890",
                                    "account": "2345xxxx56789"
                                },
                                {
                                    "phone_number": "0987654321",
                                    "account": "98765xxx4321"
                                },
                            ],
                            "wifi": {
                                "imei": "5464658",
                                "account": "2345xxxx56789"
                            },
                            "hakiri": {
                                "line_id": "65846598",
                                "account": "2345xxxx56789"
                            },
                        }],
                        "success": true,
                        "timestamp": 0
                    }
                },
                API_CONTRACT_WIFI: {
                    Response: {
                        "code": 0,
                        "message": "",
                        "result": {
                            "current": 0,
                            "pages": 0,
                            "records": [{
                                "agent": "",
                                "contractNo": "contractwifi",
                                "contractStatus": 1,
                                "contractor": "",
                                "email": "",
                                "id": "",
                                "imei": "112233",
                                "mnpSolutionPrice": 220,
                                "solutionPrice": 0,
                                "wifiPlanCode": "",
                                "wifiPlanName": "50G",
                                "wifiPlanPrice": 0
                            }],
                            "size": 0,
                            "total": 0
                        },
                        "success": true,
                        "timestamp": 0
                    }
                },
                API_CONTRACT_FIBER: {
                    Response: {
                        "code": 0,
                        "message": "",
                        "result": {
                            "current": 0,
                            "pages": 0,
                            "records": [{
                                "agent": "",
                                "contractNo": "contractOtp",
                                "contractStatus": 1,
                                "contractor": "",
                                "email": "",
                                "id": "",
                                "mnpSolutionPrice": 230,
                                "optLineId": "01234",
                                "optPlanCode": "",
                                "optPlanName": "50G",
                                "optPlanPrice": 0,
                                "solutionPrice": 0
                            }],
                            "size": 0,
                            "total": 0
                        },
                        "success": true,
                        "timestamp": 0
                    }
                },
                API_CONTRACT_SIM: {
                    Response: {
                        "current": 0,
                        "pages": 0,
                        "records": [{
                                "agent": "",
                                "amountPlanCode": "000003",
                                "amountPlanName": "1G",
                                "amountPlanPrice": 0,
                                "applicationYmd": "",
                                "befAmountPlanCode": "",
                                "befAmountPlanName": "",
                                "befAmountPlanPrice": 0,
                                "catchPhonePrice": 120,
                                "contractNo": "contractSim1",
                                "contractPhoneNum": "1233211231",
                                "contractStatus": 0,
                                "contractor": "",
                                "converseQuotaCode": "000012",
                                "converseQuotaName": "10分かけ放題",
                                "converseQuotaPrice": "",
                                "email": "abcd@gmail.com",
                                "fixedChangePrice": 220,
                                "id": "11111111",
                                "mnpSolutionPrice": 200,
                                "nextAmountPlanCode": "",
                                "nextAmountPlanName": "1G",
                                "nextAmountPlanPrice": 0,
                                "ortherOptionNames": "スマート留守電, キャッチフォン",
                                "otherOptionsCode": "000017,000016",
                                "smartAnsweringPrice": 120,
                                "solutionPrice": 0,
                                "stopYmd": ""
                            },
                            {
                                "agent": "",
                                "amountPlanCode": "000005",
                                "amountPlanName": "5G",
                                "amountPlanPrice": 0,
                                "applicationYmd": "",
                                "befAmountPlanCode": "33333",
                                "befAmountPlanName": "",
                                "befAmountPlanPrice": 0,
                                "catchPhonePrice": 120,
                                "contractNo": "contractSim2",
                                "contractPhoneNum": "2222222222",
                                "contractStatus": 0,
                                "contractor": "",
                                "converseQuotaCode": "000014",
                                "converseQuotaName": "通話定額は必要ない",
                                "converseQuotaPrice": "",
                                "email": "abcd@gmail.com",
                                "fixedChangePrice": 220,
                                "id": "222222222",
                                "mnpSolutionPrice": 300,
                                "nextAmountPlanCode": "",
                                "nextAmountPlanName": "5G",
                                "nextAmountPlanPrice": 0,
                                "ortherOptionNames": "",
                                "otherOptionsCode": "",
                                "smartAnsweringPrice": 120,
                                "solutionPrice": 0,
                                "stopYmd": ""
                            },
                            {
                                "agent": "",
                                "amountPlanCode": "000004",
                                "amountPlanName": "5G",
                                "amountPlanPrice": 0,
                                "applicationYmd": "",
                                "befAmountPlanCode": "",
                                "befAmountPlanName": "",
                                "befAmountPlanPrice": 0,
                                "catchPhonePrice": 120,
                                "contractNo": "contractSim3",
                                "contractPhoneNum": "000000000",
                                "contractStatus": 0,
                                "contractor": "",
                                "converseQuotaCode": "000011",
                                "converseQuotaName": "5分かけ放題",
                                "converseQuotaPrice": "",
                                "email": "abcd@gmail.com",
                                "fixedChangePrice": 220,
                                "id": "33333333333",
                                "mnpSolutionPrice": 350,
                                "nextAmountPlanCode": "",
                                "nextAmountPlanName": "5G",
                                "nextAmountPlanPrice": 0,
                                "ortherOptionNames": "キャッチフォン",
                                "otherOptionsCode": "000017",
                                "smartAnsweringPrice": 120,
                                "solutionPrice": 0,
                                "stopYmd": ""
                            }
                        ],
                        "size": 0,
                        "total": 0
                    },
                },
                API_COMPANY: {
                    Response: {
                        "code": 0,
                        "message": "",
                        "result": {
                            "businessCardUrl": "",
                            "chairman": "代表取締役を入力して下さい ",
                            "companyAddress": "会社住所を入力して下さい",
                            "companyLicenseUrl": "",
                            "companyPostalCode": "電話番号を入力して下さい",
                            "companyTel": "電話番号を入力して下さい",
                            'heardCode': 7,
                            'heardName': 'その他広告',
                            "hopeLanguage": '',
                            "introduceCode": 0,
                            "principalAddress": "職務 ",
                            "principalName": "担当者名 ",
                            "principalTel": "緊急連絡者電話",
                            "principalTitle": "担当者連絡先",
                            'toolAccount': "",
                            'toolCode': 3,
                            'toolName': "",
                            "userId": ""
                        },
                        "success": true,
                        "timestamp": 0
                    }
                },
                API_TEMP: {
                    Response: {

                        "code": 0,
                        "message": "",
                        "result": {
                            'address': "住所を入力して下さい",
                            'birthday': "2022-09-15",
                            'emergencyAddress': "緊急連絡者連絡先を入力して下さい",
                            'emergencyName': "緊急連絡者を入力して下さい",
                            'emergencyTel': "緊急連絡者電話を入力して下さい",
                            'heardCode': 1,
                            'heardName': 'その他',
                            'hopeLanguage': "",
                            'introduceCode': "紹介コードを入力して下さい",
                            'mainCertificateType': "1",
                            'mainUrl1': "",
                            'mainUrl2': "",
                            'mainUrl3': "",
                            'nationality': "Viet Nam",
                            'otherCertificateType': "2",
                            'otherUrl1': "",
                            'otherUrl2': "",
                            'postalCode': "郵便番号を入力して下さい",
                            'relation': "本人との関係を入力して下さい",
                            'sex': 0,
                            'tel': "1電話番号を入力して下さい",
                            'toolAccount': "http://domain.dh",
                            'toolCode': 0,
                            'toolName': "FB",
                            'userId': "",
                            'workSchoolAddress': null,
                            'workSchoolName': "勤務先／学校の電話番号を入力して下さい",
                            'workSchoolTel': "勤務先／学校の電話番号を入力して下さい",
                        },
                        "success": true,
                        "timestamp": 0
                    }
                },
                API_CASH: {
                    Response: {
                        "code": 0,
                        "message": "",
                        "result": {
                            "exchangeAccount": "12312",
                            "exchangeBank": "qweqwe",
                            "exchangeBranch": "12312",
                            "exchangeName": "qweqweq",
                            "money": 10000,
                            "userId": "1231",
                            "userPoint": 1112312,
                        },
                        "success": true,
                        "timestamp": 0
                    }
                },
                API_HISTORY_POINT: {
                    Response: {
                        "code": 0,
                        "message": "",
                        "result": [{
                                "dateTime": " dateTime",
                                "differentiation": "differentiation",
                                "operator": " operator",
                                "pointOperate": 10000,
                                "status": "status"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "ettrt",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "hjhjnn",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "mmmmm",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "mmnmnmghjgf",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "123 dateTime",
                                "differentiation": "123 differentiation",
                                "operator": "123 operator",
                                "pointOperate": 10000,
                                "status": "123123123 status"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            },
                            {
                                "dateTime": "12312312",
                                "differentiation": "123123",
                                "operator": "112312323",
                                "pointOperate": 12,
                                "status": "123123qwe123"
                            }
                        ],
                        "success": true,
                        "timestamp": 0
                    }
                },
                NUMBER: {
                    ZERO: 0,
                    ONE: 1,
                    TWO: 2,
                    THREE: 3,
                    FOUR: 4,
                    FIVE: 5,
                    TWENTY_FIVE: 25,
                    TWENTY_SIX: 26,
                    FORTY: 40,
                    ONE_HUNDRED: 100
                },
                STRING: {
                    IS_BLANK: "",
                    IS_NULL: null,
                    IS_INVALID: undefined,
                    STRIKE_THROUGH: '—'
                }
            },
            BUYER: {
                ACCOUNT_CATEGORY: {
                    INDIVIDUAL: 0,
                    CORPORATION: 1,
                    AGENCY: 2,
                    HEADQUARTERS: 9,
                },
                GENDER: {
                    MALE: 0,
                    FEMALE: 1,
                },
                COUNTRY: {
                    ENGLIS_NAME: '英字名'
                },
                IDENTITY_VERIFICATION: {
                    RESIDENCE_CARD: 0,
                    PASSPORT: 1,
                    DRIVERS_LICENSE: 2,
                },
                SUPPORTING: {
                    STUDENT_CARD: 0,
                    RESIDENCE_PERMIT: 1,
                    CERTIFICATE: 2,
                },
                LANGUAGE: {
                    JAPANESE: 0,
                    ENGLISH: 1,
                    CHINESE: 2,
                    VIETNAMESE: 3,
                    KOREAN: 4,
                    SRI_LANKAN: 5,
                    NEVALE: 6,
                },
                INFORMATION: {
                    REFER_A_FRIEND: 0,
                    SCHOOL_INTRODUCTION: 1,
                    STUDY_ABROAD: 2,
                    FROM_OTHER_WEBSITES: 3,
                    FACEBOOK_ADS: 4,
                    YOUTUBE_ADS: 5,
                    GOOGLE_SEARCH: 6,
                    OTHER_ADVERTISEMENTS: 7,
                    OTHERS: 9,
                },
                WHICH_SNS: [
                    { value: 0, text: 'Facebook' },
                    { value: 1, text: 'Line' },
                    { value: 2, text: 'Wechat' },
                    { value: 3, text: 'Kakao_talk' },
                ],
                SIM_PURCHASE_TYPE: {
                    SIM_TEL_TYPE: {
                        NEW_CONTRACT: 0,
                        FROM_OTHER_COMPANIES: 1,
                    }
                },
                MNP_TYPE: {
                    MNP_COM_OPERATOR: {
                        DOCOMO: 0,
                        SOFTBANK: 1,
                        AU: 2,
                        UQ: 3,
                        YM: 4,
                        OTHERS: 9,
                    },
                },
                SIM_PURCHASE_SET: {
                    IFM_COMMODITY_SET: {
                        SIM_CARD_AND_WIFI: "001",
                        SIM_CARD_AND_LIGHT_SET: "002",
                        SIM_CARD_ONLY: "003",
                        WIFI_ONLY: "004",
                        LIGHT_ONLY: "005",
                        GENERAL_MERCHANDISE: "006",
                        RENT_WIFI: "007",
                    },
                },
                SIM_PLAN: {
                    IFM_COMMODITY: [
                        { value: '000003', text: '1GB' },
                        { value: '000004', text: '3GB' },
                        { value: '000005', text: '5GB' },
                        { value: '000006', text: '10GB' },
                        { value: '000007', text: '15GB' },
                        { value: '000008', text: '20GB' },
                        { value: '000009', text: '50GB' },
                    ],
                },
                SIM_CALL_QUOTA: {
                    IFM_COMMODITY: [
                        { value: '000011', text: '5分かけ放題' },
                        { value: '000012', text: '10分かけ放題' },
                        { value: '000013', text: '完全かけ放題' },
                        { value: '000014', text: '通話定額は必要ない' },
                    ]
                },
                SIM_OPTION: {
                    IFM_COMMODITY: {
                        SMART_ANSWER_MACHINE: '000016',
                        CALL_WAITING: '000017',
                    },
                },
                OTHERS_SIM: {
                    IFM_COMMODITY: {
                        UNIVERSAL: '000019',
                        RELAY: '000020',
                        REGISTRATION_FEE: '000021',
                        DAMAGE_FEE: '000022',
                    },
                },
                HIKARI_PLAN: {
                    // IFM_COMMODITY: {
                    //   MANSION: '000025',
                    //   FAMILY: '000026',
                    // },
                    IFM_COMMODITY: [
                        { value: '000025', text: 'マンションタイプ', type: '(集合住宅向け)' },
                        { value: '000026', text: 'ファミリータイプ', type: '(戸建向け)' },
                    ],
                },
                OTHERS_HIKARI: {
                    IFM_COMMODITY: {
                        LIGHT: '000028',
                    },
                },
                WIFI_PLAN: {
                    IFM_COMMODITY: {
                        MOD_100G: '000031',
                        MOD_150G: '000032',
                    },
                },
                OTHERS_WIFI: {
                    IFM_COMMODITY: {
                        DATA: '000034',
                        COD: '000035',
                    },
                },
                POSTAGE: {
                    IFM_COMMODITY: {
                        JAPAN: '000037',
                        OVERSEAS: '000038',
                    },
                },
                RECEIVING_METHOD: {
                    GET_MODE: {
                        SELFIE: 0,
                        MAIL: 1,
                    },
                },
                RECEIVING_AREA: {
                    SEND_ADDRESS: {
                        JAPAN: 0,
                        OVERSEAS: 1,
                    },
                },
                OPT_BUY_TYPE : {
                    NEW: 0 ,
                    CHANGE_OPERATOR: 1
                },
                WIRELESS_TYPE: {
                    NEED:0,
                    NO_NEED:1,
                },

                DELIVERY_TIME:{
                    AM:"0",
                    PM:"1",
                },
                VISIT_TIME: [
                    { value: "", text: '時間', disabled: true  },
                    { value: '10:00 ～ 12:00', text: '10:00 ～ 12:00'},
                    { value: '12:00 ～ 14:00', text: '12:00 ～ 14:00'},
                    { value: '14:00 ～ 16:00', text: '14:00 ～ 16:00'},
                    { value: '16:00 ～ 19:00', text: '16:00 ～ 19:00'},
                ]
            },
            MANAGEMENT: {
                POINT_CATEGORY: {
                    CHARGE: 0,
                    CASH: 1,
                },
                CHARGE_STATE: {
                    PENDING: 0,
                    DENIED: 1,
                    CHARGED: 2,
                },
                REDEMPTION_STATUS: {
                    ADMIT_TO: 0,
                    ADMIT: 1,
                    ADMIT_OR_DENY: 2,
                    EXCHANGE_GOLD: 3,
                },
                CHARGE_PERSON: {
                    SYSTEM: 'system',
                    USER_TABLE_NAME: 'ユーザー表ID',
                },
                STOCK_STATUS: {
                    UNOPENED: 0,
                    OPENED: 1,
                    STOP: 2,
                    ALL: 9,
                },
                PRIVILEGES: {
                    HOSHIKAWA_SUPERMARKET_MANAGER: 'XH01',
                    HOSHIKAWA_MANAGER: 'XH02',
                    HOSHIKAWA_EMPLOYEE: 'XH03',
                    SIM_CARD_USER: 'NM01',
                    AGENCY_MANAGER: 'AT01',
                    AGENCY_EMPLOYEE: 'AT02',
                },
                APPROVAL_STATUS: {
                    ADMIT_STATUS: {
                        UNAPPROVED: 0,
                        APPROVED: 1,
                    },
                },
                TASK_TYPE: {
                    USER_APPROVAL: '01',
                    CHARGE_APPROVAL: '02',
                    CASH: '03',
                    LIGHT_PURCHASE: '04',
                    HIKARI_MNP: '05',
                    WIFI_PURCHASE: '06',
                    SIM_PURCHASE: '07',
                    SIM_CHANGE_PLAN: '08',
                    SIM_FLAT_RATE_CHANGE: '09',
                    SIM_OPTION_CHANGE: '10',
                    SIM_CANCELLATION: '11',
                    SIM_MNP: '12',
                    SIM_SUSPENDED: '13',
                    SIM_RESUME: '14',
                    OTHERS: '99',
                },
                PRODUCT: {
                    ORDER_TYPE: {
                        SIM_CARD: 0,
                        OPTICAL_FIBER: 1,
                        WIFI: 2,
                        MERCHANDISE: 3
                    }
                },
                CONTRACT_STATUS: {
                    ORDER_CONTRACT_STATUS: {
                        NOT_CONCLUDED: 0,
                        APPROVED: 2,
                        CONCLUDED: 1
                    }
                },
                PAYMENT_STATUS: {
                    PAY_STATUS: {
                        UNPAID: 0,
                        PAID: 1,
                    }
                },
                CONTRACT: {
                    CONTRACY_STATUS: {
                        OPEN: 0,
                        CANCEL: 1,
                        MNP: 2,
                        PAUSE: 3,
                        ALL: 9
                    },
                },
                ORDER: {
                    ORDER_SET_TYPE: {
                        SIM_CARD_AND_WIFI: "001",
                        SIM_CARD_AND_LIGHT_SET: "002",
                        SIM_CARD_ONLY: "003",
                        WIFI_ONLY: "004",
                        LIGHT_ONLY: "005",
                        GENERAL_MERCHANDISE: "006",
                        RENT_WIFI: "007",
                    },
                    ORDER_TYPE: [
                        "SIMカードとWi-Fi",
                        "SIMカードと光",
                        "SIMカード",
                        "Wi-Fi",
                        "光",
                        "般商品"
                    ],
                    OREDER_PAYMENT_OPTION: [
                        '银行 卡',
                        '微信支付',
                        '阿里 支付',
                        '便利 店 支付',
                        '到 店 支付!',
                    ]
                },

            },
            PAYMENT: {
                PAYMENT_TYPE: {
                    CREDIT: "0",
                    WECHAT: "1",
                    ALI: "2",
                    COMBINI: "3",
                    STORE: "4",
                    BANK: "5"
                },
                REVENUE_SUMMARY:{
                    ORDER_SIM:"SIMの購入費用",
                    CHANGE_DATA_PACKAGE:"SIMプラン変更費用",
                    CHANGE_CALL_PACKAGE: "SIM通話定額変更費用",
                    CHANGE_OPTION: "SIMオプション費用",
                    CANCEL_CONTRACT_SIM: "SIM解約費用",
                    CHANGE_MNP_SIM: "SIMのMNP転出費用",
                    CHARGE_DATA_SIM: "SIMパケットチャッジ費用",
                    ORDER_OTP: "光の購入費用",
                    CANCEL_CONTRACT_OTP: "光解約費用",
                    CHANGE_MNP_OTP: "光のMNP転出費用",
                    ORDER_WIFI: "Wi-Fiの購入費用",
                    CANCEL_CONTRACT_WIFI: "Wi-Fi解約費用",
                    RENT_WIFI: "レンタルWi-Fiの購入費用",
                    CHARGE_POINT: 'ポイントチャージ費用',
                }
            },

        };
    },
};

Vue.use(ConstPlugin);
