<template>
    <div class="">
      <div class="group_3 flex-col">
        <div class="image-wrapper_1 flex-col">
          <b-embed
            type="iframe"
            aspect="16by9"
            :src="src"
            allowfullscreen
          ></b-embed>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: 'home-section3',
  props: ['src'],
  data() {
    return {}
  }
}

</script>
<style lang="scss" scoped>
@import '@/assets/scss/common.scss';
@import './index.response.scss';

@media (max-width: 768px) {
  @import './index.response.m.scss';
}
</style>