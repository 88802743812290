import Vue from 'vue';

// 点我以外的区域触发点击事件
Vue.directive('click-outside', {
  bind: function(el, binding, vnode) {
    // 点击指令绑定元素以外的区域时触发的处理函数
    function documentHandler(e) {
      if (el.contains(e.target)) {
        return false;
      }
      if (binding.expression) {
        binding.value(e);
      }
    }
    // 绑定处理函数
    el.__vueClickOutside__ = documentHandler;
    document.addEventListener('click', documentHandler);
  },
  unbind: function(el, binding) {
    // 解绑处理函数
    document.removeEventListener('click', el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  }
});
