<template>
  <div class="p-sim-selection">
    <section>
      <div class="p-personal__otherInfo">
        <div class="p-personal__otherInfo__SNS">
          <div class="p-personal__otherInfo__SNS__Advertisement">
            <div
              class="row p-personal__otherInfo__SNS__Advertisement__price p-2"
            >
              <div class="col-lg-12">
                <h2>
                  {{ $t("COMMON.PAYMENT.PAYMENT_AMOUNT") }}
                  <span
                    >{{ tax_price(getAmount)
                    }}{{ $t("COMMON.PAYMENT.TAX") }}</span
                  >
                </h2>
              </div>
            </div>
            <!-- 支付方式 -->
            <van-radio-group v-model="radio" @change="handleRadioChange"  checked-color="#f35f0d" style="text-align: left;">
              <van-cell-group border>
                <van-cell :title="$t('COMMON.PAYMENT.CREDIT.TITLE')" clickable @click="radio = '1'" style="font-size: 13px; font-weight: 500; padding: 15px 0; border-bottom: 1px solid #ebedf0;">
                  <template #right-icon>
                    <van-radio name="1" />
                  </template>
                </van-cell>

                <van-cell :title="$t('COMMON.PAYMENT.CODE.TITLE')" clickable @click="radio = '2'" style="font-size: 13px; font-weight: 500; padding: 15px 0; border-bottom: 1px solid #ebedf0;">
                  <template #right-icon>
                    <van-radio name="2" />
                  </template>
                </van-cell>

                <van-cell :title="$t('COMMON.PAYMENT.CASH.TITLE')" clickable @click="radio = '3'" style="font-size: 13px; font-weight: 500; padding: 15px 0; border-bottom: 1px solid #ebedf0;">
                  <template #right-icon>
                    <van-radio name="3" />
                  </template>
                </van-cell>

                <van-cell style="all: unset;">
                  <b-collapse :visible="radio == 1" id="collapse-pay_1">
                    <div class="method1">
                    <div class="row">
                      <div class="col-lg-12">
                        <p>{{ $t("COMMON.PAYMENT.CREDIT.TEXT") }}</p>
                      </div>
                    </div>
                    <div class="pay_x">
                      <img src="@/assets/assets/images/mastercard_curved_icon.png" alt="">
                      <img src="@/assets/assets/images/visa_curved_icon.png" alt="">
                      <img src="@/assets/pay_1.svg" alt="">
                      <img src="@/assets/pay_2.webp" alt="">
                      <img class="pay_x_fix" src="@/assets/pay_3.svg" alt="">
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <!-- <div class="row mb-4 card-hear">
                          <div class="col-sm-3">
                            <img
                              src="@/assets/assets/images/mastercard_curved_icon.png"
                              alt=""
                            />
                          </div>
                          <div class="col-sm-3">
                            <img
                              src="@/assets/assets/images/visa_curved_icon.png"
                              alt=""
                            />
                          </div>
                          <div class="col-sm-3">
                            <img
                              src="@/assets/assets/images/discover_curved_icon.png"
                              alt=""
                            />
                          </div>
                          <div class="col-sm-3">
                            <img
                              src="@/assets/assets/images/paypal_curved_icon.png"
                              alt=""
                            />
                          </div>
                        </div> -->
                        <div
                          v-show="cardNo != '' && settlementType == 1"
                          class="col mb-4 card"
                        >
                          <div class="card_title">
                            {{ $t("COMMON.PAYMENT.CARD_TITLE") }}
                            {{ $t("COMMON.PAYMENT.CARD_NO") }}
                            <span style="font-weight: bold;">{{ data.cardNumber }}</span>
                          </div>
                          <!-- <div class="card_content">
                            {{ $t("COMMON.PAYMENT.CARD_NO") }}
                            <span>{{ data.cardNumber }}</span>
                          </div> -->
                        </div>
                        <div
                          v-show="
                            cardNo == '' ||
                            cardNo == null ||
                            settlementType != 1
                          "
                          class="row mb-4"
                        >
                          <div class="col-lg-12 col-sm-12">
                            <b-form-input
                              class="form-input"
                              :disabled="cardNo != ''"
                              v-model="data.cardNumber"
                              :placeholder="$t('COMMON.PAYMENT.CREDIT.PLA1')"
                              type="text"
                            >
                            </b-form-input>
                            <!-- validate -->
                            <div v-show="alert" class="validation-message">
                              <span
                                class="validation-message"
                                v-show="data.cardNumber == ''"
                                >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div
                          v-show="
                            cardNo == '' ||
                            cardNo == null ||
                            settlementType != 1
                          "
                          class="row mb-4"
                        >
                          <div class="col-lg-6 col-sm-6 date">
                            <b-form-input
                              class="form-input"
                              v-model="data.expYear"
                              :placeholder="$t('COMMON.PAYMENT.CREDIT.PLA2')"
                              type="text"
                            ></b-form-input>
                            <!-- validate -->
                            <div v-show="alert" class="validation-message">
                              <span
                                class="validation-message"
                                v-show="data.expYear == ''"
                                >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                              >
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6 col-xs-6">
                            <b-form-input
                              v-model="data.expMonth"
                              @change="setMonth"
                              @keypress="isNumber($event)"
                              class="form-input"
                              :placeholder="$t('COMMON.PAYMENT.CREDIT.PLA3')"
                              type="number"
                              min="1"
                              max="12"
                              trim
                            />
                            <!-- validate -->
                            <div v-show="alert" class="validation-message">
                              <span
                                class="validation-message"
                                v-show="data.expMonth == ''"
                                >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div
                          v-show="
                            cardNo == '' ||
                            cardNo == null ||
                            settlementType != 1
                          "
                          class="row mb-4"
                        >
                          <div class="col-lg-12 col-sm-12">
                            <b-form-input
                              class="form-input"
                              v-model="data.cardholder"
                              :placeholder="$t('COMMON.PAYMENT.CREDIT.PLA5')"
                              type="text"
                            >
                            </b-form-input>
                            <!-- validate -->
                            <div v-show="alert" class="validation-message">
                              <span
                                class="validation-message"
                                v-show="data.cardholder == ''"
                                >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div
                          v-show="
                            cardNo == '' ||
                            cardNo == null ||
                            settlementType != 1
                          "
                          class="row mb-4"
                        >
                          <div class="col-lg-12 col-sm-12">
                            <b-form-input
                              class="form-input"
                              v-model="data.cvv"
                              :placeholder="$t('COMMON.PAYMENT.CREDIT.PLA6')"
                              type="text"
                            >
                            </b-form-input>
                            <!-- validate -->
                            <div v-show="alert" class="validation-message">
                              <span
                                class="validation-message"
                                v-show="data.cvv == ''"
                                >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </b-collapse>

                  <b-collapse :visible="radio == 2" id="collapse-pay_1">
                    <div class="method1">
                    <div class="row">
                      <div class="col-12 payment__form--title">
                        <h2></h2>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-12 check">
                        <label class="checkbox1">
                          {{
                            $t(
                              "SIM_SERVICE.STEP7_CONSENT_SIGN.PAYMENT_FORM.FORM2.TITLE"
                            )
                          }}
                          <input
                            type="radio"
                            checked="checked"
                            v-model="data.metadata.paymentType"
                            :value="$CONST.PAYMENT.PAYMENT_TYPE.ALI"
                            name="check1"
                            @click="changeQR('ali_pay', '2')"
                          />
                          <span class="checkmark"></span>
                        </label>

                        <label class="checkbox1">
                          {{
                            $t(
                              "SIM_SERVICE.STEP7_CONSENT_SIGN.PAYMENT_FORM.FORM3.TITLE"
                            )
                          }}
                          <input
                            type="radio"
                            checked="checked"
                            v-model="data.metadata.paymentType"
                            :value="$CONST.PAYMENT.PAYMENT_TYPE.WECHAT"
                            name="check1"
                            @click="changeQR('wechat_pay', '1')"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="col-12">
                        <img v-if="urlQr != 1" :src="urlQr" />
                      </div>
                      <!-- validate -->
                      <div v-show="alert" class="validation-message">
                        <span
                          class="validation-message"
                          v-show="data.metadata.paymentType == ''"
                          >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                        >
                      </div>
                    </div>
                  </div>
                  </b-collapse>

                  <b-collapse :visible="radio == 3" id="collapse-pay_1">
                    <div class="method1">
                    <div class="row">
                      <div class="col-lg-12">
                        <p>{{ $t("COMMON.PAYMENT.CASH.TEXT1") }}</p>
                        <p>{{ $t("COMMON.PAYMENT.CASH.TEXT2") }}</p>
                      </div>
                    </div>
                  </div>
                  </b-collapse>
                </van-cell>
              </van-cell-group>
            </van-radio-group>

            <el-collapse @change="collapseChange" accordion>
              <el-collapse-item>
                <template slot="title">
                  {{ $t("COMMON.PAYMENT.CHOOSE_METHOD.TITLE") }}
                </template>

                <el-radio-group
                  v-model="nextPayType"
                  size="small"
                  text-color="#ff7319"
                  fill="#ff7319"
                >
                  <el-radio label="0" border>{{
                    $t("COMMON.PAYMENT.CHOOSE_METHOD.METHOD1")
                  }}</el-radio>
                  <el-radio label="1" border>{{
                    $t("COMMON.PAYMENT.CHOOSE_METHOD.METHOD2")
                  }}</el-radio>
                  <el-radio label="2" border>{{
                    $t("COMMON.PAYMENT.CHOOSE_METHOD.METHOD3")
                  }}</el-radio>
                  <el-radio label="3" border>{{
                    $t("COMMON.PAYMENT.CHOOSE_METHOD.METHOD4")
                  }}</el-radio>
                  <el-radio label="4" border>{{
                    $t("COMMON.PAYMENT.CHOOSE_METHOD.METHOD5")
                  }}</el-radio>
                </el-radio-group>
              </el-collapse-item>
            </el-collapse>

            <div class="p-personal__otherInfo__SNS" style="padding: 0; margin: 0;">
            <div class="info-bonus">
              <div class="info-bonus__draw">
                <div class="info-bonus__withdraw--title">
                  <p>{{ $t("COMMON.PAYMENT.WITHDRAW.TITLE") }}</p>
                </div>
                <div class="info-bonus__withdraw--content">
                  <p>{{ $t("COMMON.PAYMENT.WITHDRAW.CONTENT1") }}</p>
                  　
                  <p>
                    {{ $t("COMMON.PAYMENT.WITHDRAW.CONTENT2")
                    }}<a href="#">{{ $t("COMMON.PAYMENT.WITHDRAW.PDF") }}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>

            <div class="row">
              <div class="col-lg-6">
                <b-button
                  v-if="type == 2"
                  variant="success"
                  @click="Confirm()"
                  class="mt-3 btn btn-block"
                  type="submit"
                >
                  {{ $t("COMMON.PAYMENT.BUTTON.CONFIRM") }}
                </b-button>
                <b-button
                  v-else
                  variant="success"
                  @click="handlePayment()"
                  class="mt-3 btn btn-block"
                  type="submit"
                >
                  {{ $t("COMMON.PAYMENT.BUTTON.PAY") }}
                </b-button>
              </div>
              <div class="col-lg-6">
                <b-button
                  @click="returnContract()"
                  variant="success"
                  class="mt-3 btn btn-block btn-cancel"
                  type="submit"
                >
                  {{ $t("COMMON.PAYMENT.BUTTON.RETURN") }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading :showLoading="isLoading" />
    </section>
  </div>
</template>
<script>
import json from "../../../../config/languages/ja.js";
import Loading from "@/components/common/Loading";

export default {
  name: "Change",
  components: { Loading },
  props: {
    dataChange: {
      orderType: 0,
      contractType: "",
      changePrice: 0,
      id: "",
      revenueSummary: "",
      amountPlan: "",
      changeMethod: "",
      changeText: "",
      changeType: "",
      converseQuota: "",
      ortherOptions: [],
    },
  },
  data() {
    return {
      radio: '',
      option_store: null,
      type: 0,
      urlQr: "1",
      chargesId: null,
      alert: false,
      payType: "",
      orderId: "",
      data: {
        amount: 0,
        brand: "",
        cardNumber: "",
        cardholder: "",
        convenienceStore: "none",
        customerName: "",
        cvv: "",
        expMonth: "",
        expYear: "",
        initialAmount: 0,
        localNumber: "",
        metadata: {
          businessType: "0",
          id: "",
          orderType: 0,
          paymentType: "",
          revenueSummary: "",
          userId: window.localStorage.getItem("userId"),
        },
        orderType: "",
        paymentType: "",
        regularFlag: 0,
      },
      nextPayType: -1,
      isLoading: false,
      cardNo: "",
      settlementType: "",

      // 支付方式
      paymentType: {
        '1': {
          t:  1, // this.type
          v: this.$CONST.PAYMENT.PAYMENT_TYPE.CREDIT, // this.data.metadata.paymentType
          f: () => { this.TypeMethod(1, 0) },
        },
        '2': {
          t: 2,
          v: '',
          f: ()  => { this.TypeMethod(2) }
        },
        '3': {
          t: 4,
          v: this.$CONST.PAYMENT.PAYMENT_TYPE.STORE,
          f: () => { this.TypeMethod(4) }
        }
      }
    };
  },
  created() {
    this.option_store = json.SIM_SERVICE.STEP7_CONSENT_SIGN.OPTION_STORE;
  },
  watch: {
    "data.expMonth"() {
      if (this.data.expMonth > 12 || this.data.expMonth.length > 2) {
        this.data.expMonth = this.data.expMonth.slice(1, 3);
      }
    },
  },
  computed: {
    getAmount() {
      return this.dataChange.change.changePrice;
    },
    getId() {
      return this.dataChange.change.id;
    },
    getContract() {
      return this.dataChange.change.contractType;
    },
  },
  methods: {
    handleRadioChange(e) {
      console.log('handleRadioChange', e)
      console.log(this.paymentType[e])
      this.type = this.paymentType[e].t
      this.data.metadata.paymentType = this.paymentType[e].v
      this.paymentType[e].f()
      this.nextPayType = this.paymentType[e].v
      console.log('this.data.metadata.paymentType', this.data.metadata.paymentType)
    },

    collapseChange(e) {
      console.log(e[0]);
      this.data.metadata.paymentType = e[0];
    },

    init() {
      this.$api
        .post("/ifmPeriodicalPayments/getPeriodical", {
          email: window.localStorage.getItem("email"),
        })
        .then((response) => {
          if (response.result.settlementType != 0) {
            if (response.result.cardNo != null) {
              this.cardNo = response.result.cardNo;
              this.data.cardNumber = response.result.cardNo;
              this.settlementType = response.result.settlementType;
            }
          }
        });
    },
    returnContract: function () {
      this.$emit("return-contract");
    },
    TypeMethod(value) {
      // var scrollDiv =
      //   document.getElementById("Methods" + value).offsetTop - 200;
      // window.scrollTo({ top: scrollDiv, behavior: "smooth" });
      // if (this.type == value) {
      //   this.type = 0;
      // } else {
      //   this.type = value;

      // }
      this.resetInput();
      if (value == 1) {
        this.init();
      }
      this.alert = false;
    },
    changeQR(brand, value) {
      this.brand(value);
      const Payload = {
        amount: Number(this.getAmount),
        brand: this.data.brand,
        cardNumber: this.data.cardNumber,
        cardholder: this.data.cardholder,
        convenienceStore: this.data.convenienceStore,
        customerName: this.data.customerName,
        cvv: this.data.cvv,
        expMonth: this.data.expMonth,
        expYear: this.data.expYear,
        initialAmount: Number(this.getAmount),
        localNumber: this.data.localNumber,
        metadata: {
          businessType: this.data.metadata.businessType,
          id: this.getId,
          orderType: this.dataChange.change.orderType,
          paymentType: this.nextPayType,
          revenueSummary: this.dataChange.change.revenueSummary,
          userId: window.localStorage.getItem("userId"),
        },
        paymentType: this.nextPayType,
        orderType: this.dataChange.change.orderType,
        regularFlag: 0,
      };
      this.$api
        .post("ifmPay/payment", Payload)
        .then((response) => {
          if (response.success) {
            this.urlQr = "data:image/png;base64," + response.result.qrImg;
            this.chargesId = response.result.chargesId;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    resetInput() {
      this.data = {
        amount: 0,
        brand: "",
        cardNumber: this.cardNo,
        cardholder: "",
        convenienceStore: "none",
        customerName: "",
        cvv: "",
        expMonth: "",
        expYear: "",
        initialAmount: 0,
        localNumber: "",
        metadata: {
          businessType: "0",
          id: "",
          orderType: "",
          paymentType: "",
          revenueSummary: "",
          userId: window.localStorage.getItem("userId"),
        },
        orderType: "",
        paymentType: "",
        regularFlag: 0,
      };
      this.urlQr = null;
    },
    brand(value) {
      var orderType = this.getContract;
      if (value == 1) {
        this.data.brand = "we_chat_mpm";
      } else if (value == 2) {
        this.data.brand = "alipay_merchant_qr";
      } else {
        this.data.brand = "none";
      }
      if (orderType == "0") {
        this.data.orderType = "0";
      } else if (orderType == "1") {
        this.data.orderType = "1";
      } else {
        this.data.orderType = "3";
      }
    },
    validateInput() {
      if (this.type == 0) {
        this.$bvToast.toast(this.$t("COMMON.PAYMENT.VALIDATE.INPUT"), {
          title: `Errors`,
          solid: true,
          variant: `danger`,
        });
        return false;
      } else {
        if (this.type == 1) {
          if (
            this.cardNo == "" &&
            (this.data.cardNumber == "" ||
              this.data.cardholder == "" ||
              this.data.cvv == "" ||
              this.data.expMonth == "" ||
              this.data.expYear == "")
          ) {
            this.alert = true;
            return false;
          }
        } else if (this.type == 2 && this.data.metadata.paymentType == "") {
          this.alert = true;
          return false;
        }
        return true;
      }
    },
    handlePayment: function () {
      if (this.type != 4) {
        this.Payment();
      } else if (this.type == 4) {
        if (this.dataChange.type == 2) {
          this.addData();
        } else {
          this.updatePay();
        }
        var suc1 = "";
        if (this.dataChange.type == 2) {
          //流量充值
          suc1 = "7";
        } else if (this.dataChange.type == 1) {
          //解约
          suc1 = "5";
        } else {
          //套餐变更
          suc1 = "8";
        }

        this.toTop();
        this.$router.push({
          path: "/success",
          query: { view: "9", suc: suc1 },
        });
      }
    },
    async Payment() {
      this.brand();
      const Payload = {
        amount: this.tax_price(Number(this.getAmount)),
        brand: this.data.brand,
        cardNumber: this.data.cardNumber,
        cardholder: this.data.cardholder,
        convenienceStore: this.data.convenienceStore,
        customerName: this.data.customerName,
        cvv: this.data.cvv,
        expMonth: this.data.expMonth,
        expYear: this.data.expYear,
        initialAmount: Number(this.getAmount),
        localNumber: this.data.localNumber,
        metadata: {
          businessType: this.data.metadata.businessType,
          id: this.getId,
          orderType: this.dataChange.change.orderType,
          paymentType: this.nextPayType,
          revenueSummary: this.dataChange.change.revenueSummary,
          userId: window.localStorage.getItem("userId"),
        },
        paymentType: this.nextPayType,
        orderType: this.dataChange.change.orderType,
        regularFlag: "0",
      };
      const check = this.validateInput();
      if (check) {
        this.$api.post("ifmPay/payment", Payload).then((response) => {
          if (response.success) {
            if (this.dataChange.type == 1) {
              this.$api
                .post("ifmContract/terminate", this.dataChange.terminate)
                .then((response) => {});
              this.updatePay();
            } else if (this.dataChange.type == 2) {
              this.addData();
            } else {
              this.updatePay();
              if (
                this.dataChange.change.revenueSummary !=
                this.$CONST.PAYMENT.REVENUE_SUMMARY.CHARGE_POINT
              ) {
                this.updateContract();
              }
            }
            if (
              this.dataChange.change.revenueSummary ==
              this.$CONST.PAYMENT.REVENUE_SUMMARY.CHARGE_POINT
            ) {
              this.charge_point();
            }
            this.toTop();

            var suc1 = "";

            if (this.dataChange.type == 1) {
              //解约
              suc1 = "5";
            } else if (this.dataChange.type == 2) {
              //流量充值
              suc1 = "7";
            } else {
              if (
                this.dataChange.change.revenueSummary !=
                this.$CONST.PAYMENT.REVENUE_SUMMARY.CHARGE_POINT
              ) {
                //plan 变更
                suc1 = "8";
              }
            }
            if (
              this.dataChange.change.revenueSummary ==
              this.$CONST.PAYMENT.REVENUE_SUMMARY.CHARGE_POINT
            ) {
              //point 充值
              suc1 = "9";
            }
            this.$router.push({
              path: "/success",
              query: { view: "9", suc: suc1 },
            });
          } else {
            if (
              response.message.search(
                this.$t("COMMON.PAYMENT.VALIDATE.TOKEN")
              ) != -1
            ) {
              this.$bvToast.toast(this.$t("VALIDATION.MESSAGE"), {
                title: `Errors`,
                solid: true,
                variant: `warning`,
              });
            } else {
              this.$bvToast.toast(this.$t("COMMON.PAYMENT.VALIDATE.FAIL"), {
                title: `Errors`,
                solid: true,
                variant: `danger`,
              });
            }
          }
        });
      }
    },
    updatePay() {
      debugger;
      this.$api
        .post("sys/user/updatePay", {
          id: window.localStorage.getItem("userId"),
          nextPayType: this.nextPayType,
        })
        .then((response) => {
          if (response.success) {
            this.$localStorage.set("nextPayType", this.nextPayType);
          }
        });
    },
    updateContract() {
      var data = {
        amountPlan: this.dataChange.change.amountPlan,
        changeMethod: this.dataChange.change.changeMethod,
        changePrice: this.dataChange.change.changePrice,
        changeText: this.dataChange.change.changeText,
        changeType: this.dataChange.change.changeType,
        converseQuota: this.dataChange.change.converseQuota,
        id: this.dataChange.change.id,
        ortherOptions: this.dataChange.change.ortherOptions,
        payType: this.nextPayType,
      };
      this.$api
        .post("ifmContract/ifmContractSimDetailed/updateContract", data)
        .then((response) => {});
    },
    charge_point() {
      this.$api
        .post("pointCharge/personalPointCharge", {
          pointOperate: this.dataChange.change.changePrice,
          payWay: this.data.metadata.paymentType,
        })
        .then((response) => {
          this.result = response.result;
        });
    },
    async Confirm() {
      const check = this.validateInput();
      // let validationUrl = "";
      // if (
      //   this.data.metadata.paymentType == "1" ||
      //   this.data.metadata.paymentType == "2"
      // ) {
      //   validationUrl = "ifmPay/payCloudValidation";
      // } else {
      //   validationUrl = "ifmPay/validation";
      // }
      if (check) {
        await this.$api
          .post("ifmPay/payCloudValidation", { chargesId: this.chargesId })
          .then((response) => {
            if (response.success) {
              if (this.dataChange.type == 1) {
                this.$api
                  .post("ifmContract/terminate", this.dataChange.terminate)
                  .then((response) => {});
                this.updatePay();
              } else if (this.dataChange.type == 2) {
                this.addData();
              } else {
                this.updatePay();
                if (
                  this.dataChange.change.revenueSummary !=
                  this.$CONST.PAYMENT.REVENUE_SUMMARY.CHARGE_POINT
                ) {
                  this.updateContract();
                }
              }
              if (
                this.dataChange.change.revenueSummary ==
                this.$CONST.PAYMENT.REVENUE_SUMMARY.CHARGE_POINT
              ) {
                this.charge_point();
              }
              this.toTop();
              let suc1 = "";
              if (this.dataChange.type == 1) {
                //解约
                suc1 = "5";
              } else if (this.dataChange.type == 2) {
                //流量充值
                suc1 = "7";
              } else {
                if (
                  this.dataChange.change.revenueSummary !=
                  this.$CONST.PAYMENT.REVENUE_SUMMARY.CHARGE_POINT
                ) {
                  //plan 变更
                  suc1 = "8";
                }
              }
              if (
                this.dataChange.change.revenueSummary ==
                this.$CONST.PAYMENT.REVENUE_SUMMARY.CHARGE_POINT
              ) {
                //point 充值
                suc1 = "9";
              }
              this.$router.push({
                path: "/success",
                query: { view: "9", suc: suc1 },
              });
            } else {
              this.$bvToast.toast(this.$t("COMMON.PAYMENT.VALIDATE.MESSAGE"), {
                title: `Errors`,
                solid: true,
                variant: `warning`,
              });
            }
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    tax_price: function (value) {
      return parseInt(value + (value * parseFloat(this.dataChange.tax)) / 100);
    },
    addData() {
      this.$api
        .post("packetMonthlyAmountResAl/add", {
          chageNum: this.dataChange.dataAdd.chageNum,
          chagePrice: this.dataChange.dataAdd.chagePrice,
          email: this.dataChange.dataAdd.email,
          payType: this.nextPayType,
          phoneNo: this.dataChange.dataAdd.phoneNo,
          simMakeCd: this.dataChange.dataAdd.simMakeCd,
          workType: this.dataChange.dataAdd.workType,
        })
        .then((response) => {
          if (response.success) {
            this.updatePay();
          }
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    setMonth() {
      if (this.data.expMonth < 10 && this.data.expMonth != "") {
        this.data.expMonth = "0" + this.data.expMonth;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>
