<template>
  <section class="home">
    <div class="page flex-col" v-if="$store.state.isMobile">
      <div class="group_2 flex-col">
        <div class="group_3 flex-row justify-between">
          <div class="image-text_1 flex-row justify-between">
            <!-- <img class="single-avatar_1"
                 src="@/assets/lxl/SketchPngaecfb0f8c3f1bae8c5ae0726d6345679cb406a5e51251439057a3474bb5eace9.png" /> -->
            <i
              class="iconfont icon-gerenzhongxin1"
              style="font-size: 32px; color: #f5efef"
            ></i>
            <span class="text-group_1" style="width: auto"
              >{{ username }}<br />{{ email }}</span
            >
          </div>
          <img
            class="image_2"
            src="@/assets/lxl/SketchPnge72ccc20a902dd5a7292f9129382e7f59476826fd692e0aca0c2095b98b630d9.png"
          />
          <div class="block_44">
            <a
              style="all: unset"
              download :href="'https://ifmobile.jp/download/Utilize_Material_Gold.pdf'"
            >
              <div class="group_4 flex-row">
                <van-notice-bar
                  style="width: 100%"
                  mode="link"
                  scrollable
                  background="#fff"
                  >{{ $t("MYPAGE_HOME.NOTICE_CONTENT") }}</van-notice-bar
                >
                <!-- <span class="text_3"></span> -->
              </div>
            </a>

            <div class="text-wrapper_1 flex-row">
              <span class="text_4">{{ $t("MYPAGE_HOME.TITLE") }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="group_9 flex-col">
        <div class="ad-banner" @click="handleCarousel">
          <b-carousel id="carousel-fade" controls v-model="carouselIndex">
            <b-carousel-slide
              caption="IF会員限定!"
              caption-tag="h5"
              @click="targetPDF"
              :img-src="require('@/assets/details/ad-10-1.png')"
            >
              <div>ドン・キホーテ、</div>
              <div>薬局など全店舗の</div>
              <div>10%OFFクーポン！</div>
            </b-carousel-slide>
            <b-carousel-slide
              caption="初来日特典！"
              caption-tag="h5"
              @click="$router.push('/details/sim/section1')"
              :img-src="require('@/assets/details/ad-10-2.png')"
            >
              <div>IF SIM新規ユーザー限定、</div>
              <div>2ヶ月間20GBプラン申込で</div>
              <div>50GB無料アップグレード！</div>
            </b-carousel-slide>
            <b-carousel-slide
              caption="IF SIMユーザー限定！"
              caption-tag="h5"
              @click="$router.push('/details/wifi/section1')"
              :img-src="require('@/assets/details/ad-10-3.png')"
            >
              <div>WiFi機器費用5,000円、</div>
              <div>手数料無料！</div>
            </b-carousel-slide>
          </b-carousel>
        </div>

        <div class="text-wrapper_x">
          <div class="l">
            <p class="text_30">
              {{ $t("MYPAGE_HOME.INVOICE", { month: month }) }}
            </p>
            <p class="text_31">
              {{
                $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.WITH_TAX", {
                  price: dataProp.price,
                })
              }}
            </p>
          </div>
          <div
            class="image-text_8 flex-row justify-between"
            @click="showPage(6)"
          >
            <p>
              <span class="text-group_8">{{ $t("MYPAGE_HOME.LINK3") }}</span>
              <i
                class="iconfont icon-jinrujiantou"
                style="color: #ffffff; font-weight: bold"
              ></i>
            </p>
          </div>
        </div>

        <div style="height: 1px; background: #e9e9e9; margin: 0 4.25vw"></div>

        <div class="text-wrapper_x">
          <div class="l">
            <p class="text_30">{{ $t("MYPAGE_HOME.REMAIN_DATA") }}</p>
            <p class="text_31">{{ dataProp.remainingData }}GB</p>
          </div>
          <div
            class="image-text_8 flex-row justify-between"
            @click="showPage(4)"
          >
            <p>
              <span class="text-group_8">{{ $t("MYPAGE_HOME.LINK4") }}</span>
              <i
                class="iconfont icon-jinrujiantou"
                style="color: #ffffff; font-weight: bold"
              ></i>
            </p>
          </div>
        </div>

        <!-- <img class="image_10" src="@/assets/lxl/SketchPng074a059c346e3383d50ea231d50d268cb8dd5503e83795215f5d1f26f8892339.png" /> -->

        <!-- <div class="section_6 flex-row justify-between">
          <div class="image-text_9 flex-row justify-between">
            <div class="text-group_9 flex-col justify-between" @click="showPage(4)">
              <span class="text_32" style="font-weight: bold;">{{ $t('MYPAGE_HOME.REMAIN_DATA') }}</span>
              <div class="image-text_8">
                <p class="text_33">
                  {{ $t('MYPAGE_HOME.LINK4') }}
                  <i class="iconfont icon-jinrujiantou" style="color: #ff7319; font-weight: bold;"></i>
                </p>
              </div>
            </div>

          </div>
          <span class="text_34">{{dataProp.remainingData}}GB</span>
        </div> -->
        <div class="section_7 flex-col">
          <div class="" style="display: flex">
            <img class="icon_18" src="@/assets/icon/icon_zhidu.svg" />
            <span class="text_35">{{ $t("MYPAGE_HOME.TEXT_CONTENT1") }}</span>
          </div>
          <div
            class="box_4 flex-row justify-between"
            @click="$router.push('/details/vip/section2')"
          >
            <span class="text_36">{{ $t("MYPAGE_HOME.LINK1") }}</span>
            <img class="image_11" src="@/assets/jinru_g.png" />
          </div>
        </div>
        <div class="section_8 flex-col">
          <div style="display: flex">
            <img class="thumbnail_4" src="@/assets/icon/icon_link.svg" />
            <span class="text_37">{{ $t("MYPAGE_HOME.TEXT_CONTENT2") }}</span>
          </div>
          <button class="button_2 flex-col" @click="showPage(8)">
            <span class="text_38">{{ $t("MYPAGE_HOME.LINK2") }}</span>
            <img class="image_12" src="@/assets/jinru_g.png" />
          </button>
        </div>
      </div>
      <!-- <div class="group_5 flex-col">
        <img class="image_3"
             src="@/assets/lxl/SketchPngb51ce43de0ee92ef93df6c29ef08093de72afcc201e75d593f364e87338109d7.png" />
        <span class="text_5">{{ $t('MYPAGE_HOME.MENU_TITLE') }}{{ $t('MYPAGE_HOME.MENU_TITLE1') }}</span>
        <div class="flex-row" style="flex-wrap: wrap;">
          <div class="image-text_2 flex-col justify-between" v-for="(item, index) in listMenu" :key="index" @click="showPage(listMenu[index].value)">
            <img class="icon_3"
              :src="index > loopData0.length - 1 ? item.url : loopData0[index].lanhuimage0 " />
            <span class="text-group_2">{{item.name}}</span>
          </div>
        </div>
      </div> -->
    </div>
    <div v-else class="box_2 flex-col">
      <div class="block_3 flex-col">
        <div class="group_1 flex-col justify-between">
          <div class="group_2 flex-row justify-between">
            <!-- <img class="image_2"
                 src="@/assets/lxl/SketchPngbfcb177b3738ba73b25ebd180c802ca3570a59fab8f1219c444d4fa3768c6079.png" /> -->
            <i
              class="iconfont icon-gerenzhongxin1"
              style="font-size: 35px; color: #f5efef"
            ></i>
            <span class="text_10"
              >{{ username }}<br />
              {{ email }}</span
            >
          </div>
          <div class="text-wrapper_2 flex-col">
            <span class="text_11"> {{ $t("MYPAGE_HOME.TITLE") }}</span>
          </div>
        </div>
        <img
          class="image_3"
          src="@/assets/lxl/SketchPngf5eefdc750f087f97543687b24e487a806b6b61eb5e905f100e2ec38d84befbc.png"
        />
        <div class="text-wrapper_3 flex-col">
          <a
            style="all: unset"
            download :href="'https://ifmobile.jp/download/Utilize_Material_Gold.pdf'"
          >
            <span class="text_12">{{ $t("MYPAGE_HOME.NOTICE_CONTENT") }}</span>
          </a>
        </div>
        <!-- <h3 class="medium">{{ item }}</h3> -->
        <div class="block_12 flex-col">
          <!-- <el-carousel height="200px" direction="vertical" :autoplay="true">
            <el-carousel-item v-for="item in imgList" :key="item.name">
              <img :src="item.src" style="height:100%; width:100%;" :title="item.title" @click="toPage(item)">

            </el-carousel-item>
          </el-carousel> -->
          <div class="ad-banner" @click="handleCarousel">
            <b-carousel
              id="carousel-fade"
              style=""
              controls
              v-model="carouselIndex"
            >
              <b-carousel-slide
                caption="IF会員限定!"
                @click="targetPDF"
                :img-src="require('@/assets/details/ad-10-1.png')"
              >
                <div>
                  <div>ドン・キホーテ、</div>
                  <div>薬局など全店舗の10%OFFクーポン！</div>
                </div>
              </b-carousel-slide>
              <b-carousel-slide
                caption="初来日特典！"
                @click="$router.push('/details/sim/section1')"
                :img-src="require('@/assets/details/ad-10-2.png')"
              >
                <div>IF SIM新規ユーザー限定、</div>
                <div>2ヶ月間20GBプラン申込で</div>
                <div>50GB無料アップグレード！</div>
              </b-carousel-slide>
              <b-carousel-slide
                caption="IF SIMユーザー限定！"
                @click="$router.push('/details/wifi/section1')"
                :img-src="require('@/assets/details/ad-10-3.png')"
              >
                <div>WiFi機器費用5,000円、手数料無料！</div>
              </b-carousel-slide>
            </b-carousel>
          </div>
          <div class="box_3 flex-row">
            <span class="text_38">
              {{ $t("MYPAGE_HOME.INVOICE", { month: month }) }}</span
            >
            <span class="text_39">{{
              $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.WITH_TAX", {
                price: dataProp.price,
              })
            }}</span>

            <div
              class="image-text_3 flex-row justify-between"
              style="cursor: pointer"
            >
              <span class="text_40" @click="showPage(6)">{{
                $t("MYPAGE_HOME.LINK3")
              }}</span>
              <i
                class="iconfont icon-jinrujiantou"
                style="color: #ff7319; font-weight: bold"
              ></i>
              <!-- <img class="thumbnail_3"
                   src="@/assets/lxl/SketchPng1f8d3253ad7b7c5c58c4e6a58d7e66f51150a524ef3688f44b698e5854a5bf26.png" /> -->
            </div>
          </div>
          <div class="box_3 flex-row">
            <span class="text_38">{{ $t("MYPAGE_HOME.REMAIN_DATA") }}</span>
            <span class="text_39">{{ dataProp.remainingData }}GB</span>
            <div
              class="image-text_3 flex-row justify-between"
              style="cursor: pointer"
            >
              <span class="text_40" @click="showPage(4)">{{
                $t("MYPAGE_HOME.LINK4")
              }}</span>
              <i
                class="iconfont icon-jinrujiantou"
                style="color: #ff7319; font-weight: bold"
              ></i>

              <!-- <img class="thumbnail_3"
                   src="@/assets/lxl/SketchPng1f8d3253ad7b7c5c58c4e6a58d7e66f51150a524ef3688f44b698e5854a5bf26.png" /> -->
            </div>
          </div>
          <div class="box_5 flex-row justify-between">
            <div class="group_3 flex-col">
              <div style="margin-top: 1.56vw">
                <img class="label_7" src="@/assets/icon/icon_zhidu.svg" />
                <span class="text_43">{{
                  $t("MYPAGE_HOME.TEXT_CONTENT1")
                }}</span>
              </div>

              <div
                class="group_5 flex-row justify-between"
                @click="$router.push('/details/vip/section2')"
              >
                <span class="text_44">{{ $t("MYPAGE_HOME.LINK1") }}</span>
                <img class="image_12" src="@/assets/lxl/jinru_h.png" />
              </div>
            </div>
            <div class="group_3 flex-col">
              <div style="margin-top: 1.56vw">
                <img class="label_7" src="@/assets/icon/icon_link.svg" />
                <span class="text_43">{{
                  $t("MYPAGE_HOME.TEXT_CONTENT2")
                }}</span>
              </div>

              <div
                class="group_5 flex-row justify-between"
                @click="showPage(8)"
              >
                <span class="text_44">{{ $t("MYPAGE_HOME.LINK2") }}</span>
                <img class="image_12" src="@/assets/lxl/jinru_h.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="block_4 flex-col">
        <img class="image_4"
             src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png" />
        <span class="text_13">{{ $t('MYPAGE_HOME.MENU_TITLE') }}{{ $t('MYPAGE_HOME.MENU_TITLE1') }}</span>
        <div class="list_1 flex-row" v-if="listMenu">
          <div class="image-text_1 flex-col justify-between" v-for="(item, index) in listMenu" :key="index" @click="showPage(listMenu[index].value)">
            <img class="label_1"  :src="index > loopData0.length - 1 ? item.url : loopData0[index].lanhuimage0 " />
            <span class="text-group_1">{{ listMenu[index].name }}</span>
          </div>
        </div>
      </div> -->
    </div>

    <div class="main">
      <div class="main_slider">
        <b-carousel
          id="carousel-fade"
          style="text-shadow: 0px 0px 2px #000"
          no-animation
          indicators
          img-width="1024"
          img-height="480"
          :interval="2000"
        >
          <b-carousel-slide
            caption=""
            v-for="(item, index) in slide"
            :key="index"
            class="slider_item"
            :img-src="item.url"
          ></b-carousel-slide>
        </b-carousel>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "referral_code",
  props: {
    dataProp: {},
  },
  data() {
    return {
      carouselIndex: 0,
      imgList: [
        {
          name: "if1",
          src: require("@/assets/lxl/SketchPnga23b3a50d425edfed1ba7f38560af5fd8e8fb6e6a8413218740d195b6ff2b578.png"),
          title: "这是lj.png",
          toSrc: "/details/sim/section5",
        },
        {
          name: "if2",
          src: require("@/assets/lxl/SketchPngd30cab387efb4d7246a4733562a5d4378993e28d9466d73b6d0f3bc6c3dfdc4b.png"),
          title: "这是lj.png",
          toSrc: "/details/sim/section5",
        },
        {
          name: "if3",
          src: require("@/assets/lxl/SketchPngfe643c3cea92452311fcae5ccb2d7ce0a755f3ed80524a45d991576591035074.png"),
          title: "这是lj.png",
          toSrc: "/details/sim/section5",
        },
      ],
      listMenu: null,
      slide: {},
      month: "",
      loopData0: [
        {
          lanhuimage0: require("@/assets/lxl/SketchPng83150401451e7bb7d22122eca2f68af64da682e9a08fa2df726a30718302632d.png"),
          lanhutext0: "ポイント履歴",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPng69e0de349f81cb31a08af9aad9d9ac9b715355cb103cdee92e36169eb6cbdcda.png"),
          lanhutext0: "換金管理",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPng57b473b262c8ce444aaa5b0f16f9e041bf07292d149b193f34447caaabf12e3a.png"),
          lanhutext0: "オーダー情報",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPngd30cab387efb4d7246a4733562a5d4378993e28d9466d73b6d0f3bc6c3dfdc4b.png"),
          lanhutext0: "データ利用状況",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPngf9f24f032e94e67000b1931604f600cb0d2c10a19a1351a9b294b7cdd1a3d3e5.png"),
          lanhutext0: "契約情報",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPngf298b6e61f3a9d447d867f08fb33465c003068d63f1756d3053c6c1fd9d6ec9f.png"),
          lanhutext0: "料金情報",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPngfe643c3cea92452311fcae5ccb2d7ce0a755f3ed80524a45d991576591035074.png"),
          lanhutext0: "定期課金",
        },
      ],
      constants: {},
    };
  },
  computed: {
    username() {
      if (window.localStorage.getItem("name")) {
        return window.localStorage.getItem("name");
      }
    },
    email() {
      if (window.localStorage.getItem("email")) {
        return window.localStorage.getItem("email");
      }
    },
  },
  mounted() {
    this.listMenu = this.$t("MYPAGE_HOME.MENU");
    this.slide = this.$t("MYPAGE_HOME.SLIDE");
    console.log(this.$t("MYPAGE_HOME.SLIDE"));
  },
  watch: {
    dataProp() {},
  },
  created() {
    var day = new Date();
    this.month = day.getMonth() + 1;
    this.$localStorage.remove("pageBack");
  },
  methods: {
    handleCarousel() {
      console.log(this.carouselIndex);
      if (this.carouselIndex == 0) this.targetPDF();
      if (this.carouselIndex == 1) this.$router.push("/details/sim/section1");
      if (this.carouselIndex == 2) this.$router.push("/details/wifi/section1");
    },
    targetPDF() {
      window.open("https://ifmobile.jp/download/Coupon_list.pdf", "_blank");
    },
    showPage: function (menuItem) {
      console.log("menuItem", menuItem);
      this.$localStorage.set("menuItemHeader", menuItem);
      this.toTop();
      // this.$parent.viewsPage = menuItem;
      this.$router.replace(`/mypage?view=${menuItem}`);
    },
    toPage(item) {
      this.$router.replace(item.toSrc);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

:deep(.carousel-caption) {
  top: 10%;
  left: 40%;
  text-align: left;

  h3,
  h5 {
    color: rgb(241, 120, 14);
  }

  div {
    color: rgb(241, 120, 14);
    // text-shadow: 0px 0px 2px rgb(241, 120, 14);
  }
}

@media (max-width: 768px) {
  @import "./index.response.m.scss";

  :deep(.carousel-caption) {
    top: 0;
    left: 35%;
    text-align: left;
    padding: 5px 0 0 0;
    bottom: 0;
    z-index: 0;

    h3,
    h5 {
      font-size: 16px;
      color: rgb(241, 120, 14);
    }

    div {
      font-size: 12px;
      line-height: 1.2;

      // text-shadow: 0px 0px 2px rgb(241, 120, 14);
    }
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #d3dce6;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
