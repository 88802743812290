<template>
  <div class="container_order">
    <div class="order">
      <div class="note">
        <el-collapse @change="handleChange">
          <!-- <h3>{{ $t("ORDER_INFO.NOTE.TITLE") }}</h3> -->
          <el-collapse-item name="1">
            <template slot="title">
              <span style="font-weight: bold; color: rgb(255, 115, 25);">{{$t('ORDER_INFO.NOTE.TITLE')}}</span> 
            </template>
            <p>{{ $t("ORDER_INFO.NOTE.CONTENT1") }}</p>
            <p>
              {{ $t("ORDER_INFO.NOTE.CONTENT2_1")
              }}<span class="text-danger">{{
                $t("ORDER_INFO.NOTE.CONTENT2_2")
              }}</span
              >{{ $t("ORDER_INFO.NOTE.CONTENT2_3") }}
            </p>
            <p>
              {{ $t("ORDER_INFO.NOTE.CONTENT3_1")
              }}<span class="text-danger">{{
                $t("ORDER_INFO.NOTE.CONTENT3_2")
              }}</span
              >{{ $t("ORDER_INFO.NOTE.CONTENT3_3") }}
            </p>

            <p>
              {{ $t("ORDER_INFO.NOTE.CONTENT4_1")
              }}<span class="text-like"
                ><a href="#/details/servers/section3" class="text-like">
                  <p>{{ $t("ORDER_INFO.NOTE.CONTENT4_2") }}</p>
                </a></span
              >
            </p>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="group_8 flex-col justify-end">
        <img
          class="image_2"
          src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
        />
        <span class="text_17">{{ $t("ORDER_INFO.TITLE") }}</span>

        <div class="order__item" v-for="(item, index) in order" :key="index">
          <div class="group_10 flex-row justify-between">
            <div class="text-wrapper_9 flex-col">
              <span class="text_18">{{ $t("ORDER_INFO.ORDER_NUMBER") }}</span>
            </div>
            <span class="text_19">{{ item.orderId }}</span>
          </div>

          <div v-if="item.serviceCode != null" class="order__item__content">
            <div class="order__item__content__row">
              <div class="order__item__content__row__value" style="color: #f1780e;">
                {{ item.orderStatus_dictText }}
              </div>
              <div class="order__item__content__row__value">
                <span
                  v-if="item.orderStatus == 1"
                  class="link"
                  @click="showPage(0)"
                >
                  <a href="#/mypage?view=0" class="text-like">
                    <p>{{ $t("ORDER_INFO.PROCESSING") }}</p>
                  </a></span
                >
              </div>
            </div>
            <div class="order__item__content__row">
              <div class="order__item__content__row__value">
                {{ $t("ORDER_INFO.ORDER_PHONE.NUMBER") }}
              </div>
              <div class="order__item__content__row__value">
                {{ item.serviceCode }}
              </div>
            </div>
            <div class="order__item__content__row">
              <div class="order__item__content__row__value">
                {{ $t("ORDER_INFO.ORDER_PHONE.CONTENT") }}
              </div>
              <div class="order__item__content__row__value">
                {{ item.applicationContents }}
              </div>
            </div>
            <!-- 利用開始日 -->
            <div class="order__item__content__row">
              <div class="order__item__content__row__value">
                {{ $t("ORDER_INFO.ORDER_PHONE.DATESTART") }}
              </div>
              <label class="order__item__content__row__value">
                <b-form-datepicker
                  value-format="timestamp"
                  dateType="time"
                  class="ifm_inputDate text-left"
                  v-if="item.orderStatus == 1"
                  :min="new Date().toISOString().substr(0, 10)"
                  locale="ja-JP"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  placeholder=""
                  v-model="item.hopeStartYmd"
                  trim
                >
                </b-form-datepicker>
                <span v-else>{{ item.hopeStartYmd }}</span>
                <!-- <span>{{ item.hopeStartYmd }}</span> -->
              </label>
            </div>
            <!-- 利用人数 -->
            <div class="order__item__content__row">
              <div class="order__item__content__row__value">
                {{ $t("ORDER_INFO.ORDER_PHONE.NUMBER_OF_USERS") }}
              </div>
              <div
                class="order__item__content__row__value"
                v-if="item.orderStatus == 1"
              >
                <b-form-input
                  @keypress="isNumber($event)"
                  v-model="item.numberOfUser"
                  trim
                ></b-form-input>
              </div>
              <div
                class="order__item__content__row__value"
                v-if="item.orderStatus == 3 || item.orderStatus == 2 || item.orderStatus == 0"
              >
                {{ item.numberOfUser }}
              </div>
              <!-- <div
                class="order__item__content__row__value"
              >
                {{ item.numberOfUser }}
              </div> -->
            </div>
            <!-- 予定金額 -->
            <div class="order__item__content__row">
              <div class="order__item__content__row__value">
                {{ $t("ORDER_INFO.ORDER_PHONE.INTENDED_AMOUNT") }}
              </div>
              <div class="order__item__content__row__value">
                {{ item.preAmount }} 円
              </div>
              <!-- <div
                class="order__item__content__row__value"
                v-if=" item.orderStatus == 1"
              >
                <div class="d-flex flex-row align-items-center" style="position: relative; width: fit-content;">
                  <b-form-input
                    v-model="item.preAmount"
                    @keypress="isNumber($event)"
                  >
                </b-form-input>
                  <span style="position: absolute; line-height: 1;" :style="[{right: $store.state.isMobile ? '5px':'15px'}]">
                    {{ $t("CASH.YEN") }}
                  </span>
                </div>
                <div
                  v-if="alert == index"
                  class="order__item__content__row validation-img"
                >
                  <span
                    v-if="item.preAmount == null || item.preAmount == ''"
                    >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                  >
                </div>
              </div>
              <div
                class="order__item__content__row__value"
                v-if="item.orderStatus == 0 || item.orderStatus == 2 || item.orderStatus == 3"
              >
              {{ item.preAmount }} {{ $t("CASH.YEN") }}
              </div> -->
            </div>
            <!-- 実際金額 -->
            <div class="order__item__content__row">
              <div class="order__item__content__row__value">
                {{ $t("ORDER_INFO.ORDER_PHONE.ACTUAL_AMOUNT") }}
              </div>
              <div
                class="order__item__content__row__value"
                v-if=" item.orderStatus == 2"
              >
                <div class="d-flex flex-row align-items-center" style="position: relative; width: fit-content;">
                  <b-form-input
                    v-model="item.orderAmount"
                    @keypress="isNumber($event)"
                  >
                </b-form-input>
                  <span style="position: absolute; line-height: 1;" :style="[{right: $store.state.isMobile ? '5px':'15px'}]">
                    {{ $t("CASH.YEN") }}
                  </span>
                </div>
                <div
                  v-if="alert == index"
                  class="order__item__content__row validation-img"
                >
                  <span
                    v-if="item.orderAmount == null || item.orderAmount == ''"
                    >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                  >
                </div>
              </div>
              <div
                class="order__item__content__row__value"
                v-if="item.orderStatus == 0 || item.orderStatus == 1 || item.orderStatus == 3"
              >
                {{ item.orderAmount }}  {{ $t("CASH.YEN") }}
              </div>
            </div>
            <!-- 图片上传 -->
            <div class="order__item__content__row flex-img" v-if="item.orderStatus == 2 || item.orderStatus == 3">
              <div
                class="order__item__content__row__value updateimg"
                @click="callFile(index, item.orderStatus)"
                for="image"
                :style="{ 'background-image': `url(${item.goodsPic})` }"
              >
                <p v-if="item.goodsPic == '' || item.goodsPic == null">
                  {{ $t("ORDER_INFO.ORDER_PHONE.UPLOAD_IMAGE") }}
                </p>
                <input
                  :id="index"
                  type="file"
                  @change="onFileChange($event, index)"
                  accept="image/x-png,image/gif,image/jpeg"
                  hidden
                />
              </div>
            </div>
            <div
              v-if="alert == index"
              class="order__item__content__row validation-img"
            >
              <span v-if="item.goodsPic == null || item.goodsPic == ''">{{
                $t("COMMON.ITEM_TYPE.VALIDATE")
              }}</span>
            </div>
            <!-- 保存按钮 -->
            <div  v-if = "item.orderStatus == 1" class="w-50 mx-auto mt-3">
            </div>
            <b-button
              @click="updatePhoneOrder(index)"
              v-if="item.orderStatus == 2 || item.orderStatus == 1"
              class="w-50 mx-auto mt-3"
              variant="success"
              >{{ $t("CASH.BTN_SURE") }}</b-button
            >
          </div>
          <div v-else class="order__item__content">
            <div class="order__item__content__row">
              <div class="order__item__content__row__value" style="color: rgb(255, 115, 25);">
                {{ orderStatus(item.orderStatus) }}
              </div>
              <div class="order__item__content__row__value">
                <span
                  v-if="item.orderStatus == 1"
                  class="link"
                  @click="showPage(0)"
                  >{{ $t("ORDER_INFO.PROCESSING") }}</span
                >
              </div>
            </div>
            <div class="order__item__content__row">
              <div class="order__item__content__row__value">
                {{ $t("ORDER_INFO.ORDER_TYPE_TITLE") }}
              </div>
              <div class="order__item__content__row__value">
                {{ orderType(item.orderType) }}
              </div>
            </div>
            <div class="order__item__content__row">
              <div class="order__item__content__row__value">
                {{ $t("ORDER_INFO.ORDER_PRICE") }}
              </div>
              <div class="order__item__content__row__value">
                {{ item.orderAmount }}
              </div>
            </div>
            <div class="order__item__content__row">
              <div class="order__item__content__row__value">
                {{ $t("ORDER_INFO.ORDER_DAY") }}
              </div>
              <div class="order__item__content__row__value">
                {{ item.createTime }}
              </div>
            </div>
            <div class="order__item__content__row">
              <div class="order__item__content__row__value">
                {{ $t("ORDER_INFO.EXPECT_DAY") }}
              </div>
              <div
                v-if="
                  item.orderType == '001' ||
                  item.orderType == '002' ||
                  item.orderType == '003'
                "
                class="order__item__content__row__value"
              >
                {{ item.simContractExpectYmd }}
              </div>
              <div
                v-if="item.orderType == '004'"
                class="order__item__content__row__value"
              >
                {{ item.wifiContractExpectYmd }}
              </div>
              <div
                v-if="item.orderType == '005'"
                class="order__item__content__row__value"
              >
                {{ item.optContractExpectYmd }}
              </div>
              <div
                v-if="item.orderType == '007'"
                class="order__item__content__row__value"
              >
                {{ item.reliefStartYmd }}
              </div>
            </div>
            <div class="order__item__content__row">
              <div class="order__item__content__row__value">
                {{ $t("ORDER_INFO.PAYMENT_METHOD") }}
              </div>
              <div class="order__item__content__row__value">
                {{ PaymentMethod(item.payWay) }}
              </div>
            </div>
            <div class="order__item__content__row">
              <div class="order__item__content__row__value">
                {{ $t("ORDER_INFO.WAY_RECEIVE") }}
              </div>
              <div
                v-if="
                  item.getMode == $CONST.BUYER.RECEIVING_METHOD.GET_MODE.SELFIE
                "
                class="order__item__content__row__value"
              >
                {{
                  $t("SIM_SERVICE.STEP4_RECEIVE.QUESTIONS_RADIO.SELFIE.TITLE")
                }}
              </div>
              <div v-else class="order__item__content__row__value">
                {{ $t("SIM_SERVICE.STEP4_RECEIVE.QUESTIONS_RADIO.OVERSEAS") }}
              </div>
            </div>
          </div>
          <div
            v-if="
              item.getMode != $CONST.BUYER.RECEIVING_METHOD.GET_MODE.SELFIE &&
              item.getMode != null
            "
            class="order__item__content"
          >
            <div class="order__item__content__row">
              <div class="order__item__content__row__value">
                {{ $t("ORDER_INFO.MAILING_AREA") }}
              </div>
              <div
                v-if="
                  item.sendAddress ==
                  $CONST.BUYER.RECEIVING_AREA.SEND_ADDRESS.JAPAN
                "
                class="order__item__content__row__value"
              >
                {{ $t("SIM_SERVICE.STEP4_RECEIVE.QUESTIONS_RADIO.IN_JAPAN") }}
              </div>
              <div v-else class="order__item__content__row__value">
                {{
                  $t("SIM_SERVICE.STEP4_RECEIVE.QUESTIONS_RADIO.OUTSIDE_JAPAN")
                }}
              </div>
            </div>
            <div class="order__item__content__row">
              <div class="order__item__content__row__value">
                {{ $t("ORDER_INFO.MAILING_NAME") }}
              </div>
              <div class="order__item__content__row__value">
                {{ item.sendName }}
              </div>
            </div>
            <div class="order__item__content__row">
              <div class="order__item__content__row__value">
                {{ $t("ORDER_INFO.CONTACT_PHONE") }}
              </div>
              <div class="order__item__content__row__value">
                {{ item.sendPhone }}
              </div>
            </div>
            <div class="order__item__content__row">
              <div class="order__item__content__row__value">
                {{ $t("ORDER_INFO.ADDRESS") }}
              </div>
              <div class="order__item__content__row__value">
                {{ item.sendDetailAddress }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // activeNames: ['1'],
      order_type: [],
      order: {},
      mailing: [],
      order_status: [],
      isClicked: false,
      alert: 99,
      ApiData: {
        code: 0,
        message: "",
        result: {
          current: 0,
          pages: 0,
          records: [],
          size: 0,
          total: 0,
        },
        success: true,
        timestamp: 0,
      },
      imageData: [],
    };
  },
  async created() {
    if (this.$CONST.DEV) {
      console.log("Debug API: orderManage/search");
      this.order = this.ApiData.result.records;
      console.log("response", this.order);
    } else {
      await this.$api
        .get("orderManage/search", {
          email: window.localStorage.getItem("email"),
          language: window.localStorage.getItem("languageCode"),
        })
        .then((response) => {
          this.order = response.result.records;
          for (let index = 0; index < this.order.length; index++) {
            if (this.order[index].simContractExpectYmd != null) {
              this.order[index].simContractExpectYmd =
                response.result.records[index].simContractExpectYmd.split(
                  " "
                )[0];
            }
            if (this.order[index].wifiContractExpectYmd != null) {
              this.order[index].wifiContractExpectYmd =
                response.result.records[index].wifiContractExpectYmd.split(
                  " "
                )[0];
            }
            if (this.order[index].optContractExpectYmd != null) {
              this.order[index].optContractExpectYmd =
                response.result.records[index].optContractExpectYmd.split(
                  " "
                )[0];
            }
            if (this.order[index].reliefStartYmd != null) {
              this.order[index].reliefStartYmd =
                response.result.records[index].reliefStartYmd.split(" ")[0];
            }
          }
        });
    }
  },
  watch: {},
  methods: {
    handleChange(val) {
      console.log(val, 888);
    },
    showPage: function (menuItem) {
      this.toTop();
      this.$localStorage.set("menuItemHeader", menuItem);
      // this.$parent.viewsPage = menuItem;
      this.$router.replace(`/mypage?view=${menuItem}`)
    },

    updatePhoneOrder(index) {
      this.isClicked = true;
      if (this.validate(index)) {
        //call API
        this.$api
          .put("ifmOrderGoods/edit", {
            goodsPic: this.imageData[index],
            hopeStartYmd: this.order[index].hopeStartYmd,
            numberOfUser: Number(this.order[index].numberOfUser),
            orderAmount: Number(this.order[index].orderAmount),
            sysOrderSubId: this.order[index].id,
          })
          .then((response) => {
            if (response.success) {
              this.$router.push({ path: "/success", query: { view: "9" ,suc:"1"} });
            }
          });
      }
    },
    validate(index) {
      if (
        this.order[index].goodsPic == null ||
        this.order[index].goodsPic == "" ||
        this.order[index].orderAmount == null ||
        this.order[index].orderAmount == ""
      ) {
        this.alert = index;
        return false;
      } else {
        this.alert = 99;
        return true;
      }
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    onFileChange(e, index) {
      const file = e.target.files[0];
      let render = new FileReader();
      this.order[index].goodsPic = URL.createObjectURL(file);
      render.onloadend = (file) => {
        this.imageData[index] = file.target.result;
      };
      render.readAsDataURL(file);
    },
    callFile(id, Stt) {
      if (Stt == 3) {
      } else {
        document.getElementById(id)?.click();
      }
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    PaymentMethod(value) {
      var payment = this.$t("ORDER_INFO.OREDER_PAYMENT_OPTION");
      for (let index = 0; index < payment.length; index++) {
        if (payment[index].value == value) {
          return payment[index].text;
        }
      }
    },
    orderStatus(value) {
      var Status = this.$t("ORDER_INFO.ORDER_STATUS");
      for (let index = 0; index < Status.length; index++) {
        if (Status[index].value == value) {
          return Status[index].text;
        }
      }
    },
    orderType(value) {
      var Type = this.$t("ORDER_INFO.ORDER_TYPE");
      for (let index = 0; index < Type.length; index++) {
        if (Type[index].value == value) {
          return Type[index].text;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container_order {
  min-height: 100vh;
  text-align: left;
  width: 60% !important;
}
.note {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 9px;
}
h3 {
  font-size: 1.6rem;
}

.order {
  .main_title {
    width: 200px;
  }

  &__item {
    background: #fff;
    border-radius: 5px;
    border: 2px solid var(--primary);
    margin: 5% 0;
    padding: 1%;

    &__number {
      display: flex;
      border-bottom: 2px solid var(--primary);
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding: 20px 15%;

      &__row {
        display: flex;
        justify-content: space-around;
        background: #f4f4f4;
        padding: 10px;
        margin-top: 5px;

        &__value {
          width: 35%;
          text-align: left;
          overflow-wrap: break-word;
          height: auto;
          .link {
            cursor: pointer;
            color: rgb(255, 115, 25);
            font-weight: 600;
            text-decoration: underline;
          }
          .link:hover {
            color: var(--success);
          }
        }
        .updateimg {
          width: 100%;
          border: 2px solid gray;
          height: 170px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
        }
      }
      .validation-message {
        margin-top: 0;
        padding: 0 0 10px 35%;
      }
      .validation-img {
        margin-top: 0;
        padding-bottom: 10px;
        color: #f44336;
        width: 100%;
        font-weight: 200;
        font-size: 19px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

.container_order {
  .form-control {
    margin-top: 5px;
    font-size: 13px;
  }
}


@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>
