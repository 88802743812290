<template>
  <div class=''>
    <b-card no-body class="card-image overflow-hidden">
      <b-row no-gutters>
        <b-col md="6">
          <b-card-body :title="title">
            <b-card-text>
              <p v-for="(item, index) in texts" :key="index">{{item}}</p>
            </b-card-text>
          </b-card-body>
        </b-col>
        <b-col md="6">
          <b-card-img :src="img" alt="Image" class="rounded-0"></b-card-img>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>

export default {
  props: {
      title: {
        type: String,
        default:''
      },
      texts: [],
      img: {
        type: String,
        default:''
      },
  },

}
</script>
<style scoped>
.card-image {
  -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  margin: 0 auto
}
</style>