<template>
  <div class="recurpayment">
    <div class="container_recur">
      <div class="title-x">
        <img
          class="image_2"
          src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
        />
        <h1 class="text_17">
          {{ $t("RECUR_PAYMENT.TITLE") }} / {{ $t("RECUR_PAYMENT.TITLE2") }}
        </h1>
      </div>
      <div class="note" style="text-align: left">
        <el-collapse @change="handleChange" v-model="activeNames">
          <el-collapse-item name="1">
            <template slot="title">
              <i
                class="iconfont icon--credit-card"
                style="margin-right: 10px"
              ></i>
              <span style="font-size: 16px">{{
                $t("RECUR_PAYMENT.MESSAGE1")
              }}</span>
            </template>
            <p>{{ $t("RECUR_PAYMENT.MESSAGE2") }}</p>
            <p>{{ $t("RECUR_PAYMENT.MESSAGE3") }}</p>
            <div class="recur">
              <div class="recur__content">
                <!-- 手动课金的场合 -->
                <div v-show="settlementType == 0" class="row">
                  <div class="col-lg-12">
                    <div class="col card credit-card credit-card_1">
                      <!-- <div class="card_title credit-card__title">{{ $t('RECUR_PAYMENT.CARD1') }}</div>
                <div class="card_content credit-card__contetnt">
                  <div class="credit-card__contetnt_num">{{ cardNo }}</div>
                  <div class="credit-card__contetnt_text">{{ $t('COMMON.PAYMENT.CARD_NO') }}</div>
                </div> -->
                    </div>
                  </div>
                </div>
                  <!-- 手动课金的场合 -->
                <div v-show="settlementType == 0" class="recur__content__info">
                  <b-button
                    variant="success"
                    @click="showDetail = !showDetail"
                    class="button mt-3"
                    size="lg"
                  >
                    {{ $t("RECUR_PAYMENT.BTN1") }}
                  </b-button>
                </div>
                <!-- 2 -->
                <!-- クレジットカード定期的场合 -->
                <div v-show="settlementType == 1" class="row">
                  <div class="col-lg-12">
                    <div class="col card credit-card credit-card_2">
                      <div class="card_title credit-card__title">
                        {{ $t("RECUR_PAYMENT.CARD1") }}
                      </div>
                      <div class="card_content credit-card__contetnt">
                        <div class="credit-card__contetnt_num">
                          {{ cardNo }}
                        </div>
                        <div class="credit-card__contetnt_text">
                          {{ $t("COMMON.PAYMENT.CARD_NO") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <!-- クレジットカード定期的场合 -->
                <div v-show="settlementType == 1" class="recur__content__info">
                  <b-button
                    variant="success"
                    @click="showDetail = !showDetail"
                    class="button mt-3"
                    size="lg"
                  >
                    {{ $t("RECUR_PAYMENT.BTN2") }}
                  </b-button>
                </div>

                <!-- 3 -->
                <!-- 口座振替的场合 -->
                <div v-show="settlementType == 2" class="row">
                  <div class="col-lg-12">
                    <div class="col card credit-card credit-card_3">
                      <div class="card_title credit-card__title">
                        {{ $t("RECUR_PAYMENT.CARD2") }}
                      </div>
                      <div class="card_content credit-card__contetnt">
                        <div class="credit-card__contetnt_num">
                          {{ cardNo }}
                        </div>
                        <div class="credit-card__contetnt_text">
                          {{ $t("COMMON.PAYMENT.CARD_NO") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="recur" v-show="showDetail">
              <div class="recur__content recur__table">
                <div class="recur__content__info">
                  <b-form-input
                    v-model="payments.cardNum"
                    class="form-input mt-5"
                    :placeholder="$t('COMMON.PAYMENT.CREDIT.PLA1')"
                    @keypress="isNumber($event)"
                    :state="cardNum"
                    trim
                  >
                  </b-form-input>
                  <div v-show="alert" class="validation-message">
                    <span
                      class="validation-message"
                      v-show="payments.cardNum == ''"
                      >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                    >
                  </div>
                </div>
                <div class="recur__content__info">
                  <b-form-input
                    v-model="payments.year"
                    class="form-input"
                    :placeholder="$t('COMMON.PAYMENT.CREDIT.PLA2')"
                    @keypress="isNumber($event)"
                    :state="year"
                    trim
                  >
                  </b-form-input>
                  <b-form-input
                    v-model="payments.month"
                    @change="setMonth"
                    @keypress="isNumber($event)"
                    class="form-input"
                    :placeholder="$t('COMMON.PAYMENT.CREDIT.PLA3')"
                    type="number"
                    min="1"
                    max="12"
                    :state="month"
                    trim
                  />
                </div>
                <div class="recur__content__info layout mt-3 mb-3">
                  <div v-show="alert" class="validation-message">
                    <span
                      class="validation-message"
                      v-show="payments.year == ''"
                      >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                    >
                  </div>
                  <div v-show="alert" class="validation-message">
                    <span
                      class="validation-message"
                      v-show="payments.month == ''"
                      >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                    >
                  </div>
                </div>
                <div class="recur__content__info">
                  <b-form-input
                    v-model="payments.cardName"
                    class="form-input"
                    :placeholder="$t('COMMON.PAYMENT.CREDIT.PLA5')"
                    type="text"
                    :state="cardName"
                    trim
                  >
                  </b-form-input>
                  <div v-show="alert" class="validation-message">
                    <span
                      class="validation-message"
                      v-show="payments.cardName == ''"
                      >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                    >
                  </div>
                  <b-form-input
                    v-model="payments.securityCode"
                    class="form-input mt-3 mb-3"
                    :placeholder="$t('COMMON.PAYMENT.CREDIT.PLA6')"
                    type="text"
                    :state="securityCode"
                    trim
                  >
                  </b-form-input>
                  <div v-show="alert" class="validation-message">
                    <span
                      class="validation-message"
                      v-show="payments.securityCode == ''"
                      >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                    >
                  </div>
                  <b-button
                    variant="success"
                    v-on:click="submit()"
                    class="button mt-3"
                    size="lg"
                  >
                    {{ $t("RECUR_PAYMENT.BTN_SUBMIT") }}
                  </b-button>
                </div>
              </div>
            </div>
            <div class="note" style="text-align: left">
              <P>{{ $t("RECUR_PAYMENT.MESSAGE4") }}</P>
              <p>{{ $t("RECUR_PAYMENT.MESSAGE5") }}</p>
              <p>{{ $t("RECUR_PAYMENT.MESSAGE6") }}</p>
            </div>
          </el-collapse-item>
          <el-collapse-item name="2">
            <template slot="title">
              <i class="iconfont icon-yinhangka" style="margin-right: 10px"></i>
              <span style="font-size: 16px">{{
                $t("RECUR_PAYMENT.MESSAGE7")
              }}</span>
            </template>
            <div style="display: flex" v-show="settlementType == 2">
              <p style="display: block">{{ $t("RECUR_PAYMENT.MESSAGE8_1") }}</p>
              <div
                style="margin: 0 10px !important"
                class="credit-card__contetnt_num"
              >
                {{ cardNo }}
              </div>
              <p style="display: block">{{ $t("RECUR_PAYMENT.MESSAGE8_2") }}</p>
            </div>
            <p>
              {{ $t("RECUR_PAYMENT.MESSAGE9") }}
              <a
                download :href="'https://ifmobile.jp/download/Oral_Seat_Vibration_Replacement_Dependency_Letter.pdf'"
                target="_blank"
                style="color: rgb(241, 120, 14)"
                >「口座振替依頼書(IF Mobile)」</a
              >
              &nbsp;<a
                download :href="'https://ifmobile.jp/download/Recorded_As_An_Example.pdf'"
                target="_blank"
                style="color: rgb(241, 120, 14)"
                >「口座振替依頼書(記入例)」</a
              >
            </p>
            <p>{{ $t("RECUR_PAYMENT.MESSAGE10") }}</p>
            <p>{{ $t("RECUR_PAYMENT.MESSAGE11") }}</p>
            <p>{{ $t("RECUR_PAYMENT.MESSAGE12") }}</p>
            <p>{{ $t("RECUR_PAYMENT.MESSAGE13") }}</p>
            <p>{{ $t("RECUR_PAYMENT.MESSAGE14") }}</p>
            <p>{{ $t("RECUR_PAYMENT.MESSAGE15") }}</p>
          </el-collapse-item>
          <el-collapse-item name="3">
            <template slot="title">
              <i class="iconfont icon-jifen1" style="margin-right: 10px"></i>
              <span style="font-size: 16px">{{
                $t("RECUR_PAYMENT.MESSAGE16")
              }}</span>
            </template>
            <div style="display: flex">
              <p>
                {{ $t("RECUR_PAYMENT.MESSAGE17_1") }}
                <a href="#/mypage?view=1" class="thumbnail_4">
                  {{ $t("RECUR_PAYMENT.MESSAGE17_2") }}
                </a>
                {{ $t("RECUR_PAYMENT.MESSAGE17_3") }}
              </p>
            </div>
            <div>
              <p>
                {{ $t("RECUR_PAYMENT.MESSAGE18") }}
                <a style="color: rgb(255, 115, 25)" href="#/mypage?view=1">{{
                  $t("RECUR_PAYMENT.MESSAGE18_1")
                }}</a>
                {{ $t("RECUR_PAYMENT.MESSAGE18_2") }}
                <a
                  style="color: rgb(255, 115, 25)"
                  href="#/details/vip/section5"
                  class="important_highlight"
                  >{{ $t("POINT_HISTORY.NOTICE.HIGHLIGHT1") }}</a
                >
              </p>
              <p>{{ $t("RECUR_PAYMENT.MESSAGE16") }}</p>
            </div>

            <div
              style="
                border: 1px solid #ebebeb;
                border-radius: 8px;
                overflow: hidden;
              "
            >
              <img style="width: 100%" src="@/assets/demo_kejin.png" alt="" />
            </div>

            <div v-show="alert" class="validation-message my-3">
              <span v-show="charge_price < 5000">{{
                $t("POINT_HISTORY.NOTICE.TEXT4")
              }}</span>
            </div>
            <label
              class="btn-secondary content__card__form-submit__button mt-3"
              size="lg"
            >
              {{ $t("POINT_HISTORY.SUBMIT") }}
            </label>
            <p>{{ $t("RECUR_PAYMENT.MESSAGE23") }}</p>
            <p>{{ $t("RECUR_PAYMENT.MESSAGE24") }}</p>
            <Change
              v-if="showPay"
              @return-contract="returnContract"
              :dataChange="dataChange"
            ></Change>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import Change from "../Change/index.vue";
export default {
  components: { Change },
  data() {
    return {
      showDetail: false,
      showType1: false,
      settlementType: 9,
      cardNo: "",
      activeNames: [],
      payments: {
        cardName: "",
        cardNum: "",
        email: "",
        month: "",
        periodicalStatus: "",
        securityCode: "",
        year: new Date().getFullYear(),
      },
      isClicked: false,
      alert: false,
      currentPoint: 0,
      charge_price: 0,
      charge_price_input: "",
      dataChange: {},
    };
  },
  watch: {
    "payments.month"() {
      if (this.payments.month > 12 || this.payments.month.length > 2) {
        this.payments.month = this.payments.month.slice(1, 3);
      }
    },
    charge_price_input() {
      if (this.charge_price_input > 100000) {
        this.charge_price_input = this.charge_price_input.slice(0, 5);
      }
      this.charge_price = this.charge_price_input;
    },
  },
  computed: {
    cardName() {
      if (this.isClicked) {
        if (this.payments.cardName == null) {
          return false;
        }
        return this.payments.cardName.length > 0;
      }
    },
    cardNum() {
      if (this.isClicked) {
        if (
          this.payments.cardNum == null ||
          !this.payments.cardNum.match(/^[0-9]+$/)
        ) {
          return false;
        }
        return this.payments.cardNum.length > 0;
      }
    },
    month() {
      if (this.isClicked) {
        if (this.payments.month == null) {
          return false;
        }
        return this.payments.month.length > 0;
      }
    },
    securityCode() {
      if (this.isClicked) {
        if (this.payments.securityCode == null) {
          return false;
        }
        return this.payments.securityCode.length > 0;
      }
    },
    year() {
      if (this.isClicked) {
        if (this.payments.year == null) {
          return false;
        }
        return this.payments.year != "";
      }
    },
  },
  async created() {
    if (this.$CONST.DEV) {
      console.log("Debug API: 定期課金（初期表示)");
      this.dataResponse = this.ApiData.result;
      console.log(this.dataResponse);
    } else {
      this.$api
        .post("ifmPeriodicalPayments/getPeriodical", {
          email: window.localStorage.getItem("email"),
        })
        .then((response) => {
          //定期形式(1:银行卡定期，2:口座定期)
          this.settlementType = response.result.settlementType;
          //卡号
          this.cardNo = response.result.cardNo;
          //activeNames
          this.activeNames.push(this.settlementType);
        });

      this.$api.get("pointCharge/showPoint").then((response) => {
        this.currentPoint = response.result.userPoint;
      });
    }
  },
  methods: {
    charge() {
      if (this.charge_price < 5000) {
        this.alert = true;
      } else {
        this.alert = false;
        this.dataChange.change = {
          changePrice: this.charge_price,
          id: "",
          orderType: 5,
          revenueSummary: this.$CONST.PAYMENT.REVENUE_SUMMARY.CHARGE_POINT,
        };
        this.showPay = true;
      }
    },
    handleChange(val) {
      console.log(val);
    },
    submit() {
      this.isClicked = true;
      var payLoad = {
        cardName: this.payments.cardName,
        cardNum: this.payments.cardNum,
        email: window.localStorage.getItem("email"),
        month: this.payments.month,
        periodicalStatus: this.settlementType,
        securityCode: this.payments.securityCode,
        year: this.payments.year,
      };
      var check = this.checkInputText();
      if (check) {
        this.$api
          .post("ifmPeriodicalPayments/periodicalPayments", payLoad)
          .then((response) => {
            if (response.success) {
              this.$router.push({ path: "/success", query: { view: "9" } });
            } else {
              this.$bvToast.toast(this.$t("COMMON.PAYMENT.VALIDATE.FAIL"), {
                title: `Errors`,
                solid: true,
                variant: `danger`,
              });
            }
          });
      } else {
        this.$bvToast.toast(this.$t("COMMON.PAYMENT.VALIDATE.CHECK_INPUT"), {
          title: `Errors`,
          solid: true,
          variant: `warning`,
        });
      }
    },

    enter_yourself() {
      if (this.charge_price_input == "") {
        this.charge_price = 0;
      }
    },
    checkInputText() {
      if (
        this.payments.cardName == null ||
        this.payments.cardName == "" ||
        this.payments.cardNum == null ||
        this.payments.cardNum == "" ||
        this.payments.month == null ||
        this.payments.month == "" ||
        this.payments.securityCode == null ||
        this.payments.securityCode == "" ||
        this.payments.year == null ||
        this.payments.year == ""
      ) {
        this.alert = true;
        return false;
      } else {
        this.alert = false;
        return true;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    setMonth() {
      if (this.payments.month < 10 && this.payments.month != "") {
        console.log(this.payments.month);
        this.payments.month = "0" + this.payments.month;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>
