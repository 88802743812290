<template>
  <section>
    <h1 class="main_title">特定商取引法に基づく表示</h1>
    <b-card class="content__card">
      <table class="table">
        <thead>

        </thead>
        <tbody>
          <tr>
            <th>販売業者名</th>
            <td>星和通商株式会社</td>
          </tr>
          <tr>
            <th>代表者名</th>
            <td>宮沢　千秋</td>
          </tr>
          <tr>
            <th>設立</th>
            <td>Wednesday, July 15, 2015</td>
          </tr>
          <tr>
            <th>所在地</th>
            <td>〒169-0075　東京都新宿区高田馬場1丁目28−3　工新ビル 5階</td>
          </tr>
          <tr>
            <th>お問い合わせ先</th>
            <td>メール：info@seiwao.com <br> 電話：03-6383-4910（受付時間　10:00～18:30）</td>
          </tr>
          <tr>
            <th>販売価格</th>
            <td>968円～　商品ページに記載しております。 </td>
          </tr>
          <tr>
            <th>販売価格以外に発生する費用</th>
            <td>消費税、郵送費用などがあります。<br> その他諸費用は各商品ページをご参照ください。</td>
          </tr>

          <tr>
            <th>代金の支払方法及び支払時期</th>
            <td>1.クレジットカード（VISA、MasterCard、JCB、AMEX、Diners）：支払時期は各カード会社の引き落とし日です。<br>
              2.口座振替：支払時期は毎月の27日です。<br>
              3.コンビニ決済：請求メール送信した日から当月の25日までです。<br>
              ※商品によってご利用いただけるお支払い方法が異なります。各商品のお支払い画面でご確認ください。
            </td>
          </tr>
          <tr>
            <th>引渡時期、発送方法</th>
            <td>お申し込み内容や登録審査状況に不備、または品切れ・品不足などがない場合、お申し込み完了から最短3～4日で契約のご住所にお届けします。<br>
              ただし、土日祝をはさむ場合やお届け先が離島の場合は配送にお時間をいただくことがあります。
            </td>
          </tr>
          <tr>
            <th>返品・交換について</th>
            <td>（返品）<br> 商品発送後の返品・返却等はお受けいたしかねます。<br>
              （交換）<br>
              商品が不良の場合のみ交換いたします。<br>
              不良品の場合は弊社が負担いたします、それ以外はお客様の負担となります。
            </td>
          </tr>
          <tr>
            <th>保証・修理</th>
            <td>（新品）弊社は、商品についての保証を行いません。商品の修理及び修理の受付先は、商品に付帯する製造者の保証書に記載された条件によるものとします。<br><br>
              （中古）弊社は、商品到着後30日以内の保証を定めるものとします。31日以降の商品の修理及び修理の受付先は、製造者の定める条件によるものとします。
            </td>
          </tr>
          <tr>
            <td colspan="2" class="title-end">「古物営業法」に基づく表示<br>
              東京都公安委員会発行<br>
              古物商販売許可申請番号　第304372217419号<br>
              星和通商株式会社
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
  </section>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  mounted() {

  },

  created() {
    this.toTop();
  },
  computed: {},
  methods: {
    toTop: function () {
      window.scrollTo(0,0);
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  width: 80%;
  text-align: left;
  margin: auto;

  .main_title {
    max-width: 420px;
    border: none;
    text-decoration: underline;
    text-underline-offset: 10px;
    margin: auto;
    text-align: center;
  }

  .content__card {
    min-width: 200px;
    background: #fff;
    border-radius: 5px;
    border: 2px solid var(--primary);
    width: 80%;
    margin: 50px auto 50px;
  }

  .title-end {
    text-align: center;
    font-size: 25px;
    color: red;
  }
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border: 1px solid #212529;
}

.table th {
  font-weight: bold;
  width: 30%;
  background: #f1f1f1;
  vertical-align: middle;
}

@media screen and (max-width: 1000px) {

  section {
    width: 100%;
  }

  .content__card {
    width: 90% !important;
  }
}

@media screen and (max-width: 390px) {
  .content__card {
    width: 100% !important;
  }
}
</style>
