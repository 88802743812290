<template>
  <div class="accountsecurity">
      <div class="fee">
          <div class="title-x" style="margin-bottom: 20px;">
            <img
              class="image_2"
              
              src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
            />
            <h1 class="text_17">
              {{ $t('ACCOUNT_SECURITY.TITLE') }}
            </h1>
          </div>
          <div >
              <div  class="fee__content" style="margin-top: 0px;">
                  <!-- <div class="fee__content__type">
                      <h3>{{ $t('ACCOUNT_SECURITY.NOTICE') }}</h3>
                  </div>
                  <div class="fee__content__type">
                      <h3>{{ $t('ACCOUNT_SECURITY.MESSAGE3') }}</h3>
                  </div> -->
                  <el-alert
                    type="warning"
                    :closable="false"
                    style="text-align: left; padding: 5px 0; margin: 0 0 20px 0;"
                  >
                    {{ $t('ACCOUNT_SECURITY.NOTICE') }}<br/>
                    {{ $t('ACCOUNT_SECURITY.MESSAGE3') }}
                  </el-alert>
                  <div class="fee__content__info">
                      <div class="fee__content__info__row" style="margin-top: 0px;">
                          <b-form-input type="password" v-model="currentPassword" :placeholder="$t('ACCOUNT_SECURITY.CURRENT_PASSWORD')" :state="current"></b-form-input>
                      </div>
                      <div v-if="alert" class="validation-message">
                          <span v-if="currentPassword == ''">{{
                              $t("COMMON.ITEM_TYPE.VALIDATE")
                          }}</span>
                      </div>
                      <div class="fee__content__info__row">
                          <b-form-input type="password" v-model="newPassword" :placeholder="$t('ACCOUNT_SECURITY.NEW_PASSWORD')" :state="New"></b-form-input>
                      </div>
                      <div v-if="alert" class="validation-message">
                          <span v-if="newPassword == ''">{{
                              $t("COMMON.ITEM_TYPE.VALIDATE")
                          }}</span>
                      </div>
                      <div class="fee__content__info__row">
                          <b-form-input type="password" v-model="reEnterNewPassword" :placeholder="$t('ACCOUNT_SECURITY.RE_ENTER_PASSWORD')" :state="reEnter"></b-form-input>
                      </div>
                      <div v-if="alert" class="validation-message">
                          <span v-if="reEnterNewPassword == ''">{{
                              $t("COMMON.ITEM_TYPE.VALIDATE")
                          }}</span>
                      </div>
                      <div v-if="passUnlike" class="validation-message">
                          <span v-if="newPassword != reEnterNewPassword && reEnterNewPassword != '' ">{{
                              $t("REGISTER.ERROR.RESPASSWORD")}}</span>
                          </div>
                      <div class="fee__content__info__row">
                          <b-input-group size="lg" class="code">
                              <b-input-group-prepend is-text>
                              <a>{{ randomCode }}</a>
                              </b-input-group-prepend>
                              <b-form-input type="text" v-model="confirmationCode" :placeholder="$t('ACCOUNT_SECURITY.CONFIRMATION_CODE')"  :state="Code" trim/>
                          </b-input-group>
                      </div>
                      <div v-if="alert" class="validation-message">
                          <span v-if="confirmationCode == ''">{{
                              $t("COMMON.ITEM_TYPE.VALIDATE")
                          }}</span>
                      </div>
                      <div v-if="CodeUnlike" class="validation-message">
                          <span v-if="randomCode != confirmationCode && confirmationCode != ''">{{
                              $t("COMMON.ERR.CODE_INPUT")
                          }}</span></div>
                      <b-button variant="danger" @click="change" >{{ $t('ACCOUNT_SECURITY.SAVE_CHANGES') }}</b-button>
                  </div>
              </div>

          </div>
      </div>
  </div>
</template>
<script>
export default {
components: {
},
  data() {
      return {
          randomCode: this.makeCode(4),
          currentPassword: "",
          newPassword: "",
          reEnterNewPassword: "",
          confirmationCode: "",
          isClicked: false,
          alert: false,
          passUnlike: false,
          CodeUnlike: false,
          language: "",
          email: ""
      }
  },
  created() {
  },
  computed: {
     current() {
          if(this.isClicked){
              return this.currentPassword.length > 0;
          }
      },
      New() {
          if(this.isClicked){
              return this.newPassword.length > 0;
          }
      },
      reEnter() {
          if(this.isClicked){
              return this.reEnterNewPassword.length > 0 && this.reEnterNewPassword == this.newPassword;
          }
      },
      Code() {
          if(this.isClicked){
              return this.confirmationCode.length > 0 && this.randomCode == this.confirmationCode;
          }
      },
  },
  watch: {
  },
  methods: {
      makeCode(length) {
          var result = "";
          var characters =
              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
          var charactersLength = characters.length;
          for (var i = 0; i < length; i++) {
              result += characters.charAt(
              Math.floor(Math.random() * charactersLength)
              );
          }
          return result;
      },
      async change(){
          this.isClicked = true;
          var payLoad = {
            confirmpassword: this.reEnterNewPassword,
            email: this.$localStorage.get("email"),
            language: this.$localStorage.get("languageCode"),
            oldpassword: this.currentPassword,
            password: this.newPassword
          }
          if(this.validate()){
              if (this.current && this.New && this.reEnter && this.Code) {
                await this.$api.put("userLogin/updatePassword", payLoad).then((response) => {
                  if(response.success) {
                    this.$router.push({ path: '/success' });
                  }else {
                    this.$bvToast.toast(this.$t('ACCOUNT_SECURITY.MESSAGE1'), {
                      title: `Errors`,
                      solid: true,
                      variant: `warning`
                    })
                  }
                });
              }
          }else{
              this.randomCode = this.makeCode(4);
          }
      },
      validate() {
          if (this.currentPassword == "" ||this.newPassword == "" ||
              this.reEnterNewPassword == "" ||this.confirmationCode == "" ){
              this.alert = true;
              return false
          } else if(this.newPassword != this.reEnterNewPassword ){
                  this.passUnlike = true;
              return false
          }
          if (this.currentPassword == this.newPassword && this.newPassword == this.reEnterNewPassword) {
              this.$bvToast.toast(this.$t('ACCOUNT_SECURITY.MESSAGE2'), {
                title: `Errors`,
                solid: true,
                variant: `warning`
              })
              return false
          }
          if(this.randomCode != this.confirmationCode){
              this.CodeUnlike = true;
              return false
          }
          else {
              this.alert = false
              return true
          }

      }
  },
};
</script>



<style lang="scss" scoped>

.fee {
  &__title {
      background-color: #f35f0d;
      color: #fff;
      border-radius: 15px;
      text-align: left;
      padding: 12px;
  }
  &__content {
      background: #fff;
      border-radius: 5px;
      border: 2px solid var(--primary);
      margin: 5% 0;
      padding: 1%;

      &__info {
          display: flex;
          width: 80%;
          margin: auto;
          flex-direction: column;
          align-items: center;
          row-gap: 5px;

          &__row {
              display: flex;
              width: 100%;
              justify-content: space-around;
              margin-top: 30px;
              background: #f4f4f4;
              .form-control {
                  height: calc(2.5em + 0.75rem + 2px);
              }
          }

          button {
              width: 45%;
              margin: 20px;
              height: 50px;

          }


      }

      &__type {
          display: flex;
          padding: 10px;
          color: #a3a6a9;
          border-bottom: 2px solid var(--primary);
      }
  }
}


@media screen and (max-width: 575px) {

  .fee {
      &__content {
          &__row {
              display: flex;
              justify-content: space-around;
              background: #F4F4F4;
              padding: 10px;
              flex-direction: column;
          }

          &__info {
              button {
                  width: 75%;
              }
          }
      }

  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>
