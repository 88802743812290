<template>
  <div class="adv">
      <iframe class="adv__iframe" src="https://www.youtube.com/embed/Yj2iELI6OeI?controls=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=Yj2iELI6OeI" ></iframe>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.adv{
  height: 100vh;
  margin-bottom: 100px;
  &__title{
    margin-bottom: 30px;
    font-size: 32px;
    color: #F35F0D;
    font-weight: 600;

  }
  video{
    margin-bottom: 70px;
  }
  &__iframe {
        display: flex;
      flex-wrap: wrap;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
iframe[sandbox] .read-article {
    display: none;
}
}
@media (max-width: 600px){
  .adv{
    margin-bottom: 50px;
    height: 30vh;
    &__title{
      margin-bottom: 30px;
      font-size: 20px;
    }
    video{
      margin-bottom: 50px;
    }
  }
}
@media (min-aspect-ratio: 16/9) {
    .adv iframe {
        height: 56.25vw;
    }
}

@media (max-aspect-ratio: 16/9) {
    .adv iframe {
        width: 177.78vh;
    }
}

</style>
