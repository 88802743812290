<template> 
  <div class="login flex-col">
    <!-- <div  v-if="$store.state.isMobile" style="height: 13.87vw;"></div> -->
    <div v-if="$store.state.isMobile" class="box_2 flex-row" @click="$router.replace('/home')">
      <div class="section_1 flex-col"></div>
      <!-- <div class="section_2 flex-col"></div> -->
    </div>
    <div class="box_1 flex-col">
      <FadeTransition :duration="200" mode="out-in">
        <router-view></router-view>
      </FadeTransition>
    </div>
    <div class="group_1 flex-row" @click="$router.replace('/home')">
      <div class="box_6 flex-col"></div>
      <div class="box_7 flex-col"></div>
    </div>
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
export default {
  components: {
    FadeTransition,
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/common.scss';
@import './index.response.scss';

@media (max-width: 768px) {
  @import './index.response.m.scss';
  
}
</style>
  