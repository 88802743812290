import Vue from "vue";
import { saveAs } from "file-saver";

Vue.directive("download", {
  bind(el, binding) {
    el.addEventListener("click", () => {
      const url = binding.value;
      const filename =
        url.substring(url.lastIndexOf("/") + 1) || "downloaded-file";

      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error("网络响应错误");
          }
          return response.blob();
        })
        .then((blob) => {
          // 尝试使用 a 标签下载
          try {
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
          } catch (error) {
            console.error("下载链接创建失败，尝试使用 FileSaver.js", error);
            // 如果 a 标签方法失败，使用 FileSaver.js
            saveAs(blob, filename);
          }
        })
        .catch((error) => {
          console.error("下载出错:", error);
        });
    });
  },
});
