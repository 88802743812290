<template>
	<section>
		<div class="container">
			<div class="ctn__features">
				<div class="row">
					<div class="col-md-4 ctn__features__icon">
						<img src="@/assets/Group (3).png" alt="" />
						<div class="ctn__features__icon__content">
							<h4 class="ctn__features__icon__content__title">{{ $t("INDEX.FEATURES.TITLE1") }}</h4>
							<p>{{ $t("INDEX.FEATURES.FEATURE1") }}</p>
						</div>
					</div>
					<div class="col-md-4 ctn__features__icon">
						<img src="@/assets/Group (2).png" alt="" />
						<div class="ctn__features__icon__content">
							<h4 class="ctn__features__icon__content__title">{{ $t("INDEX.FEATURES.TITLE2") }}</h4>
							<p>{{ $t("INDEX.FEATURES.FEATURE2") }}</p>
						</div>
					</div>
					<div class="col-md-4 ctn__features__icon">
						<img src="@/assets/Group 1543.png" alt="" />
						<div class="ctn__features__icon__content">
							<h4 class="ctn__features__icon__content__title">{{ $t("INDEX.FEATURES.TITLE3") }}</h4>
							<p>{{ $t("INDEX.FEATURES.FEATURE3") }}</p>
						</div>
					</div>
					<div class="col-md-12">
						<h1>{{ $t("INDEX.FEATURES.TITLE") }}</h1>
					</div>
					<div class="col-md-4 ctn__features__icon">
						<img src="@/assets/Group (1).png" alt="" />
						<div class="ctn__features__icon__content">
							<h4 class="ctn__features__icon__content__title">{{ $t("INDEX.FEATURES.TITLE4") }}</h4>
							<p>{{ $t("INDEX.FEATURES.FEATURE4") }}</p>
						</div>
					</div>
					<div class="col-md-4 ctn__features__icon">
						<img src="@/assets/Group.png" alt="" />
						<div class="ctn__features__icon__content">
							<h4 class="ctn__features__icon__content__title">{{ $t("INDEX.FEATURES.TITLE5") }}</h4>
							<p>{{ $t("INDEX.FEATURES.FEATURE5") }}</p>
						</div>
					</div>
					<div class="col-md-4 ctn__features__icon">
						<img src="@/assets/Sim_Card_2_.png" alt="" />
						<div class="ctn__features__icon__content">
							<h4 class="ctn__features__icon__content__title">{{ $t("INDEX.FEATURES.TITLE6") }}</h4>
							<p>{{ $t("INDEX.FEATURES.FEATURE6") }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	data() {
		return {
			checkColor1: true,
			checkColor2: true,
			checkColor3: true,
		};
	},
	computed: {},
	methods: {},
};
</script>

<style lang="scss" scoped>
//
section {
	.ctn__features {
		margin: 130px auto 130px auto;

		h1 {
			font-size: 32px;
			color: #212529;
			font-weight: bolder;
			padding: 6.5%;
		}

		&__icon {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: left;
			align-content: center;
			padding: 10px;

			img {
				padding-right: 20px;
			}

			&__content {
				text-align: left;

				&__title {
					color: #d70c19;
					font-weight: bold;
				}
			}
		}
	}
}
</style>
