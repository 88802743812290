<template>
  <div class="textmore">
    <slot v-if="false"></slot>
    <p v-if="text.length != null" class="t-x">
      <span v-for="(item, index) in t" :key="index">
        {{showFlag ? flag : '' }}
        {{ item }}
        <br v-if="t.length-1 != index" />
      </span>
      <a v-if="isOverflow" @click="isShow = !isShow">{{ this.tip[Number(isShow)] }}</a>
    </p> 
  </div>
</template>

<script>
export default {
  props: {
    length: {
      type: Number,
      default: 50,
    },
    show: {
      type: Boolean,
      default: false,
    },
    showFlag: {
      type: Boolean,
      default: false,
    },
    flag: {
      type: String,
      default: "※",
    },
    tip: {
      type: Array,
      default: () => ['もっと', '折返']
    }
  },
  data() {
    return {
      isShow: this.show
    }
  },
  computed: {
    hasSlot() {
      console.log(this.$slots.default[0])
      return Boolean(this.$slots.default)
    },
    text() {
      return this.hasSlot ? this.$slots.default[0].text : '';
    },
    isOverflow() {
      return this.text.length > this.length;
    },
    t() {
      let ts = this.isOverflow && !this.isShow ? 
        `${this.text.slice(0, this.length)}...` 
        : 
        this.text;
      return ts.split(this.flag);
    }
  },
}
</script>

<style lang="scss" scoped>
.textmore {
  display:flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  .t-x {
    text-align: left;
    margin-bottom: 0;
    a {
      width: fit-content;
      position: relative;
      cursor: pointer;
      color: rgb(255, 115, 26);

      &:hover {
        color: rgb(255, 115, 26);
        text-decoration: underline;
      }
    }
  }
}
</style>