<template>
  <div class="footer">
    <!-- Mob端 -->
    <div v-if="$store.state.isMobile" class="group_13 flex-col">
      <div class="text-wrapper_5 flex-row justify-between">
        <span class="text_22">製品</span>
        <span class="text_23">IF会員</span>
      </div>
      <div class="image-wrapper_2 flex-row justify-between">
        <img
          class="image_4"
          src="@/assets/lxl/SketchPng937b88449c268f48435237c939da62c2bc2b521212427c2c2f0d2c7cb7dfe53f.png"
        />
        <img
          class="image_5"
          src="@/assets/lxl/SketchPng937b88449c268f48435237c939da62c2bc2b521212427c2c2f0d2c7cb7dfe53f.png"
        />
      </div>
      <div class="text-wrapper_6 flex-row justify-between">
        <span class="text_24" @click="$router.push('/details/sim/section1')"
          >SIM</span
        >
        <span class="text_25" @click="$router.push('/details/vip/section1')"
          >IF会員</span
        >
      </div>
      <div class="text-wrapper_7 flex-row justify-between">
        <span class="text_26" @click="$router.push('/details/wifi/section1')"
          >Wi-Fi</span
        >
        <span class="text_27" @click="$router.push('/details/vip/section2')"
          >紹介制度</span
        >
      </div>
      <div class="text-wrapper_8 flex-row justify-between">
        <span class="text_28" @click="$router.push('/details/light/section1')"
          >光回線</span
        >
        <span class="text_29" @click="$router.push('/details/vip/section3')"
          >提携店舗・施設</span
        >
      </div>
      <div class="text-wrapper_9 flex-row justify-between">
        <span class="text_30" @click="$router.push('/details/pro/section1')"
          >サービス・端末</span
        >
        <span class="text_31" @click="$router.push('/details/vip/section4')"
          >イベント掲載</span
        >
      </div>
      <div class="text-wrapper_9 flex-row justify-between">
        <span
          class="text_30"
          @click="
            goRouter(
              $CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.SIM_CARD_ONLY
            )
          "
          >お申し込み</span
        >
        <span class="text_31" @click="$router.push('/details/vip/section5')"
          >IFポイント</span
        >
      </div>
      <div class="text-wrapper_9 flex-row justify-between">
        <span class="text_30"> </span>
        <span class="text_31" @click="$router.push('/login')">会員登録</span>
      </div>
      <div class="text-wrapper_5 flex-row justify-between">
        <span class="text_22" @click="$router.push('/details/servers/section1')"
          >サポート</span
        >
        <span class="text_23" @click="$router.push('/details/servers/section1')"
          >会社情報</span
        >
      </div>
      <div class="image-wrapper_2 flex-row justify-between">
        <img
          class="image_4"
          src="@/assets/lxl/SketchPng937b88449c268f48435237c939da62c2bc2b521212427c2c2f0d2c7cb7dfe53f.png"
        />
        <img
          class="image_5"
          src="@/assets/lxl/SketchPng937b88449c268f48435237c939da62c2bc2b521212427c2c2f0d2c7cb7dfe53f.png"
        />
      </div>
      <div class="text-wrapper_11 flex-row justify-between">
        <span class="text_34" @click="$router.push('/details/servers/section1')"
          >Q&amp;A</span
        >
        <span class="text_35" @click="$router.push('/details/com/section1')"
          >運営会社</span
        >
      </div>
      <div class="text-wrapper_12 flex-row justify-between">
        <span class="text_36" @click="$router.push('/details/servers/section2')"
          >本人確認</span
        >
        <span class="text_37" @click="$router.push('/details/com/section2')"
          >特定商取引法に基づく表記</span
        >
      </div>
      <div class="text-wrapper_13 flex-row justify-between">
        <span class="text_38" @click="$router.push('/details/sim/section4')"
          >設定方法</span
        >
        <span class="text_39" @click="$router.push('/details/com/section3')"
          >サービス約款</span
        >
      </div>
      <div class="text-wrapper_14 flex-row justify-between">
        <span class="text_40" @click="$router.push('/details/sim/section5')"
          >支払方法</span
        >
        <span class="text_41" @click="$router.push('/details/com/section4')"
          >プライバシーポリシー</span
        >
      </div>
      <div class="text-wrapper_14 flex-row justify-between">
        <span class="text_40" @click="$router.push('/details/sim/section3')"
          >MNP</span
        >
        <!-- <span class="text_41" @click="$router.push('/details/com/section4')">個人情報の取り扱いについて</span> -->
      </div>
      <!-- <div class="section_4 flex-row justify-between">
                <div class="group_14 flex-col"></div>
                <div class="group_15 flex-col"></div>
            </div> -->
      <img
        class="image_8"
        src="@/assets/lxl/SketchPng90831172e2142675b4ea6313523c0fb871163af198a9cd180ed5e7317882a4bf.png"
      />
      <span class="text_42">follow us</span>
      <div class="section_5 flex-row justify-between">
        <a
          class="image-wrapper_i flex-col"
          href="https://instagram.com/ifmobile2022?igshid=YmMyMTA2M2Y="
          target="_blank"
        >
          <img class="label_8" src="@/assets/lxl/ins.svg" />
        </a>
        <a
          class="image-wrapper_i flex-col"
          href="https://twitter.com/ifmobile2023"
          target="_blank"
        >
          <img class="label_9" src="@/assets/lxl/twitter.svg" />
        </a>
        <a
          class="image-wrapper_i flex-col"
          href="https://www.youtube.com/channel/UCNwKTiPECjDRu5OxRV4W1wQ"
          target="_blank"
        >
          <img class="label_10" src="@/assets/lxl/youtube.svg" />
        </a>
        <a
          class="image-wrapper_i flex-col"
          href="https://www.xiaohongshu.com/user/profile/62b66ea2000000001902bfc5?xhsshare=CopyLink&appuid=62b66ea2000000001902bfc5&apptime=1678695122"
          target="_blank"
        >
          <img class="label_10" src="@/assets/lxl/xhs.svg" />
        </a>
        <!-- <div class="group_9 flex-col"></div> -->
        <a
          class="image-wrapper_i flex-col"
          href="https://www.tiktok.com/@ifmobile1"
          target="_blank"
        >
          <img class="label_11" src="@/assets/lxl/tiktok.svg" />
        </a>
        <a
          class="image-wrapper_i flex-col"
          href="https://www.tiktok.com/@ifmobile1"
          target="_blank"
        >
          <img class="label_11" src="@/assets/lxl/tiktok_g.svg" />
        </a>
        <a
          class="image-wrapper_i flex-col"
          href="https://www.facebook.com/IFMobile"
          target="_blank"
        >
          <img class="image_12" src="@/assets/lxl/meta.svg" />
        </a>
      </div>
      <div class="lang">
        <b-dropdown
          size="sm"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content>
            <span class="text_67">{{ languageItem[indexLanguage].name }}</span>
            <b-icon icon="chevron-down" scale="0.8"></b-icon>
          </template>
          <!-- <div :key="indexLang" v-for="(itemLang, indexLang) in languageItem">
            <b-dropdown-item
              href="#"
              v-if="indexLang != indexLanguage"
              @click="changeLang(itemLang.value, indexLang)"
              >{{ itemLang.name }}</b-dropdown-item
            >
          </div> -->
        </b-dropdown>
      </div>
      <p class="text_43">Copyright (C) 2023 SEIWA TRADING CO.,LTD.</p>
      <p class="text_43" style="margin-top: 5px">All Rights Reserved</p>
      <img
        class="image_9"
        src="@/assets/lxl/SketchPng2a11810a23d04d4d82ef3c1eb5d3992a2e318cd6d5945d8e9e0c0b17792f2694.png"
      />
      <div class="text-wrapper_15">
        <span class="text_45">©︎</span>
        <!-- <span class="text_45">IF Mobile &nbsp; R2事業再構築より作成  </span> -->
      </div>
    </div>
    <!-- PC端 -->
    <div v-else class="flex-col">
      <div class="section_9 flex-col">
        <div class="group_6 flex-col">
          <div class="text-wrapper_11 flex-row">
            <span class="text_44">製品</span>
            <span class="text_45">IF会員</span>
            <span class="text_46">サポート</span>
            <span class="text_47">会社情報</span>
          </div>
          <div class="image-wrapper_2 flex-row justify-between">
            <img
              class="image_7"
              src="@/assets/lxl/SketchPng69f92a0f9fb4597171fc5f465c656026d44e5b65df081871cf4d11a7d33c94bf.png"
            />
            <img
              class="image_8"
              src="@/assets/lxl/SketchPng69f92a0f9fb4597171fc5f465c656026d44e5b65df081871cf4d11a7d33c94bf.png"
            />
            <img
              class="image_9"
              src="@/assets/lxl/SketchPng69f92a0f9fb4597171fc5f465c656026d44e5b65df081871cf4d11a7d33c94bf.png"
            />
            <img
              class="image_10"
              src="@/assets/lxl/SketchPng69f92a0f9fb4597171fc5f465c656026d44e5b65df081871cf4d11a7d33c94bf.png"
            />
          </div>
          <div class="text-wrapper_12 flex-row">
            <span class="text_48" @click="$router.push('/details/sim/section1')"
              >SIM</span
            >
            <span class="text_49" @click="$router.push('/details/vip/section1')"
              >IF会員</span
            >
            <span
              class="text_50"
              @click="$router.push('/details/servers/section1')"
              >Q&amp;A</span
            >
            <span class="text_51" @click="$router.push('/details/com/section1')"
              >運営会社</span
            >
          </div>
          <div class="text-wrapper_13 flex-row">
            <span
              class="text_52"
              @click="$router.push('/details/wifi/section1')"
              >Wi-Fi</span
            >
            <span
              class="text_53"
              @click="$router.push('/details/vip/section2')"
            >
              紹介制度</span
            >
            <span
              class="text_54"
              @click="$router.push('/details/servers/section2')"
              >本人確認</span
            >
            <span class="text_55" @click="$router.push('/details/com/section2')"
              >特定商取引法に基づく表記</span
            >
          </div>
          <div class="text-wrapper_14 flex-row">
            <span
              class="text_56"
              @click="$router.push('/details/light/section1')"
              >光回線</span
            >
            <span class="text_57" @click="$router.push('/details/vip/section3')"
              >提携店舗・施設</span
            >
            <span
              class="text_58"
              @click="$router.push('/details/servers/section3')"
              >設定方法</span
            >
            <span class="text_59" @click="$router.push('/details/com/section3')"
              >サービス約款</span
            >
          </div>
          <div class="text-wrapper_15 flex-row">
            <span class="text_60" @click="$router.push('/details/pro/section1')"
              >サービス・端末</span
            >
            <span class="text_61" @click="$router.push('/details/vip/section4')"
              >イベント掲載</span
            >
            <span
              class="text_62"
              @click="$router.push('/details/servers/section4')"
              >支払方法</span
            >
            <span class="text_63" @click="$router.push('/details/com/section4')"
              >プライバシーポリシー</span
            >
          </div>
          <div class="text-wrapper_15 flex-row">
            <span
              class="text_60"
              @click="
                goRouter(
                  $CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.SIM_CARD_ONLY
                )
              "
              >お申し込み</span
            >
            <span class="text_61" @click="$router.push('/details/vip/section5')"
              >IFポイント</span
            >
            <span class="text_62" @click="$router.push('/details/sim/section3')"
              >MNP</span
            >
            <!-- <span class="text_63" @click="$router.push('/details/com/section4')">個人情報の取り扱いについて</span> -->
          </div>
          <div class="text-wrapper_15 flex-row">
            <span class="text_60" @click=""> </span>
            <span class="text_61" @click="$router.push('/login')"
              >会員登録</span
            >
            <span class="text_62" @click=""> </span>
            <span class="text_63" @click=""> </span>
          </div>
          <img
            class="image_11"
            src="@/assets/lxl/SketchPnga4334af8b89a6c89191603981940beeaf7cb0bd792beffabced02b271e1a7cfd.png"
          />
          <div class="block_6 flex-row">
            <div class="group_7 flex-col"></div>
            <!-- <div class="group_8 flex-col"></div> -->
            <span class="text_64">follow us</span>
            <!-- <a class="image-wrapper_3 flex-col" href="https://www.facebook.com/IFMobile" target="_blank">
              <img
                class="label_7"
                src="@/assets/lxl/Facebook.svg"
              />
            </a> -->
            <a
              class="image-wrapper_4 flex-col"
              href="https://instagram.com/ifmobile2022?igshid=YmMyMTA2M2Y="
              target="_blank"
            >
              <img class="label_8" src="@/assets/lxl/ins.svg" />
            </a>
            <a
              class="image-wrapper_5 flex-col"
              href="https://twitter.com/ifmobile2023"
              target="_blank"
            >
              <img class="label_9" src="@/assets/lxl/twitter.svg" />
            </a>
            <a
              class="image-wrapper_6 flex-col"
              href="https://www.youtube.com/channel/UCNwKTiPECjDRu5OxRV4W1wQ"
              target="_blank"
            >
              <img class="label_10" src="@/assets/lxl/youtube.svg" />
            </a>
            <a
              class="image-wrapper_6 flex-col"
              href="https://www.xiaohongshu.com/user/profile/62b66ea2000000001902bfc5?xhsshare=CopyLink&appuid=62b66ea2000000001902bfc5&apptime=1678695122"
              target="_blank"
            >
              <img class="label_10" src="@/assets/lxl/xhs.svg" />
            </a>
            <!-- <div class="group_9 flex-col"></div> -->
            <a
              class="image-wrapper_7 flex-col"
              href="https://www.tiktok.com/@ifmobile1"
              target="_blank"
            >
              <img class="label_11" src="@/assets/lxl/tiktok.svg" />
            </a>
            <a
              class="image-wrapper_7 flex-col"
              href="https://www.tiktok.com/@ifmobile1"
              target="_blank"
            >
              <img class="label_11" src="@/assets/lxl/tiktok_g.svg" />
            </a>
            <!-- <div class="group_10 flex-row">
              <div class="image-text_5 flex-col justify-between">
                <img
                  class="thumbnail_4"
                  
                  src="@/assets/lxl/tiktok_g.svg"
                />
                <span class="text-group_9">tiktok</span>
              </div>
            </div> -->
            <a
              class="image-wrapper_8 flex-col"
              href="https://www.facebook.com/IFMobile"
              target="_blank"
            >
              <img class="image_12" src="@/assets/lxl/meta.svg" />
            </a>
          </div>
          <img
            class="image_13"
            src="@/assets/lxl/SketchPnga4334af8b89a6c89191603981940beeaf7cb0bd792beffabced02b271e1a7cfd.png"
          />
          <div class="block_7 flex-row justify-between">
            <div class="text-wrapper_16">
              <span class="text_65"
                >Copyright (C) 2023 SEIWA TRADING CO.,LTD. All Rights
                Reserved</span
              >
              <span class="text_66"></span>
              <!-- <span class="text_67" style="padding-left: 10px;">IF Mobile &nbsp; R2事業再構築より作成 </span> -->
            </div>
            <div class="lang">
              <!-- <b-dropdown
                size="sm"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template #button-content>
                  <span class="text_67">{{
                    languageItem[indexLanguage].name
                  }}</span>
                  <b-icon icon="chevron-down" scale="0.8"></b-icon>
                </template>
                <div
                  :key="indexLang"
                  v-for="(itemLang, indexLang) in languageItem"
                >
                  <b-dropdown-item
                    href="#"
                    v-if="indexLang != indexLanguage"
                    @click="changeLang(itemLang.value, indexLang)"
                    >{{ itemLang.name }}</b-dropdown-item
                  >
                </div>
              </b-dropdown> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      indexLanguage: 0,
      languageItem: [
        {
          name: this.$t("HEADER.HD14"),
          value: "ja",
        },
        {
          name: this.$t("HEADER.HD15"),
          value: "cn",
        },
        {
          name: this.$t("HEADER.HD16"),
          value: "en",
        },
        {
          name: this.$t("HEADER.HD17"),
          value: "vi",
        },
      ],
      loopData0: [
        {
          lanhuBg0: "rgba(255,255,255,1.000000)",
          lanhutext0: "音声SIM",
          lanhufontColor0: "rgba(45,42,40,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "モバイルWi-Fi",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "光回線",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "サービス • 端末",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
      ],
      loopData1: [
        {
          lanhuimage0: require("@/assets/lxl/SketchPngf27f876420d183d87f22e143903a602e7675d7c355281f41a047d1cbb8bf9d8d.png"),
          lanhutext0: "1GB",
          lanhutext1: "月額",
          lanhutext2: "税込",
          lanhutext3: "880",
          lanhutext4: "2968",
          lanhutext5: "円/月",
          lanhutext6: "円/月",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPngf27f876420d183d87f22e143903a602e7675d7c355281f41a047d1cbb8bf9d8d.png"),
          lanhutext0: "3GB",
          lanhutext1: "月額",
          lanhutext2: "税込",
          lanhutext3: "1280",
          lanhutext4: "1408",
          lanhutext5: "円/月",
          lanhutext6: "円/月",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPngf27f876420d183d87f22e143903a602e7675d7c355281f41a047d1cbb8bf9d8d.png"),
          lanhutext0: "5GB",
          lanhutext1: "月額",
          lanhutext2: "税込",
          lanhutext3: "1580",
          lanhutext4: "1738",
          lanhutext5: "円/月",
          lanhutext6: "円/月",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPngf27f876420d183d87f22e143903a602e7675d7c355281f41a047d1cbb8bf9d8d.png"),
          lanhutext0: "10GB",
          lanhutext1: "月額",
          lanhutext2: "税込",
          lanhutext3: "1980",
          lanhutext4: "2178",
          lanhutext5: "円/月",
          lanhutext6: "円/月",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPngf27f876420d183d87f22e143903a602e7675d7c355281f41a047d1cbb8bf9d8d.png"),
          lanhutext0: "15GB",
          lanhutext1: "月額",
          lanhutext2: "税込",
          lanhutext3: "2480",
          lanhutext4: "2728",
          lanhutext5: "円/月",
          lanhutext6: "円/月",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPngf27f876420d183d87f22e143903a602e7675d7c355281f41a047d1cbb8bf9d8d.png"),
          lanhutext0: "20GB",
          lanhutext1: "月額",
          lanhutext2: "税込",
          lanhutext3: "2980",
          lanhutext4: "3278",
          lanhutext5: "円/月",
          lanhutext6: "円/月",
        },
      ],
      constants: {},
    };
  },
  created() {
    for (const key in this.languageItem) {
      if (this.languageItem[key].value == this.$localStorage.get("language")) {
        this.indexLanguage = key;
      }
    }
  },
  methods: {
    goRouter(value) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$localStorage.set("menuItemHeader", value);
      this.$router.push("/order");
    },
    changeLang(value, index) {
      this.indexLanguage = index;
      this.$localStorage.set("language", value);
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>
