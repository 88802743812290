<template>
  <section>
    <h1 class="main_title">運営会社</h1>
    <b-card class="content__card">
      <div class="content">
        <div class="content__table">
          <table class="table">
            <thead>

            </thead>
            <tbody>
              <tr>
                <th>運営会社名</th>
                <td>星和通商株式会社 <br> SEIWA TRADING CO.,LTD</td>
              </tr>
              <tr>
                <td>IFモバイル <br> 事業部所在地</td>
                <td>〒169-0075 <br> 東京都新宿区高田馬場１丁目２８−３工新ビル 5階</td>
              </tr>
              <tr>
                <td>電話番号</td>
                <td>03-6383-4910</td>
              </tr>
              <tr>
                <td>代表者</td>
                <td>代表取締役社長　　宮沢 千秋</td>
              </tr>
              <tr>
                <td colspan="2">許認可・届出</td>
              </tr>
              <tr>
                <td>電気通信事業許可番号</td>
                <td>A-03-19030号</td>
              </tr>
              <tr>
                <td>古物商</td>
                <td>東京都公安委員会 <br> 第304372217419号</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-card>
  </section>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  mounted() {

  },

  created() {
    this.toTop();
  },
  computed: {},
  methods: {
    toTop: function () {
      window.scrollTo(0,0);
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  width: 80%;
  text-align: left;
  margin: auto;

  .main_title {
    max-width: 200px;
    border: none;
    text-decoration: underline;
    text-underline-offset: 10px;
    margin: auto;
    text-align: center;
  }

  .content__card {
    max-width: 800px;
    min-width: 200px;
    background: #fff;
    border-radius: 5px;
    border: 2px solid var(--primary);
    width: 80%;
    margin: 50px auto 50px;

    &__content {

      &__table {
        padding: 10px;
      }
    }
  }
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  width: 50%;
  border-top: none;
}

@media screen and (max-width: 1000px) {

  section {
    width: 100%;
  }

  .content__card {
    width: 90% !important;
  }
}

@media screen and (max-width: 390px) {
  .content__table {
    padding: 0;
  }

  .content__card {
    width: 100% !important;
  }
}
</style>
