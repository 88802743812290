
<template>
  <section class="datausage">
    <Change
      v-if="showPay"
      @return-contract="returnContract"
      :dataChange="dataChange"
    ></Change>
    <div v-if="!showPay" class="main-x">
      <div class="title-x card-x" style="margin-bottom: 10px; padding-top: 0">
        <img
          class="image_2"
          src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
        />
        <h1 class="text_17">
          {{ $t("DATA_USAGE.TITLE") }}
        </h1>
      </div>
      <div v-for="(item, index) in result" :key="index" class="content-x">
        <div class="section_14 flex-row">
          <div class="text-wrapper_10 flex-col justify-between">
            <span class="text_11">{{
              $t("DATA_USAGE.CONTACT_PHONE_NUMBER")
            }}</span>
            <span class="text_22">{{ item.phoneNo }}</span>
          </div>
          <div class="image-x">
            <img
              class="image_10"
              src="@/assets/lxl/SketchPng710facc492bcfddba5c1128ccc8539102e8c7c8d0ca34e543638f85786ddfe67.png"
            />
          </div>
          <div class="text-wrapper_10 flex-col justify-between">
            <span class="text_11">{{ $t("DATA_USAGE.DATA_PACKAGES") }}</span>
            <span class="text_22"
              >{{ item.simPlan }}{{ item.simPlan ? "" : "" }}</span
            >
          </div>
          <div class="text-wrapper_time">
            <span class="text_11">{{ $t("DATA_USAGE.UPDATE_DATE") }}</span>
            <span class="text_22">{{ date }}&nbsp;{{ time }}</span>
          </div>
        </div>
        <div class="content__chart">
          <div class="content__chart__pie">
            <div id="chart" class="chart-x">
              <vc-donut
                background="white"
                foreground="grey"
                :size="$store.state.isMobile ? 58 : 14.59"
                unit="vw"
                :thickness="22"
                has-legend
                legend-placement="top"
                :total="item.totalPacketData"
                :start-angle="0"
                :auto-adjust-text-size="true"
                :sections="[
                  {
                    label: $t('DATA_USAGE.TO_USE'),
                    value: item.availableData,
                    color: '#E2E5EB',
                  },
                  {
                    label: $t('DATA_USAGE.EXTANDT'),
                    value: item.residualData,
                    color: '#FF731A',
                  },
                ]"
                @section-click="handleSectionClick"
              >
                <p style="margin-bottom: 10px">{{ $t("DATA_USAGE.TOTAL") }}</p>
                <h4>{{ item.residualData }}GB</h4>
              </vc-donut>
              <div class="chart-x_text">
                <div class="chart-x_text-item">
                  <span>{{ $t("DATA_USAGE.DATA_USAGE_THIS_MONTH") }}</span>
                  <span>{{
                    $t("DATA_USAGE.ALL_DATA", { data: item.totalPacketData })
                  }}</span>
                </div>
                <div class="chart-x_text-item">
                  <span>{{ $t("DATA_USAGE.TO_USE") }}</span>
                  <span>{{ item.availableData }} GB</span>
                </div>
                <div class="chart-x_text-item">
                  <span>{{ $t("DATA_USAGE.EXTANDT") }}</span>
                  <span>{{ item.residualData }} GB</span>
                </div>
              </div>
            </div>
          </div>

          <div class="content__chart__message">
            {{ $t("DATA_USAGE.MESSAGE") }}
          </div>

          <div class="content__chart__btn">
            <button
              type="button"
              @click="showBuyLayout(index)"
              class="btn btn-primary"
            >
              {{ $t("DATA_USAGE.BTN") }}
              <i class="iconfont icon-z044"></i>
            </button>
          </div>

          <div
            v-show="buy.status && buy.index == index"
            class="content__chart__card"
          >
            <!-- <div v-show="buy.status && buy.index == index" class="content__chart__card__title">{{ $t("DATA_USAGE.PACKET_CHARGE.GUIDE") }}</div> -->
            <el-alert
              v-show="buy.status && buy.index == index"
              :title="$t('DATA_USAGE.PACKET_CHARGE.GUIDE')"
              type="warning"
              :show-icon="false"
              :closable="false"
              show-icon
            >
            </el-alert>
            <div class="mb-3">
              <h3 class="content__chart__card__title">
                {{ $t("DATA_USAGE.PACKET_CHARGE.TITLE") }}
              </h3>

              <div class="content__chart__card__input">
                <p>{{ $t("DATA_USAGE.PACKET_CHARGE.SIM_NUMBER") }}</p>
                <b-input v-model="item.phoneNo" disabled />
              </div>

              <div class="content__chart__card__input">
                <p>{{ $t("DATA_USAGE.PACKET_CHARGE.FEE_AMOUNT") }}</p>

                <div
                  class="flex-row align-center"
                  style="
                    background-color: #e9ecef;
                    flex: 1;
                    border-radius: 0.25rem;
                    padding: 0.375rem 0.75rem;
                  "
                >
                  <van-stepper
                    v-model="chageNum"
                    min="1"
                    max="10"
                    step="1"
                    @change="ChancePrice(index)"
                  />
                  <span class="text_1" style="margin-left: 10px">GB</span>
                </div>
              </div>

              <div class="content__chart__card__input">
                <p>{{ $t("DATA_USAGE.PACKET_CHARGE.AMOUNT_OF_MONEY") }}</p>
                <b-input
                  :value="tax_price(item.chargeUnitPrice * chageNum) + ' 円'"
                  disabled
                ></b-input>
              </div>

              <div
                style="
                  display: flex;
                  justify-content: center;
                  margin: 30px 0 15px;
                "
              >
                <b-button
                  style="margin: 0"
                  class="content__chart__card__btn btn-submit"
                  @click="
                    btnSubmit(
                      chageNum,
                      chagePrice,
                      item.phoneNo,
                      item.simMakeCd,
                      item.chargeUnitPrice
                    )
                  "
                  variant="warning mr-3"
                >
                  {{ $t("DATA_USAGE.PACKET_CHARGE.CONFIRM") }}
                </b-button>
                <b-button
                  style="margin: 0"
                  class="content__chart__card__btn"
                  @click="close()"
                  variant="danger ml-2"
                  >{{ $t("DATA_USAGE.PACKET_CHARGE.CANCEL") }}
                </b-button>
              </div>
            </div>
            <el-alert
              v-show="buy.status && buy.index == index"
              :title="$t('DATA_USAGE.PACKET_CHARGE.NOTICE')"
              :show-icon="false"
              type="warning"
              :closable="false"
            >
            </el-alert>
            <!-- <span >{{ $t("DATA_USAGE.PACKET_CHARGE.NOTICE") }}</span> -->
          </div>

          <div class="content__chart__bar">
            <div id="chart">
              <ag-charts-vue
                :options="{
                  theme: {
                    baseTheme: 'ag-default',
                    palette: {
                      fills: [
                        '#5C2983',
                        '#0076C5',
                        '#21B372',
                        '#FDDE02',
                        '#F76700',
                        '#D30018',
                      ],
                      strokes: ['black'],
                    },
                    overrides: {
                      cartesian: {
                        title: {
                          fontSize: 24,
                        },
                        series: {
                          column: {
                            label: {
                              enabled: true,
                              color: 'black',
                            },
                          },
                        },
                      },
                    },
                  },
                  title: {
                    text: $t('DATA_USAGE.BARCHART_TITLE'),
                  },
                  subtitle: {
                    text: 'in Gigabyte',
                  },
                  data: item.fourMonthsResidualDataPOJOList,
                  series: [
                    {
                      type: 'column',
                      xKey: 'month',
                      yKeys: ['exploitData'],
                      yNames: ['GIGABYTE'],
                    },
                  ],
                }"
              ></ag-charts-vue>
            </div>
            <el-alert
              style="text-align: left"
              title="3日5GB超た場合には、低速モードになります。（速度制限は当日0時に解除）"
              :show-icon="false"
              type="warning"
              :closable="false"
            ></el-alert>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { AgChartsVue } from "ag-charts-vue";
import Change from "../Change/index.vue";
export default {
  name: "chart",
  components: { "ag-charts-vue": AgChartsVue, Change },
  data() {
    return {
      date: "",
      time: "",
      buy: {
        index: 99,
        status: false,
      },
      chagePrice: 888,
      dataUsage: {},
      result: {},
      chageNum: 1,
      options: null,
      dataChange: {
        change: {
          changePrice: 0,
          revenueSummary: this.$t("DATA_USAGE.REVENUESUMMARY"),
          orderType: 0,
        },
        dataAdd: {},
        tax: 0,
        type: 2,
      },
      showPay: false,
    };
  },
  created() {
    this.getData();
  },
  computed: {},
  watch: {},
  methods: {
    handleSectionClick(section, event) {
      console.log(`${section.label} clicked.`);
    },
    ChancePrice(index) {
      this.chagePrice = this.result[index].chargeUnitPrice * this.chageNum;
    },

    close() {
      this.buy.status = false;
    },

    printDate: function () {
      return new Date().toLocaleDateString();
    },

    printTime: function () {
      return new Date().toLocaleTimeString();
    },
    btnSubmit(chageNum, chagePrice, phoneNo, simMakeCd, chargeUnitPrice) {
      this.dataChange.dataAdd = {
        chageNum: chageNum,
        chagePrice: chagePrice,
        email: window.localStorage.getItem("email"),
        phoneNo: phoneNo,
        simMakeCd: simMakeCd,
        workType: "0",
      };
      this.dataChange.change = {
        changePrice: chargeUnitPrice * chageNum,
        orderType: 0,
        id: phoneNo,
        revenueSummary: this.dataChange.change.revenueSummary,
      };
      this.showPay = true;
      this.toTop();
    },
    getData() {
      if (this.$CONST.DEV) {
        this.result = this.$CONST.COMMON.API_DATA_USAGE.Response["result"];
      } else {
        this.$api
          .post("packetMonthlyAmountResAl/list", {
            language: window.localStorage.getItem("languageCode"),
            email: window.localStorage.getItem("email"),
          })
          .then((response) => {
            this.result = response.result;
            this.$emit("setData", {
              result: this.result[0].residualData,
              page: 4,
            });
          });
        this.$api
          .post("simPurchase/init", {
            language: window.localStorage.getItem("languageCode"),
          })
          .then((response) => {
            this.dataChange.tax = response.result.taxrate;
          });
      }
    },
    tax_price: function (value) {
      return parseInt(value + (value * parseFloat(this.dataChange.tax)) / 100);
    },
    showBuyLayout(index) {
      if (this.buy.index == index && this.buy.status) {
        this.buy.status = false;
      } else {
        this.buy.index = index;
        this.buy.status = true;
        this.chageNum = 1;
      }
    },
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        filename: "i-was-html.pdf",
      });
    },
    returnContract() {
      this.showPay = false;
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted: function () {
    this.date = this.printDate();
    this.time = this.printTime();
  },
};
</script>


<style lang="scss" scoped>
section {
  .form_title {
    margin-bottom: 5%;
  }

  .btn-warning,
  .btn-danger {
    color: #fff;
    background-color: var(--newColor);
    border-color: var(--newColor);
  }

  .btn-danger:hover {
    background-color: var(--success);
    border-color: var(--success);
  }

  .btn-warning:hover {
    background-color: var(--success);
    border-color: var(--success);
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>
