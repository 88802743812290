import axios from "axios";
import router from "@/router/index";
import Vue from "vue";

function apiAxios(method, url, params) {
  // const uri = 'https://632294d0362b0d4e7dcf47ae.mockapi.io/api/'
  const uri = "https://www.ifmobile.jp//ifm/";

  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: uri + url,
      data: method === "POST" || method === "PUT" ? params : null,
      params: method === "GET" ? params : null,
      headers: {
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods":
        //   "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        // "Content-Type": "application/json;charset=UTF-8/multipart/form-data",
        "X-Access-Token": window.localStorage.getItem("token"),
        languageCode: window.localStorage.getItem("languageCode"),
      },
      withCredentials: false,
    })
      .then(
        function (response) {
          resolve(response.data);
        },
        function (res) {
          if (res.code == "ERR_NETWORK") {
            router.replace("/err");
            return false;
          }
          if (
            res.response.status == 400 ||
            res.response.status == 401 ||
            res.response.status == 404 ||
            res.response.status == 500
          ) {
            if (res.response.data.message == "Token失效，请重新登录") {
              var token = localStorage.getItem("token");
              if (token) {
                localStorage.removeItem("token");
                localStorage.removeItem("accountBy");
                localStorage.removeItem("name");
                localStorage.removeItem("nameKana");
                localStorage.removeItem("roleId");
                localStorage.removeItem("userId");
                localStorage.removeItem("email");
                localStorage.removeItem("userIdCode");

                sessionStorage.setItem("loginComplete", 1);
              }
              router.replace("/login");
            } else {
              router.replace("/err");
            }
          }
          if (res.code == 1010) {
            localStorage.removeItem("token");
            localStorage.removeItem("accountBy");
            localStorage.removeItem("name");
            localStorage.removeItem("nameKana");
            localStorage.removeItem("roleId");
            localStorage.removeItem("userId");

            sessionStorage.setItem("loginComplete", 1);

            this.$router.push("/login");
          }
          reject(res);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}

export default {
  get: function (url, params) {
    return apiAxios("GET", url, params);
  },
  post: function (url, params) {
    return apiAxios("POST", url, params);
  },
  put: function (url, params) {
    return apiAxios("PUT", url, params);
  },
};
