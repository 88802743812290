
import Vue from 'vue';
import Vuex from 'vuex';
import app from '../main'
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    isMobile: false,
    return: {
      workStep: 1,
    }
  },

  // mutations: {
  //   setSteps(state, payload) {
  //     state.workStep = payload;
  //   }
    mutations : {
      SET_LANG (state, payload) {
        app.vm.locale = payload
      }
    },
    
    actions : {
      setLang({commit}, payload) {
        commit('SET_LANG', payload)
      }
    }
});
