<template>
  <div class="">
    <!-- mob -->
    <div v-if="$store.state.isMobile" class="group_16 flex-col justify-end">
      <span class="text_46">{{ $t("INDEX.PLAN.CONTENT") }}</span>
      <span class="text_47">{{ $t("INDEX.PLAN.TITLE") }}</span>
      <p class="text_48">
        {{ $t("INDEX.PLAN.TITLE_TEXT1") }}<br />
        <span style="color: red">{{ $t("INDEX.PLAN.TITLE_TEXT2") }}</span>
        <br />
        <span style="color: red">{{ $t("INDEX.PLAN.TITLE_TEXT2_1") }}</span>
        <!-- <span style="color: red; font-size: 4.2vw; font-weight: bolder;">{{ '人気!' }}</span>   -->
      </p>
      <div class="group_17 flex-row justify-between">
        <template v-for="(v, i) in loopData0">
          <div v-if="curIndex == i" class="group_18 flex-col">
            <div class="section_6 flex-col"></div>
            <span class="text_49">{{ v.lanhutext0 }}</span>
          </div>
          <div v-else class="text-wrapper_18 flex-col" @click="curIndex = i">
            <span class="text_52">{{ v.lanhutext0 }}</span>
          </div>
        </template>
      </div>
      <div v-for="(tab, i) in curTabData" :key="i" class="group_19 flex-col">
        <div class="text-wrapper_19 flex-col">
          <!-- <span class="text_53">{{ tab.skuName }}</span> -->
        </div>
        <div class="list_2 flex-col">
          <div
            class="list-items_2 flex-row"
            v-for="(item, index) in tab.data"
            :key="i + '-' + index"
          >
            <img class="thumbnail_10" :src="item.lanhuimage0" />
            <div class="text-group_9 flex-col justify-between">
              <span class="text_54" v-html="item.lanhutext0"></span>
              <span
                v-if="item.lanhutext3 >= 0"
                class="text_55"
                v-html="i == 1 ? '' : item.lanhutext1"
              ></span>
            </div>
            <span
              v-if="item.lanhutext3 >= 0"
              class="text_56"
              v-html="item.lanhutext2"
            ></span>
            <div class="text-wrapper_20 flex-col justify-between">
              <span
                :style="{
                  'text-align': Number(item.lanhutext3) >= 0 ? 'right' : 'left',
                }"
                class="text_57"
                >{{ item.lanhutext3 }}</span
              >
              <span
                v-if="item.lanhutext3 >= 0"
                class="text_58"
                v-html="tax_included(item.lanhutext4)"
              ></span>
            </div>
            <div
              v-if="item.lanhutext3 >= 0"
              class="text-wrapper_21 flex-col justify-between"
            >
              <span
                class="text_59"
                v-html="i == 1 ? item.lanhutext5 : item.lanhutext5 + '/月'"
              ></span>
              <span
                class="text_60"
                v-html="i == 1 ? item.lanhutext6 : item.lanhutext6 + '/月'"
              ></span>
            </div>
          </div>
        </div>
        <button class="button_3 flex-col" @click="goRouter(tab.link)">
          <span class="text_21">{{ $t("BUTTON.CHOOSE") }}</span>
          <img class="image_3" src="@/assets/lxl/jinru_b.png" />
        </button>
        <div class="text-wrapper_22 flex-col">
          <span class="text_61">{{ curTabData[i].title }}</span>
          <span v-if="i == 0 && curIndex != 3" class="text_61_1">月额料金</span>
        </div>
      </div>
    </div>
    <!-- pc -->
    <div v-else class="section_9 flex-col">
      <span class="text_23">{{ $t("INDEX.PLAN.CONTENT") }}</span>
      <span class="text_24">{{ $t("INDEX.PLAN.TITLE") }}</span>
      <p class="text_25">
        {{ $t("INDEX.PLAN.TITLE_TEXT1") }}<br />
        <span style="color: red">{{ $t("INDEX.PLAN.TITLE_TEXT2") }}</span>
        <span style="color: red">{{ $t("INDEX.PLAN.TITLE_TEXT2_1") }}</span>
      </p>
      <div class="list_1 flex-row">
        <div
          class="text-wrapper_6 flex-col"
          :style="{
            background:
              curIndex == index ? '#fff' : 'rgba(112,103,97,1.000000)',
          }"
          v-for="(item, index) in loopData0"
          :key="index"
          @click="curIndex = index"
        >
          <div
            v-if="curIndex == index"
            class="group_4 flex-col"
            style="transform: translateY(-0.28vw)"
          ></div>
          <span
            class="text_26"
            :style="{
              color:
                curIndex == index ? 'rgba(45,42,40,1)' : 'rgba(255,255,255,1)',
            }"
            v-html="item.lanhutext0"
          ></span>
        </div>
      </div>
      <div v-for="(tab, i) in curTabData" :key="i" class="group_5 flex-col">
        <div class="text-wrapper_7 flex-col">
          <!-- <span class="text_27">{{ tab.skuName }}</span> -->
        </div>
        <div class="grid_1 flex-row">
          <div
            class="flex-row"
            :class="[tab.col == 3 ? 'list-items_1' : 'list-items_2']"
            v-for="(item, index) in tab.data"
            :key="index"
          >
            <img class="thumbnail_2" :src="item.lanhuimage0" />
            <div class="text-group_5 flex-col justify-between">
              <span class="text_28">{{ item.lanhutext0 }}</span>
              <span v-if="item.lanhutext3 >= 0" class="text_29">{{
                i == 1 ? "" : item.lanhutext1
              }}</span>
            </div>
            <span v-if="item.lanhutext3 >= 0" class="text_30">{{
              item.lanhutext2
            }}</span>
            <div class="text-group_6 flex-col justify-between">
              <span
                class="text_31"
                :style="{ margin: item.lanhutext3 >= 0 ? '' : '0 1vw' }"
                >{{ item.lanhutext3 }}</span
              >
              <span v-if="item.lanhutext3 >= 0" class="text_32">{{
                tax_included(item.lanhutext4)
              }}</span>
            </div>
            <div
              v-if="item.lanhutext3 >= 0"
              class="text-wrapper_8 flex-col justify-between"
            >
              <span class="text_33">{{
                i == 1 ? item.lanhutext5 : item.lanhutext5 + "/月"
              }}</span>
              <span class="text_34">{{
                i == 1 ? item.lanhutext6 : item.lanhutext6 + "/月"
              }}</span>
            </div>
          </div>
        </div>
        <div
          class="block_5 flex-row justify-between"
          @click="goRouter(tab.link)"
        >
          <span class="text_42">{{ $t("BUTTON.CHOOSE") }}</span>
          <img class="image_6" src="@/assets/lxl/jinru_b.png" />
        </div>
        <div class="text-wrapper_10 flex-col">
          <span class="text_43">{{ curTabData[i].title }}</span>
          <span v-if="i == 0 && curIndex != 3" class="text_43_1">月额料金</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      curIndex: 0,
      taxrate: "",
      options: [],
      simPlan: [],
      optpPlan: [],
      wifiPlan: [],
      simPlanOption: this.$t("PLAN.OPTION1"),
      simPlanOption1: this.$t("PLAN.OPTION2"),
      loopData0: [
        {
          lanhuBg0: "rgba(255,255,255,1.000000)",
          lanhutext0: this.$t("PLAN.PL54") + this.$t("MENU.SIM_ORDER"),
          lanhufontColor0: "rgba(45,42,40,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: this.$t("PLAN.PL55") + this.$t("MENU.WIFI_SERVICE"),
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: this.$t("MENU.OTP_SERVICE"),
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: this.$t("MENU.SELL_SERVICE"),
          lanhufontColor0: "rgba(255,255,255,1)",
        },
      ],
      constants: {},
      tabs: {
        simPlan: [
          {
            name: "simPlan",
            title: this.$t(
              "SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.SIM_CARD_PURCHASE_DETAILS.PLAN"
            ),
            skuName: this.$t("INDEX.PLAN.MONTHLY_FEE"),
            data: [],
            col: 3,
            link: this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET
              .SIM_CARD_ONLY,
          },
        ],
        wifiPlan: [
          {
            name: "wifiPlan",
            title: this.$t(
              "SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.SIM_CARD_PURCHASE_DETAILS.PLAN"
            ),
            skuName: this.$t("INDEX.PLAN.MONTHLY_FEE"),
            data: [],
            col: 3,
            link: this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET
              .WIFI_ONLY,
          },
          {
            name: "rentalWifi",
            title: this.$t("RENT_WIFI.TITLE"),
            skuName: this.$t("INDEX.PLAN.MONTHLY_FEE"),
            data: [],
            col: 2,
            link: this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET
              .RENT_WIFI,
          },
        ],
        optpPlan: [
          {
            name: "optpPlan",
            title: this.$t("INDEX.PLAN.PLAN_BASIC"),
            skuName: this.$t("INDEX.PLAN.MONTHLY_FEE"),
            data: [],
            col: 2,
            link: this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET
              .LIGHT_ONLY,
          },
        ],
        phonePlan: [
          {
            name: "phonePlan",
            title: this.$t("INDEX.PLAN.SELL_PHONE.CONTENTS"),
            skuName: this.$t("INDEX.PLAN.BENEFITS"),
            data: [
              {
                lanhuimage0: require("@/assets/lxl/SketchPngf27f876420d183d87f22e143903a602e7675d7c355281f41a047d1cbb8bf9d8d.png"),
                lanhutext0: this.$t("PLAN.PL56"),
                lanhutext1: this.$t("PLAN.PL46"),
                lanhutext2: "税込",
                lanhutext3: this.$t("PLAN.PL57"),
                lanhutext4: 20000,
                lanhutext5: "円/月",
                lanhutext6: "円/月",
              },
              {
                lanhuimage0: require("@/assets/lxl/SketchPngf27f876420d183d87f22e143903a602e7675d7c355281f41a047d1cbb8bf9d8d.png"),
                lanhutext0: this.$t("PLAN.PL58"),
                lanhutext1: this.$t("PLAN.PL50"),
                lanhutext2: "税込",
                lanhutext3: this.$t("PLAN.PL59"),
                lanhutext4: 98000,
                lanhutext5: "円/月",
                lanhutext6: "円/月",
              },
            ],
            col: 2,
            link: this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET
              .GENERAL_MERCHANDISE,
          },
        ],
      },
    };
  },
  computed: {
    curTabData() {
      let name = Object.keys(this.tabs)[this.curIndex];
      return this.tabs[name];
    },
  },
  created() {
    this.getData();
    this.options = this.$CONST.BUYER.SIM_PLAN.IFM_COMMODITY;
    this.options1 = this.$t("SIM_SERVICE.STEP2_CHOOSE_CABLE.IFM_COMMODITY");
    this.getTax();
  },
  methods: {
    goRouter(value) {
      this.toTop();
      console.log("đ", this.simPlanOption);
      this.$localStorage.set("menuItemHeader", value);
      this.$router.push("/order");
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    tax_included: function (value) {
      return parseInt(value + (value * parseFloat(this.taxrate)) / 100);
    },
    getData() {
      const arrStur1 = (arr) => {
        return arr.map((item) => {
          return {
            lanhuimage0: require("@/assets/lxl/SketchPngf27f876420d183d87f22e143903a602e7675d7c355281f41a047d1cbb8bf9d8d.png"),
            lanhutext0: item.commodityName,
            lanhutext1: " ",
            lanhutext2: "税込",
            lanhutext3: item.price,
            lanhutext4: item.price,
            lanhutext5: "円",
            lanhutext6: "円",
          };
        });
      };
      this.$api
        .post("simPurchase/init", {
          language: window.localStorage.getItem("languageCode"),
        })
        .then((response) => {
          const { amountPlan, optPlans, wifiPlans, rentalWifi } =
            response.result;
          this.tabs.simPlan[0].data = arrStur1(amountPlan);
          this.tabs.optpPlan[0].data = arrStur1(optPlans);
          this.tabs.wifiPlan[0].data = arrStur1(wifiPlans);
          this.tabs.wifiPlan[1].data = arrStur1(rentalWifi);
          // console.log(this.tabs)
          // this.simPlan = response.result.amountPlan;
          // this.optpPlan = response.result.optPlans;
          // this.wifiPlan = response.result.wifiPlans;
        });
    },
    getTax() {
      this.$api
        .post("simPurchase/init", {
          language: window.localStorage.getItem("languageCode"),
        })
        .then((response) => {
          this.taxrate = response.result.taxrate;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>
