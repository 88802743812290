export const getList = (vm)=>[
    {
      name: vm.$t("MENU.SIM_SERVICE"),
      value: vm.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.SIM_CARD_ONLY,
      url: "/details/sim/section1",
      type: 0,
      number: 0,
      children: [
        {
          name: 'IF SIMの特徴',
          value: '',
          url: '',
          number: 0,
          path: '/details/sim/section1',
        },
        {
          name: '料金・プラン',
          value: '',
          url: '',
          number: 0,
          path: '/details/sim/section2',
        },
        {
          name: 'MNP',
          value: '',
          url: '',
          number: 0,
          path: '/details/sim/section3',
        },
        {
          name: '初期設定',
          value: '',
          url: '',
          number: 0,
          path: '/details/sim/section4',
        },
        {
          name: '支払方法',
          value: '',
          url: '',
          number: 0,
          path: '/details/sim/section5',
        },
        {
          name: 'ご利用開始までの流れ',
          value: '',
          url: '',
          number: 0,
          path: '/details/sim/section6',
        },
      ]
    },
    {
      name: vm.$t("MENU.WIFI_SERVICE"),
      value: vm.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.WIFI_ONLY,
      url: "/details/wifi/section1",
      number: 1,
      type: 0,
      children: [
        {
          name: 'IF Wi-Fi特徴',
          value: '11',
          url: '',
          number: 0,
          path: '/details/wifi/section1',
        },
        {
          name: '一括購入',
          value: '11',
          url: '',
          number: 0,
          path: '/details/wifi/section2',
        },
        {
          name: 'レンタル',
          value: '11',
          url: '',
          number: 0,
          path: '/details/wifi/section3',
        },
      ]
    },
    {
      name: vm.$t("MENU.OTP_SERVICE"),
      value: vm.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.LIGHT_ONLY,
      url: "/details/light/section1",
      type: 0,
      number: 2,
      children: [
        {
          name: 'IF光回線特徴',
          value: '',
          url: '',
          number: 0,
          path: '/details/light/section1',
        },
        {
          name: '料金・プラン',
          value: '',
          url: '',
          number: 0,
          path: '/details/light/section2',
        },
      ]
    },

    {
      name: vm.$t("MENU.TERMINAL"),
      value: '',
      url: "/details/pro/section1",
      type: 0,
      number: 3,
      children: [
        {
          name: 'サービス一覧',
          path: '/details/pro/section1',
        },
        {
          name: '端末一覧',
          path: '/details/pro/section2',
        },
      ]
    },

    {
      name: vm.$t("MENU.MYPAGE"),
      value: 2,
      url: "/mypage",
      type: 1,
      number: 4,
      children: [],
    },
    {
      name: vm.$t("FOOTER.TITLE2.LINK1"),
      value: 4,
      url: "/details/vip/section1",
      type: 0,
      number: 5,
      children: [
        {
          name: 'IF 会員',
          path: '/details/vip/section1',
        },
        {
          name: '紹介制度',
          path: '/details/vip/section2',
        },
        {
          name: '提携店舗・施設',
          path: '/details/vip/section3',
        },
        {
          name: 'イベント掲載',
          path: '/details/vip/section4',
        },
        {
          name: 'IFポイント',
          path: '/details/vip/section5',
        },
      ],
    },
    {
      name: vm.$t("FOOTER.TITLE3.TITLE"),
      value: 5,
      url: "/details/servers/section1",
      type: 0,
      number: 6,
      children: [
        {
          name: 'Q＆A',
          path: '/details/servers/section1',
        },
        {
          name: '本人確認',
          path: '/details/servers/section2',
        },
        {
          name: '本人確認書類にてよくある不備',
          path: '/details/servers/section3',
        },
        {
          name: '窓口',
          path: '/details/servers/section4',
        },
        // {
        //   name: 'SNS',
        //   path: '/details/servers/section5',
        // },
      ],
    },
    {
      name: vm.$t("MENU.INVESTIGATION"),
      value: 6,
      url: "/inquiry",
      type: 0,
      number: 7,
      children: [],
    },
    {
      name: vm.$t("BUTTON.LOGOUT"),
      value: 7,
      url: "/login",
      type: 0,
      number: 8,
      children: [],
    },
    {
      name: vm.$t("BUTTON.LOGIN"),
      value: 8,
      url: "/login",
      type: 0,
      number: 8,
      children: [],
    },
  ]