<template>
  <section class="main">
    <div>
      <img
        class="banner__logo"
        :class="{ change: show }"
        style="
          position: absolute;
          width: 10%;
          font-size: 16px;
          margin-left: 75%;
          margin-top: -7%;
          transition: width 2s, transform 2s, margin-left 2s;
        "
        src="@/assets/assets/images/header_flowerpot.png"
      />
    </div>

    <div style="text-align: left">
      <img
        src="@/assets/assets/images/header_penpot.png"
        :class="{ change1: show }"
        style="
          width: 10%;
          position: absolute;
          z-index: 1;
          margin-top: -17%;
          margin-left: -45%;
          transition: width 2s, transform 2s, margin-left 2s;
        "
      />
    </div>

    <div
      class="banner__logo"
      :class="{ changeTitle: show }"
      v-show="showTitle1"
      style="
        width: 100%;
        font-size: 250px;
        font-weight: bold;
        transition: font-size 3s;
      "
    >
      <p class="banner__left">{{ $t("INDEX.LOGO.ANIMATION1") }}</p>
    </div>
    <SlideYUpTransition :duration="2000" :delay="500" mode="out-in">
      <div
        class="banner__logo"
        :class="{ changeTitle: show }"
        v-show="showTitle2"
        style="
          width: 100%;
          font-size: 250px;
          font-weight: bold;
          transition: font-size 3s;
        "
      >
        <p class="banner__left">{{ $t("INDEX.LOGO.ANIMATION2") }}</p>
      </div>
    </SlideYUpTransition>

    <SlideYUpTransition :duration="2000" :delay="500" mode="out-in">
      <div
        class="banner__logo"
        :class="{ changeTitle: show }"
        v-show="showTitle3"
        style="
          width: 100%;
          font-size: 250px;
          font-weight: bold;
          transition: font-size 3s;
        "
      >
        <p class="banner__left">
          {{ $t("INDEX.LOGO.ANIMATION3") }}
          <!-- <span class="ntt" style="color: red; font-size: 24px">Docomo</span> -->
        </p>
      </div>
    </SlideYUpTransition>

    <SlideYUpTransition :duration="1500" :delay="500" mode="out-in">
      <div class="banner__content" v-show="show1">
        <p style="font-size: 20px">{{ $t("INDEX.SLOGAN") }}</p>
        <div class="banner__content__text"></div>
      </div>
    </SlideYUpTransition>

    <div class="box-box"></div>
    <div class="banner__button">
      <b-button
        variant="success"
        v-show="true"
        class="btn-main"
        @click="showBtn = !showBtn"
      >
        {{ $t("BUTTON.CHOOSE") }}
      </b-button>
      <br />
      <ZoomCenterTransition :duration="400" :delay="200" mode="out-in">
        <b-button
          variant="success"
          class="btn-option"
          v-show="showBtn"
          @click="
            goRouter(
              $CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.SIM_CARD_ONLY
            )
          "
        >
          {{ $t("MENU.SIM_ORDER") }}
        </b-button>
      </ZoomCenterTransition>
      <ZoomCenterTransition :duration="400" :delay="400" mode="out-in">
        <b-button
          variant="success"
          class="btn-option"
          v-show="showBtn"
          @click="
            goRouter($CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.WIFI_ONLY)
          "
        >
          {{ $t("MENU.WIFI_SERVICE") }}
        </b-button>
      </ZoomCenterTransition>
      <ZoomCenterTransition :duration="400" :delay="600" mode="out-in">
        <b-button
          variant="success"
          class="btn-option"
          v-show="showBtn"
          @click="
            goRouter($CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.LIGHT_ONLY)
          "
        >
          {{ $t("MENU.OTP_SERVICE") }}
        </b-button>
      </ZoomCenterTransition>
      <ZoomCenterTransition :duration="400" :delay="800" mode="out-in">
        <b-button
          variant="success"
          class="btn-option"
          v-show="showBtn"
          @click="
            goRouter(
              $CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET
                .GENERAL_MERCHANDISE
            )
          "
        >
          {{ $t("MENU.SELL_SERVICE") }}
        </b-button>
      </ZoomCenterTransition>
    </div>

    <div style="text-align: right">
      <img
        src="@/assets/assets/images/header_pen.png"
        :class="{ change2: show }"
        style="
          width: 5%;
          margin-right: -10%;
          margin-top: 0%;
          transition: width 2s, transform 2s, margin-left 2s;
        "
      />
      <img
        src="@/assets/assets/images/header_tablet.png"
        :class="{ change3: show }"
        style="
          width: 10%;
          z-index: -1;
          margin-bottom: -20%;
          margin-right: -30%;
          transition: width 2s, transform 2s, margin-left 2s;
        "
      />
    </div>

    <div class="banner__logo" v-show="show" style="text-align: left">
      <img
        src="@/assets/assets/images/header_papers.png"
        :class="{ change4: show }"
        style="
          width: 56%;
          z-index: -1;
          margin-top: -49%;
          margin-left: -60%;
          margin-bottom: -28%;
          transition: width 2s, transform 2s, margin-left 2s;
        "
      />
      <img
        src="@/assets/assets/images/header_marker.png"
        :class="{ change5: show }"
        style="
          width: 13%;
          z-index: -1;
          margin-top: -77%;
          margin-left: -60%;
          margin-bottom: -28%;
          transition: width 2s, transform 2s, margin-left 2s;
        "
      />
      <img
        src="@/assets/assets/images/header_edding.png"
        :class="{ change6: show }"
        style="
          width: 18%;
          z-index: -1;
          margin-top: -49%;
          margin-left: -60%;
          margin-bottom: -28%;
          transition: width 2s, transform 2s, margin-left 2s;
        "
      />
      <img
        src="@/assets/assets/images/header_paperclip.png"
        :class="{ change7: show }"
        style="
          width: 4%;
          z-index: -1;
          margin-top: -78%;
          margin-left: -60%;
          margin-bottom: -28%;
          transition: width 2s, transform 2s, margin-left 2s;
        "
      />
      <img
        src="@/assets/assets/images/header_paperclip.png"
        :class="{ change8: show }"
        style="
          width: 4%;
          z-index: -1;
          margin-top: -60%;
          margin-left: -10%;
          margin-bottom: -28%;
          transition: width 2s, transform 2s, margin-left 2s;
        "
      />
    </div>
  </section>
</template>
<script>
import {
  SlideYUpTransition,
  SlideXLeftTransition,
  SlideXRightTransition,
  ZoomCenterTransition,
} from "vue2-transitions";
export default {
  name: "app",
  components: {
    SlideYUpTransition,
    SlideXLeftTransition,
    SlideXRightTransition,
    ZoomCenterTransition,
  },
  data() {
    return {
      show: false,
      show1: false,
      showBtn: true,
      showTitle1: true,
      showTitle2: false,
      showTitle3: false,
    };
  },
  created() {
    setTimeout(this.timeOut, 500);
    setTimeout(this.timeOut1, 3000);
    setTimeout(this.timeOut2, 3000);
    setTimeout(this.timeOut3, 5000);
  },
  methods: {
    goRouter(value) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$localStorage.set("menuItemHeader", value);
      this.$router.push("/order");
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    timeOut() {
      this.show = true;
      this.show1 = true;
    },
    timeOut1() {
      this.showTitle1 = false;
    },
    timeOut2() {
      this.showTitle2 = true;
    },
    timeOut3() {
      this.showTitle2 = false;
      setTimeout(() => {
        this.showTitle3 = true;
      }, 2510);
    },
  },
};
</script>
<style lang="scss" scoped>
.changeTitle {
  font-size: 150px !important;
}

.change {
  margin-left: 20% !important;
  width: 30% !important;
  transition: width 2s, transform 2s, margin-left 2s;
  transition-timing-function: ease-in;
  // transform: rotate(90deg);
}

.change1 {
  margin-left: -28% !important;
  width: 41% !important;
  transition: width 2s, transform 2s, margin-left 2s;
  // transform: rotate(90deg);
}

.change2 {
  margin-right: -10% !important;
  width: 19% !important;
  transition: width 2s, transform 2s, margin-right 2s;
  // transform: rotate(90deg);
}

.change3 {
  margin-right: -30% !important;
  width: 59% !important;
  transition: width 2s, transform 2s, margin-right 2s;
  // transform: rotate(90deg);
}

//
.change4 {
  margin-left: -3% !important;
  width: 32% !important;
  transition: width 2s, transform 2s, margin-right 2s;
  // transform: rotate(90deg);
}

.change5 {
  margin-left: -25% !important;
  width: 13% !important;
  transition: width 2s, transform 2s, margin-right 2s;
  // transform: rotate(90deg);
}

.change6 {
  margin-left: -10% !important;
  width: 18% !important;
  transition: width 2s, transform 2s, margin-right 2s;
  // transform: rotate(90deg);
}

.change7 {
  margin-left: -5% !important;
  width: 4% !important;
  transition: width 2s, transform 2s, margin-right 2s;
  // transform: rotate(90deg);
}

.change8 {
  margin-left: -10% !important;
  width: 4% !important;
  transition: width 2s, transform 2s, margin-right 2s;
}

section {
  background: rgb(249, 249, 249);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 115vh;
  padding: 8%;
  overflow: hidden;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.banner {
  &__logo {
    margin-top: 75px;
    align-items: center;
  }

  &__left {
    font-size: 71px;
    color: var(--newColor);
  }

  &__button {
    line-height: 33px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;

    .btn-main {
      max-width: 19px;
      min-width: 180px;
      font-size: 26px;
      background-color: var(--success);
      border-width: 0px;
      border-radius: 5px;
      margin: 10px;
    }

    .btn-option {
      min-width: 150px;
      font-size: 22px;
      background-color: var(--newColor);
      border-width: 0px;
      border-radius: 5px;
      margin: 10px;
    }

    :hover {
      background-color: var(--success);
    }
  }

  &__content {
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 45px;
      text-align: center;
      align-items: center;
      color: #000000;
      margin-bottom: 2rem;
    }

    &__text {
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 40px;
        text-align: center;
        color: #000000;
        margin: 0 100px;
        margin: 2rem 10% 2rem 10%;
      }
    }
  }
}

@media (max-width: 1400px) {
  section {
    height: calc(100vh - 100px) !important;
  }

  .banner {
    button {
      width: 20%;
    }
  }
}

@media (max-width: 800px) {
  .banner__left {
    font-size: 50px;
  }

  .banner__button .btn-option {
    min-width: 120px;
    background-color: var(--newColor);
    border-width: 0px;
    border-radius: 5px;
    margin: 10px 5px;
  }

  .btn-main {
    font-size: 20px !important;
  }

  .changeTitle {
    font-size: 80px !important;
  }
}

@media (max-height: 680px) {
  .banner__left {
    margin-bottom: 0;
  }

  .banner__content p {
    margin-bottom: 0;
  }
}

@media (max-width: 600px) {
  .banner {
    display: flex;
    justify-content: center;

    &__content {
      p {
        font-size: 24px;
      }
    }

    button {
      width: 40% !important;
    }
  }

  .banner__button .btn-option {
    min-width: 135px;
    font-size: 16px;
  }

  .btn-link {
    margin-top: -50%;
  }
}

@media (max-width: 400px) {
  .changeTitle {
    font-size: 50px !important;
  }

  .banner__content p {
    font-size: 17px !important;
  }

  .box-box {
    width: 30px;
    height: 50px;
  }

  .banner__button {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50px);

    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 0;
    margin: auto;
  }
}

.banner .banner__logo {
  animation: slide1 4.5s 1;
  position: relative;
  animation-delay: 1.5s;
  animation-timing-function: ease;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.banner .banner__content {
  animation: slide2 3.5s 1;
  position: relative;
  animation-delay: 1s;
  animation-timing-function: ease;
  animation-duration: 1s;
  animation-fill-mode: both;
}
</style>
