<template>
 <div  style="max-width: 70vmax;">
    <b-card-group deck >
    <b-card v-for="(slide,index) in slides " :key="index"
      img-alt="Card image" img-top :img-src="slide.img" :title="slide.title"
      style="max-width: 25vmax;min-width: 25rem;border: none;" align
    >
    </b-card>
    </b-card-group> 
  </div>
</template>
<script>

export default {
    data() {
    return {
      slides: [{
        "title": "安心なDOCOMO回線、高スピード（4G、5G対応）",
        "img": require('@/assets/img/card-group/card1.png')
      }, {
        "title": "新規＆乗り換え対応、審査最短30分完了！",
        "img": require('@/assets/img/card-group/card2.png')
      }, {
        "title": "SIMフリーな端末であれば全て対応可能",
        "img": require('@/assets/img/card-group/card3.png')
      }, {
        title: "5分/10分かけ放題、遠隔サポートなどのオプションサービスも用意",
        img: require('@/assets/img/card-group/card4.png')
      }]
    };
  },

}
</script>