<template>
  <section class="qr_container">
    <!-- <div class="top"></div> -->
    <div class="card-x">
      <div>
        <div class="title-x">
          <img
            class="image_2"
            src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
          />
          <h1 class="text_17">
            {{ $t("REFERRAL_CODE.TITLE") }}
          </h1>
        </div>
        <!-- <el-alert
          :closable="false"
          @click="$router.push('/details/vip/section2')"
          :title="$t('REFERRAL_CODE.TEXT1')"
          type="warning">
        </el-alert> -->
        <van-notice-bar
          wrapable
          :scrollable="false"
          :text="$t('REFERRAL_CODE.TEXT1')"
          @click="$router.push('/details/vip/section2')"
          mode="link"
        />

        <div class="form">
          <!-- <h2 class="my-4">{{ $t("REFERRAL_CODE.QR") }}</h2> -->
          <div class="b-qr">
            <!-- <qrcode-vue
              id="picture"
              class="qrCode"
              :value="urlRegis"
              :size="size"
              level="H"
            /> -->
            <div v-html="SP_3" @click="down"></div>
          </div>

          <el-alert
            style="margin-top: 20px; padding-right: 30px"
            :title="urlRegis"
            type="success"
            :closable="false"
            close-text="copy"
          >
            <span
              @click="copyText(urlRegis)"
              style="
                position: absolute;
                right: 15px;
                top: 12px;
                cursor: pointer;
              "
              >Copy</span
            >
          </el-alert>
          <!-- <h2 class="my-4">{{ $t("REFERRAL_CODE.URL") }}</h2> -->
          <!-- <span class="text">{{ this.urlRegis }}</span> -->
        </div>

        <el-alert :closable="false" type="info">
          <span style="font-size: 12px">
            {{ $t("REFERRAL_CODE.TEXT") }}
          </span>
        </el-alert>
      </div>
    </div>
  </section>
</template>

<script>
import copy from "copy-to-clipboard";
import QrcodeVue from "qrcode.vue";
import { encodeData, rendererCircle } from "beautify-qrcode";
import { imgbase64 } from "./logo";
// const download = require('download');

export default {
  name: "referral_code",
  data() {
    return {
      urlRegis: "",
      size: 180,
      SP_3: "",
    };
  },
  mounted() {
    if (window.localStorage.getItem("registerUrl")) {
      this.urlRegis = window.localStorage.getItem("registerUrl");
      const qrcode = encodeData({
        text: this.urlRegis,
        correctLevel: 0,
      });
      const originalSvgString = rendererCircle(qrcode, {
        otherColor: "rgb(255, 105, 0)",
        posColor: "rgb(255, 105, 0)",
      });

      const imageWidth = 8; // 图片宽度，根据需要调整
      const imageHeight = 8; // 图片高度，根据需要调整

      this.SP_3 = this.insertBase64ImageIntoSVG(
        originalSvgString,
        imgbase64,
        imageWidth,
        imageHeight
      );
      console.log("qrcode", qrcode, this.SP_3);
    }
  },
  async created() {
      this.$api
        .get("memberManagement/list", {
          email: window.localStorage.getItem("email"),
        })
        .then((response) => {
          //メモ
          this.memo = response.result.records[0].memo
        });
  },
  methods: {
    copyText(text) {
      if (copy(text)) {
        this.$message({
          message: "success",
          type: "success",
        });
      }
    },
    down() {
      this.convertAndDownloadSVG(this.SP_3, "qrcode.png");
      // this.time = setTimeout(() => {
      //   let canvas = document
      //     .getElementById("picture")
      //     .getElementsByTagName("canvas");
      //   let a = document.createElement("a");
      //   a.href = canvas[0].toDataURL("img/png");

      //   a.download = "二维码";

      //   a.click();

      //   this.$message.warn("下载中，请稍后...");
      // }, 1000);
    },
    up() {
      clearTimeout(this.time);
    },
    generateRandomString(
      length = 8,
      charset = "abcdefghijklmnopqrstuvwxyz0123456789"
    ) {
      const result = [];

      for (let i = 0; i < length; i++) {
        result.push(charset.charAt(Math.floor(Math.random() * charset.length)));
      }

      return result.join("");
    },
    insertBase64ImageIntoSVG(svgString, base64Image, imageWidth, imageHeight) {
      // 创建一个DOM解析器
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgString, "image/svg+xml");
      const svgElement = svgDoc.documentElement;

      // 计算图片的插入位置，这里简单地将其置于SVG中心
      const viewBox = svgElement.getAttribute("viewBox").split(" ");
      const svgWidth = parseFloat(viewBox[2]);
      const svgHeight = parseFloat(viewBox[3]);
      const x = (svgWidth - imageWidth) / 2 + parseFloat(viewBox[0]);
      const y = (svgHeight - imageHeight) / 2 + parseFloat(viewBox[1]);

      // 创建image元素并设置其属性为Base64编码的图片数据
      const imageElement = svgDoc.createElementNS(
        "http://www.w3.org/2000/svg",
        "image"
      );
      imageElement.setAttributeNS(null, "href", base64Image);
      imageElement.setAttribute("x", x.toString());
      imageElement.setAttribute("y", y.toString());
      imageElement.setAttribute("width", imageWidth.toString());
      imageElement.setAttribute("height", imageHeight.toString());

      // 将image元素插入到SVG中
      svgElement.appendChild(imageElement);

      // 将修改后的SVG DOM元素转换回字符串
      const serializer = new XMLSerializer();
      const newSvgString = serializer.serializeToString(svgElement);

      return newSvgString;
    },
    convertSVGToPNG(
      svgData,
      callback,
      backgroundColor = "white",
      scaleFactor = 20
    ) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = function () {
        // 根据scaleFactor调整canvas尺寸以提高清晰度
        canvas.width = img.width * scaleFactor;
        canvas.height = img.height * scaleFactor;

        // 设置背景色
        if (backgroundColor) {
          ctx.fillStyle = backgroundColor;
          ctx.fillRect(0, 0, canvas.width, canvas.height);
        }

        // 调整绘制比例
        ctx.scale(scaleFactor, scaleFactor);

        // 绘制SVG图像
        ctx.drawImage(img, 0, 0);

        // 转换为PNG
        const pngData = canvas.toDataURL("image/png");
        callback(pngData);
      };
      img.src =
        "data:image/svg+xml;base64," +
        btoa(unescape(encodeURIComponent(svgData)));
    },
    downloadPNG(pngData, filename = "image.png") {
      const a = document.createElement("a");
      a.href = pngData;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    convertAndDownloadSVG(svgData, filename) {
      this.convertSVGToPNG(svgData, (pngData) => {
        this.downloadPNG(pngData, filename);
      });
    },
  },
  components: {
    QrcodeVue,
  },
};
</script>

<style lang="scss" scoped>
.qr_container {
  // background-color: #241f9c;
}
.top {
  background-image: url("@/assets/QrBackground.jpg");
  background-position: bottom;
  height: 257px;
  position: absolute;
  top: 74px;
  width: 100%;
}

.form {
  margin: 5% 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text {
  font-size: 20px;
  font-weight: 600;
}
.border {
  border: none !important;
  background: #f7f8fa;

  h4 {
    color: rgb(255, 115, 25);
    font-size: 16px !important;
    font-weight: normal !important;
  }
}
.btn-main {
  margin-top: 5%;
}

.content__item {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
h4 {
  text-align: center;
  font-weight: 600;
}
.b-qr {
  position: relative;
  ::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15%;
    height: 15%;
    border-radius: 5px;
    // background-color: #ffffff;
    // background-image: url(@/assets/tabbar/if.svg);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.qrCode {
  width: 220px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
}
section {
  width: 100%;
  text-align: left;

  .content__card {
    min-width: 200px;
    max-width: 560px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid var(--primary);
    margin: 5% 0px;
    width: 90%;
    margin: 50px auto 50px;
    &__title {
      text-align: center;
    }
    &__line {
      width: 100%;
      border-bottom: 1px solid var(--primary);
    }
  }
}

@media screen and (max-width: 900px) {
  section {
    width: 100%;
  }

  h3 {
    font-size: 18px;
    display: flex;
    flex-direction: column;

    .text {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 630px) {
  section .content__card {
    max-width: 410px;
  }
  .text {
    font-size: 16px;
  }
  .my-4 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}
@media screen and (max-width: 390px) {
  // .qr_code {
  //     width: 100% !important;
  // }

  h3 {
    font-size: 16px;

    .text {
      font-size: 11px;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>
