<template>
  <div>
    <h1>Kết quả thanh toán</h1>
    <div>{{ this.orderResult }}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      orderResult: "",
    };
  },
  created() {
    let uri = window.location.search.substring(1);
    this.orderResult = new URLSearchParams(uri);
  },
};
</script>
