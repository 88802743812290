<template>
  <div>
    <header-app class="header" />
    <div v-if="$store.state.isMobile" class="section_2 flex-col">
      <img
        class="icon_6"
        src="@/assets/lxl/SketchPngdd01eddf259d5175bdaa377106c564b288dee895f151344e74e37faf4cb7895b.png"
      />
      <!-- <span class="text_8">{{ $t("COMMON.SUC.ORDER_PAY_BUTTON") }}</span> -->
      <span class="text_8">{{ $t("COMMON.SUC.CONTENT1") }}</span>
      <!-- 订单处理 -->
      <span v-if="flg1" class="text_8">{{ $t("COMMON.SUC.CONTENT2") }}</span>
      <!-- 服务预约 -->
      <span v-if="flg1" class="text_8">{{ $t("COMMON.SUC.CONTENT3") }}</span>
      <!-- 月费支付 -->
      <span v-if="flg3" class="text_8">{{ $t("COMMON.SUC.CONTENT4") }}</span>
      <!-- 流量充值 -->
      <span v-if="flg6" class="text_8">{{ $t("COMMON.SUC.CONTENT5") }}</span>
      <!-- 套餐变更 -->
      <span v-if="flg7" class="text_8">{{ $t("COMMON.SUC.CONTENT6") }}</span>
      <!-- 会员信息更新 -->
      <span v-if="flg2" class="text_8">{{ $t("COMMON.SUC.CONTENT7") }}</span>
      <!-- 积分充值 -->
      <span v-if="flg8" class="text_8">{{ $t("COMMON.SUC.CONTENT8") }}</span>
      <!-- 兑现申请 -->
      <span v-if="flg5" class="text_8">{{ $t("COMMON.SUC.CONTENT9") }}</span>
      <!-- MNP、解约、暂停/解除 -->
      <span v-if="flg4" class="text_8">{{ $t("COMMON.SUC.CONTENT10") }}</span>
      <!-- 咨询 -->
      <span v-if="flg9" class="text_8">{{ $t("COMMON.SUC.CONTENT11") }}</span>
      <button
        class="button_1 flex-col"
        @click="revertHomeMyPage()"
        role="button"
      >
        <div class="image-text_1 flex-row justify-between">
          <span class="text-group_1">{{
            $t("COMMON.SUC.CHANGE_PAY_BUTTON")
          }}</span>
          <img class="icon_7" src="@/assets/lxl/jinru_b.png" />
        </div>
      </button>
    </div>
    <div v-else class="box_4 flex-col">
      <img
        class="image_6"
        src="@/assets/lxl/SketchPng81ac7ef4a0620b35081573bfb3030f21cfa9646f9b7c35d2f26e435aba19ea26.png"
      />
      <span class="text_size">{{ $t("COMMON.SUC.CONTENT1") }}</span>
      <!-- 订单处理 -->
      <span v-if="flg1" class="text_size">{{ $t("COMMON.SUC.CONTENT2") }}</span>
      <!-- 服务预约 -->
      <span v-if="flg1" class="text_size">{{ $t("COMMON.SUC.CONTENT3") }}</span>
      <!-- 月费支付 -->
      <span v-if="flg3" class="text_size">{{ $t("COMMON.SUC.CONTENT4") }}</span>
      <!-- 流量充值 -->
      <span v-if="flg6" class="text_size">{{ $t("COMMON.SUC.CONTENT5") }}</span>
      <!-- 套餐变更 -->
      <span v-if="flg7" class="text_size">{{ $t("COMMON.SUC.CONTENT6") }}</span>
      <!-- 会员信息更新 -->
      <span v-if="flg2" class="text_size">{{ $t("COMMON.SUC.CONTENT7") }}</span>
      <!-- 积分充值 -->
      <span v-if="flg8" class="text_size">{{ $t("COMMON.SUC.CONTENT8") }}</span>
      <!-- 兑现申请 -->
      <span v-if="flg5" class="text_size">{{ $t("COMMON.SUC.CONTENT9") }}</span>
      <!-- MNP、解约、暂停/解除 -->
      <span v-if="flg4" class="text_size">{{
        $t("COMMON.SUC.CONTENT10")
      }}</span>
      <!-- 咨询 -->
      <span v-if="flg9" class="text_size">{{
        $t("COMMON.SUC.CONTENT11")
      }}</span>

      <button @click="revertHomePage()" role="button">
        <div class="section_1 flex-row">
          <span class="text_14">{{ $t("COMMON.SUC.ORDER_PAY_BUTTON") }}</span>
          <img class="image_7" src="@/assets/lxl/jinru_b.png" />
        </div>
      </button>

      <!-- <button @click="revertHomeMyPage()" role="button">
        <div class="section_1 flex-row">
          <span class="text_14">{{ $t("COMMON.SUC.CHANGE_PAY_BUTTON") }}</span>
          <img
            class="image_7"
            src="@/assets/lxl/jinru_b.png"
          />
        </div>
      </button> -->
    </div>
    <footer-app />
  </div>
</template>

<script>
import Header from "@/components/commonn/Header/index.vue";
import Footer from "@/components/commonn/Footer/index.vue";
export default {
  name: "suc",
  data() {
    return {
      // viewsPage: 3,
      flg1: false,
      flg2: false,
      flg3: false,
      flg4: false,
      flg5: false,
      flg6: false,
      flg7: false,
      flg8: false,
      flg9: false,
      flg10: false,
    };
  },
  created() {
    if (this.$route.query.view) {
      this.viewsPage = this.$route.query.view;
    }

    if (this.$route.query.suc === "1" || this.$route.query.suc === "2") {
      //端末  订单生成
      this.flg1 = true;
    } else if (this.$route.query.suc === "3") {
      //用户信息更细
      this.flg2 = true;
    } else if (this.$route.query.suc === "4") {
      //料金支付
      this.flg3 = true;
    } else if (this.$route.query.suc === "5") {
      //解约
      this.flg4 = true;
    } else if (this.$route.query.suc === "6") {
      //換金申請
      this.flg5 = true;
    } else if (this.$route.query.suc === "7") {
      //流量充值
      this.flg6 = true;
    } else if (this.$route.query.suc === "8") {
      //套餐变更
      this.flg7 = true;
    } else if (this.$route.query.suc === "9") {
      //积分充值
      this.flg8 = true;
    } else if (this.$route.query.suc === "10") {
      //咨询
      this.flg9 = true;
    }
  },
  components: {
    "header-app": Header,
    "footer-app": Footer,
  },
  methods: {
    revertHomeMyPage() {
      this.$router.push({ path: "/mypage", query: { view: "9" } });
    },
    revertHomePage() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}

.text_size {
  font-size: 18px;
  text-align: left !important;
  margin-left: 50px;
  margin-top: 20px;
}
</style>
