export default {
    BUTTON: {
        PREVIOUS: 'Back',
        NEXT: 'Next',
        MYPAGE: 'My page',
        ORDER: 'Purchase ',
        LOGIN: 'Member Login',
        LOGOUT: 'Logout',
        CANCEL: 'Cancel',
        REGISTER: 'Member registration',
        CHOOSE: 'Apply',
        CONFIRM: ' Apply with this plan',
        PAYMENT_NEXT: " Payment",
    },

    VALIDATION: {
        REQUIRED: 'No usage',
    },

    MENU: {
        SIM_SERVICE: "SIM",
        SIM_ORDER: "SIM",
        WIFI_SERVICE: "Wi-Fi",
        OTP_SERVICE: "FIBER-OPTIC INTERNET",
        SELL_SERVICE: 'Service・Device',
        TERMINAL: "Service・Device",
        LOG_OUT: "Logout",
        INVESTIGATION: "Contact us",
        LOGIN: 'Login',
        MYPAGE: 'My page',
        MYPAGE_MENU: [
            
            {
                name: "Referral URL",
                value: 8,
                url: "",
            },
            {
                name: "Usage situation",
                value: 4,
                url: "",
            },
            {
                name: "Payment・Usage fee ",
                value: 6,
                url: "",
            },
            {
                name: "Contract plan",
                value: 5,
                url: "",
            },
            {
                name: "Application status",
                value: 3,
                url: "",
            },
            {
                name: "Member information",
                value: 0,
                url: "",
            },
            {
                name: "Point management",
                value: 1,
                url: "",
            },
            {
                name: "Exchange for money manage",
                value: 2,
                url: "",
            },
            {
                name: "Subscription payment",
                value: 7,
                url: "",
            },
            {
                name: "Account and security",
                value: 10,
                url: "",
            },
        ],
    },

    COMMON: {
        STRING: {
            IS_BLANK: "",
            IS_NULL: null,
            IS_INVALID: undefined,
            STRIKE_THROUGH: '—'
        },
        ITEM_TYPE: {
            NECESSARY: "Necessary",
            PRICE_MONTH: "(Monthly %{price}yen)",
            PRICE_MONTH_STEP1: "Monthly %{price}yen",
            PRICE_MONTH_STEP1_TAX: "(%{price}yen tax included)",
            ONLY_PRICE: "%{price} yen",
            VALIDATE: "Required fields"
        },
        NAVBAR: {
            STEP1: "Select Voice call plan",
            STEP2: "Select Fiber-optic internet",
            STEP3: "Select pocket Wi-Fi",
            STEP35: "Rental Wi-Fi",
            STEP4: "Pick up method",
            STEP5: "Confirmation of application content",
            STEP6: "Identity Verification",
            STEP7: "Contract",
        },
        SUC: {
            CONTENT1: "Processing is complete. Please review the following information:",
            CONTENT2: "□ Order Processing: Until the product arrives, please check the notification from the member-exclusive page and email.",
            CONTENT3: "□ Service Reservation: After the completion status is displayed on the member-exclusive page or a reservation completion email is received, please visit the store at the designated time.",
            CONTENT4: "□ Monthly Payment: If there are payment issues or refund processing occurs, please contact customer support.",
            CONTENT5: "□ Data Recharge: After the purchase is completed, it will be charged immediately and enter high-speed mode. Please check the remaining data volume on the usage status page.",
            CONTENT6: "□ Plan Change: After the change is completed, the data plan and optional plan will be applied in the following month, and the unlimited calling plan will be applied the next day. Changes can only be made once per month.",
            CONTENT7: "□ Membership Information Update: Once the changed documents are approved, changes or deletions cannot be made by the individual within 3 months.",
            CONTENT8: "□ Point Recharge: After the recharge is completed, please select IF points as the payment method for future transactions.",
            CONTENT9: "□ Cash Conversion Request: If you want to use QRcode payment, please contact customer support.",
            CONTENT10: "□ MNP, Cancellation, Temporary Suspension/Reactivation: After the application is completed, please wait for contact from customer support.",
            CONTENT11: "□ Inquiry: After submitting an inquiry, please wait for contact from customer support.",
            ORDER_PAY_BUTTON: "Back to Homepage",
            CHANGE_PAY_BUTTON: "To MyPage",
        },
        ERR: {
            CONTENT: [
                "Error occurred during screen transition.",
                "Please log out, close the browser, and try again later.",
                "Also, if the error persists, please contact the IF Mobile support center.",
            ],
            REVER_ERR: "Back to Homepage",
            CODE_INPUT: 'Authentication code mismatch!',
        },
        PAYMENT: {
            MESSAGE:"Please choose a payment method for the initial fee.",
            CHOOSE_PAY_WAY: "Please choose a payment method for the initial fee.",
            MESSAGE3:"We offer two payment options: online payment (credit card payment, QR code payment) and offline payment (online banking, cash, ATM transfer).",
            MESSAGE4:"□ If you choose credit card payment, please enter your credit card information and click the 'Confirm' button to complete the payment.",
            MESSAGE5:"□ If you choose various QR code payments, please open the corresponding payment QR code, scan it for payment, and then click the 'Confirm' button to complete the payment.",
            MESSAGE6:"□ If you choose online banking, please select it and click the 'Confirm' button directly to complete the process. The payment account information will be sent to your registered email address. Please check your email and complete the payment through your online banking app.",
            MESSAGE7:"□ If you choose cash or ATM transfer, please take a photo or screenshot of the amount and transfer account after selecting it, and then click the 'Confirm' button. After transferring the amount using an ATM, please send the receipt to customer service or visit the store within the designated time to make payment in Japanese yen cash.",
            MESSAGE8:"※If you wish to set up automatic deduction for future phone bills (credit card deduction, bank card automatic deduction, IF points automatic deduction), please make the selection below and then click the 'Confirm' button to complete the process.",
            ONLINE_PAY: "online payment",
            DEFERRED_PAY: "deferred payment",
            PAYMENT_AMOUNT: "Payment:",
            CARD_TITLE: "CreditCard",
            CARD_NO: "Last 4-digits",
            TAX: "yen（tax included）",
            BANKING: "Online  Bank ",
            BANKING_MESSAGE: "Get the information you need to pay",
            MESSAGE1:"After selecting online banking, please click the 'Confirm' button directly to complete the process. The payment account information will be sent to your registered email address.Please check your email and complete the payment through your online banking app.",
            MESSAGE2:"Please refer to the following steps for usage instructions.",

            CREDIT: {
                TITLE: "Credit card",
                RECOMMENT: "Recommendation!",
                TEXT: "Monthly charges will be automatically deducted from your credit card.Registration required.Debit cards are also accepted.Credit cards issued outside Japan can also be used.However, it is necessary to open the international payment service (RMB/USD).",
                PLA1: 'Card number',
                PLA2: 'Expiration date (years)',
                PLA3: 'Expiration date (month)',
                PLA4: 'Card name（Name）',
                PLA5: 'Card name (last name)',
                PLA6: 'Security code',
            },
            CODE: {
                TITLE: "Various code payment services (WeChat Pay, Alipay，Paypay)",
                ALIPAY: 'Alipay international payment',
                WECHAT: 'Wechatpay international payment',
                MESSAGE: "You can make payment by scanning the barcode.",
            },
            STORE: {
                TITLE: "Convenience store payment(unavailable for use)",
                TEXT: 'Cash payment at convenience store.Please pay within 7 days after ordering.',
                PLA1: 'Username',
                PLA2: 'Phone number',
                STORE_TYPE: 'Convenience store type:',
            },
            CASH: {
                TITLE: "Cash or ATM bank transfer",
                TITLE1: "Cash",
                TITLE2: "ATM bank transfer",
                TEXT1: 'Face-to-face cash payment at stores and agencies.',
                TEXT2: 'We only accept cash in Japanese Yen.',
                TEXT3: "Remittance address: General 5954038, Shitiangu Branch, Sumitomo Mitsui Banking Corporation.",
                TEXT4: "Please send the transfer details to the customer support center after the remittance.（Line / weechat / mail）。",
                TEXT5: 'Here are payment method',
            },
            CHOOSE_METHOD: {
                TITLE: "Please select a payment method for future monthly charges.",
                METHOD1: "Credit card automatic withdrawal (recommended)",
                METHOD2: "Account transfer Automatic withdrawal (recommended)",
                METHOD3: "Various code payments WeChat、Alipay、Paypay",
                METHOD4: "Convenience store",
                METHOD5: "Cash  Online Bank  ATM bank transfer",
                METHOD6: "IF point",
            },
            WITHDRAW: {
                TITLE: "About automatic withdrawal from bank account",
                CONTENT1: "It cannot be used to pay the initial fee.",
                CONTENT2: "Monthly payments will be automatically deducted from your bank account.We will send you a registration form for automatic withdrawal and an envelope together with the product, so please fill the form out and return it to us.",
                PDF: "「XXXXXX.PDF」"
            },
            BUTTON: {
                CONFIRM: "Confirm",
                PAY: "Next",
                RETURN: "Back"
            },
            VALIDATE:{
                INPUT:"Select payment method",
                FAIL: 'Process failed',
                CHECK_INPUT: "You didn't enter all required information",
                MESSAGE: 'Please finish the payment',
            }
        },
    },

    SIM_SERVICE: {
        STEP1_PLAN_SELECT: {
            TITLE: "SIM selection",
            QUESTIONS_PURCHASE_METHOD: {
                TITLE: 'Materials required before application',
                CARD1: {
                    TITLE: 'Payment method',
                    TEXT1: 'Various credit cards',
                    TEXT2: 'Bank transfer',
                    TEXT3: 'Various QR code payment WeChat, Alipay',
                    TEXT4: 'Convenience store',
                    LINK: 'Payment method details',
                },
                CARD2: {
                    TITLE1: 'Identity verification documents',
                    TITLE2: " (driver's license, insurance card, etc.)",
                    TEXT1: 'For identity verification based on the Mobile Phone Improper Use Prevention Act,Identity verification document is required.',
                    TEXT2: '※Register photo',
                    LINK: 'Identity verification document',
                },
                CARD3: {
                    TITLE1: 'MNP reservation number',
                    TITLE2: '(only for those who change mobile carrier company)',
                    TEXT1: 'If you want to keep your current phone number,It is necessary to obtain an MNP reservation number in advance.',
                    TEXT2: 'MNP from your current mobile carrier company,',
                    TEXT3: 'Please let us know your requirements.',
                    LINK: 'Click here for details',
                },
                CARD4: {
                    TITLE: 'Email address',
                    TEXT1: 'For membership registration and for receiving messages',
                    TEXT2: 'Please prepare an email address.',
                    TEXT3: "Please let us know your requirements.",
                },
            },
            QUESTIONS_SELECT_TEL_NUMBER: {
                NEW_AGREEMENT: "New contract",
                ANOTHER_COMPANY: {
                    TITLE: "MNP",
                    TEL_PLACE_HOLDER: "Please enter your current mobile phone number.",
                    MNP_PLACE_HOLDER: "MNP reservation number",
                    DATE_PLACE_HOLDER: "Expiration date of MNP reservation number ",
                    SIM_PLACE_HOLDER: "Please enter only if you know the phone number",
                    TITLE_BANK: "Please select your current mobile phone company.",
                    BANKS: [
                        { value: 0, text: 'DOCOMO' },
                        { value: 1, text: 'SOFTBANK' },
                        { value: 2, text: 'AU' },
                        { value: 3, text: 'UQ' },
                        { value: 4, text: 'Y!M' },
                        { value: 9, text: 'Others' }
                    ],
                }
            },
            QUESTIONS_SET: {
                TITLE: "Buy SIM only or a set",
                SIM: "SIM only",
                OTP: "Fiber-optic internet and Voice call as a set",
                WIFI: "Pocket  Wi-Fi and Voice call as a set",
            },
            QUESTIONS_RATE_PLAN: {
                TITLE: "High Speed Data plan　You can change after contract completed",
            },
            QUESTIONS_FLAT_RATE_CALL: {
                TITLE: "Call quota option",
            },
            QUESTIONS_OPTION: {
                TITLE: "Other options",
                OPTION1: "Answering machine message",
                OPTION2: "Catch phone",
                OPTION3: "Unnecessary",
            },
            QUESTIONS_DESIRED_DATE: {
                TITLE: "Desired date to start using",
            },
            SIM_CALL_QUOTA: [
                { value: '000011', text: 'Unlimited calls within 5 minutes' },
                { value: '000012', text: 'Unlimited calls within 10 minutes' },
                { value: '000013', text: 'Unlimited calls with no time limit' },
                { value: '000014', text: 'Unnecessary' },
            ],
            NONE: 'Unnecessary',
            OPTIONCHOSSE: [
              { value: '000016', text: 'Answering machine' },
              { value: '000017', text: 'catch phone' },
            ],
        },
        STEP2_CHOOSE_CABLE: {
            NAVBAR: "Select Fiber-optic internet",
            TITLE: "Select Fiber-optic internet",
            QUESTIONS_CHOOSE_PACKAGE: {
                TITLE: "Hikari line type ",
            },
            QUESTIONS_START_DAY: {
                TITLE: " Desired date to start using ",
            },
            RENT: {
                TITLE1: " New contractor ",
                TITLE2: " Change carrier ",
                USAGE_STATUS: "Usage status",
                QES: 'Wireless router rental',
                NEED: 'Wish',
                NO_NEED: 'Do not wish '
            },
            PHONE: {
                TITLE: 'Daytime contact phone number',
            },
            TIME: {
                TITLE: 'Daytime contact hours',
            },
            IFM_COMMODITY: [
                { value: '000025', text: 'Mansion Type', type: '(For condos)' },
                { value: '000026', text: 'Family type', type: '(For independent housing)' },
            ],
        },
        STEP3_WIFI_SELECTION: {
            REQUIRED1: "Wi-Fi high-speed data plan",
            REQUIRED2: "Desired date to start using",
        },
        STEP4_RECEIVE: {
            QUESTIONS_RADIO: {
                SELFIE: {
                    TITLE: 'Store pickup',
                    TEXT1: 'Headquarters',
                    TEXT2: 'Store Name: IF Mobile',
                    TEXT3: 'Address: 5F,SEIWA , koshin Building, 1-28-3 Takadanobaba, Shinjuku-ku, Tokyo',
                    TEXT4: 'Agencies',
                },
                TIME_DELI: 'Desired delivery time',
                OVERSEAS: "Send by post",
                IN_JAPAN: "Japan",
                OUTSIDE_JAPAN: "Overseas",
                AM: "Morning",
                PM: "Afternoon",
            },
            QUESTIONS_TEXTINPUT: {
                SELECT:"Select",
                MAILING_NAME: "Mailing name",
                POSTAL_CODE: "Postal code",
                MAILING_CONTACT_PHONE: "Phone number",
                MAILING_DETAILS_ADDRESS: "Address",
                JA: "Domestic delivery fee in Japan: %{price}yen",
                FOREIGN: "Overseas delivery fee: %{price}yen",
                DELIVERY_TIME:"Desired delivery time",
                VISIT:"Store pickup time",
                VISIT_DATE:"Please select a visit date",
                MESSAGE1: "To go back to the previous page, click on the purchase flow icon at the top.",
            },
            VISIT_TIME: [
              { value: "", text: 'Time', disabled: true  },
              { value: '10:00 ～ 12:00', text: '10:00 ～ 12:00'},
              { value: '12:00 ～ 14:00', text: '12:00 ～ 14:00'},
              { value: '14:00 ～ 16:00', text: '14:00 ～ 16:00'},
              { value: '16:00 ～ 19:00', text: '16:00 ～ 19:00'},
          ]
        },
        STEP5_PURCHASE_CONFIRMATION: {
            TITLE: "Confirm plan contents and prices",
            TEXT: "Please confirm the selected plan and price and continue the process.",
            COMMISSION: "Registration fee",
            ANOTHER_COMMISSION: "Commission fee",
            PACKAGE_FEE: "Mansion Type",
            TAX: "Tax",
            AMOUNT: 'Payment amount',
            WITHOUT_TAX: "%{price} yen(Tax excluded)",
            WITH_TAX: "%{price} yen(tax included)",
            CONFIRM: "Please verify that there are no errors in the above content. If corrections are necessary, click on the icon at the top of the purchase flow page, make the necessary modifications, and then return to the product confirmation page.",
            FIXED_COSTS: "Fixed costs",
            QUANTITATIVE_FEE: "Quantitative fee",
            TEXT1: "Call charge of the month before last",
            UNSETTLED: "Unsettled",
            OVERSEAS_FEE: "Overseas usage fee",
            OPENING_COST: "Opening construction cost",
            INSTALLMENT_COST : "Installment payment for construction cost",
            DEPOSIT: "Deposit",
            SIM_CARD_PURCHASE_DETAILS: {
                TITLE: "Voice call ",
                CONTRACT_TYPE: " Contract type",
                PHONE_NUMBER: "Phone number",

                MNP_PHONE_NUMBER:"Current mobile phone number",
                MNP_CODE:"MNP reservation number",
                MNP_DEADLINE:"Expiration date of MNP reservation number",
                SIM_BRAND_TITLE:"Current mobile phone company",
                SIM_BRAND_0:"DOCOMO",
                SIM_BRAND_1:"SOFTBANK",
                SIM_BRAND_2:"AU",
                SIM_BRAND_3:"UQ",
                SIM_BRAND_4:"Y!M",
                SIM_BRAND_9:"Others",

                OPEN_DAY: " Desired date to start using",
                PLAN: " High speed data plan",
                TOTAL_INITIAL_COST: "Total month cost",
                SIM_DAMAGES: "SIM Card issuance fee",
                UNIVERSERVE: "Universal service fee",
                RELAYSERVICE: "Call relay service fee",
                FLAT: ' Call quota option',
                MONTH_BASE: ' Call charges for%{month}, two months ago',
                SMS_BASE: ' SMS charges for%{month}, two months ago',
                MONTH: '28Day',
                FIRST_MONTH_FEE: "Fee for the month of  start date",
                ONETIME_FEE:"One-time payment only for the first time",
                DETAIL: "Click here for details",
                INITIAL_COST:"Initial costs",
                MONTHLY_FEE: "Monthly fee",
                NEXT_MONTH_FEE: "Fee for the next month of  start date",
                PAYMENT_PREVIOUS_MONTH: "Payment is due at the end of the previous month.",
                MONTHLY_PAYMENT:"Monthly payment",
                MONTHLY_PAYMENT_TYPE:"+Call charge +SMS charge",
                SMS_FEE_LAST_MONTH: "SMS fee for the month before last",
            },
            LIGHT_PURCHASE_DETAILS: {
                TITLE: "FIBER-OPTIC INTERNET",
                PLAN: " plan",
                OPEN_DAY: " Desired date to start using",
                TOTAL_INITIAL_COST: "Total initial cost",
                USAGE_STATUS: "Usage status",
            },
            WIFI_PURCHASE_DETAILS: {
                TITLE: "mobile Wi-Fi",
                RENT_TITLE: "Rental Wi-Fi",
                PLAN: " plan",
                OPEN_DAY: " Desired date to start using",
                COD: "COD",
                DEVICE_PRICE: "Wi-FI device price",
            },
            RECEIVE: {
                TITLE: "Pick up method",
                PRODUCT_ACQUISITION_METHOD: "Pick up method",
                AREA: " Area",
                MAILING_NAME: " Mailing name",
                MAIL_CONTACT_PHONE: " Phone number",
                LOCAL_POSTAL_CODE: " Postal code",
                MAILING_DETAILS_ADDRESS: " Address"
            },
            TOTAL: {
                POSTAGE: "Delivery fee",
                TOTAL_INITIAL_COST: "Total initial payment amount",
            }
        },
        STEP6_INFOMATION_CONFIRM: {
            LOGIN_ALERT: "Please log in or create a My Page.",
            INFO_DETAIL_ALERT: "Please fill in the information completely",
            LOGIN_NOTIFIC_ATION: "Creating my page is required for uploading the personal confirmation documents. Please register your user ID (email address) and password.",
            NOTIFICATION: 'By logging into IF Mobile "member-only page," you can check your usage, add data, change your plan, and update your user information.',
            NOTICE_CONTENT: '',
            QUESTIONS_MEMBER_CONFIRM: {
                TITLE2: " New registration",
                TITLE1: " Member",
                MEMBER: "login",
                NOT_A_MENBER: "Create my page",
                SIGN_UP: 'New registration',
                NONE_SIGN_UP: 'Enter customer information without registration',
            },
            QUESTIONS_REGISTER_INFO: {
                TITLE: " Registered customer",
                REGISTER: "Detailed member information registration",
            }
        },
        STEP7_CONSENT_SIGN: {
            CONTENT: 'Tap Terms and Conditions and check the contents before applying.',
            TITLE: 'Terms and conditions',
            NAVBAR: "Consent form signature   ",
            CHECKBOX: "I understand and agree to the above terms and conditions.",
            TEMP1: "Terms and Conditions",
            TEMP2: "Contract details and precautions ",
            BTN: {
              BTN1: "SIM",
              BTN2: "Wi-Fi",
              BTN3: " FIBER-OPTIC INTERNET"
            },
            PAYMENT_FORM: {
                FORM1: {
                    PLA1: 'Card number',
                    PLA2: 'Expiration date (years)',
                    PLA3: 'Expiration date (month)',
                    PLA4: 'Card name (Name)',
                    PLA5: 'Card name (last name)',
                    PLA6: 'Security code',
                },
                FORM2: {
                    TITLE: 'Alipay international payment'
                },
                FORM3: {
                    TITLE: 'Wechatpay international payment'
                },
                FORM4: {
                    TITLE: 'Convenience Store Payment',
                    PLA1: 'Username',
                    PLA2: 'Phone number',
                    PLA3: 'Convenience store type:',
                }
            },
            PAYMENT_METHOD: '* Payment method：',
            SIGN: {
                TITLE: "Signature Contractor himself/herself only;",
                ACCEPT: "Confirm",
                CLEAN: "Reset"
            },
            OPTION: [
                { value: null, text: 'Choose a payment method' },
                { value: '0', text: 'Credit: Credit card payment' },
                { value: '1', text: 'Alipay: Alipay International payment' },
                { value: '2', text: 'wechatpay：Wechatpay international payment' },
                { value: '3', text: 'Unionpay: Union online payment' },
                { value: '4', text: 'Pay at store' },
                { value: '5', text: 'Pay at convenient store' }
            ],
            OPTION_STORE: [
                { text: 'Convenience store type:', value: null },
                { text: 'Seven Eleven', value: 'seven_eleven' },
                { text: 'Family Mart', value: 'family_mart' },
                { text: 'Lawson', value: 'lawson' },
                { text: 'Mini Stop', value: 'mini_stop' },
                { text: 'Seico Mart', value: 'seico_mart' },
                { text: 'Pay Easy', value: 'pay_easy' },
                { text: 'Circle K', value: 'circle_k' },
                { text: 'Sunkus', value: 'sunkus' },
                { text: 'Daily Yamazaki', value: 'daily_yamazaki' },
                { text: 'Yamazaki Daily Store', value: 'yamazaki_daily_store' },
            ],
        },
        GENERAL_MERCHADISE: {
          TITLE: 'Enter application details',
        }
    },

    FOOTER: {
        TITLE1: {
            TITLE: 'Product',
            LINK1: 'SIM',
            LINK2: 'Wi-Fi',
            LINK3: 'Fiber-optic internet',
            LINK4: 'Service・Device',//tag
        },
        TITLE2: {
            TITLE: 'IF member',
            LINK1: 'Membership service',
            LINK2: 'Member registration',
        },
        TITLE3: {
            TITLE: 'Support',
            LINK1: 'Q&A',
            LINK2: 'Window',
            LINK3: 'Setup method',
            LINK4: 'Payment method',
        },
        TITLE4: {
            TITLE: 'Company information',
            LINK1: 'Operating company',
            LINK2: 'Notation based on Specified Commercial Transactions',
            LINK3: 'Terms and Conditions',
            LINK4: 'Privacy policy',
        },
    },

    LOGIN: {
        TITLE: "Login",
        USERNAME: "Customer ID: Email Address",
        PASSWORD: "Password",
        CODE: "Authentication code",
        FORGET_MESSAGE: "※At least 6 half-width alphanumeric characters",
        FORGET_PASSWORD: "If you forgot your password,",
        RECOVER_PASSWORD: "click here",
        NO_ACCOUNT: "Are you using it for the first time? New registration",
        NEW_ACCOUNT: "click here",
        CANCEL: 'Cancel',
        LOGIN: "Login",
    },

    FORGET_PASSWORD: {
      TITLE: "Customers who have forgotten their password",
      EMAIL_ADDRESS: "Email address",
      SEND: "Send",
      CODE: "Authentication code",
      VALIDATE_CODE: "Verification code is not correct",
      VALIDATE_MAIL: "This is not a valid email address",
      PASSWORD: "New password",
      CONFIRM_PASSWORD: "New password (Confirm)",
      MESSAGE1: "We will reset your password for My Page and send an email to your registered email address with an authentication code.",
      MESSAGE2: 'If you have email reception settings, please allow emails from "ifmobile.jp".',
      MESSAGE3: "If you do not receive the email, please contact the IF Mobile Customer Center.",
      TITLE2:"Customer ID (registered email address)",
      BUTTON: {
        SUBMIT: "Request for Reissuance",
        CANCEL: "Return",
      },
    },

    REGISTER: {
        TITLE: "Create my page",
        INDIVIDUAL: "Individual",
        CORPORATION: "Corporation",
        EMAIL_ADDRESS: "Email address",
        SEND_VERIFICATION_CODE: "Send",
        MESSAGE: "Please enter your mail address.",
        PLACE_HOLDER: {
            PLH0: 'Email address',
            PLH1: 'Authentication code',
            PLH2: 'Name (spelled the same as in passport)',
            PLH3: 'Name (in Katakana)',
            PLH4: 'Password (alphanumeric, at least 6 characters)',
            PLH5: 'Re-enter password',
            PLH6: "Company name (spelled the same as in the copy of the register)",
            PLH7: "Company name Company name (in Katakana)",
            MESSAGE1:"Your registered email address will be your customer ID.",
            MESSAGE2:'We will also send you important notices, so please make sure to register with correct information.Please allow emails from "ifmobile.jp".',
            MESSAGE3:"If you do not have an email address, please register one so that you can receive our messeges.",
            MESSAGE4:"The password is necessary for logging in, so please make sure to save it.",
        },
        BUTTON_RESGITER: "Create my page",
        BUTTON_RESGITER_CANCEL:"Back",
        ERROR: {
            RESPASSWORD: "Password and confirm password don't match",
            EMAIL_EXIST: "The email already exists, please change it.",
        }
    },

    PERSONAL_MANAGER: {
        NOTE: {
            TITLE: 'Confirmation of personal information',
            TEXT1: 'The personal information you provide will be used only for verification.',
            TEXT2: '1、Based on Japanese law, when purchasing a voice data card, it is obligatory to submit a company verification document with an address. In addition, it is limited to those with a remaining expiration date of 1 month or more.',
            TEXT3: '2、Please make sure that the delivery address of the SIM card matches that of the identity verification document. If the identity verification documents do not match, please upload supporting documents that prove your address at the same time. *Within 6 months from the date of issue.',
            TEXT4: '3. Please upload a photo taken by the customer himself/herself holding the identity verification document and not covering his/her face. In the meanwhile, please be careful to take pictures so that descriptions can be read clearly.',
            TEXT5: '4、Please enter correct company information. If the uploaded information does not meet the above requirements, you will not be able to purchase a SIM card.',
            TEXT6: 'Identity verification documents',
            TEXT7: 'Click here for details',
        },
        NOTE1: {
            TITLE: '※Please upload a photo of the front and back of your identity verification document and a photo of yourself holding the document in your hand.',
            TEXT1: '①The file format is JPG/PNG and the size is up to 4MB.',
            TEXT2: '②Please be sure to upload your own identification documents and face photo.',
            TEXT3: "In the unlikely event that the person in the photo is different, or if it doesn't meet the requirements our company established",
            TEXT4: 'Please note that we may not be able to process your registration.',
        },
        NOTE2: {
          TEXT: 'File format, JPG / PNG, size up to 4MB',
          TEXT2: 'Please fill in the following when you change your identity verification type or contact information.'
        },
        BASICINFO: {
            CONTENT: "Please make sure that the SIM card delivery address matches the identity verification document.If it does not match the identity verification document, please upload supporting documents that prove your address.※Within 6 months from the date of issue.",
            LINE:"----------------------------------------------------------------------------------------------------------------",
            BASIC_INFORMATION: "Please enter information about the contractor",
            TITLE: "Contract signer's name:",
            TITLE1: 'Must match with the identification document　(Kanji or Romaji)',
            TITLE2: 'User name(full-width Kana):',
            HO: 'Family Name',
            TEN: 'Given Name',
            HO1: 'Family name',
            TEN1: 'Given name',
            PLACEHOLDER: {
                PLA1: 'Enter',
                PLA3: 'Please enter number',
                PLA4: 'If you have a referral code, please enter it。',
            },
            GENDER: "* Sex ：",
            RADIO_MAN: "Male",
            RADIO_WOMAN: "Female",
            YEAR:"Year",
            MONTH:"Month",
            DAY:"Day",
            DATE_OF_BIRTH: "* Birthday ：",
            IDENTITY_VERIFICATION_DOCUMENTS: "* Identity verification documents ：",
            ID: '* Identification document number :',
            SELECT_IDENTITY_VERIFICATION_DOCUMENTS: {
                SELEST: "Please select one option",
                OPTION_RESIDENCE_CARD: {
                    name: "Residence card",
                    value: ''
                },
                OPTION_PASSPORT: {
                    name: "Passport",
                    value: ''
                },
                OPTION_DRIVER_LICENSE: {
                    name: "Driver's license",
                    value: ''
                },
            },
            COUNTRY_OF_CITIZENSHIP: "* Nationality ：",
            CONTENT1: {
                TEXT1: "Please upload a photo of the front side of your identity verification document.",
                TEXT2: "Please upload a photo of the back side of your identity verification document.",
                TEXT3: "Please upload a photo of yourself, including your face, with your identity verification document holding in your hand.",
            },
            SUPPORTING_DOCUMENTS: "Supporting documents ：",
            SELECT_SUPPORTING_DOCUMENTS: {
                SELECT: "Please select one option",
                OPTION_HEALTH_INSURANCE_CARD: {
                    name: "Health insurance card ",
                    value: ''
                },
                OPTION_MY_NUMBER_CARD: {
                    name: "My number card",
                    value: ''
                },
                OPTION_STUDENT_CARD: {
                    name: "Student card",
                    value: ''
                },
                OPTION_NOTIFICATION: {
                    name: "Notification of residence permit",
                    value: ''
                },
                OPTION_LANDING_CERTIFICATE: {
                    name: "Landing certificate",
                    value: ''
                },
            },
            CONTENT2: {
                TEXT1: "Upload supporting documents※Limited to those within 6 months from the date of issue",
                TEXT2: "Upload supporting documents※Limited to those within 6 months from the date of issue",
            },
        },
        CONTACTINFO: {
            CONTACT_INFORMATION: "Contractor contact information",
            CONTACT_PHONE_NUMBER: "*Contact phone number ：",
            POST_CODE: "*Postal code ：",
            ADDRESS: "*Address ： ",
            TITLE_REQUIRED: "For the emergency contact information, if you are a foreigner, please fill in the contact information for the country in which you are located.",
            EMERGENCY_CONTACT: "*Emergency contact ：",
            EMERGENCY_CONTACT_INFORMATION: "*Emergency contact address ：",
            EMERGENCY_CONTACT_PHONE: "*Emergency contact phone ：",
            EMERGENCY_RELATION: '*Relationship :',
            PREFERRED_LANGUAGE: "* Preferred language ：",
            SELECT_PREFERRED_LANGUAGE: {
                SELEST: "Please select one option",
                CHECKBOX_JAPANESE: {
                    name: "Japanese",
                    value: ''
                },
                CHECKBOX_ENGLISH: {
                    name: "English",
                    value: ''
                },
                CHECKBOX_CHINESE: {
                    name: "Chinese",
                    value: ''
                },
                CHECKBOX_VIETNAMESE: {
                    name: "Vietnamese",
                    value: ''
                },
                CHECKBOX_KOREAN: {
                    name: "Korean",
                    value: ''
                },
                CHECKBOX_SRILANKAN: {
                    name: "Sri Lankan",
                    value: ''
                },
                CHECKBOX_NEVALA: {
                    name: "Nevale",
                    value: ''
                },
            },
        },
        WORKSCHOOLINFO: {
            TITLE: "Work/School information (optional) Required for international students",
            NAMEPHONE__NAME: "Workplace／school ：",
            NAMEPHONE__NUMBERPHONE: "Workplace／School phone number:",
            ADDRESS: "Workplace／school address ：",
        },
        OTHERINFO: {
            TITLE: "Other information",
            ADVERTISEMENT: "*Where did you know about IF MOBILE?",
            CHECKBOX__ROUNDED: {
                CHECKBOX_REFER_A_FRIEND: {
                    name: "Friends referral",
                    value: ''
                },
                CHECKBOX_SCHOOL_INTRODUCTION: {
                    name: "Introduction from school",
                    value: ''
                },
                CHECKBOX_INTRODUCTION: {
                    name: "Study abroad/Introduction from agency",
                    value: ''
                },
                CHECKBOX_INTRODUCED_FROM: {
                    name: "Introduction from other websites",
                    value: ''
                },
                CHECKBOX_FACEBOOK: {
                    name: "Facebook Ads",
                    value: ''
                },
                CHECKBOX_YOUTUBE: {
                    name: "Youtube Ads",
                    value: ''
                },
                CHECKBOX_GOOGLE: {
                    name: "Google Ads",
                    value: ''
                },
                CHECKBOX_OTHER_ADVERTISEMENTS: {
                    name: "Others Ads",
                    value: ''
                },
                CHECKBOX_OTHERS: {
                    name: "Others",
                    value: ''
                },
            },
            USE: {
                TITLE: "Which SNS do you use?",
                CHECKBOX_FACEBOOK: {
                    name: "Facebook",
                    value: ''
                },
                CHECKBOX_LINE: {
                    name: "LINE",
                    value: ''
                },
                CHECKBOX_WECHAT: {
                    name: "WeChat",
                    value: ''
                },
                CHECKBOX_KAKAO: {
                    name: "Kakao Talk",
                    value: ''
                },
                CHECKBOX_SNS: {
                    name: "Please enter your SNS name",
                    value: ''
                },
            },
        },
        REFERRAL_CODE: "Referral code:",
        BTN: "Save",
        DELETE: 'Delete'
    },

    COMPANY_MANAGER: {
        NOTE: {
            TITLE: 'Company confirmation',
            TEXT1: 'The personal information you provide will be used only for company verification.',
            TEXT2: '1、Based on Japanese law, when purchasing a voice data card, it is obligatory to submit a company verification document with an address. In addition, it is limited to those with a remaining expiration date of 1 month or more.',
            TEXT3: '2、Please enter correct company information. If the uploaded information does not meet the above requirements, you will not be able to purchase a SIM card.',
        },
        BASIC_INFORMATION: {
            TITLE: 'Enter information about your contracting company',
            CEO: '* CEO :',
            PDF: '* Business license :',
            TITLE_ADD_IMG: 'Business card :',
            TEXT_ADD_IMG: 'Please upload a photo of your business card',
        },
        CONTACTINFO: {
            TITLE: 'Contract company contact information',
            PHONE1: '* Company telphone number:',
            ZIP_CODE: '* Company postal code:',
            ADDRESS: '* Company address：',
            NAME: '* Name of person in charge:',
            CONTACT: '* Contact phone number:',
            POSITION: '* Position:',
        },
        PLACEHOLDER: {
            PLA1: "It needs to match with Company Transcript",
            PLA2: "If you have a referral code, please enter it。",
        },
        REFERRAL_CODE: "Referral code:",
        LANGUAGE: "Preferred language：",
        BTN: "Save",
        UPDATED_CONTACT: {
            TITLE: "Please fill in the following when the identity verification type or contact information is changed.",
            PLACEHOLDER: "Please enter your updated contact information",
        }
    },

    INDEX: {
        LOGO: {
            ANIMATION1: "IF",
            ANIMATION2: "Mobile",
            ANIMATION3: "IF Mobile",
        },
        SLOGAN: "To Serve Your Needs",
        PLAN: {
            CONTENT: "The Plans",
            TITLE: "Low-priced pricing system! ",
            TITLE_TEXT1: "Combine \"voice SIM\" and \"Wi-Fi\" freely! Choose a  plan according to your usage. ",
            TITLE_TEXT2: "Recommended plan for monthly life: 5G voice SIM + 100G mobile Wi-Fi   Popular!",
            PLAN_BASIC: "Basic plan",
            MONTHLY_FEE:"Monthly fee",
            PRICE: "Unit price",
            BENEFITS: "Benefits",
            SIM: {
                PERMISSION: "Option service",
            },
            SELL_PHONE: {
              PLAN_BASIC: 'Type',
              CONTENTS:"Content",
            }
        },
        APPLY: {
            STEP1: {
                NAME: "STEP 1",
            },
            STEP2: {
                NAME: "STEP 2",
            },
            STEP3: {
                NAME: "STEP 3",
            },
        },
        INTRODUCTION: {
            CONTENT: "Smart and Simple",
            TITLE: "What is IF Mobile?",
            TEXT1: "●No credit card required, simple screening , products that meet your needs.",
            TEXT2: "●A brand that provides redeemable points (IF P) and a variety of member services, offering value beyond communication.",
            BTN: "View details",
            INTRODUCTION_CARD: {
                CARD1: {
                    TITLE: "Voice SIM",
                    TEXT1: "Seven types of capacity plans, simple and easy",
                    TEXT2: "You can continue to use your current smartphone and enjoy unlimited data usage.",
                    TEXT3: "There is also a special campaign for MNP transfer",
                    BTN2: "Get SIM card",
                },
                CARD2: {
                    TITLE: "Pocket Wi-Fi",
                    TEXT1: "No speed restrictions, no contract period",
                    TEXT2: "Ready-to-use with no installation or setup required",
                    TEXT3: "Both one-time purchase and rental OK",
                    BTN2: "Get Mobile Wi-Fi",
                },
                CARD3: {
                    TITLE: "FIBER-OPTIC INTERNET",
                    TEXT1: "NTT line speed is fast",
                    TEXT2: "Giga unlimited use (no communication limit, no speed reduction)",
                    TEXT3: "It takes less than 1 week to be available",
                    BTN2: "Get fiber-optic line",
                },
                CARD4: {
                    TITLE: "Service・Device",
                    TEXT1: "Discount coupons for partner stores or travel.",
                    TEXT2: "Dining at partner restaurants earns IF points that can be exchanged for cash.",
                    TEXT3: "Payment in installments is possible by purchasing a set of SIM and device (new/used).",
                    BTN2: "Get a device",
                },
            },
        },
        QUESTIONS: {
            CONTENT: "Let's talk with us",
            TITLE: "Contact Us",
            ADDRESS: "Our Office",
            ADDRESS_TEXT: "201 Oak Stress Building 27, Manchester, USA",
            CALL_US: "Call Us",
            PHONE: "+1 719-338-4628",
            EMAIL_US: "Email Us",
            EMAIL: "support@envato.com",
            INPUT: {
              NAME: 'Name※',
              EMAIL: 'Email※',
              PHONE: 'Phone※',
              MESS: 'Your Message※'
            }
        },
    },

    PAGINATION: {
        NUMBER_RECORD: "Displayed results:",
    },

    INQUIRY: {
        TITLE: "Contact us",
        NOTE: "If you have any questions, please submit the following and we will get back to you within 2 days!",
        EMAIL: "Email address:",
        CONTACT: "Inquiry type",
        SNS: "Others",
        SNS_NAME: "SNS名",
        SNS_ID: "SNS ID",
        RETURN: "Back",
        SEND: "Send",
    },

    ORDER_INFO: {
        NOTE: {
            TITLE: "「Incomplete (must be processed)」",
            CONTENT1: "It is possible that the process was not completed correctly due to incompleteness or discrepancies in the identity verification documents, or there is an unpaid bill.",
            CONTENT2_1: "In this case, we will send to your e-mail address registered at the time of application ",
            CONTENT2_2: "[Important] Request for re-upload due to incomplete identity verification documents for IF Mobile or payment completion",
            CONTENT2_3: "mail as above.",
            CONTENT3_1: "After confirming the reason for the deficiency,",
            CONTENT3_2: "【Process】",
            CONTENT3_3: "Please press the button and follow the necessary procedures.",
            CONTENT4_1: "Check out the details on the page below.",
            CONTENT4_2: '"Incomplete identity verification"',
        },
        TITLE: "Application history",
        ORDER_NUMBER: "Application number　",
        ORDER_TYPE_TITLE: "Application details",
        ORDER_PRICE: "Price（tax included）yen",
        ORDER_DAY: "Application date and time",
        EXPECT_DAY: "Usage start date",
        PAYMENT_METHOD: "Payment method",
        WAY_RECEIVE: "Pick up method",
        MAILING_AREA: "Mailing area",
        MAILING_NAME: "Mailing name",
        CONTACT_PHONE: "Mail contact phone",
        ADDRESS: "Mailing details address",
        PROCESSING: "To process",
        OREDER_PAYMENT_OPTION: [
          { value: '0', text: 'card' },
          { value: '1', text: 'Wechat' },
          { value: '2', text: 'Ali' },
          { value: '3', text: 'Pay at convenient store' },
          { value: '4', text: 'Pay at store' },
          { value: '5', text: 'Bank transfer' },
        ],
        ORDER_TYPE: [
          { value: '001', text: 'SIM+Wi-Fi' },
          { value: '002', text: 'SIM+FIBER-OPTIC' },
          { value: '003', text: 'SIM' },
          { value: '004', text: 'Wi-Fi' },
          { value: '005', text: 'FIBER-OPTIC' },
          { value: '006', text: 'Service・Device' },//tag
          { value: '007', text: 'Rental Wi-Fi' },
        ],
        ORDER_STATUS: [
          { value: '0', text: 'Screening (Processing)' },
          { value: '1', text: 'Incomplete (must be processed)' },
          { value: '2', text: 'Shipping (Arranged)' },
          { value: '3', text: 'In progress (Completed) ' },
        ],
        ORDER_PHONE: {
            NUMBER: "Service number",
            CONTENT: "Application type",
            DATESTART: "Usage start date",
            NUMBER_OF_USERS: "Number of users",
            INTENDED_AMOUNT: "Planned amount",
            ACTUAL_AMOUNT: "Actual amount",
            UPLOAD_IMAGE: "Please upload a photo of receipt.",
        }
    },

    BAR_MENU: {
        LOG_OUT: "Logout",
        POINT: "Points:",
        MYPAGE:"My IF Mobile",
    },

    CONTRACT_DETAIL: {
        TITLE: 'Contract content',
        NOTE: 'Voice SIM line',
        CONTRACT_PHONE: "Contract phone number",
        CONTRACT_STATUS: 'Usage status',
        CONTRACT_PLAN: 'High speed data plan',
        DATESTART:'Usage start date',
        BTN_DETAIL: 'Check/change content',
        THIS_MONTH: 'Current month',
        NEXT_MONTH: 'Next month',
        PLAN_DATA: 'Data plan',
        CALL_QUOTA: 'Call quota plan',
        OPTION: 'Option service',
        //option amountPlan
        TUTORIAL1: 'The plan change will be applied in the following month.',
        TUTORIAL2: 'A change fee of 220 yen will be charged. You can change it only once a month.',
        TUTORIAL3: 'Select plan for next month',
        //option converseQuota
        TUTORIAL4: 'Changes of call quota plan will be applied the next day.',
        TUTORIAL5: 'A change fee of 220 yen and a call quota charge for the current month will be incurred.',
        TUTORIAL6: 'You can change it only once a month.',
        //option ortherOption
        TUTORIAL7: 'Any change in options will take effect the following month.',
        TUTORIAL8: 'A change fee of 220 yen will be charged.',
        TUTORIAL9: 'You can change it only once a month.',
        //MNP TUTORIAL
        MNPTUTORIAL1: 'Due to MNP transfer, a cancellation fee will be incurred within the contract period.',
        MNPTUTORIAL2: 'A cancellation fee of 11,000 yen and a handling fee of 3,300 yen will be charged.',
        MNPTUTORIAL3: 'After the payment procedure is completed, the MNP transfer number can be issued on the same day.',
        //Cancellation TUTORIAL
        CANCELTUTORIAL1: 'Cancellation will result in a cancellation fee within the contract period.',
        CANCELTUTORIAL2: 'A cancellation fee of 11,000 yen will be charged.',
        CANCELTUTORIAL3: 'After the payment procedure is completed, it will be canceled on the same day.',
        OPEN: "Open",
        CANCEL: "Cancel",
        MNP:"MNP",
        TEMPORARY_STOP: "Temporary pause",
        GO_AGAIN: "Reissue",
        ALL:"All",
        PAYMENT: 'Change fee',
        CONFIRM: 'Confirm',
        BTN_CANCEL: 'Cancel',
        PLAN_CHANGE: 'Plan change',
        CALL_CHANGE: 'Change call quota plan',
        OPTION_CHANGE: 'Change option',
        NOTE2: 'Hikari line contract',
        NOTE3: 'Mobile Wi-Fi contract',
        CONTRACT_LINE_ID: 'Hikari line ID',
        CONTRACT_LINE_TYPE:"Hikari line type",
        CONTRACT_IMEI: 'IMEI number',
        CANCEL_CONTRACT: 'To cancel',
        CANCEL_CONTRACT_TITLE: 'Cancellation application',
        CHANGE_MNP_TITLE: 'MNP transfer',
        CONTRACT_TYPE: 'Contract type',
        EXPLANATORY_TEXT: 'Explanatory text',
        EXPLANATORY_PLACEHOLDER: 'Comment',
        SELECT_OPTION1: 'Please select a contract phone number',
        SELECT_OPTION2: 'Please select IMEI',
        SELECT_OPTION3: 'Please select a line ID',
        YEN: 'yen',
        UPDATE_STATUS: {
            TITLE: "Suspension of use, reissuance, resumption (lost, stolen, temporary return to home country, etc.)",
            OPTION: "Please select the target SIM card",
            OPTION1: "Reissue a new SIM card",
            OPTION2: "Suspend SIM card",
            OPTION3: "Restart sim card",
            NOTICE1: "Monthly billing will continue to occur even during the suspension period.",
            NOTICE2: "In the case of reissue, reissue fee of 5,000 yen will be charged.",
            BUTTON: "Confirm",
            UPDATE_FALSE: "Operation failed",
        }
    },

    CASH: {
        GOLD_EXCHANGE_RELUES: "Cash exchange rules",
        NOTE: "① Exchange from 10000 points unit. (1 point = 1 yen)",
        NOTE1: "② Account transfer (customer pays the fee), various code payments (Paypay, PayPal, Alipay, WechatPay), and can be exchanged at a store visit.",
        NOTE2: "③ From the 25th to the end of the month, you can apply for cash from My Page and it will be granted from the 28th to the end of the following month.",
        NUMBER_OF_REDEMPTION_POINTS: "Exchange points:",
        REDEEMABLE_POINTS: "Exchangable points：",
        REDEMPTION_POINTS: "Points for cash exchange：",
        AMOUNTS: "Amount：",
        BANK_NAME: "※Bank name：",
        BRANCH_NAME: "※Branch name：",
        ACCOUNT_HOLDER: "※Account holder：",
        YEN: 'Yen',
        JAPAN_BANK: "Japan bank account",
        BRANCH_NUMBER: "※Branch number：",
        ACCOUNT_NUMBER: "※Account number：",
        TELEPHONE_NUMBER: "※Phone number：",
        BTN_BANK:"Japan bank account",
        BTN_PAY: "Barcode PAY",
        BTN_SURE: "Save",
        NOTICE: "If you want to change your saved bank account information, please contact our customer support center."
    },

    REFERRAL_CODE: {
        TITLE: "Referral URL",
        QR: "QR Code",
        CODE: "ifmobile.com?id=2023323de34334234343434343434332324545",
        URL: "URL",
        TEXT: "3,000 points are automatically awarded for each contract",
        TEXT1: "Just share the URL and introduce it!",
        TEXT2: "Detailed",
    },

    FEE_INFO: {
        PDF: 'Download usage details 　PDF',
        TITLE: 'Usage fee',
        MONTH_TITLE: "Payment fee",
        SIM: "Voice SIM",
        OPT: "Fiber-optic line fee",
        WIFI: "Wi-Fi fee",
        PHONE: "Contract phone number",
        OTPID: "Line ID:",
        IMEI: "IMEI",
        AMOUNT: "Usage fee",
        PRICE: " yen ",
        PAYMENTFLAG: "Payment status",
        UNPAID: "Unpaid",
        PAID: "Paid",
        PAYDEADLINE: "Due date for payment",
        PAY: "Payment",
        DETAIL: "View usage details",
        CREATE_FORM: "Create bill",
        CALL_DETAILS: "Call details for the month before last ",
        MONTH_BASE: ' Call fee for the month before last',
        SMS_BASE: ' SMS fee for the month before last ',
        DOMESTIC_CALL: "Domestic call：%{number}　Minute",
        INTERNATIONAL_CALL: "International call：%{number}　Minute",
        SMS_DETAILS: "SMS details for the month before last ",
        DOMESTIC_SMS: "Domestic SMS：%{number}　messages",
        INTERNATIONAL_SMS: "International SMS：%{number}　messages",
        //hiden form info
        FIVE_MINUTE: "Unlimited calls within 5 minutes",
        TEN_MINUTE: "Unlimited calls within 10 minutes",
        COMPLETELY_MINUTE:"Unlimited calls with no time limit",
        NO_MINUTE:"Unnecessary",
        ABSENCEPHONE: "Answering machine",
        TELEPHONECHARGE: "Call fee ",
        SMSFEE: "SMS fee	",
        CATCHPHONE: "Catch phone",
        PLAN: "Plan usage fee",
        TEXT1:"Please make sure to complete the payment before the due date. If we are unable to confirm the payment within a certain period of time, we will suspend your service. If the payment is delayed due to temporary return to your home country or other reasons, please be sure to contact us in advance.",
        TEXT2:"If you wish to have your monthly fees automatically debited from your bank account, credit card, or IF points, please contact our customer support center. You can also check the subscription page for confirmation.",
        MESSAGE1:"This month's bill includes the high-speed data  charges for this month and the call and SMS charges from two months ago.",
        MESSAGE2:"Payment needs to be made in advance, and this month's bill is for next month's expenses.",
    },

    DATA_USAGE: {
        MESSAGE: 'After the high-speed data remaining amount reaches 0 GB,it will be in low-speed mode.High speed data charge',
        TITLE: 'High-speed data usage',
        CONTACT_PHONE_NUMBER: 'Contract phone number',
        DATA_PACKAGES: ' High speed data plan',
        UPDATE_DATE: 'Update date and time：',
        DATA_USAGE_THIS_MONTH: 'Total amount of data for this month',
        TOTAL: ' Remaining data for this month',
        TO_USE: 'Used data amount ',
        EXTANDT: 'Remaining data',
        BTN: 'Purchase additional high-speed data capacity',
        ALL_DATA: "%{data}GB (+Charged data)",
        BARCHART_TITLE:"Usage breakdown for the last 4 days",
        REVENUESUMMARY: "SIM package fee",
        PACKET_CHARGE: {
            TITLE: '',
            GUIDE: 'Select the amount of data and press the "Confirm" button. The additional charge per 1GB is 550 yen. Expiration date: Until the end of this month',
            SIM_NUMBER: 'SIM number：',
            FEE_AMOUNT: "Data charge",
            AMOUNT_OF_MONEY: 'Amount (including tax)',
            CONFIRM: 'Purchase',
            CANCEL: 'Cancel',
            NOTICE:'After selecting the capacity and clicking the "Purchase" button, the purchase will be completed and data will be charged immediately and it will be in high speed mode.',
        },
    },

    RECUR_PAYMENT: {
        BTN_SUBMIT: 'Confirm',
        BTN1: 'Credit card information registration',
        TITLE: 'Recurring billing',
        TITLE2: 'Recurring payment',
        BTN2: 'Change subscription card',
        MESSAGE1:"●Credit card",
        MESSAGE2:"Please register a credit card to be used for monthly payments.",
        MESSAGE3:"Information such as credit card numbers will be registered with the payment service provided by Univapay and securely managed.",
        MESSAGE4:"Schedule for Applying Changes",
        MESSAGE5:"・Changes made by the 1st to 3rd of the month will be applied to the current month's payment.",
        MESSAGE6:"・Changes made between the 4th of the month and the end of the month will be applied to the next month's payment.",
        MESSAGE7:"●Automatic bank transfer",
        MESSAGE8_1:"Automatic bank transfer ",
        MESSAGE8_2:" Logging",
        MESSAGE9:"If you would like to use automatic bank transfer for monthly payments, please select bank transfer as your payment method for monthly payments, download the request form below, fill it out and stamp it, then send it back to us.",
        MESSAGE10:"Mailing address: 169-0075 東京都新宿区高田馬場1-28-3工新ビル　504号室　IF Mobile　お客様サポートセンター ",
        MESSAGE11:"※Account transfer registration: effective from the following month at the earliest, and effective from the second month after registration if registered at the end of the month.",
        MESSAGE12:"※Mailing period: If you send it by the 16th of each month, you can start using bank transfer from the following month.",
        MESSAGE13:"If sent after the 16th of each month, automatic account withdrawal will be possible from the following month.",
        MESSAGE14:"※Please be sure to affix your seal.",
        MESSAGE15:"※The name on the seal must match exactly with the one registered with the financial institution.",
        MESSAGE16:"●IF points",
        MESSAGE17_1:"IF points (For more details,",
        MESSAGE17_2:"please refer to IF point history",
        MESSAGE17_3:"）",
        MESSAGE18:"If you would like to use IF points to automatically deduct monthly fees, please choose IF points as the payment method for your monthly fees in the future and charge ",
        MESSAGE18_1:"IF points",
        MESSAGE18_2:"in the point management of your My Page.",
        MESSAGE19:"Possession points",
        MESSAGE20:"Point charge 1 point = 1 yen",
        MESSAGE21:"Enter point",
        MESSAGE22:"Charge",
        MESSAGE23:"Only full payment with points is supported, partial payment with points is not available.",
        MESSAGE24:"Please note that IF points cannot be used for data charges, cancellation fees, MNP transfer fees, or any handling fees.",
        SIM: {
            TITLE: 'SIM card recurring billing',
            CONTRACT_PHONE_NUMBER: 'Contract phone number',
        },
        WIFI: {
            TITLE: 'Wi-Fi recurring billing',
            IMEI: 'IMEI',
        },
        HIKARI: {
            TITLE: 'Fiber-optic line recurring billing',
            LINE_ID: 'Line ID',
        },
        CARD1: 'Credit card',
        CARD2: 'Bank account transfer',
    },

    MYPAGE_HOME: {
        TITLE: "Important Notices",
        NOTICE_CONTENT: "Procedures for automatically withdrawing monthly charges from a bank account or credit card",
        TEXT_CONTENT1:"The points accumulated in the referral system can be exchanged for cash! ",
        LINK1: "Click here for details",
        TEXT_CONTENT2:"Referral URL/Barcode: Please use after copying and downloading from Menu/Point Management.",
        LINK2: " my point management",
        INVOICE:"Bill of %{month}",
        LINK3: "To payment",
        REMAIN_DATA: "Remaining data",
        LINK4: "Data charge",
        MENU_TITLE: "Most used",
        MENU_TITLE1:"menu",
        MENU: [
        {
            name: "Point management ",
            value: 1,
            url: require("@/assets/historypoint.jpg"),
            icon: "clock-history",
        },
        {
            name: "Cash management",
            value: 2,
            url: require("@/assets/Cashmanagement.jpg"),
            icon: "cash-coin",
        },
        {
            name: "Order information",
            value: 3,
            url: require("@/assets/Orderinformation.jpg"),
            icon: "box-seam",
        },
        {
            name: "Data usage",
            value: 4,
            url: require("@/assets/Datausage.jpg"),
            icon: "pie-chart",
        },
        {
            name: "Contract information",
            value: 5,
            url: require("@/assets/Contractinformation.jpg"),
            icon: "receipt-cutoff",
        },
        {
            name: "Fee information",
            value: 6,
            url: require("@/assets/Feeinformation.jpg"),
            icon: "list-check",
        },
        {
            name: "Recurring billing",
            value: 7,
            url: require("@/assets/recurringbilling.jpg"),
            icon: "calendar2-check",
        },
        {
            name: "Account and security",
            value: 10,
            url: require("@/assets/AccountAndSecurity.png"),
            icon: "calendar2-check",
        },
        {
            name: "Logout",
            value: 99,
            url: require("@/assets/LogOut.png"),
            icon: "calendar2-check",
        }
    ],
  },

    POINT_HISTORY: {
        DATETIME: "Date and time of usage",
        DIFFERENTIATION: "Differentiation",
        OPERATOR: "Operating point",
        STATUS: "Status",
        POINTOPERATE: "Operator",
        DEPICT: "Point detail",
        TITLE: "Point management",
        CURRENT_POINT: "Possession points",
        CHARGE_PRICE: "Point charge 1 point = 1 yen",
        ENTER_YOURSELF: "Point input",
        SUBMIT: "Charge",
        TABLE_TITLE:'Point history',
        MESSAGE:"View ways to accumulate and use points",
        NOTICE: {
            TEXT1: "Please enter the correct points.",
            TEXT2: "1 point = 1 yen",
            TEXT3: "1000 units",
            TEXT4: "Minimum Charge Points: 5000, Maximum Charge Points: 100000",
            TEXT5: "Charge points cannot be refunded for any reason.",
            IMPORTANT1:"*For more information",
            IMPORTANT2: "Please confirm.",
            HIGHLIGHT1: "IF IF Point Usage Rules",
            HIGHLIGHT2: "Click here for payment method",
        },
        POINT_TYPE: [
          { value: '0', text: 'IntroductionGET' },
          { value: '1', text: 'Consumption GET' },
          { value: '2', text: 'SetGET' },
          { value: '3', text: 'Self-charge' },
          { value: '4', text: 'Administrator-charge' },
          { value: '5', text: 'Monthly usage' },
          { value: '6', text: 'Service usage' },
          { value: '7', text: 'Cash exchange' },
        ],
        POINT_STATUS: [
          { value: '01', text: 'Confirming' },
          { value: '02', text: 'Confirmed' },
          { value: '03', text: 'confirm refushed' },
          { value: '04', text: 'Charged' },
          { value: '05', text: 'Exchanged' },
        ],
        POINT_DETAIL: [
          { value: '0', text: 'Japan bank account' },
          { value: '1', text: 'BarcodePAY' },
        ],
    },

    SLIDE: [
        {
            url: require("@/assets/slide1.jpg"),
        },
        {
            url: require("@/assets/slide2.jpg"),
        },
        {
            url: require("@/assets/slide3.jpg"),
        },
        ],

    RENT_WIFI : {
        TITLE: "Wi-Fi equipment rental",
        NOTICE: "Please select your desired plan and proceed",
        NECESSARY_TITLE1: "Select your desired plan",
        NECESSARY_TITLE2: "Please proceed",
        NECESSARY_TITLE3: "Rental start date",
        OPTION:[
          { "name": "1 night 2 days plan", "price": "1180" },
          { "name": "2 nights 3 days plan", "price": "1770" },
          { "name": "3 nights 4 days plan", "price": "2360" },
          { "name": "4 nights 5 days plan", "price": "2950" },
          { "name": "5 nights 6 days plan", "price": "3540" },
          { "name": "6 nights 7 days plan", "price": "4130" },
          { "name": "7 nights 8 days plan", "price": "4720" },
          { "name": "8 nights 9 days plan", "price": "5310" },
          { "name": "9 nights 10 days plan", "price": "5900" },
          { "name": "10 nights 11 days plan", "price": "6490" },
          { "name": "11 nights 12 days plan", "price": "7080" },
          { "name": "12 nights 13 days plan", "price": "7670" },
          { "name": "13 nights 14 days plan", "price": "8260" },
          { "name": "1 month pack", "price": "8850", "date": "(15 days ~ 31 days)" },
          { "name": "2 months pack", "price": "15750", "date": "(60 nights 61 days)" },
          { "name": "3 months pack", "price": "22000", "date": "(90 nights 91 days)" },
          { "name": "4 months pack", "price": "27600", "date": "(120 nights 121 days)" },
          { "name": "5 months pack", "price": "30600", "date": "(150 nights 151 days)" },
          { "name": "6 months pack", "price": "33600", "date": "(180 nights 181 days)" }
      ],
    },

    TERMS: {
        SERVICE_CHARGE: "Universal service charge",
    },

    ACCOUNT_SECURITY:{
        TITLE: "Change password",
        NOTICE:"* Please fill in the required fields,",
        CURRENT_PASSWORD:"Current password *",
        NEW_PASSWORD: "New password *",
        RE_ENTER_PASSWORD: "New password (confirm) * ",
        CONFIRMATION_CODE: "Authentication code",
        SAVE_CHANGES: "Save changes",
        MESSAGE1: "The old password is wrong",
        MESSAGE2: "New password is the same as old one",
    },

    PLAN:{
      PL1: "nights",
      PL2: "Day",
      PL3: "months",
      PL45: "SIM Free iPhone8[64GB]",
      PL46: "[Used good, near mint condition]",
      PL47: "Base price 20,000 yen",
      PL48: "(Tax incl. 22,000 yen)",
      PL49: "SIM free iPhone13[128GB]",
      PL50: "New goods",
      PL51: "Base price 98,000 yen",
      PL52: "(Tax incl. 107,800 yen)",
      PL53: "Pack",
      PL54: "Voice",
      PL55: "Mobile",
      PL56: "Restaurants, travel, daily services",
      PL57: "Up to 50% off",
      PL58: "iPhone/Android (new, used), IoT devices",
      PL59: "Member discounts/Installment payments",
      OPTION1: [
        { "price": "20 yen/ 30 seconds", "text": "Call fee" },
        { "price": "500 yen/GB", "text": "Additional charge" },
        { "price": "3 yen/message", "text": "Domestic SMS" },
        { "price": "100 yen/message", "text": "Internation SMS" },
      ],
      OPTION2: [
        { "text": "Unlimited call within 5 minutes", "price": "500 yen" },
        { "text": "Unlimited call within 10 minutes", "price": "850 yen" },
        { "text": "Answering machine ", "price": "350 yen" },
        { "text": "Catchphone", "price": "250 yen" },
      ],
  },

  HEADER:{
    HD1: "Member registration",
    HD2: "Payment・Plan",
    HD3: "MNP",
    HD4: "Initial setting",
    HD5: "Payment method",
    HD6: "Bulk purchase",
    HD7: "Rental",
    HD8: "Payment",
    HD9: "Referaal system",
    HD10: "benefit",
    HD11: "Partner service",
    HD12: "Q＆A",
    HD13: "SNS",
    HD14: "日本語",
    HD15: "中国",
    HD16: "English",
    HD17: "Tiếng Việt",
    MESSAGE: "Do you want to log out?",
    YES: "Agree",
  },

  GENERAL:{
    TEXT1: "Service number",
    TEXT2: "Application content",
    TEXT3: "Usage start date",
    TEXT4: "Number of users",
    TEXT5: "Planned amount Yen",
    TEXT6: "Yen",
    LINK:"Service・Device",
  },

};
