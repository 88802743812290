<template>
  <div class="header">
    <!-- Mob -->
    <div v-if="$store.state.isMobile">
      <div class="box_2 flex-row">
        <div class="flex-row" @click="$router.replace('/home')">
          <div class="icon_1 flex-col"></div>
          <!-- <div class="group_2 flex-col"></div> -->
        </div>

        <div style="margin: auto">
          <button
            v-if="!isLogin"
            class="button_1 flex-col"
            @click="goChild(newMenuItem[4].number, newMenuItem[4], $event)"
          >
            <span class="text_2">{{ newMenuItem[4].name }}</span>
          </button>
          <button
            v-else
            class="button_1 flex-col"
            @click="showPageMenu = !showPageMenu"
            v-click-outside="handleClickOutsideM1"
          >
            <span class="text_2">{{ newMenuItem[4].name }}</span>
          </button>
        </div>

        <!-- <img 
        v-b-modal.modal-1
        class="icon_2"
        
      /> -->

        <b-modal id="modal-1" title="" size="sm" :centered="true" hide-footer>
          <b-form-group label="" v-slot="{ ariaDescribedby }">
            <div
              @click="changeLang(itemLang.value, indexLang)"
              :key="indexLang"
              v-for="(itemLang, indexLang) in languageItem"
            >
              <b-form-radio
                v-model="languageItem[indexLanguage].name"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="itemLang.name"
              >
                {{ itemLang.name }}
              </b-form-radio>
            </div>
          </b-form-group>
        </b-modal>

        <div v-click-outside="handleClickOutsideM2">
          <img
            v-b-toggle.my-collapse
            @click="list = !list"
            class="icon_3"
            :src="list ? require('@/assets/caidan.png') : require('@/assets/chacha.png')"
          />
          <b-collapse
            v-model="visible"
            class="new_menu__menu__container"
            id="my-collapse"
          >
            <template v-for="(item, index) in newMenuItem">
              <!-- 🐔一级跳转 login -->
              <li
                v-if="index != 4 && item.children.length == 0"
                class="new_menu__menu__container__item"
                :key="index"
                v-b-toggle="`no-accordion-${index}`"
                @click="() => {
                  if (item.children.length == 0) {
                    $router.push(item.url);
                    visible = false;
                    list = true;
                  }
                }"
              >
                <span :class="{'login-item': item.url == '/login'}" @click="">{{ item.name }} </span>
                <img
                  style="transform: rotate(90deg);"
                  class="thumbnail_3"
                  src="@/assets/lxl/xiangshagn_o.png"
                />
              </li>
              <!-- 🎧二级跳转 -->
              <li
                v-else-if="index != 4"
                class="new_menu__menu__container__item"
                :key="index"
              >
                <span class="" 
                  @click="() => {
                    $router.push(item.url);
                    visible = false;
                    list = true;
                  }"
                >{{ item.name }} </span>
                <img
                  v-b-toggle="`accordion-${index}`"
                  @click="() => {
                    list = false; 
                    visible = true;
                  }"
                  style="transform: rotate(180deg);"
                  class="thumbnail_3"
                  src="@/assets/lxl/xiangshagn_o.png"
                />
              </li>
              <b-collapse
                v-if="index != 4"
                :id="`accordion-${index}`"
                class="mb-child"
                accordion="my-accordion"
                role="tabpanel"
              >
                <div
                  class="mb-child__item"
                  v-for="(itemChild, indexChild) in item.children"
                  :key="indexChild"
                  @click="
                    () => {
                      $router.push(itemChild.path);
                      visible = false;
                      list = true;
                    }
                  "
                >
                  {{ itemChild.name }}
                </div>
              </b-collapse>
            </template>

            <!-- <li
              class="new_menu__menu__container__item"
              v-b-toggle="`accordion-${4}`"
            >
              <a @click="goChild(item.number, item, $event)">
                <span @click="showLang = !showLang" class="">{{
                  languageItem[indexLanguage].name
                }}</span>
              </a>
              <img
                style="transform: rotate(180deg);"
                class="thumbnail_3"
                src="@/assets/lxl/xiangshagn_o.png"
              />
            </li>
            <b-collapse
              :id="`accordion-${4}`"
              class="mb-child"
              accordion="my-accordion"
              role="tabpanel"
            >
              <template v-for="(itemLang, indexLang) in languageItem">
                <div
                  class="mb-child__item"
                  v-if="indexLang != indexLanguage"
                  :key="indexLang"
                  @click="changeLang(itemLang.value, indexLang)"
                >
                  {{ itemLang.name }}
                </div>
              </template>
            </b-collapse> -->
            <div style="padding-bottom: 3vw"></div>
          </b-collapse>
        </div>
        

        <template v-if="isLogin">
          <div v-show="showPageMenu" class="menu-x">
            <Menu :viewsPage="viewsPage"></Menu>
          </div>
        </template>
      </div>

    </div>
    <!-- PC -->
    <div v-else class="section_1 flex-col justify-end">
      <div class="group_1 flex-row">
        <div class="section_2 flex-row" @click="$router.push('/home')"></div>
        <!-- nav -->
        <div class="section_4 flex-row">
          <template v-for="(item, index) in newMenuItem">
            <div
              v-if="index != 4"
              class="text_1"
              :class="[index == newMenuItem.length - 1 ? 'text-login' : '']"
              @click="goChild(item.number, item, $event)"
              @mouseenter="showChildMeun(index)"
              @mouseleave="handleClickOutside"
            >
              <span class="text_1_t">{{ item.name }} </span>
              <ul
                class="text_1_ul"
                v-show="index == curNavIndex"
              >
                <li
                  v-for="(jtem, jndex) in item.children"
                  :key="`${index}-${jndex}`"
                  @click.stop="$router.push(jtem.path)"
                >
                  {{ jtem.name }}
                </li>
              </ul>
            </div>
          </template>
        </div>
        <!-- login -->
        <div
          v-if="!isLogin"
          class="text-wrapper_1 flex-col"
          @click="goChild(newMenuItem[4].number, newMenuItem[4], $event)"
        >
          <span class="text_8">{{ newMenuItem[4].name }}</span>
        </div>
        <div v-else 
          v-b-hover="hoverHandler" 
          style="position: relative">
          <div v-click-outside="() => {showPageMenu = false}">
            <div
              class="text-wrapper_1 flex-col"
              @click="showPageMenu = !showPageMenu"
            >
              <span class="text_8">{{ newMenuItem[4].name }}</span>
            </div>
            <Menu v-show="showPageMenu" :viewsPage="viewsPage"></Menu>
          </div>
          
          <!-- <b-dropdown offset="-50" ref="dropdown-1" class="my-drop" text="align" variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              
            </template>
            
          </b-dropdown> -->
        </div>
        <!-- language -->
        <img class="thumbnail_1" :src="languageItem[indexLanguage].url" />
        <b-dropdown
          id="my-dropdown"
          size="lg"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          style="position: absolute; right: 10px; bottom: 0"
        >
          <template #button-content></template>
          <div :key="indexLang" v-for="(itemLang, indexLang) in languageItem">
            <b-dropdown-item
              href="#"
              v-if="indexLang != indexLanguage"
              @click="changeLang(itemLang.value, indexLang)"
              >{{ itemLang.name }}</b-dropdown-item
            >
          </div>
        </b-dropdown>
        <!-- <label for="my-dropdown__BV_toggle_" class="flex-row"> -->
        <label for="" class="flex-row">
          <span class="text_9" style="font-size: 1.04vw">{{
            languageItem[indexLanguage].name
          }}</span>
        </label>
      </div>
      <img
        class="image_1"
        src="@/assets/lxl/SketchPng24e8588f3f33ef81331ba68e67d43c0174d4f929f9683a226c7073b0e834e9a0.png"
      />
    </div>
  </div>
</template>

<script>
import { getList } from "./menu.js";
import Menu from "../Menu/index.vue";

export default {
  components: { Menu },
  data() {
    return {
      visible: false,
      showPageMenu: false,
      isLogin: false,
      list: true,
      step: null,
      main_item: [
        {
          name: this.$t("HEADER.HD1"),
          value: 9,
          url: "/register",
          type: 1,
          number: 9,
          children: [],
        },
        {
          name: this.$t("MENU.MYPAGE"),
          value: 2,
          url: "/mypage",
          type: 1,
          number: 10,
          children: [],
        },
      ],
      changeHeader: true,
      activeItem: 0,
      newMenuItem: [],
      indexLanguage: 0,
      languageItem: [
        {
          name: this.$t("HEADER.HD14"),
          value: "ja",
          url: require("@/assets/lxl/flag-jp.png"),
        },
        {
          name: this.$t("HEADER.HD15"),
          value: "cn",
          url: require("@/assets/lxl/flag-zh.png"),
        },
        {
          name: this.$t("HEADER.HD16"),
          value: "en",
          url: require("@/assets/lxl/flag-en.png"),
        },
        {
          name: this.$t("HEADER.HD17"),
          value: "vi",
          url: require("@/assets/lxl/flag-tg.png"),
        },
      ],
      curNavIndex: -1,
      showLang: false,
      childList: [],
      iconplus: require("@/assets/lxl/SketchPng4e547370183efdd50209f88a14ab7f53ab0bed4bf9750995a24797edc9a7c7c9.png"),
      iconredu: require("@/assets/lxl/SketchPngc38b584043a42ea8385f90f97c806fadf783800b647605f3de016ed2bf9caee6.png"),
    };
  },

  computed: {
    viewsPage() {
      return this.$route.query?.view || "";
    },
  },
  created() {
    // this.viewsPage = this.$route.query?.view || ''
    if (this.$localStorage.get("menuItemHeader")) {
      this.activeItem = this.$localStorage.get("menuItemHeader");
    }
    this.checkLogin();

    for (const key in this.languageItem) {
      if (this.languageItem[key].value == this.$localStorage.get("language")) {
        this.indexLanguage = key;
      }
    }
    console.log('this.indexLanguage',  this.indexLanguage)
  },
  mounted() {
    this.lastScrollPosition = window.pageYOffset;
    window.addEventListener("scroll", this.onScroll);
    setTimeout(() => {
      try {
        this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
          if (!this.getElementByAriaControls(collapseId)) return
          let img = this.getElementByAriaControls(collapseId);
          if (img && img.tagName.toLowerCase() !== "img") return;

          // 获取img元素的src属性值
          let srcBase64 = img.getAttribute("src");

          if (
            srcBase64.indexOf("TlS3560EAAAAASUVORK5CYII=") > -1 ||
            srcBase64.indexOf("IBNDjBhMAAAAASUVORK5CYII=") > -1
          ) {
            this.replaceImageSrc(
              img,
              isJustShown ? this.iconplus : this.iconredu
            );
          } else {
            this.rotateImage180(img);
          }
        });
      } catch (error) {
        
      }
      
    }, 1000);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    this.$root.$off("bv::collapse::state");
  },
  methods: {
    
    handleClickOutsideM1 () {
      this.showPageMenu = false
    },

    handleClickOutsideM2 () {
      this.list = true;
      this.visible = false;
    },

    // 定义一个函数来获取属性值为aria-controls的元素
    getElementByAriaControls(value) {
      console.log('element.getElementsByTagName', value)
      // 使用CSS选择器和querySelector方法来获取匹配的元素
      if (!document.querySelector('[aria-controls="' + value + '"]')) return '';
      const element = document.querySelector('[aria-controls="' + value + '"]');
      if (element &&  element.tagName.toLowerCase() === "img") {
        return element;
      } else {
        return element.getElementsByTagName("img")[0];
      }
    },
    // 定义一个函数，用于将img元素旋转180度
    rotateImage180(img) {
      try {
        // Do something with the img element
        // console.log(img, img.style.transform)
        // 设置img元素的transform属性为旋转180度的样式
        // 获取img元素的旋转角度
        let angle = img.style.transform
          ? parseInt(
              img.style.transform.replace("rotate(", "").replace("deg)", "")
            )
          : 0;
        // 增加180度
        angle = angle == 180 ? 0 : 180
        // angle += 180;
        // 设置img元素的transform样式为rotate(angle)deg
        img.style.transform = `rotate(${angle}deg)`;
      } catch (error) {}
    },
    // 定义一个函数，用于动态替换img元素的src属性
    // 接受一个图片路径参数
    replaceImageSrc(img, newSrc) {
      try {
        // 设置img元素的src属性为新的图片路径
        img.setAttribute("src", newSrc);
      } catch (error) {}
    },
    showChildMeun(index) {
      // console.log(this.newMenuItem[index].children)
      const arrs = this.newMenuItem[index]?.children || [];
      this.curNavIndex = arrs.length > 0 ? index : -1;
    },
    handleClickOutside() {
      this.curNavIndex = -1;
    },
    onOver(element) {
      console.log("onOver");
      // this.$refs[element].visible = true;
    },
    onLeave(element) {
      // this.$refs[element].visible = false;
    },
    hoverHandler(isHovered) {
      if (isHovered) {
        // Do something
        this.onOver("dropdown-1");
      } else {
        // Do something else
        console.log("not");
        // this.onLeave('dropdown-1')
      }
    },
    changeLang(value, index) {
      console.log(111, value, index);
      this.indexLanguage = index;
      this.showLang = false;
      this.$localStorage.set("language", value);
      console.log(this.$route.path);
      location.reload();
      // this.$router.go(0)
    },
    goChild(number, item, event) {
      console.log(number, item, event);
      if (this.step != number) {
        this.step = number;
      } else {
        this.step = null;
      }
      if (event.type == "click") {
        if (item.children.length == 0) {
          this.goRouter(item);
        }
        this.$router.push(item.url);
      }
    },
    checkLogin() {
      // setting routeList
      this.newMenuItem = getList(this);
      console.log(this.$localStorage.get("token"));
      if (this.$localStorage.get("token")) {
        this.newMenuItem[4] = this.main_item[1];
        this.newMenuItem.splice(9, 1);
        this.isLogin = true;
      } else {
        this.newMenuItem[4] = this.main_item[0];
        this.newMenuItem.splice(8, 1);
        this.isLogin = false;
      }
      console.log(this.newMenuItem);
    },
    goRouter: function (value, e) {
      let page = this.$localStorage.get("menuItemHeader");
      if (
        page ==
          this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.SIM_CARD_ONLY ||
        page ==
          this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.WIFI_ONLY ||
        page == this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.LIGHT_ONLY
      ) {
        if (
          (value.value != page &&
            value.value ==
              this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET
                .SIM_CARD_ONLY) ||
          (value.value != page &&
            value.value ==
              this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.WIFI_ONLY) ||
          (value.value != page &&
            value.value ==
              this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.LIGHT_ONLY)
        ) {
          this.$localStorage.remove("Answer");
        }
      }
      if (value.value === 7) {
        // this.$confirm({
        //   message: this.$t("HEADER.MESSAGE"),
        //   button: {
        //     no: this.$t("BUTTON.CANCEL"),
        //     yes: this.$t("HEADER.YES"),
        //   },
        //   /**
        //    * Callback Function
        //    * @param {Boolean} confirm
        //    */
        //   callback: (confirm) => {
        //     if (confirm) {
        //       this.logOut();
        //     }
        //   },
        // });
        this.logOut();
      } else {
        console.log("menuItemHeader", value.value);
        this.$localStorage.set("menuItemHeader", value.value);
        this.activeItem = value.value;
        this.$router.push({
          path: value.url,
        });
        // if(value.value == 2) {
        //   console.log(this.$route.path)
        //   // location.reload();
        // }
      }
    },
    logOut: function () {
      var token = localStorage.getItem("token");
      if (token) {
        if (this.$CONST.DEV) {
          console.log("Debug API: userLogin/login");
          const items = [
            "token",
            "accountBy",
            "name",
            "nameKana",
            "roleId",
            "userId",
            "points",
            "email",
            "userIdCode",
            "registerUrl",
          ];
          items.forEach((v) => localStorage.removeItem(v));
          sessionStorage.setItem("loginComplete", 1);
        } else {
          this.$api.post("userLogin/logout").then((response) => {
            const items = [
              "token",
              "accountBy",
              "name",
              "nameKana",
              "roleId",
              "userId",
              "email",
              "userIdCode",
              "registerUrl",
            ];
            items.forEach((v) => localStorage.removeItem(v));
            sessionStorage.removeItem("loginComplete");
            sessionStorage.removeItem("clickToLogin");
            this.$router.push("/login");
          });
        }
      }
    },
    // Toggle if navigation is shown or hidden
    onScroll() {
      if (window.pageYOffset < 0) {
        return;
      }
      this.changeHeader = window.pageYOffset < 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>
