<template>
  <vueper-slides :dragging-distance="50">
    <vueper-slide
      class="test"
      v-for="(slide, i) in slides"
      :key="i"
      :image="slide.image"
      :title="slide.title"
      :content="slide.content"
      :link="slide.link"
    />
  </vueper-slides>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  components: { VueperSlides, VueperSlide },

  data() {
    return {
      slides: [
        {
          title: "全力、留学生たちの日本での生活を支援",
          content: "全力、留学生たちの日本での生活を支援",
          image: require("@/assets/slide1.jpg"),
        },
        {
          title: "Slide #2",
          content: "Slide 2 content.",
          image: require("@/assets/slide2.jpg"),
        },
        {
          title: "Slide #3",
          content: "Slide 3 content.",
          image: require("@/assets/slide3.jpg"),
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.test {
  font-size: 5em;
  color: rgba(165, 147, 147, 0.63);
}
</style>
