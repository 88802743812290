<template>
  <div class="cash">
    <div class="content__card">
      <div class="title-x">
        <img
          class="image_2"
          src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
        />
        <h1 class="text_17">
          {{ $t("CASH.GOLD_EXCHANGE_RELUES") }}
        </h1>
      </div>
      <div class="content__card__content text_1">
        {{ $t("CASH.REDEEMABLE_POINTS") }}
        <span class="">{{ pointsNew }} P</span>
      </div>
      <el-alert
        style="margin-top: 10px; padding-left: 5px; padding-right: 5px"
        :closable="false"
        type="warning"
        :show-icon="false"
      >
        <div>
          <!-- <div>{{ $t('CASH.NOTE_1') }}</div> -->
          <!-- <div>{{ $t('CASH.NOTE_2') }}</div> -->
          <div>
            <text-more
              :length="$store.state.isMobile ? 18 : 32"
              flag="※"
              style="width: 100%"
            >
              1、10000ポイント単位からの交換です。 (1ポイント＝1円)
              ※※2、口座振込（手数料はお客様負担）、各種コード決済(Paypay、PayPal、Alipay、WechatPay)、店舗来店で交換可能です。
              ※※3、25日～月末にマイページから換金申請で翌月28日～月末に付与されます。
            </text-more>
          </div>
        </div>
      </el-alert>
    </div>

    <template v-if="pointsNew > 0">
      <div class="content__card">
        <div class="title-x">
          <img
            class="image_2"
            src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
          />
          <div class="text_17 flex-row justify-between align-center">
            {{ $t("CASH.NUMBER_OF_REDEMPTION_POINTS") }}
          </div>
        </div>
        <div>
          <div class="content__card__content__check" v-if="havePoint">
            <div class="content__card__content__check__title">
              <label class="content__card__content__text" for="sb-large">{{
                $t("CASH.REDEMPTION_POINTS")
              }}</label>
              <el-input-number
                id="sb-large"
                v-model="exchangePoint"
                :min="10000"
                :step="10000"
                label="P"
              >
              </el-input-number>
            </div>
            <div>
              <label class="content__card__content__text">
                <p>
                  {{ $t("CASH.AMOUNTS") }}
                  {{ this.exchangePoint * this.exchangeMoney }}
                  {{ $t("CASH.YEN") }}
                </p>
              </label>
            </div>
          </div>
        </div>
        <div class="cash_btn" v-if="havePoint">
          <label class="radio-layout mr-2">
            <input
              type="radio"
              name="group1"
              v-model="result.exchangeDepict"
              value="0"
            />
            <div class="btn-radio-layout">{{ $t("CASH.BTN_BANK") }}</div>
          </label>
          <label class="radio-layout">
            <input
              type="radio"
              name="group1"
              v-model="result.exchangeDepict"
              value="1"
            />
            <div class="btn-radio-layout">{{ $t("CASH.BTN_PAY") }}</div>
          </label>
        </div>
      </div>
      <div v-if="havePoint && result.exchangeDepict == 0" class="content__card">
        <div>
          <div class="title-x">
            <img
              class="image_2"
              src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
            />
            <h1 class="text_17">
              {{ $t("CASH.JAPAN_BANK") }}
            </h1>
          </div>
          <div>
            <p class="content__card__content__text">
              {{ $t("CASH.BANK_NAME") }}
            </p>
            <b-form-input
              v-model="result.exchangeBank"
              :state="exchangeBank"
              trim
            ></b-form-input>
          </div>
          <div v-if="alert" class="validation-message">
            <span
              v-if="result.exchangeBank == null || result.exchangeBank == ''"
              >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
            >
          </div>
          <div>
            <p class="content__card__content__text">
              {{ $t("CASH.BRANCH_NAME") }}
            </p>
            <b-form-input
              v-model="result.exchangeBranch"
              :state="exchangeBranch"
              trim
            ></b-form-input>
          </div>
          <div v-if="alert" class="validation-message">
            <span
              v-if="
                result.exchangeBranch == null || result.exchangeBranch == ''
              "
              >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
            >
          </div>
          <div>
            <p class="content__card__content__text">
              {{ $t("CASH.BRANCH_NUMBER") }}
            </p>
            <b-form-input
              v-model="result.exchangeBranchNumber"
              :state="exchangeBranchNumber"
              trim
            ></b-form-input>
          </div>
          <div v-if="alert" class="validation-message">
            <span
              v-if="
                result.exchangeBranchNumber == null ||
                result.exchangeBranchNumber == ''
              "
              >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
            >
          </div>
          <div>
            <p class="content__card__content__text">
              {{ $t("CASH.ACCOUNT_NUMBER") }}
            </p>
            <b-form-input
              v-model="result.exchangeAccount"
              :state="exchangeAccount"
              trim
            ></b-form-input>
          </div>
          <div v-if="alert" class="validation-message">
            <span
              v-if="
                result.exchangeAccount == null || result.exchangeAccount == ''
              "
              >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
            >
          </div>
          <div>
            <p class="content__card__content__text">
              {{ $t("CASH.ACCOUNT_HOLDER") }}
            </p>
            <b-form-input
              v-model="result.exchangeName"
              :state="exchangeName"
              trim
            ></b-form-input>
          </div>
          <div v-if="alert" class="validation-message">
            <span
              v-if="result.exchangeName == null || result.exchangeName == ''"
              >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
            >
          </div>
          <div>
            <p class="content__card__content__text">
              {{ $t("CASH.TELEPHONE_NUMBER") }}
            </p>
            <b-form-input
              v-model="result.phoneNumber"
              :state="phoneNumber"
              trim
            ></b-form-input>
          </div>
          <div v-if="alert" class="validation-message">
            <span
              v-if="result.phoneNumber == null || result.phoneNumber == ''"
              >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
            >
          </div>
        </div>
        <el-alert
          style="margin-top: 10px"
          :title="$t('CASH.NOTICE')"
          :closable="false"
          type="info"
          show-icon
        >
        </el-alert>
        <div class="cash_btn">
          <b-button
            @click="cashOK"
            class="content__card__btn"
            variant="success my-3"
            >{{ $t("CASH.BTN_SURE") }}</b-button
          >
        </div>
        <!-- <div class="border p-2 cash_btn">{{ $t("CASH.NOTICE") }}</div> -->
      </div>
      <b-button
        v-if="havePoint && result.exchangeDepict == 1"
        @click="cashOK"
        class="content__card__btn"
        variant="success my-3"
        >{{ $t("CASH.BTN_SURE") }}</b-button
      >
    </template>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isClicked: false,
      alert: false,
      havePoint: true,
      payBank: 99,
      points: 0,
      exchangePoint: 10000,
      exchangeMoney: 0,
      result: {
        exchangeDepict: 0,
      },
    };
  },
  created() {
    this.getPoint();
    if (this.$CONST.DEV) {
      console.log("Debug API: Cash/init");
      this.result = this.$CONST.COMMON.API_CASH.Response.result;
      this.$localStorage.set("points", this.result.userPoint);
      this.exchangeMoney = this.result.money / 10000;
      this.checkPoint();
    } else {
      this.$api.post("Cash/init").then((response) => {
        this.result = response.result;
        this.exchangeMoney = response.result.money / 10000;
        this.checkPoint();
      });
    }
  },
  computed: {
    pointsNew() {
      if (this.points >= 10000) {
        return this.points - this.exchangePoint;
      } else return this.points;
    },

    exchangeBank() {
      this.showErr = false;
      if (this.isClicked) {
        return this.result.exchangeBank == null ||
          this.result.exchangeBank == ""
          ? false
          : true;
      }
    },
    exchangeAccount() {
      this.showErr = false;
      if (this.isClicked) {
        return this.result.exchangeAccount == null ||
          this.result.exchangeAccount == ""
          ? false
          : true;
      }
    },
    exchangeName() {
      this.showErr = false;
      if (this.isClicked) {
        return this.result.exchangeName == null ||
          this.result.exchangeName == ""
          ? false
          : true;
      }
    },
    exchangeBranch() {
      this.showErr = false;
      if (this.isClicked) {
        return this.result.exchangeBranch == null ||
          this.result.exchangeBranch == ""
          ? false
          : true;
      }
    },
    exchangeBranchNumber() {
      this.showErr = false;
      if (this.isClicked) {
        return this.result.exchangeBranchNumber == null ||
          this.result.exchangeBranchNumber == ""
          ? false
          : true;
      }
    },
    phoneNumber() {
      this.showErr = false;
      if (this.isClicked) {
        return this.result.phoneNumber == null || this.result.phoneNumber == ""
          ? false
          : true;
      }
    },
  },
  methods: {
    async getPoint() {
      await this.$api.get("pointCharge/showPoint").then((response) => {
        if (response.success) {
          this.points = response.result.userPoint;
        }
      });
    },
    checkPoint() {
      if (this.points < 10000) {
      } else {
        this.havePoint = true;
      }
    },
    cashOK() {
      this.isClicked = true;
      let payload = {
        exchangeAccount:
          this.result.exchangeDepict == 0 ? this.result.exchangeAccount : "",
        exchangeBank:
          this.result.exchangeDepict == 0 ? this.result.exchangeBank : "",
        exchangeBranch:
          this.result.exchangeDepict == 0 ? this.result.exchangeBranch : "",
        exchangeBranchNumber:
          this.result.exchangeDepict == 0
            ? this.result.exchangeBranchNumber
            : "",
        exchangeName:
          this.result.exchangeDepict == 0 ? this.result.exchangeName : "",
        money: this.exchangePoint * this.exchangeMoney,
        payWay: this.result.exchangeDepict,
        phoneNumber:
          this.result.exchangeDepict == 0 ? this.result.phoneNumber : "",
        userPoint: this.exchangePoint,
      };

      var payWay = this.result.exchangeDepict == 1 ? true : this.validate();
      if (payWay) {
        if (this.$CONST.DEV) {
          console.log("Debug API: Cash/OK");
          console.log(payload);
          this.$localStorage.set("pageBack", "/mypage");
          this.$router.push({ path: "/success", query: { view: "9" } });
        } else {
          this.$api.post("Cash/exchange", payload).then((response) => {
            if (response.success) {
              this.$localStorage.set("pageBack", "/mypage");
              this.$router.push({
                path: "/success",
                query: { view: "9", suc: "6" },
              });
            }
          });
        }
      }
    },
    validate() {
      if (
        this.result.exchangeAccount == "" ||
        this.result.exchangeAccount == null ||
        this.result.exchangeBank == "" ||
        this.result.exchangeBank == null ||
        this.result.exchangeBranch == "" ||
        this.result.exchangeBranch == null ||
        this.result.exchangeBranchNumber == "" ||
        this.result.exchangeBranchNumber == null ||
        this.result.exchangeName == "" ||
        this.result.exchangeName == null ||
        this.result.phoneNumber == "" ||
        this.result.phoneNumber == null
      ) {
        this.alert = true;
        return false;
      } else {
        this.alert = false;
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.validation-message {
  display: flex;
  margin: 1% 0%;
}

.btn-submit {
  color: #fff;
  background-color: var(--newColor);
  border-color: var(--newColor);
}

.btn-danger {
  color: #fff;
  background-color: var(--newColor);
  border-color: var(--newColor);
}

.btn-submit:hover {
  color: #fff;
  background-color: var(--success);
  border-color: var(--success);
}

.btn-danger:hover {
  color: #fff;
  background-color: var(--success);
  border-color: var(--success);
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>
