<template>
  <section class="ftco-section">
    <div class="title">
      <h4 class="plan__content">{{ $t("INDEX.INTRODUCTION.CONTENT") }}</h4>
      <h3 class="plan__title">{{ $t("INDEX.INTRODUCTION.TITLE") }}</h3>
      <p class="plan__text">{{ $t("INDEX.INTRODUCTION.TEXT") }}</p>
    </div>
    <carousel-3d :width="430" :height="640">
      <slide :index="0">
        <label class="card" for="item-1" id="sim-1">
            <div class="card-body">
              <h3 class="card-title">{{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD1.TITLE") }}</h3>
              <img src="@/assets/simqt.png" alt="sim">
              <div class="content">
                <h5>{{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD1.TEXT1") }}</h5>
                <p>{{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD1.TEXT2") }}</p>
                <div class="button-link">
                  <b-button class="btn-main btn-style ml-2 mr-2" >
                    {{ $t("INDEX.INTRODUCTION.BTN") }}
                  </b-button>
                  <b-button class="btn-main btn-style ml-2 mr-2" @click="goRouter($CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.SIM_CARD_ONLY)">
                    {{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD1.BTN2") }}
                  </b-button>
                </div>
              </div>
            </div>
        </label>
      </slide>
      <slide :index="1">
        <label class="card" for="item-2" id="sim-2">
          <div class="card-body">
              <h3 class="card-title">{{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD2.TITLE") }}</h3>
              <img src="@/assets/wifi.gif" alt="sim">
              <div class="content">
                <p>{{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD2.TEXT1") }}</p>
                <p>{{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD2.TEXT2") }}</p>
                <p>{{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD2.TEXT3") }}</p>
                <div class="button-link">
                  <b-button class="btn-main btn-style ml-2 mr-2" >
                    {{ $t("INDEX.INTRODUCTION.BTN") }}
                  </b-button>
                  <b-button class="btn-main btn-style ml-2 mr-2"  @click="goRouter($CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.WIFI_ONLY)">
                    {{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD2.BTN2") }}
                  </b-button>
                </div>
              </div>
            </div>
        </label>
      </slide>
      <slide :index="2">
        <label class="card" for="item-3" id="sim-3">
          <div class="card-body">
              <h3 class="card-title">{{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD3.TITLE") }}</h3>
              <img src="@/assets/otp.png" alt="sim">
              <div class="content">
                <p>{{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD3.TEXT1") }}</p>
                <p>{{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD3.TEXT2") }}</p>
                <p>{{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD3.TEXT3") }}</p>
                <div class="button-link">
                  <b-button class="btn-main btn-style ml-2 mr-2" >
                    {{ $t("INDEX.INTRODUCTION.BTN") }}
                  </b-button>
                  <b-button class="btn-main btn-style ml-2 mr-2" @click="goRouter($CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.LIGHT_ONLY)">
                    {{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD3.BTN2") }}
                  </b-button>
                </div>
              </div>
            </div>
        </label>
      </slide>
      <slide :index="3">
        <label class="card" for="item-4" id="sim-4">
          <div class="card-body">
              <h3 class="card-title">{{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD4.TITLE") }}</h3>
              <img src="@/assets/ano.png" alt="sim">
              <div class="content">
                <p>{{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD4.TEXT1") }}</p>
                <p>{{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD4.TEXT2") }}</p>
                <p>{{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD4.TEXT3") }}</p>
                <div class="button-link">
                  <b-button class="btn-main btn-style ml-2 mr-2" >
                    {{ $t("INDEX.INTRODUCTION.BTN") }}
                  </b-button>
                  <b-button class="btn-main btn-style ml-2 mr-2" @click="goRouter($CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.GENERAL_MERCHANDISE)">
                    {{ $t("INDEX.INTRODUCTION.INTRODUCTION_CARD.CARD4.BTN2") }}
                  </b-button>
                </div>
              </div>
            </div>
        </label>
      </slide>
    </carousel-3d>
  </section>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';
export default {
  components: {
    Carousel3d,
    Slide
  },
  data() {
    return {};
  },
  methods: {
    goRouter(value) {
      this.toTop();
      this.$localStorage.set("menuItemHeader", value);
      this.$router.push("/order");
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  }
}
</script>

<style lang="scss" scoped>
input[type=radio] {
  display: none;
}

.plan__text {
  letter-spacing: normal;
  line-height: 24px;
  font-weight: 400;
  max-width: 650px;
  margin: auto;
  padding-bottom: 3%;
}

.plan__title {
  font-size: 50px;
  font-weight: 600;
  margin-top: 10px;
  letter-spacing: -1px;
  padding: 20px;
}

.plan__content {
  color: var(--success) !important;
}

.content {
  margin: 10px 0;
}

.button-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn-style {
  color: #fff;
  background-color: #000000;
  margin: 2% 0;
  font-size: 100%;
  width: 235px;
}

.btn-style:focus {
  color: #fff;
}

.card {
  position: absolute;
  width: 430px;
  height: 650px;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform .4s ease;
  cursor: pointer;
  text-align: center;
}

.cards {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

@media (max-width: 1200px) {
  .card {
    position: absolute;
    width: 300px;
    height: 565px;
    left: 0;
    right: 0;
    margin: auto;
    transition: transform .4s ease;
    cursor: pointer;
  }

  .content {
    font-size: 12px;
  }

  .btn-style {
    width: 200px;
  }
  .group-future {
    height: 100vh !important;
  }
}

@media (max-width: 600px) {
  .plan {
    &__title {
      font-size: 36px;
    }

    &__text {
      margin: 0 18px;
    }
  }
  .card {
    position: absolute;
    width: 230px;
    height: 550px;
    left: 0;
    right: 0;
    margin: auto;
    transition: transform .4s ease;
    cursor: pointer;
  }

  .content {
    font-size: 10px;
  }

  .button-link {
    font-size: 8px;
  }

  .btn-style {
    width: 150px;
    height: 20px;
  }
}
</style>
