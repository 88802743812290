<template>
  <!-- <div class="if-slide">
        <div v-for=" (slide,index) in slides" :key="index">
          <CardImage :title="slide.title" :texts="slide.texts" :img="slide.img" />
        </div> 
  </div>
  <div> -->
  <b-card no-body>
    <b-tabs content-class="if-groupcard" align="center">
      <b-tab class="slide" v-for=" (slide,index) in slides" :key="index" :title="slide.title">
          <!-- <CardImage class="if-card" :title="slide.title" :texts="slide.texts" :img="slide.img" /> -->
          <div class="cards">
            <div class="card_info">
              <h2 class="title">{{slide.title}}</h2>
              <p class="text" v-for=" (text) in slide.texts" :key="text">{{text}}</p>
            </div>
            <div class="card_image"><img :src="slide.img" alt=""></div>
          </div>
    </b-tab>
    </b-tabs>
  </b-card>

</template>

<script>
import CardImage from "@/components/common/CardImage.vue";
export default {
  components: { CardImage },

  data() {
    return {
      slides: [{
        title: "高品質",
        texts: ["安心なDOCOMO回線、", "高スピード（4G、5G対応）"],
        img: require('@/assets/img/slide/slide1.jpg')
      }, {
        title: "プラン多様",
        texts: ["ギガプラン1G～50Gまで選択多種、", "5分/10分かけ放題、遠隔サポート", "などのオプションサービスも用意"],
        img: require('@/assets/img/slide/slide2.jpg')
      }, {
        title: "アフターサービス充実",
        texts: ["支払い方法多様（国内＆国際対応）、", "専用個人ページで使用状況を随時に確認、", "多言語対応"],
        img: require('@/assets/img/slide/slide3.jpg')
      }]
    };
  },
};
</script>
<style lang="scss" scoped>
.if-groupcard{
   border: 0px ;
}
.nav-link {
  padding: 99px !important;
}
.card {
   border: none !important ;
   width: 100% !important;
}
.tabs {
  background-color: #F35F0D;
  padding-top: 20px;
}
.cards {
  display: flex;
}
.card_info {
  width: 50%;
    background-color: white;
    display: flex;
    /* align-self: center; */
    /* height: auto; */
    justify-content: center;
    flex-direction: column;
    .title {
      margin-bottom: 15px;
    }
}
.card_image {
  width: 50%;
}
.card_image img {
  width: 100%;
}
.a{
  color:#D70C19 !important
}
</style>
